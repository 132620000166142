import { AfterViewInit, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UIChart } from 'primeng/chart';
import { Subject, debounceTime, withLatestFrom } from 'rxjs';
import { SubSink } from 'subsink';

import { DashboardStore } from '../dashboardv2.store';

@Component({
  selector: 'app-pipeline',
  templateUrl: './pipeline.component.html',
  styleUrls: [
    '../dashboardv2.component.scss',
    './pipeline.component.scss',
  ],
})
export class PipelineComponent implements AfterViewInit, OnDestroy {

  @ViewChild(UIChart) chart!: UIChart;

  private resizeObserver!: ResizeObserver;

  private elementResized$ = new Subject<{ width: number, height: number }>();

  currentWidth: number | undefined;

  subs = new SubSink();

  pipeline$ = this.dashboardStore.pipelineViewModel$;

  constructor(
    private readonly dashboardStore: DashboardStore,
    private elementRef: ElementRef,
    private router: Router,
  ) {}

  ngAfterViewInit(): void {
    this.refreshChartOnWidthIncrease();
  }

  refreshChartOnWidthIncrease(): void {
    this.resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {

        const { width, height } = entry.contentRect;

        if (this.currentWidth === undefined) {
          this.currentWidth = width;
        }

        this.elementResized$.next({ width, height });
      }
    });

    this.resizeObserver.observe(this.elementRef.nativeElement);

    this.subs.sink = this.elementResized$.pipe(
      debounceTime(100),
      withLatestFrom(this.dashboardStore.pipelineViewModel$),
    ).subscribe(([ { width: newWidth }, pipelineState ]) => {

      const widthIncreased = newWidth > this.currentWidth;

      if (widthIncreased && !pipelineState.loading) {
        this.chart.refresh();
      }

      this.currentWidth = newWidth;
    });
  }

  ngOnDestroy(): void {
    this.resizeObserver.disconnect();
    this.subs.unsubscribe();
  }

  options = {
    plugins: {
      legend: {
        labels: {
          padding: 25,
        },
        position: 'bottom',
        align: 'start',
      },
      tooltip: {
        titleFont: {
          size: 14,
        },
        bodyFont: {
          size: 14,
        },
      },
    },
    onHover: (event: any, chartElement: any[]) => {
      event.native.target.style.cursor = chartElement.length ? 'pointer' : 'default';
    }
  };

  goToJobsPage(stageLabel: string) {
    this.router.navigate([ '/jobs' ], {
      queryParams: {
        stage: stageLabel.toLowerCase(),
        state: 'open',
      },
    });
  }
}
