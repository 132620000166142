import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { ButtonModule } from 'primeng/button';

type Direction = 'ASC' | 'DESC';

@Component({
  selector: 'app-sort-button',
  standalone: true,
  imports: [
    ButtonModule,
  ],
  templateUrl: './sort-button.component.html',
  styleUrl: './sort-button.component.scss'
})
export class SortButtonComponent implements OnChanges {

  @Input() currentSortBy: string;

  @Input() key: string;

  @Input() loading: boolean;

  @Output() sortByChanged = new EventEmitter<string>();

  delimiter = ' ';

  direction: Direction

  ngOnChanges(): void {
    if (this.currentKey === this.key) {
      this.direction = this.currentDirection;
    } else {
      this.direction = undefined;
    }
  }

  get currentKey() {
    if (!this.currentSortBy) { return; }
    return this.currentSortBy.split(this.delimiter)[0];
  }

  get currentDirection() {
    if (!this.currentSortBy) { return; }
    return this.currentSortBy.split(this.delimiter)[1] as Direction;
  }

  get icon() {
    if (this.direction === 'ASC') {
      return 'pi pi-sort-amount-up-alt'
    } else if (this.direction === 'DESC') {
      return 'pi pi-sort-amount-down';
    }
    return 'pi pi-sort-alt';
  }

  handleButtonClicked() {

    if (this.loading) { return; }

    const newDirection: typeof this.direction = this.direction === 'ASC' ? 'DESC' : 'ASC';

    const newSortBy = `${this.key} ${newDirection}`;

    this.sortByChanged.emit(newSortBy);
  }
}
