import { inject } from '@angular/core';
import { ApolloError } from '@apollo/client/core';

import { AssetService } from '@karve.it/features';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  BulkEditCalendarEventGQL,
  BulkEditCalendarEventMutationVariables,
  BulkEditCalendarEventOnScheduleGQL,
  CalendarEvent,
  FindGQL,
  FindQueryVariables,
  LockWindowGQL,
  LockWindowMutationVariables,
  MutationLockWindowArgs
} from 'graphql.generated';
import { ConfirmationService } from 'primeng/api';
import {
  catchError,
  filter,
  from,
  map,
  Observable,
  of,
  switchMap,
  withLatestFrom
} from 'rxjs';
import { eventTypeInfoMapV2 } from 'src/app/global.constants';
import { strToTitleCase } from 'src/app/js';
import { EstimateHelperService } from 'src/app/services/estimate-helper.service';
import { FreyaHelperService } from 'src/app/services/freya-helper.service';
import { FreyaNotificationsService } from 'src/app/services/freya-notifications.service';
import { YemboHelperService } from 'src/app/services/yembo-helper.service';
import { convertCalendarFormatToStandard } from 'src/app/time';

import { dayjs } from '../../../core/dayjs/dayjs';
import { ScheduleActions } from '../../../schedules/store/schedule.actions';
import { scheduleFeature } from '../../../schedules/store/schedules.reducer';
import { jobToolFeature } from '../../job-tool.reducer';
import {
  getTimingForQuery
} from '../../jobsv2-events-helpers';

import { ScheduleEventsActions } from './event-schedule.actions';
import {
  eventScheduleSelectors,
  selectPlaceholderEvent,
  selectTotalTime
} from './event-schedule.selectors';

const generateFakeStartTimes = (date: string, interval: number) => {
    const start = dayjs.tz(date).startOf('day').unix();
    const end = dayjs.tz(date).endOf('day').unix();    
    const times = [];

    interval = interval || 15 * 60;
    
    for (let time = start; time < end; time += interval) {
        times.push(time);
    }
    
    return times;
};

export const findTimesEffect = createEffect(
  () => {
    const actions$ = inject(Actions);
    const findGQL = inject(FindGQL);
    const store = inject(Store);
    const freyaHelperService = inject(FreyaHelperService);

    return actions$.pipe(
      ofType(
        // dateSelected is fired when the component is initially loaded, so we don't need to listen to it
        // ScheduleEventsActions.openBookDialogButtonClicked,

        // time changes
        ScheduleEventsActions.dateSelected,
        ScheduleEventsActions.timeSelected, 
        ScheduleEventsActions.assetsSelected,
        
        // duration changes
        ScheduleEventsActions.includeDockTravelChecked,
  
        // Update if we try to reschedule and after we book the event
        ScheduleEventsActions.rescheduleButtonClicked,
        ScheduleEventsActions.bookEventSuccess,
        ScheduleEventsActions.bookEventError,
        ScheduleEventsActions.bookingRestrictionsChanged,
        ScheduleActions.reloadButtonClicked,
      ),
      withLatestFrom(
        store.select(jobToolFeature.selectJob),
        store.select(jobToolFeature.selectEventToBook),
        store.select(jobToolFeature.selectSelectedDate),
        store.select(selectTotalTime),
        store.select(jobToolFeature.selectIsBookingRestrictionDisabled),
        store.select(eventScheduleSelectors.selectStartTimeInterval),
      ),
      switchMap(([action, job, eventToBook, date, totalTime, restrictionsDisabled, interval]) => {

        if(restrictionsDisabled) {
          return of(
            ScheduleEventsActions.findAvailabilitySuccess({
              startTimes: generateFakeStartTimes(date, interval),
              possibleWindows: [],
              hasLockedWindows: false,
            })
          );
        }

        if (!eventToBook || !date) {
          return of(ScheduleEventsActions.findAvailabilityError({
            error: new Error(`No event or date to find`),
          }))
        }
  
        const formattedDate = convertCalendarFormatToStandard(date);

        const queryVariables: FindQueryVariables = {
          timeWindow: {
            startDate: formattedDate,
            endDate: formattedDate,
            minWindowLength: totalTime,
          },
          minNumAssets: 1,
          assetsFilter: !restrictionsDisabled ? {
            types: eventTypeInfoMapV2[eventToBook.type]?.assetTypes || [],
          }: undefined,
          overrideAvailabilities: restrictionsDisabled,
          overrideUnavailabilities: restrictionsDisabled,
          zoneInput: job.zone.id,
          excludedEventIds: [ eventToBook.id ],
        };

        return from(
          findGQL.fetch(queryVariables, {
            fetchPolicy: 'network-only',
          })
        ).pipe(
          switchMap((response) => {
            const windows = response?.data?.find?.windows || [];
            const avails = [] as number[];
            let hasLockedWindows = false;

            for (const window of windows) {
              if (freyaHelperService.lockDate > window.end) {
                hasLockedWindows = true;
                continue;
              }
              avails.push(...window.startTimes);
            }

            const possibleWindows = windows;
            const possibleTimes = [...new Set(avails)];

            return of(
              ScheduleEventsActions.findAvailabilitySuccess({
                startTimes: possibleTimes,
                possibleWindows,
                hasLockedWindows,
              })
            );
          }),
          catchError((error) =>
            of(ScheduleEventsActions.findAvailabilityError({ error }))
          )
        );
      })
    );
  },
  { functional: true, dispatch: true }
);

export const setDateFromSchedule = createEffect((
  actions$ = inject(Actions),
  store = inject(Store),
) => {

    return actions$.pipe(
      ofType(ScheduleActions.fcViewChanged),
      withLatestFrom(store.select(jobToolFeature.selectSelectedDate)),
      map(([action, selectedDate]) => {
        const strSelectedDate = selectedDate;
        const strDate = dayjs(action.date).format('MM/DD/YYYY');
        
        return [ strSelectedDate, strDate ]
      }),
      filter(([ strSelectedDate, strDate ]) => strSelectedDate !== strDate),
      map(([strSelectedDate, strDate]) => {
        return ScheduleEventsActions.dateSelected({
          date: strDate,
          // prevent infinite loops
          fromFullCalendarChange: true,
        });
      })
    );
  },
  { functional: true, dispatch: true }
);

export const initiateEventBookingEffect = createEffect(
  () => {
    const actions$ = inject(Actions);
    const store = inject(Store);
    const yemboHelper = inject(YemboHelperService);
    const assetService = inject(AssetService);
    const confirmationService = inject(ConfirmationService);
    const lockWindowGQL = inject(LockWindowGQL);
    const localNotify = inject(FreyaNotificationsService);
    const bulkEditCalendarEventGQL = inject(BulkEditCalendarEventOnScheduleGQL);

    return actions$.pipe(
      ofType(ScheduleEventsActions.bookButtonClicked),
      withLatestFrom(
        store.select(jobToolFeature.selectJob),
        store.select(jobToolFeature.selectEventToBook),
        store.select(selectPlaceholderEvent),
        store.select(jobToolFeature.selectSelectedStartTime),
        store.select(jobToolFeature.selectSelectedAssets),
        store.select(eventScheduleSelectors.selectEventItinerary),
        store.select(jobToolFeature.selectIncludeDockTravel),
        store.select(jobToolFeature.selectAlreadyScheduled),
        store.select(jobToolFeature.selectIsBookingRestrictionDisabled),
      ),
      switchMap(([action,
        job, event, placeholderEvent,
        startTime, selectedAssets, itinerary,
        includeDockTravel, alreadyScheduled, restrictionsDisabled,
      ]) => {
        if (!event) {
          return of(ScheduleEventsActions.bookEventError({ error: 'Event to book not set' }));
        }

        const restrictionsEnabled = !restrictionsDisabled;

        return assetService
          .listAssets({
            filter: {
              ids: selectedAssets || undefined,
            },
          })
          .pipe(
            switchMap((res) => {
              const selectedAsset = res.data.assets.assets.find(
                (a) => a.id === selectedAssets[0]
              );
              const assetHasYemboEmail = Boolean(
                selectedAsset?.metadata?.yemboEmail
              );

              if (
                yemboHelper.yemboEnabled &&
                event?.type === 'virtualEstimate' &&
                !assetHasYemboEmail
              ) {
                return new Observable<boolean>((observer) => {
                  confirmationService.confirm({
                    header: 'No Yembo Email',
                    message:
                      'This asset does not have a Yembo email configured. Smart Consult will be booked for the default admin email.',
                    accept: () => {
                      observer.next(true);
                      observer.complete();
                    },
                    reject: () => {
                      observer.next(false);
                    },
                    acceptLabel: 'Continue',
                    rejectLabel: 'Cancel',
                  });
                });
              }

              return of(true) as Observable<boolean>;

              // return triggerLockWindowQuery(job, event, bookingData, configs, estimateHelper, lockWindowGQL, localNotify);
            }),
            filter((doBook) => doBook === true),
            switchMap(() => {

              const selectedStartTime = startTime;
              const timingForQuery = getTimingForQuery(
                itinerary,
                selectedStartTime
              );

              //in case that location was not set
              //eg mover doesn't have dock
              const filteredLocations = itinerary.locations.filter(
                (l) => l.locationId
              );

              const locationsForQuery = filteredLocations.map((location) => ({
                estimatedTimeAtLocation: location.estimatedTimeAtLocation,
                locationId: location.locationId,
                order: location.order,
                travelTimeToNextLocationOffset:
                  location.travelTimeToNextLocationOffset,
                type: location.type,
              }));

              if (!placeholderEvent.eventIsPlaceholder) {
                // there are no changes
                localNotify.addToast.next({
                  severity: 'success',
                  summary: 'No changes to event',
                  detail: 'Event not updated',
                });
                return of(
                  ScheduleEventsActions.bookEventSuccess({
                    updatedEvent: event,
                  }),
                );
              } else if (alreadyScheduled) {

                const editEventVariables: BulkEditCalendarEventMutationVariables = {
                  restrictionsEnabled,
                  edits: [{
                    id: event?.id,
                    edit: {
                      start: selectedStartTime,
                      end: timingForQuery?.end,
                      setAssets: {
                        addAssets: selectedAssets,
                        removeAssets: event?.assets?.map((a) => a.id),
                      },
                    },
                  }],
                };

                return from(bulkEditCalendarEventGQL.mutate(editEventVariables, {
                  context: {
                    headers: {
                      'x-zone': job.zone.id,
                    },
                  },
                })).pipe(
                  switchMap((response) => {
                    const { total, events } = response?.data?.bulkEditCalendarEvent;

                    if (total === events.length) {
                      localNotify.addToast.next({
                        severity: 'success',
                        summary: 'Event rescheduled',
                      });
                      return of(
                        ScheduleEventsActions.bookEventSuccess({
                          updatedEvent: events[0],
                        }),
                      );
                    } else {
                      const error = new Error('Error updating event - event not returned');
                      throw error;
                    }
                  }),
                  catchError((error) => {
                    localNotify.addToast.next({
                      severity: 'error',
                      summary: 'Error booking event',
                      detail: error.message,
                    });

                    return of(ScheduleEventsActions.bookEventError({ error: error.message }));
                  }),
                  
                );
              } else {
                const lockQueryInput: LockWindowMutationVariables = {
                  assetIds: selectedAssets,
                  type: event?.type,
                  title: event?.title || strToTitleCase(event?.type),
                  start: startTime,
                  end: timingForQuery.end,
                  jobId: job?.id,
                  updateEventSequentialOrder: true,
                  eventId: event?.id,
                  attributes: [event?.type],
                  locations: locationsForQuery,

                  overrideAvailabilities: restrictionsDisabled,
                  overrideUnavailabilities: restrictionsDisabled,
                  modifyStartForTravel: includeDockTravel,
                  modifyEndForTravel: includeDockTravel,
                  restrictionsEnabled,
                };

                return from(
                  lockWindowGQL.mutate(lockQueryInput, {})
                ).pipe(
                  switchMap((response) => {
                    localNotify.addToast.next({
                      severity: 'success',
                      summary: 'Event booked',
                    });
                    return of(
                      ScheduleEventsActions.bookEventSuccess({
                        updatedEvent: response?.data
                          ?.lockWindow as unknown as CalendarEvent,
                      })
                    );
                  }),
                  catchError((error) => {
                    localNotify.addToast.next({
                      severity: 'error',
                      summary: 'Error booking event',
                      detail: error,
                    });

                    return of(ScheduleEventsActions.bookEventError({ error: error.message }));
                  }),
                );
              }
            }),
          );
      })
    );
  },
  { functional: true, dispatch: true }
);
