import { Component, OnInit } from '@angular/core';
import { createSelector, Store } from '@ngrx/store';
import { MarkdownModule } from 'ngx-markdown';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { FreyaCommonModule } from '../../../freya-common/freya-common.module';
import { SharedModule } from '../../../shared/shared.module';
import { WorkOrdersActions } from '../../job-state/workorders-state/workorders.actions';
import { jobToolFeature } from '../../job-tool.reducer';

@Component({
  selector: 'app-upsell-products-dialog',
  standalone: true,
  imports: [
    FreyaCommonModule,
    SharedModule, 
    MarkdownModule,
  ],
  templateUrl: './upsell-products-dialog.component.html',
  styleUrl: './upsell-products-dialog.component.scss'
})
export class UpsellProductsDialogComponent implements OnInit{

  itinerary$ = this.store.select(jobToolFeature.selectItinerary);
  itineraryDuration$ = this.store.select(createSelector(
    jobToolFeature.selectItinerary,
    (itinerary) => {

      return {
        total: itinerary.reduce((p, c) => p + c.duration, 0),
        travel: itinerary
          .filter((f) => f.type === 'TRAVEL')
          .reduce((p, c) => p + c.duration, 0),
        labor: itinerary
          .filter((f) => f.type === 'LABOR')
          .reduce((p, c) => p + c.duration, 0),
      };

    }
  ));
  salesPitch$ = this.store.select(jobToolFeature.selectAgentInstructions);
  selectedProducts$ = this.store.select(jobToolFeature.selectSelectedProducts);
  generateChargesWithAILoading$ = this.store.select(jobToolFeature.generateChargesWithAILoading);
  eventId: string;


  constructor(
        public config: DynamicDialogConfig,
        private ref: DynamicDialogRef,
        private store: Store
  ) { }


  ngOnInit(): void {
    this.eventId = this.config.data.eventId;
  }

  generateCharges() {
    this.store.dispatch(WorkOrdersActions.regenerateChargesWithAI({ eventId: this.eventId }));
  }

  onClose(): void {
    this.ref.close();
  }
}
