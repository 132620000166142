<section>
  <header>
    <div>
      <h2><i class="pi pi-calendar p-mr-1"></i> Timeline</h2>
    </div>

    <p-button
      label="Add Event"
      icon="pi pi-plus"
      [outlined]="true"
      (onClick)="openNewEventDialog()"
      [disabled]="(jobLoaded$ | async) === false"
    ></p-button>

  </header>
  <main
    class="p-mt-4"
    *ngIf="jobLoaded$ | async; else loading"
  >
    <article
      *ngFor="let jobEvent of (jobEvents$ | async); let eventIndex = index"
      [style]="jobEvent.style"
    >
      <div
        class="eventDetails"
        (click)="openEventInRightPanel(jobEvent.event.id)"
      >
        <span class="title">{{ jobEvent.title }}</span>
        <span class="info"> {{ jobEvent.details }} </span>
      </div>
      <div>
        <p-button
          *ngIf="jobEvent?.event?.status === 'required'"
          class="p-m-1"
          [outlined]="true"
          label="Book"
          (click)="bookEvent(jobEvent?.event)"
          icon="pi pi-calendar-plus"
          [disabled]="isEventMissingLocations(jobEvent?.event?.id)"
          pTooltip="{{ getMissingLocationsTooltip(jobEvent?.event?.id) }}"
        ></p-button>
        <button
          *ngIf="[ 'booked', 'confirmed', 'completed' ].includes(jobEvent?.event?.status)"
          pButton
          class="p-m-1"
          [outlined]="true"
          [label]="jobEvent?.event?.status === 'completed' ? 'Invoice' : 'Complete'"
          [icon]="event?.status === 'completed' ? 'pi pi-file' : 'pi pi-check-circle'"
          (click)="promoteAndInvoice(jobEvent?.event)"
          ></button>
        <p-button
          icon="pi pi-ellipsis-v"
          [text]="true"
          (onClick)="openActionMenu($event, jobEvent, eventIndex)"
        />
      </div>
    </article>
  </main>
</section>

<p-menu
  #menu
  [model]="eventMenuItems"
  [popup]="true"
/>



<ng-template #loading>
  <main class="p-mt-4">
    <p-skeleton
      class="flex-1"
      *ngFor="let _ of [1, 2, 3]"
      width="100%"
      height="5rem"
    />
  </main>
</ng-template>
