import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { createSelector, Store } from '@ngrx/store';
import { QuillEditorComponent } from 'ngx-quill';
import { MenuItem } from 'primeng/api';

import { lastValueFrom } from 'rxjs';
import { SubSink } from 'subsink';

import { AddCommentInput } from '../../../../generated/graphql.generated';
import { FreyaCommonModule } from '../../../freya-common/freya-common.module';
import { brandingFeature } from '../../../state/branding.store';
import { generateUUID } from '../../../utilities/state.util';
import { JobToolActions } from '../../job-tool.actions';
import { jobToolFeature } from '../../job-tool.reducer';
import { commentDefaultToolbar, isQuillEmpty } from '../comments.utils';
import { JobCommentArtifactHandlerService } from '../job-comment-artifact-handler.service';

@Component({
  selector: 'app-job-add-comment',
  standalone: true,
  imports: [
    CommonModule,
    QuillEditorComponent,
    FormsModule,
    FreyaCommonModule,
    
  ],
  templateUrl: './job-add-comment.component.html',
  styleUrl: './job-add-comment.component.scss'
})
export class JobAddCommentComponent implements OnInit {
  @ViewChild(QuillEditorComponent, { static: true }) editor: QuillEditorComponent | undefined;

  mutating$ = this.store.select(jobToolFeature.addCommentLoading);
  commentModes$ = this.store.select(brandingFeature.selectCommentModes);

  currentCommentMode$ = this.store.select(createSelector(
    brandingFeature.selectCommentModes,
    jobToolFeature.selectCurrentCommentMode,
    (modes, currentCommentMode) => {

      const mode = modes?.find((m) => m.id === currentCommentMode);
      return mode || {
        id: 'Comment',
        name: 'Comment',
      };
    },
  ));

  commentModeMenuItems$ = this.store.select(createSelector(
    brandingFeature.selectCommentModes,
    jobToolFeature.selectCurrentCommentMode,
    (modes, currentCommentMode) => {
      if (!modes?.length) {
        modes = [{
          id: 'Comment',
          name: 'Comment',
        }];
      }
  
      return modes
        .filter((m) => !m?.hidden)
        .map((m) => ({
          ...m,
          label: m.name,
          visible: m.id !== currentCommentMode,
          tooltip: m.description,
          tooltipOptions: {
            tooltipPosition: 'left',
          },
          // disabled: allDisabled,
          command: () => this.store.dispatch(JobToolActions.commentModeSet({
            mode: m.id,
          })),
        }));
    },
  ));


  subs = new SubSink();
  newCommentContent = {};

  hasPermission = true;

  modules = {
    toolbar: commentDefaultToolbar,
    keyboard: {
      bindings: {
        submit: {
          key: 'Enter',
          ctrlKey: true,
          handler: (range, context) => {
            this.addComment();
          },
        },
      },
    },
    // imageHandler: {
    //   upload: async (file: File) => {
    //     const artifact = await lastValueFrom(this.jobCommentArtifactHandlerService.uploadFile(file));
    //     console.log(artifact);

        // make the URL for the comment based on the current url
        // const url = new URL(location.href);

        // // clear everything but the zone
        // const zone = url.searchParams.get('zone');
        // url.search = '';
        // url.searchParams.set('zone', zone);

        // // set the path
        // url.pathname = `/api/artifact/${ artifact.id }`;

        // console.log(url.toString());

        // return url.toString();

    //     return `/api/artifact/${ artifact.id }`;
    //   }
    // },
  };

  subject = '';

  // closeJobCommentLabel = 'Close Job';
  error: string;

  constructor(
    private store: Store,
    private jobCommentArtifactHandlerService: JobCommentArtifactHandlerService,
  ) { }

  ngOnInit(): void {
      this.subject = '';
      // this.updateCommentModes();

      this.subs.sink = this.editor.onEditorCreated.subscribe((res) => {
        const toolbarModule: any = this.editor?.quillEditor?.getModule('toolbar');

        if (!toolbarModule) { return; }
        // toolbarModule.addHandler('image', () => {

          // const fileInput = this.editor.editorElem.querySelector('input.ql-image[type=file]');
          // console.log('add image', fileInput);
          // if (fileInput == null) {
          //   fileInput = document.createElement('input');
          //   fileInput.setAttribute('type', 'file');
          //   fileInput.setAttribute('accept', 'image/png, image/gif, image/jpeg, image/bmp, image/x-icon');
          //   fileInput.classList.add('ql-image');
          //   fileInput.addEventListener('change', () => {
          //     if (fileInput.files != null && fileInput.files[0] != null) {
          //       let reader = new FileReader();
          //       reader.onload = (e) => {
          //         let range = this.quill.getSelection(true);
          //         this.quill.updateContents(new Delta()
          //           .retain(range.index)
          //           .delete(range.length)
          //           .insert({ image: e.target.result })
          //         , Emitter.sources.USER);
          //         fileInput.value = "";
          //       }
          //       reader.readAsDataURL(fileInput.files[0]);
          //     }
          //   });
          //   this.container.appendChild(fileInput);
          // }
          // fileInput.click();
        // });
      });
  }

  contentChanged(event) {
    this.error = undefined;
  }

  onEditorCreated() {}

  canAddComment() {
    // if (!this.jobId) return false;
    if (this.isEmpty()) return false;
    if (!this.hasPermission) return false;
    return true;
  }

  addComment() {
    if (!this.canAddComment()) { return false; }

    const text = this.editor.quillEditor.getText();
    const contents = this.editor.quillEditor.getContents();

    // const input: AddCommentInput = {
      // objectId: this.jobId,
      // objectType: 'Job',
      // mode: this.mode,
    // };

    const id = generateUUID();
    const threadId = generateUUID();
    this.store.dispatch(JobToolActions.addComment({
      input: {
        id,
        threadId,
        text,
        contents: JSON.stringify(contents),
        subject: this.subject,
      },
    }));
    this.clearContent();
    return true;

  }

  clearContent() {
    this.editor?.quillEditor?.setContents([{ insert: '\n' }]);
    this.subject = '';
  }

  isEmpty() {
    return isQuillEmpty(this.editor?.quillEditor);
  }
}
