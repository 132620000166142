import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { distinctUntilChanged, filter, Subject } from 'rxjs';

import { OnlineStatusService } from 'src/app/online-status.service';

import { BaseJobFragment, Job_UsersFragment } from '../../../generated/graphql.generated';

import { defaultJobIcon, JOB_ROLE_MAP, jobStageIcons, PRESET_FILTERS, ZONE_TYPES } from '../../global.constants';
import { safeParseJSON, strToTitleCase } from '../../js';
import { FRONTEND_PERMISSIONS } from '../../permissions.constants';
import { scheduleFeature } from '../../schedules/store/schedules.reducer';
import { BrandingService } from '../../services/branding.service';
import { JobParams } from '../../shared/query-params.service';

export interface SidebarItem {
    id?: string;
    label: string;
    sublabel?: string;
    icon: string;
    routerLink?: string[];
    queryParams?: any;

    alwaysShowItems?: boolean;

    tooltip?: string;

    hiddenByDefault?: boolean;
    permissions?: string;
    items?: SidebarItem[];
    hidden?: boolean;
    disabled?: boolean;
    hiddenIfZoneType?: string[];
    command?: (res: { originalEvent: Event; item: SidebarItem }) => void;

    additionalAction?: {
        icon?: string;
        tooltip?: string;
        routerLink?: string[];
        queryParams?: any;
        hidden?: boolean;
        disabled?: boolean;
        command?: (res: { originalEvent: Event; item: SidebarItem }) => void;

        // hiddenByDefault?: boolean;
        // permissions?: string;
    };
}

interface PartialJob {
    id: string;
    zone?: string;
    code: string;
    collectionId: string;
    subTotal: number;
    step: number | string;
    customerName?: string;
    title?: string;
    stage: string;

}

const ESTIMATING_JOBS_PREFIX = 'estimating-jobs';

@Injectable()
export class MenuService {
    isOnline: boolean;

    get menuSource$() { return this.menuSource.asObservable(); }
    get resetSource$() { return this.resetSource.asObservable(); }

    sidebar: SidebarItem[];
    jobs: PartialJob[] = [];
    lastZone: string;

    private menuSource = new Subject<string>();
    private resetSource = new Subject<void>();

    // private scheduleDate$ = this.store.select(scheduleFeature.selectFormattedDate);
    private scheduleDate: string;
    private isDispatchEnabled = false;
    public isJobCreationToolEnabled = false;
    private isJobV2PageEnabled = false;
    private showEstimating = false;
    public isQuickAccessEnabled = false;

    get zoneId() {
        return this.branding.currentZone().value?.id;
    }

    get isJobCreationEnabled() {
        return this.isJobCreationToolEnabled;
    }

    get isJobV2Enabled() {
        return this.isJobV2PageEnabled;
    }

    constructor(
        private branding: BrandingService,
        public router: Router,
        private onlineStatusService: OnlineStatusService,
        private store: Store
    ) {
        this.onlineStatusService.isOnline$.subscribe(status => {
            this.isOnline = status;
            this.setSidebar();
        });

        // this.scheduleDate$.pipe(filter(Boolean), distinctUntilChanged()).subscribe(date => {
        //     this.scheduleDate = date;
        //     this.setSidebar();
        // });

        this.branding.watchFeature('dispatch').subscribe((enabled) => {
            this.isDispatchEnabled = enabled;
            this.setSidebar();
        });

        this.branding.watchFeature('new-job-creation-tool').subscribe((enabled) => {
            this.isJobCreationToolEnabled = enabled;
            this.setSidebar();
        });

        this.branding.watchFeature('quick-access').subscribe((enabled) => {
            this.isQuickAccessEnabled = enabled;
            this.setSidebar();
        });

        this.branding.watchFeature('jobs-v2').subscribe((enabled) => {
            this.isJobV2PageEnabled = enabled;
        });

        this.branding.watchFeature('show-estimating').subscribe((enabled) => {
            this.showEstimating = enabled;
            this.setSidebar();
        });
    }

    getCurrentEstimatingJobsStorageName(): string {
        return ESTIMATING_JOBS_PREFIX;
        // const zone = this.auth.user?.zone;
        // return zone ? `${ ESTIMATING_JOBS_PREFIX }::${ zone }` : ESTIMATING_JOBS_PREFIX;
    }

    getJobsFromLocalStorage() {
        // if (!this.zoneId) {
        // this.jobs = [];
        // return [];
        // }

        return safeParseJSON(localStorage.getItem(this.getCurrentEstimatingJobsStorageName()), []) || [];
    }

    onMenuStateChange(key: string) {
        this.menuSource.next(key);
    }

    reset() {
        this.resetSource.next();
    }

    pushJob(
        job?: BaseJobFragment & Job_UsersFragment,
        step: string | number = 0,
        remove = false,
    ) {
        this.jobs = this.getJobsFromLocalStorage();

        // remove job if it already exists - it will be re-added to the top
        const existing = this.jobs.findIndex((j) =>
            // match "new" jobs (without an id)
            (j.id === job?.id && j.id === undefined)
            // match jobs by id or collectionId
            || (job && (
                j.id === job.id
                || (job.collectionId && j.collectionId === job.collectionId)
            ))
        );

        if (existing >= 0) {
            this.jobs.splice(existing, 1);
        }

        if (!remove && job?.id && job.stage && job.code) {
            const customer = job.users?.find((u) => u.role === JOB_ROLE_MAP.customerRole);
            let customerName: string;
            if (customer?.user) {
                customerName = [customer.user.givenName, customer.user.familyName].filter(Boolean).join(' ');
            }


            const title = `[${job.code}] ${customerName || job.code || job.subTotal}`;

            // remove "unset" jobs
            this.jobs = this.jobs.filter((j) => j?.id);

            this.jobs = [{
                id: job.id,
                collectionId: job.collectionId,
                stage: job.stage,
                code: job.code,
                subTotal: job.subTotal,
                customerName,
                step,
                title,
                zone: this.zoneId,
            }, ...this.jobs].slice(0, 5);
        } else if (!remove && !job?.id) {
            this.jobs = [...this.jobs].slice(0, 5);
        }

        localStorage.setItem(this.getCurrentEstimatingJobsStorageName(), JSON.stringify(this.jobs));
        this.setSidebar();
    }

    getSidebarItemByID(id: string): SidebarItem | undefined {
        return this.sidebar?.find((i) => i.id === id);
    }

    getJobParamsByName(name: string): JobParams {
        return PRESET_FILTERS.jobs.find((f) => f.name.toLowerCase() === name.toLowerCase())?.filter;
    }

    setSidebar() {
        const currentZone = this.zoneId;
        if (currentZone !== this.lastZone) {
            // this.setJobsFromLocalStorage();
        }
        this.lastZone = this.zoneId;
        this.jobs = this.getJobsFromLocalStorage();

        const estimating: SidebarItem = {
            id: 'estimating',
            label: 'Estimating',
            icon: 'pi pi-money-bill',
            disabled: !this.isOnline,
            // tooltip: 'New Job',
            routerLink: ['/estimating'],
            queryParams: {
                step: '0',
                zone: this.zoneId,
            },
            permissions: FRONTEND_PERMISSIONS.estimating,
            alwaysShowItems: true,

            additionalAction: {
                icon: 'pi pi-plus',
                tooltip: 'New Job',
                disabled: !this.isOnline,
                routerLink: ['/estimating'],
                queryParams: {
                    step: '0',
                    zone: this.zoneId,
                },
            },

        };

        const jobs = this.jobs;
        if (jobs?.length) {
            const genJobLabel = (j) => `${strToTitleCase(j.stage || '')} | ${j.customerName || j.code}`;
            estimating.items = estimating.items || [];
            const [firstJob] = jobs;
            estimating.routerLink = firstJob.id ? ['/estimating', firstJob.id] : ['/estimating'];
            estimating.queryParams.step = firstJob.step;
            if (firstJob.zone) {
                estimating.queryParams.zone = firstJob.zone;
            }

            estimating.sublabel = firstJob.customerName || firstJob.code;
            // estimating.tooltip = firstJob.title;

            estimating.items = jobs.map((j) => {
                const existing = estimating.items.find((e) => e.id === j.id);
                const label = [strToTitleCase(j.stage || '')];
                if (j.code) {
                    label.push(j.code);
                }
                return Object.assign(existing || {}, {
                    id: j.id,
                    label: label.join(' | '),
                    disabled: !this.isOnline,
                    sublabel: j.customerName || '',
                    tooltip: j.code,
                    icon: jobStageIcons[j.stage] || defaultJobIcon,
                    routerLink: j.id ? ['/estimating', j.id] : ['/estimating'],
                    command: (event) => {
                        if (this.lastZone !== j.zone) {
                            event.originalEvent.preventDefault();
                            this.router.navigateByUrl('/loading', { skipLocationChange: true }).then(() => {
                                this.router.navigate(['/estimating', j.id],
                                    {
                                        queryParams: {
                                            step: j.step,
                                            zone: j.zone,
                                        }
                                    });
                            });
                        }
                    },
                    queryParams: {
                        step: j.step,
                        zone: j.zone,
                    },
                });
            });
        }

        this.sidebar = [
            {
                label: 'Dashboard',
                icon: 'pi pi-home',
                routerLink: ['/'],
                disabled: !this.isOnline,
            },
            {
                label: 'Schedule',
                icon: 'pi pi-calendar-times',
                routerLink: ['/schedule'],
                permissions: FRONTEND_PERMISSIONS.schedule,
                disabled: !this.isOnline,
                items: [
                    {
                        label: 'Day',
                        disabled: !this.isOnline,
                        icon: 'pi pi-calendar-clock',
                        routerLink: ['/schedule/', 'day'],
                        queryParams: {
                            date: this.scheduleDate,
                            zone: this.zoneId,
                        },
                    },
                    {
                        label: 'Week',
                        disabled: !this.isOnline,
                        icon: 'pi pi-calendar',
                        routerLink: ['/schedule/', 'week'],
                        queryParams: {
                            date: this.scheduleDate,
                            zone: this.zoneId,
                        },

                    },
                    {
                        label: 'Month',
                        disabled: !this.isOnline,
                        icon: 'pi pi-calendar-minus',
                        routerLink: ['/schedule/month'],
                        queryParams: {
                            date: this.scheduleDate,
                            zone: this.zoneId,
                        },

                    },
                    {
                        label: 'Dispatch',
                        disabled: !this.isOnline,
                        hidden: !this.isDispatchEnabled,
                        icon: 'pi pi-truck',
                        routerLink: ['/schedule/dispatch'],
                        queryParams: {
                            date: this.scheduleDate,
                            zone: this.zoneId,
                        },
                    }
                ]
            },
            {
                label: 'Jobs',
                icon: defaultJobIcon,
                disabled: !this.isOnline,
                routerLink: ['/jobs'],
                queryParams: {},
                items: [
                    {
                        label: 'Leads',
                        disabled: !this.isOnline,
                        icon: jobStageIcons.lead,
                        routerLink: ['/jobs/'],
                        queryParams: this.getJobParamsByName('leads'),
                    },
                    {
                        label: 'Estimates',
                        icon: jobStageIcons.estimate,
                        disabled: !this.isOnline,
                        routerLink: ['/jobs/'],
                        queryParams: this.getJobParamsByName('estimates'),
                    },
                    {
                        label: 'Bookings',
                        icon: jobStageIcons.booking,
                        disabled: !this.isOnline,
                        routerLink: ['/jobs/'],
                        queryParams: this.getJobParamsByName('booking'),
                    },
                    {
                        label:
                            'Invoices',
                        icon: jobStageIcons.invoice,
                        disabled: !this.isOnline,
                        routerLink: ['/jobs/'],
                        queryParams: this.getJobParamsByName('invoices'),
                    },
                    {
                        label: 'Pending',
                        icon: 'pi pi-exclamation-circle',
                        disabled: !this.isOnline,
                        routerLink: ['/jobs/'],
                        queryParams: this.getJobParamsByName('pending'),
                    },
                    {
                        label: 'Closed',
                        icon: 'pi pi-circle-fill',
                        disabled: !this.isOnline,
                        routerLink: ['/jobs/'],
                        queryParams: this.getJobParamsByName('closed'),
                    },
                ],
                permissions: FRONTEND_PERMISSIONS.jobs
            },
            this.showEstimating ? estimating : undefined,
            // {label: 'Leads', icon: 'pi pi-folder-open', routerLink: ['/leads']},
            {
                label: 'Users',
                items: [
                    {
                        label: 'Customers',
                        icon: 'pi pi-shopping-cart',
                        disabled: !this.isOnline,
                        routerLink: ['/users/customers']
                    },
                    {
                        label: 'Employees',
                        icon: 'pi pi-id-card',
                        disabled: !this.isOnline,
                        routerLink: ['/users/employees']
                    },
                    {
                        label: 'All Customers',
                        icon: 'pi pi-users',
                        disabled: !this.isOnline,
                        routerLink: ['/users/allcustomers']
                    }
                ],
                icon: 'pi pi-users',
                disabled: !this.isOnline,
                routerLink: ['/users'],
                permissions: FRONTEND_PERMISSIONS.users
            },
            {
                label: 'Reports',
                disabled: !this.isOnline,
                icon: 'pi pi-chart-line',
                routerLink: ['/reports'],
                permissions: FRONTEND_PERMISSIONS.reports
            },
            {
                label: 'Business',
                disabled: !this.isOnline,
                icon: 'pi pi-table',
                routerLink: ['/business'],
                items: [
                    // {label: 'Corporate', icon: 'pi pi-briefcase', routerLink: ['/corporate']},
                    {
                        label: 'Assets',
                        icon: 'pi pi-tablet',
                        routerLink: ['/business/assets'],
                        permissions: FRONTEND_PERMISSIONS.assets
                    },
                    {
                        label: 'Availability',
                        icon: 'pi pi-calendar',
                        routerLink: ['/business/availability'],
                        permissions: FRONTEND_PERMISSIONS.availability,
                    },
                    {
                        label: 'Products',
                        icon: 'pi pi-tag',
                        routerLink: ['/business/products'],
                        permissions: FRONTEND_PERMISSIONS.products
                    },
                    {
                        label: 'Areas',
                        icon: 'pi pi-map',
                        routerLink: ['/business/areas'],
                        // hiddenIfZoneType: [ZONE_TYPES.corporate, ZONE_TYPES.root],
                        permissions: FRONTEND_PERMISSIONS.franchise,
                    },
                    {
                        label: 'Marketing',
                        icon: 'pi pi-wallet',
                        routerLink: ['/business/marketing'],
                        permissions: FRONTEND_PERMISSIONS.marketing
                    },
                    {
                        label: 'Discounts',
                        icon: 'pi pi-tag',
                        routerLink: ['/business/discounts'],
                        permissions: FRONTEND_PERMISSIONS.marketing
                    },
                    {
                        label: 'Taxes',
                        icon: 'pi pi-dollar',
                        routerLink: ['/business/taxes'],
                        hiddenIfZoneType: [ZONE_TYPES.corporate, ZONE_TYPES.root],
                        permissions: FRONTEND_PERMISSIONS.franchise,
                    },
                    {
                        label: 'Invoices',
                        icon: 'pi pi-file',
                        routerLink: ['/business/invoices'],
                        permissions: FRONTEND_PERMISSIONS.franchise,
                    },
                    {
                        label: 'Inventory',
                        icon: 'pi pi-box',
                        routerLink: ['/business/inventory'],
                        // hiddenIfZoneType: [ZONE_TYPES.corporate, ZONE_TYPES.root],
                        permissions: FRONTEND_PERMISSIONS.franchise,
                    },
                    {
                        label: 'Payment Types',
                        icon: 'pi pi-credit-card',
                        routerLink: ['/business/payment'],
                        permissions: FRONTEND_PERMISSIONS.settings
                    },
                    {
                        label: 'System Rules',
                        icon: 'pi pi-book',
                        routerLink: ['/business/rules'],
                        // hiddenIfZoneType: [ZONE_TYPES.corporate, ZONE_TYPES.root],
                        permissions: FRONTEND_PERMISSIONS.franchise,
                    },
                    {
                        label: 'Business Units',
                        icon: 'pi pi-sitemap',
                        routerLink: ['/business/units'],
                        // hiddenIfZoneType: [ZONE_TYPES.franchise],
                        permissions: FRONTEND_PERMISSIONS.franchiseManagement,
                    },
                    {
                        label: 'Settings',
                        icon: 'pi pi-cog',
                        routerLink: ['/business/settings'],
                        permissions: FRONTEND_PERMISSIONS.settings
                    },
                    {
                        label: 'Accounting',
                        icon: 'pi pi-table',
                        routerLink: ['/business/accounting'],
                        permissions: FRONTEND_PERMISSIONS.settings,
                    },
                    {
                        label: 'History',
                        icon: 'pi pi-history',
                        routerLink: ['/business/history'],
                        permissions: FRONTEND_PERMISSIONS.settings,
                    }
                ],
                permissions: FRONTEND_PERMISSIONS.franchise
            },
        ].filter(Boolean);
    }

}
