import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';

import { FormsModule } from '@angular/forms';
import { Store } from '@ngrx/store';
import { QuillEditorComponent, QuillModules } from 'ngx-quill';
import { ConfirmationService, MenuItem } from 'primeng/api';
import Quill, { Delta } from 'quill/core';

import { BehaviorSubject, combineLatest, map } from 'rxjs';

import { BaseCommentFragment, BaseJobUserFragment, BaseUserFragment, CommentMode, FullJobFragment } from '../../../../generated/graphql.generated';
import { PlusAuthenticationService } from '../../../core/public-api';
import { FreyaCommonModule } from '../../../freya-common/freya-common.module';
import { DetailsHelperService } from '../../../services/details-helper.service';
import { FreyaNotificationsService } from '../../../services/freya-notifications.service';
import { PermissionService } from '../../../services/permission.service';
import { permissionHasRestriction } from '../../../services/permissions.util';
import { TimezoneHelperService } from '../../../services/timezone-helper.service';
import { appropriateTimeMeasurement } from '../../../shared/appropriate-time-measurement.pipe';
import { SharedModule } from '../../../shared/shared.module';
import { brandingFeature } from '../../../state/branding.store';
import { CallState, generateUUID, isErrorState } from '../../../utilities/state.util';
import { JobToolActions } from '../../job-tool.actions';
import { JobToolComment, jobToolFeature } from '../../job-tool.reducer';
import { commentDefaultToolbar, isQuillEmpty } from '../comments.utils';
import { TimeBlotClickEvent } from '../quill-modules/time.blot';

import { mapCommentEmailStatus } from './mapCommentEmailStatus';

export type CommentEditMode = 'view' | 'edit';
export type CommentWithCallState = (BaseCommentFragment | JobToolComment) & {
  callState?: CallState;
};

export const defaultCommentMode = {
  id: 'Comment',
  name: 'Comment',
  icon: 'pi pi-circle-fill',
};

@Component({
  selector: 'app-job-comment',
  standalone: true,
  imports: [
    CommonModule,
    QuillEditorComponent,
    FormsModule,
    FreyaCommonModule,
    SharedModule,
  ],
  templateUrl: './job-comment.component.html',
  styleUrl: './job-comment.component.scss'
})
export class JobCommentComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() job: FullJobFragment;

  private comment$ = new BehaviorSubject<CommentWithCallState | null>(null);
  @Input() set comment(value: CommentWithCallState) {
    this.comment$.next(value);
  }

  get comment() { return this.comment$.value; }

  @Input() replyEnabled = true;
  @Input() showReplies = true;
  @Input() isReply = false;
  @Input() repliesExpanded = true;

  @Input() parent: JobCommentComponent;

  @ViewChild('editor', { static: false }) editor: QuillEditorComponent | undefined
  @ViewChild('replyEditor', { static: false }) replyEditor: QuillEditorComponent | undefined

  mutating$ = this.store.select(jobToolFeature.addCommentLoading);
  commentModes$ = this.store.select( brandingFeature.selectCommentModes );
  commentMode$ =
    combineLatest([
      this.comment$,
      this.commentModes$,
    ]).pipe(
      map(([comment, commentModes]): CommentMode => {
        if (!commentModes?.length) { return defaultCommentMode; }
        if (!comment?.thread) { return defaultCommentMode; }
        const commentMode = commentModes.find((m) => m.id === comment?.thread?.mode);
        if (!commentMode) { return defaultCommentMode; }
        return commentMode;
      })
    );

    emailStatus$ = this.comment$.pipe(
      map(mapCommentEmailStatus)
    );
    
    

  editModules: QuillModules = {
    toolbar: commentDefaultToolbar,
    keyboard: {
      bindings: {
        submit: {
          key: 'Enter',
          ctrlKey: true,
          handler: (range, context) => {
            this.addReply();
          },
        },
      },
    },
  };
  viewModules: QuillModules = {
    toolbar: false,
  };

  contents: Delta | string;

  // Contents used when comment.system === true
  systemContents: Delta;

  replyContents: Delta;

  format = 'text';

  isEditing: boolean = false;

  canEdit$ = 
  combineLatest([
    this.comment$,
    this.commentModes$,
  ]).pipe(
    map(([comment, commentModes]): CommentMode => {
      if (!commentModes?.length) { return defaultCommentMode; }
      if (!comment?.thread) { return defaultCommentMode; }
      const commentMode = commentModes.find((m) => m.id === comment?.thread?.mode);
      if (!commentMode) { return defaultCommentMode; }
      return commentMode;
    })
  );

  canEdit = false;
  /**
        permission: 'comments.delete',
        restrictions: {
            restriction_overrideOwnership: true,
        }, */
  canDelete = false;
  showMenu = false;

  replyOpened = false;

  original: any;
  authorJobRoles: BaseJobUserFragment[] = [];

  commentMenuItems: MenuItem[] = [
    {
      label: 'Delete Comment',
      styleClass: 'delete-comment',
      command: ($event) => {
        // console.log($event);
        this.confirmService.confirm({
          header: 'Are you sure you want to delete this comment?',
          message: 'This action cannot be undone.',
          rejectButtonStyleClass: 'p-button-outlined',
          acceptButtonStyleClass: 'p-button-danger',
          rejectLabel: 'Cancel',
          acceptLabel: 'Delete Comment',
          accept: () => {
            this.store.dispatch(JobToolActions.deleteComment({
              comment: this.comment,
            }));
          },
        })

      }
    }
  ];

  isErrorState = isErrorState;

  get replies() {
    if (!('replies' in this.comment)) {
      return [];
    }

    return this.comment.replies;
  }

  constructor(
    private detailsHelper: DetailsHelperService,
    private store: Store,
    private confirmService: ConfirmationService,
    private auth: PlusAuthenticationService,
    private timezoneHelper: TimezoneHelperService,
    private notify: FreyaNotificationsService,
    private permissions: PermissionService,
  ) {}

  ngOnInit(): void {
    this.setEditMode(false);
    // console.log(this.contents);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.subscribeToEvents(this.editor?.quillEditor);
      this.subscribeToEvents(this.replyEditor?.quillEditor);
    }, 1);
  }

  subscribeToEvents(quill: Quill) {
    quill?.on(
      'time-click',
      (event: TimeBlotClickEvent) => {
        navigator.clipboard.writeText(event.formattedTime).then(() => {
          this.notify.info('Copied to clipboard', event.formattedTime);
        }).catch((err) => {
          console.error('Clipboard copy failed:', err);
        });
      },
    );

  }

  ngOnChanges(changes: SimpleChanges): void {
      if (changes.comment) {
        this.initComment();
      }

      this.authorJobRoles = this.findAuthorJobRoles(this.comment.author);
      // console.log(this.authorJobRoles, this.comment.author, this.job?.users);
  }

  initComment() {
    this.format = 'text';
    if (this.comment?.contents) {
      this.contents = JSON.parse(this.comment.contents);
      this.format = 'object';
    } else {
      this.contents = this.comment.text;
    }

    if (this.comment.system) {
      this.setSystemContents();
    }


    if (!this.comment.thread) {
      this.replyEnabled = false;
    }

    const deletePermission = this.permissions.getPermission('comments.delete');
    const canDeleteAny = permissionHasRestriction(deletePermission, 'overrideOwnership', true);
    const updatePermission = this.permissions.getPermission('comments.update');
    const canUpdateAny = permissionHasRestriction(updatePermission, 'overrideOwnership', true);

    this.canEdit = deletePermission && (this.isOwnComment() || canDeleteAny);
    this.canDelete = updatePermission && (this.isOwnComment() || canUpdateAny);
    if (!this.canDelete) {
      this.showMenu = false;
    }

  }

  /**
   * Sets this.systemContents from this.contents
   * 
   * It prefixes the author and suffixes the time to the contents array.
   * 
   * We need to do this so that system contents shows on one line instead of many
   */
  setSystemContents() {
    let systemContents = new Delta();

    // SET AUTHOR
    let authorName = this.comment.authorName;
    const author = this.comment.author;
    if (author) {
      authorName = [ author.givenName, author.familyName ]
        .filter(Boolean)
        .join(' ');

      if (this.comment.authorName
        && authorName !== this.comment.authorName
        && this.comment.authorName !== 'System'
      ) {
        authorName += ` (${ this.comment.authorName })`;
      }
    }

    authorName = authorName.trim();
    systemContents.insert(
      authorName || 'System',
      {
        details: author && {
          type: 'users',
          item: author,
        },
      },
    );
    systemContents.insert(' ');


    // SET CONTENTS
    if (typeof this.contents === 'string') {
      systemContents.insert(this.contents);
    } else {
      systemContents = systemContents.concat(this.contents);
    }

    systemContents.insert(' ');
    // SET TIME
    const appropriateTime = appropriateTimeMeasurement(this.comment.createdAt * 1000);
    // const time = this.timezoneHelper.dayjs(this.comment.createdAt * 1000);
    // const formattedTime = time.format('YYYY/MM/DD HH:mm:ss');
    systemContents.insert(appropriateTime, {
      time: this.comment.createdAt * 1000,
    });

    this.systemContents = systemContents;

    // console.log(this.systemContents);


  }

  isOwnComment() {
    if (!this.comment) { return false; }
    if (!this.auth.user) { return false; }
    return this.comment?.author?.id === this.auth.user?.id;
  }

  onEditorCreated() {
    // console.log(this.editor);
  }

  viewAuthorInSidePanel(author: BaseUserFragment){
    this.detailsHelper.open('users', {id: author.id});
  }

  findAuthorJobRoles(author: BaseUserFragment): BaseJobUserFragment[] {
    const job = this.job;
    if (!job?.users?.length) { return []; }
    if (!author) { return []; }

    const jobRoles = job.users
      .filter((u) => u.user?.id === author.id)
      .sort((a, b) => a.role > b.role ? -1 : 1);

    return jobRoles;
  }

  setEditMode(isEditing: boolean, check = true) {
    if (check && !isEditing && this.original) {
      this.contents = this.original;
    } else if (check && isEditing) {
      this.original = this.contents;
    }

    this.isEditing = isEditing;

    if (isEditing) {
      setTimeout(() => {
        this.replyEditor?.quillEditor.focus();
      }, 100);
    }
  }

  save() {
    const text = this.editor.quillEditor.getText();
    const contents = this.editor.quillEditor.getContents();

    this.store.dispatch(JobToolActions.updateComment({
      input: {
        ids: [ this.comment.id ],
        update: {
          text,
          contents: JSON.stringify(contents),
        },
      },
    }));

    this.setEditMode(false, false);
  }

  openReply() {
    // if not parent then let parent know that we want to reply

    if (this.parent) {
      this.parent.openReply();
      return;
    }

    if (!this.replyEnabled) { return; }

    this.repliesExpanded = true;
    this.replyOpened = true;

    // focus reply editor, timeout required
    // because we may be in a focus event already
    setTimeout(() => {
      this.replyEditor.quillEditor.focus();
    }, 10);
  }

  closeReply() {
    this.replyOpened = false;
  }

  clearReply() {
    this.replyEditor?.quillEditor?.setContents([{ insert: '\n' }]);
  }

  canAddReply() {
    if (!this.replyEnabled) return false;
    if (!this.job) return false;
    if (this.addReplyIsEmpty()) { return false; }
    // TODO: check permissions

    return true;
  }

  addReply() {
    if (!this.canAddReply()) { return false; }

    const text = this.replyEditor.quillEditor.getText();
    const contents = this.replyEditor.quillEditor.getContents();

    const id = generateUUID();
    this.store.dispatch(JobToolActions.addComment({
      input: {
        id,
        text,
        contents: JSON.stringify(contents),
        threadId: this.comment.thread.id,
      },
    }));
    this.clearReply();
  }

  delete(comment: BaseCommentFragment) {

    this.store.dispatch(JobToolActions.deleteComment({
      comment,
    }));
  }

  addReplyIsEmpty() {
    return isQuillEmpty(this.replyEditor?.quillEditor);
  }
}
