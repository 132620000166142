<div
	class="card"
	*ngIf="user"
>
	<header>
		<h5>{{ user.familyName }}, {{ user.givenName }}</h5>
		<div class="actions">
			<p-button
				label="Cancel"
				(onClick)="onClose()"
				[outlined]="true"
				severity="secondary"
			/>
			<p-button
				label="Save"
				icon="pi pi-check"
				(onClick)="onSave()"
			/>
		</div>
	</header>
	<p-divider></p-divider>
	<div
		class="event-resources"
		[formGroup]="userDetailsForm"
	>
		<section>
			<h6>Salary</h6>
			<div class="section-content">
				<p-inputNumber
					[ngModel]="userSalary"
					(onInput)="onSalaryChange($event)"
					mode="currency"
					currency="USD"
					[min]="0"
					[max]="10000"
				></p-inputNumber>
			</div>

		</section>
		<section>
			<h6>Availability</h6>
			<div class="section-content">
				<input
					type="text"
					pInputTextarea
					[ngModel]="shiftAvailability"
					(input)="onAvailabilityChange($event)"
				/>
			</div>
		</section>
	</div>
</div>