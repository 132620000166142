<p-message *ngIf="error" severity="error" [text]="error"></p-message>

<input
  *ngIf="(currentCommentMode$ | async)?.showSubject"
  type="text"
  #subjectInput
  pInputText
  [(ngModel)]="subject"
  placeholder="Subject"
  class="subject"
/>

<div class="addComment">
  <quill-editor
    [(ngModel)]="newCommentContent"
    format="object"
    [modules]="modules"
    [disabled]="!hasPermission"
    (onContentChanged)="contentChanged($event)"
    (onEditorCreated)="onEditorCreated()"
    placeholder="Write a comment here"
  ></quill-editor>

  <div class="buttons">
    <p-splitButton
      class="btn"
      [label]="(currentCommentMode$ | async)?.name"
      [model]="commentModeMenuItems$ | async"
      (onClick)="addComment()"
    ></p-splitButton>

    <!-- <p-button
		class="btn"
		[disabled]="!hasPermission"
		[label]="closeJobCommentLabel"
		(onClick)="closeJobCommentLabel"
		[outlined]="true"
		(onClick)="addCommentAndCloseJob()"
	>
	</p-button> -->

    <!-- <p-button
		class="btn"
		[disabled]="(mutating$ | async) || !hasPermission"
		label="Take Picture"
		icon="pi pi-camera"
		[outlined]="true"
	>
	</p-button> -->
  </div>
</div>
