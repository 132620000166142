import { on } from '@ngrx/store';

import { cloneDeep } from 'lodash';
import { DEFAULT_INVENTORY, FIELD_CONFIG, JOB_CATEGORIES, JOB_FORM_FIELDS, zoneSensitiveChanges } from 'src/app/global.constants';
import { LoadingState } from 'src/app/utilities/state.util';

import { JobToolActions } from '../../job-tool.actions';
import { JobToolState } from '../../job-tool.reducer';

import { generateFieldsInputFromLatestChanges, generateInputFromLatestCustomerChanges, generateInventoryInputFromLatestChanges, generateJobInputFromLatestChanges, generateJobInputMetadataFromLatestChanges, generateLocationsInputsFromLatestChanges, generateTimeSlotFromLatestChanges, trackChanges, defaultJobState } from '../../jobsv2-helpers';

import { JobEditActions } from '../../jobv2-edit/jobv2-edit-state/jobv2-edit.actions';

import { JobCreateActions } from './jobv2-create.actions';


export const jobCreateReducers = [
    on(JobCreateActions.createJobToolOpened, (state: JobToolState, res): JobToolState => {
        return {
            ...state,
            job: undefined,
            ...defaultJobState as Partial<JobToolState>,
            jobFormMode: 'create',
        };
    }),
    on(JobCreateActions.resetZoneSensitiveInputs, (state: JobToolState): JobToolState => {
        const fieldToExclude = FIELD_CONFIG.customer.howHeard.name;
        const { [fieldToExclude]: _, ...remainingFields } = state.fieldsInput as { [key: string]: any };
        const existingCustomerSelected = state?.customerInput?.id;
        const filteredChanges = state?.changes?.filter(change => !zoneSensitiveChanges.includes(change.fieldName));
        return {
            ...state,
            changes: filteredChanges,
            validationErrors: (!existingCustomerSelected) ? state.validationErrors : {},
            suggestedCustomers: undefined,
            customerSearchInput: undefined,
            resolvedServiceArea: undefined,
            availableTimeSlots: undefined,
            locationsInputs: undefined,
            distances: undefined,
            selectedTimeSlot: undefined,
            fieldsInput: {
                ...remainingFields
            },
            jobTiming: {
                moving: {
                    totalLocationTime: 0,
                    totalTime: 0,
                    totalTravelTime: 0,
                    partialTravelTime: 0,
                }
            },

        }
    }),
    on(JobCreateActions.findTimesSuccess, (state: JobToolState, res): JobToolState => {
        const avails = [];

        for (const window of res.result.data.find.windows) {
            avails.push(...window.startTimes);
        }

        return {
            ...state,
            availableTimeSlots: avails,
            callState: {
                ...state.callState,
                findAvailableTimes: LoadingState.LOADED,
            }
        }
    }),
    on(JobCreateActions.findTimesError, (state: JobToolState, res): JobToolState => {
        return {
            ...state,
            callState: {
                ...state.callState,
                findAvailableTimes: {
                    error: res.error.message,
                }
            }
        }
    }),
    on(JobCreateActions.selectTimeSlot, (state: JobToolState, res): JobToolState => {
        const changes = trackChanges(state.changes, {
            fieldName: JOB_FORM_FIELDS.selectedTimeSlot,
            namespace: 'jobInput',
            value: res.timeSlot,
        });
        return {
            ...state,
            selectedTimeSlot: res.timeSlot,
            changes,
        }
    }),
    on(JobCreateActions.getCurrentUserSuccess, (state: JobToolState, res): JobToolState => {
        return {
            ...state,
            user: res.user,
        }
    }),
    on(JobCreateActions.createCustomer, (state: JobToolState, res): JobToolState => {
        return {
            ...state,
            callState: {
                ...state.callState,
                createCustomer: LoadingState.LOADING,
            }
        }
    }),
    on(JobCreateActions.createCustomerError, (state: JobToolState, res): JobToolState => {
        return {
            ...state,
            callState: {
                ...state.callState,
                createCustomer: {
                    error: 'An error occurred during customer information saving. Some changes might not be saved.' + res.error.message,
                }
            }
        }
    }),
    on(JobCreateActions.updateCustomer, (state: JobToolState, res): JobToolState => {
        return {
            ...state,
            callState: {
                ...state.callState,
                updateCustomer: LoadingState.LOADING,
            }
        }
    }),
    on(JobCreateActions.updateCustomerSuccess, (state: JobToolState): JobToolState => {
        //as update query doesn't return full customer info
        const editInput = { ...state.customerInput };
        return {
            ...state,
            customerInput: {
                ...editInput,
            },
            callState: {
                ...state.callState,
                updateCustomer: LoadingState.LOADED,
            }
        }
    }),
    on(JobCreateActions.updateCustomerError, (state: JobToolState, res): JobToolState => {
        return {
            ...state,
            callState: {
                ...state.callState,
                updateCustomer: {
                    error: 'An error occurred during customer information saving. Some changes might not be saved.' + res.error.message,
                }
            }
        }
    }),
    on(JobCreateActions.createJob, (state: JobToolState, res): JobToolState => {
        return {
            ...state,
            callState: {
                ...state.callState,
                createJob: LoadingState.LOADING,
            }
        }
    }),
    on(JobCreateActions.createJobSuccess, (state: JobToolState, res): JobToolState => {
        return {
            ...state,
            jobInput: {
                ...state.jobInput,
                id: res.jobId,
            },
            callState: {
                ...state.callState,
                createJob: LoadingState.LOADED,
            }
        }
    }),
    on(JobCreateActions.createJobError, (state: JobToolState, res): JobToolState => {
        return {
            ...state,
            callState: {
                ...state.callState,
                createJob: {
                    error: 'An error occurred during job creation. Changes are not saved. ' + res.error.message,
                }
            }
        }
    }),
    on(JobCreateActions.setFields, (state: JobToolState, res): JobToolState => {
        return {
            ...state,
            callState: {
                ...state.callState,
                setFields: LoadingState.LOADING,
            }
        }
    }),
    on(JobCreateActions.setFieldsSuccess, (state: JobToolState, res): JobToolState => {
        return {
            ...state,
            callState: {
                ...state.callState,
                setFields: LoadingState.LOADED,
            }
        }
    }),
    on(JobCreateActions.setFieldsError, (state: JobToolState, res): JobToolState => {
        return {
            ...state,
            callState: {
                ...state.callState,
                setFields: {
                    error: 'An error occurred during saving job information. Some changes might not be saved.' + res.error.message,
                }
            }
        }
    }),
    on(JobCreateActions.setTags, (state: JobToolState, res): JobToolState => {
        return {
            ...state,
            callState: {
                ...state.callState,
                setTags: LoadingState.LOADING,
            }
        }
    }),
    on(JobCreateActions.setTagsSuccess, (state: JobToolState, res): JobToolState => {
        return {
            ...state,
            callState: {
                ...state.callState,
                setTags: LoadingState.LOADED,
            }
        }
    }),
    on(
        JobCreateActions.saveJobForm,
        (state: JobToolState): JobToolState => ({
        ...state,
        callState: {
            ...state.callState,
            createJob: LoadingState.LOADING,
        },
    })),
    on(JobCreateActions.createJobSuccess, (state: JobToolState, res): JobToolState => ({
        ...state,
        callState: {
            ...state.callState,
            createJob: LoadingState.LOADED,
        },
    })),
    on(JobCreateActions.setTagsError, (state: JobToolState, res): JobToolState => {
        return {
            ...state,
            callState: {
                ...state.callState,
                setTags: {
                    error: 'An error occurred during saving job information. Some changes might not be saved.' + res.error.message,
                }
            }
        }
    }),
    on(JobCreateActions.discardJob, (state: JobToolState, res): JobToolState => {
        const prevLocationsInputs = state.locationsInputs?.dock;
        return {
            ...state,
            availableTimeSlots: [],
            customerSearchInput: undefined,
            distances: {},
            fieldsInput: {},
            customerInput: undefined,
            validationErrors: {},
            inventoryInput: cloneDeep(DEFAULT_INVENTORY),
            jobInput: {
                //as selecting from UI is disabled now, predefine it here
                jobCategory: JOB_CATEGORIES[0],
                metadata: {},
            },
            jobTiming: {
                moving: {
                    totalLocationTime: 0,
                    totalTime: 0,
                    totalTravelTime: 0,
                    partialTravelTime: 0,
                }
            },
            locationsInputs: { dock: prevLocationsInputs },
            selectedTimeSlot: undefined,
            suggestedCustomers: [],
            resolvedServiceArea: undefined,
            editCustomerWhenCreateJobMode: false,
            jobTagsIds: [],
            changes: [],
            callState: {
                ...state.callState,
                createJob: LoadingState.INIT,
            }
        }
    }),
    on(JobCreateActions.stateForCreateJobGenerated, (state: JobToolState, { retrievedState }): JobToolState => {
        const hasChanges = retrievedState?.changes?.length;
        if (!hasChanges) {
            const prevLocationsInputs = state.locationsInputs?.dock;
            return {
                ...state,
                availableTimeSlots: [],
                customerSearchInput: undefined,
                distances: {},
                fieldsInput: {},
                customerInput: undefined,
                validationErrors: {},
                inventoryInput: cloneDeep(DEFAULT_INVENTORY),
                jobInput: {
                    jobCategory: JOB_CATEGORIES[0], // Predefine here as selecting from UI is disabled
                    metadata: {},
                },
                jobTiming: {
                    moving: {
                        totalLocationTime: 0,
                        totalTime: 0,
                        totalTravelTime: 0,
                        partialTravelTime: 0,
                    },
                },
                locationsInputs: { dock: prevLocationsInputs },
                selectedTimeSlot: undefined,
                suggestedCustomers: [],
                resolvedServiceArea: undefined,
                editCustomerWhenCreateJobMode: false,
                jobTagsIds: [],
                changes: [],
            };
        }

        const customerFromChanges = generateInputFromLatestCustomerChanges(retrievedState?.changes || []);
        const jobFromChanges = generateJobInputFromLatestChanges(retrievedState?.changes || []);
        const jobInputMetaFromChanges = generateJobInputMetadataFromLatestChanges(retrievedState?.changes || []);
        const locationsInputsFromChanges = generateLocationsInputsFromLatestChanges(retrievedState?.changes || []);
        const inventoryInputFromChanges = generateInventoryInputFromLatestChanges(retrievedState?.changes || []);
        const fieldsInputFromChanges = generateFieldsInputFromLatestChanges(retrievedState?.changes || []);
        const timeSlotFromChanges = generateTimeSlotFromLatestChanges(retrievedState?.changes || []);

        return {
            ...state,
            customerInput: {
                ...state.customerInput,
                ...customerFromChanges,
            },
            jobInput: {
                ...state.jobInput,
                ...jobFromChanges,
                metadata: {
                    ...state.jobInput?.metadata,
                    ...jobInputMetaFromChanges,
                },
            },
            locationsInputs: {
                ...state.locationsInputs,
                ...locationsInputsFromChanges,
            },
            inventoryInput: {
                ...state.inventoryInput,
                ...(inventoryInputFromChanges.inventory ? inventoryInputFromChanges.inventory : {}),
            },
            fieldsInput: {
                ...state.fieldsInput,
                ...fieldsInputFromChanges,
            },
            availableTimeSlots: retrievedState?.availableTimeSlots
                ? [...retrievedState.availableTimeSlots]
                : [],
            selectedTimeSlot: timeSlotFromChanges.selectedTimeSlot ?? state.selectedTimeSlot,
            resolvedServiceArea: {
                ...state.resolvedServiceArea || retrievedState?.resolvedServiceArea,
            },
            addableAdditionalLocations: state.addableAdditionalLocations?.filter(location => {
                return !state.locationsInputs?.hasOwnProperty(location.key);
            }),
            changes:
                [
                    ...retrievedState?.changes,
                    //used to track whether changes came from create mode
                    //and display New / Continue opportunity button
                    {
                        fieldName: 'changes_from_create',
                        namespace: 'changes_from_create',
                        value: 'changes_from_create',
                    }
                ],
        };
    }),
] as const;
