import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { MenuItem } from 'primeng/api';
import { filter, first } from 'rxjs';

import { BaseArtifactFragment } from '../../../generated/graphql.generated';
import { getPandaDocId } from '../../artifacts/artifact.util';
import { FreyaCommonModule } from '../../freya-common/freya-common.module';
import { DetailsHelperService } from '../../services/details-helper.service';
import { DocumentHelperService } from '../../services/document-helper.service';
import { FreyaHelperService } from '../../services/freya-helper.service';
import { FreyaMutateService } from '../../services/freya-mutate.service';
import { SharedModule } from '../../shared/shared.module';
import { TagInfo } from '../job-invoice/job-invoice.component';
import { JobToolActions } from '../job-tool.actions';

type EstimateStatus = 'sent' | 'draft';

export type TagColor = {
  backgroundColor: string;
  text: string;
}

@Component({
  selector: 'app-job-estimate',
  standalone: true,
  imports: [
    FreyaCommonModule,
    SharedModule,
  ],
  templateUrl: './job-estimate.component.html',
  styleUrls: [
    './job-estimate.component.scss',
    '../job-financials/document-style.scss',
  ]
})
export class JobEstimateComponent implements OnChanges {

  @Input() estimate: BaseArtifactFragment;

  status?: EstimateStatus;

  sending = false;

  sendEstimateDisabled = false;

  isPandadoc = false;

  colorMap: Record<EstimateStatus, TagColor> = {
    sent: { backgroundColor: '#E3FBE3', text: '#0A470A' },
    draft: { backgroundColor: '#F0F4F8', text: '#32383E' },
  };

  actionsMenu: MenuItem[] = [
  ];

  tags: TagInfo[] = [];

  constructor(
    private freyaHelper: FreyaHelperService,
    private freyaMutate: FreyaMutateService,
    private documentHelper: DocumentHelperService,
    private store: Store,
    private actions: Actions,
    private detailsHelper: DetailsHelperService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.estimate) {
      this.setTags();
      this.setSendEstimateDisabled();
      this.setActions();
    }
  }

  setTags() {
    this.setStatus();
    this.setIsPandoc();

    const tags: TagInfo[] = [];

    if (!this.isPandadoc) {
      this.tags = tags;
      return;
    }

    if (this.status === 'draft') {
      tags.push({
        text: 'Draft',
        ...this.freyaHelper.getTagColors('neutral'),
      })
    } else if (this.status === 'sent') {
      tags.push({
        text: 'Sent',
        ...this.freyaHelper.getTagColors('success'),
      });
    }

    if (this.estimate.metadata?.signedByCustomer) {
      tags.push({
        text: 'Signed by Customer',
        ...this.freyaHelper.getTagColors('success'),
      });
    }

    if (this.estimate.metadata?.signedByEmployee) {
      tags.push({
        text: 'Signed by Employee',
        ...this.freyaHelper.getTagColors('neutral'),
      });
    }

    this.tags = tags;
  }

  setStatus() {

    const defaultStatus = 'draft';

    if (!this.estimate || !this.estimate.attributes) {
      this.status = defaultStatus;
      return;
    }

    const statusAttribute = this.estimate.attributes.find(a => a.startsWith('status'));

    if (!statusAttribute) {
      this.status = defaultStatus;
      return;
    }

    const [ _, status ] = statusAttribute.split('::');

    if (!this.isValidStatus(status)) {
      this.status = defaultStatus;
      return
    }

    this.status = status;
  }

  isValidStatus(putativeStatus: string): putativeStatus is EstimateStatus {
    return putativeStatus === 'sent' || putativeStatus === 'draft';
  }

  downloadDocument() {
    this.freyaHelper.downloadFile(this.estimate.signedUrl || this.estimate.url, this.estimate.name);
  }

  sendEstimate() {
    this.sending = true;

    this.store.dispatch(JobToolActions.sendEstimateButtonClicked({ estimate: this.estimate }));

    this.actions.pipe(
      ofType(JobToolActions.sendEstimateSuccess, JobToolActions.sendEstimateError),
      filter(({ estimate }) => estimate.id === this.estimate.id),
      first(),
    ).subscribe(() => this.sending = false);
  }

  setSendEstimateDisabled() {
    this.sendEstimateDisabled = this.sending || this.estimate?.metadata?.placeholderEmail;
  }

  setActions() {
    this.actionsMenu = [
      { label: 'View', icon: 'pi pi-eye', command: () => this.openInDialog() },
      { label: 'Download', icon: 'pi pi-download', command: () => this.downloadDocument() },
      { label: 'Delete', icon: 'pi pi-trash', command: () => this.deleteDocument() },
      {
        label: 'View As Employee',
        icon: 'pi pi-external-link',
        command: () => this.documentHelper.openInPandadoc(this.estimate, 'employee'),
        visible: this.isPandadoc,
      },
      {
        label: 'View As Customer',
        icon: 'pi pi-external-link',
        command: () => this.documentHelper.openInPandadoc(this.estimate, 'customer'),
        visible: this.isPandadoc,
      },
      {
        label: 'Print',
        icon: 'pi pi-print',
        command: () => this.documentHelper.printDocument(this.estimate),
        visible: Boolean(this.documentHelper.getPrintableType(this.estimate)),
      },
      {
        label: 'Open in Right Panel',
        icon: 'pi pi-external-link',
        command: () => this.openInRightPanel(),
      },
    ];
  }

  setIsPandoc() {
    this.isPandadoc = this.checkPandadoc();
  }

  get documentType() {
    return this.estimate.metadata?.docTypeName;
  }

  checkPandadoc() {
    if (getPandaDocId(this.estimate)) {
      return true;
    }
    return false;
  }

  openInDialog() {
    this.freyaHelper.openInDialog(this.estimate);
  }

  deleteDocument() {
    this.freyaMutate.openDeleteObject({
      objectId: this.estimate.id,
      objectName: this.estimate.name,
      objectType: 'artifact',
    });
  }

  openInRightPanel() {
      this.detailsHelper.detailsItem.next({ type: 'artifact', item: { id: this.estimate.id } });
  }
}
