import { Injectable } from '@angular/core';

import { map, switchMap } from 'rxjs';

import { ArtifactService } from '../../core/public-api';

@Injectable({
  providedIn: 'root'
})
export class JobCommentArtifactHandlerService {

  constructor(
    private artifactService: ArtifactService,
  ) { }

  uploadFile(file: File) {
    return this.artifactService.createArtifact([{
      contentType: file.type,
      fileSize: file.size,
      name: file.name,
      namespace: 'job-comment-artifact',
      attributes: [
        `lastModified:${file.lastModified}`,
      ],
    }]).pipe(
      // Switch to the inner observable to handle the artifact creation and file upload sequentially
      switchMap((result) => {
        const [artifact] = result.data.createArtifacts.artifacts;
        console.log(artifact);
        const signedPolicy = JSON.parse(artifact.signedPolicy);
  
        console.log(file, signedPolicy);

        // TODO: if existing comment then add to existing comment
  
        // Return the observable from the uploadFile method to chain the operation
        return this.artifactService.uploadFile(file, signedPolicy).pipe(
          map(() => artifact) // Return the artifact after successful upload
        );
      })
    );
  }

}
