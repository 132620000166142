<div class="dispatch-component">
  <div
    class="p-grid main-wrapper"
    cdkDropListGroup
  >
    <div
      class="p-grid main-wrapper"
      cdkDropListGroup
    >
      <div class="p-col-12 p-d-flex p-jc-between p-ai-center section-title">
        <header class="p-d-flex p-jc-between p-ai-center">
          <!-- <h4>Dispatch (BETA)</h4> -->

          <form [formGroup]="filterEventsForm">
            <p-calendar
              formControlName="date"
              placeholder="Select Date"
              [showIcon]="true"
              dateFormat="M d, yy"
            />
          </form>

          <p-button
            label="Today"
            [outlined]="true"
            (onClick)="changeDate('today')"
          />
          <p-buttonGroup class="date-action-buttons">
            <p-button
              icon="pi pi-angle-left"
              [outlined]="true"
              [text]="true"
              size="large"
              (onClick)="changeDate('prev')"
            />
            <p-button
              icon="pi pi-angle-right"
              size="large"
              [outlined]="true"
              [text]="true"
              (onClick)="changeDate('next')"
            />
          </p-buttonGroup>
        </header>
        <div
          class="delete-zone"
          cdkDropList
          (cdkDropListDropped)="removeResource($event)"
          [cdkDropListEnterPredicate]="isResourceFromDispatchEvent"
        >
          <div class="content">
            <i class="pi pi-trash"></i>
            <span>Drop here to remove</span>
          </div>
        </div>
        <div class="p-d-flex action-buttons">
          <p-button
            icon="pi pi-refresh"
            styleClass="p-mr-2"
            [outlined]="true"
            (onClick)="hardRefreshComponent()"
          />
          <!-- <p-button
        class="hide-on-mobile"
        label="Create Job"
        icon="pi pi-plus"
        ></p-button> -->
          <p-button
            *ngIf="aiAssignCrewEnabled$ | async"
            [loading]="assigningCrewWithAI$ | async"
            class="hide-on-mobile"
            label="Assign Crew"
            icon="pi pi-sparkles"
            (onClick)="assignCrewWithAI()"
          ></p-button>
          <p-button
            [outlined]="true"
            label="Send schedule"
            icon="pi pi-envelope"
            styleClass="p-ml-2"
            (onClick)="openSendScheduleDialog()"
            class="hide-on-mobile"
          ></p-button>
        </div>
      </div>
      <main class="p-col-12 p-grid">
        <div class="p-col-12 p-sm-6 p-md-6 p-lg-6 p-xl-3 desktop-separator">
          <app-dispatch-crew></app-dispatch-crew>
        </div>
        <div class="p-col-12 p-sm-6 p-md-6 p-lg-6 p-xl-9">
          <div
            class="p-d-flex p-jc-start p-ai-center todaysJob"
            [formGroup]="filterEventsForm"
          >
            <h5>Today's Jobs</h5>

            <p-dropdown
              formControlName="sortBy"
              [options]="sortByOptions"
            >
              <ng-template
                pTemplate="selectedItem"
                let-selectedOption
              >
                <ng-container *ngTemplateOutlet="
                  dropdownItemTemplate;
                  context: { $implicit: selectedOption, labelPrefix: 'Sort:' }
                "></ng-container>
              </ng-template>
              <ng-template
                pTemplate="item"
                let-item
              >
                <ng-container *ngTemplateOutlet="
                  dropdownItemTemplate;
                  context: { $implicit: item, labelPrefix: '' }
                "></ng-container>
              </ng-template>
            </p-dropdown>

            <p-dropdown
              formControlName="eventStatus"
              [options]="eventStatusOptions"
              panelStyleClass="p-text-capitalize"
            >
              <ng-template
                pTemplate="selectedItem"
                let-selectedOption
              >
                <ng-container *ngTemplateOutlet="
                  dropdownItemTemplate;
                  context: { $implicit: selectedOption, labelPrefix: 'Status:' }
                "></ng-container>
              </ng-template>
              <ng-template
                pTemplate="item"
                let-item
              >
                <ng-container *ngTemplateOutlet="
                  dropdownItemTemplate;
                  context: { $implicit: item, labelPrefix: '' }
                "></ng-container>
              </ng-template>
            </p-dropdown>
          </div>
          <ng-container *ngIf="conflictingEvents$ | async as conflictingEvents">
            <div
              class="conflicting-events-message"
              *ngIf="conflictingEvents.length"
            >
              There are {{ conflictingEvents.length }} events with scheduling
              conflicts:
              <b
                *ngFor="
                let conflictingEvent of conflictingEvents;
                let last = last
              "
                (click)="scrollToEvent(conflictingEvent.event.id)"
              >
                {{ conflictingEvent.customer.name }}
                <small>({{ conflictingEvent.event.job.code }})</small>
                <ng-container *ngIf="!last">, </ng-container>
              </b>
            </div>
          </ng-container>
          <div
            class="p-d-flex p-mt-4 dispatchEvents"
            *ngIf="eventsLoaded; else loading"
          >
            <ng-container *ngIf="events?.length; else noEvents">
              <app-dispatch-card
                class="dispatch-card"
                *ngFor="let event of events; let i = index"
                [event]="event"
                [id]="'event-' + event.event.id"
                (editDispatchEvent)="openEditDispatchEventDialog(i)"
              ></app-dispatch-card>
            </ng-container>
            <ng-template #noEvents>
              <p class="no-events-message">
                No events scheduled for
                {{ filterEventsForm.value.date | date: 'MMM d, yyyy' }}
              </p>
            </ng-template>
          </div>

          <ng-template #loading>
            <div
              class="p-d-flex p-mt-4 dispatchEvents"
              style="height: 65vh"
            >
              <div
                class="dispatch-card"
                *ngFor="let item of [0, 0, 0, 0, 0, 0]"
              >
                <p-skeleton
                  width="85%"
                  height="100%"
                />
              </div>
            </div>
          </ng-template>
        </div>
      </main>
    </div>
  </div>

  <p-dialog
    [modal]="true"
    [(visible)]="visible"
    [closable]="false"
    [draggable]="true"
    [breakpoints]="{ '1024px': '62vw', '510px': '93vw' }"
    [style]="{ width: '35vw' }"
  >
    <ng-template pTemplate="headless">
      <app-dispatch-edit-event
        *ngIf="eventUnderEdittingId !== null"
        [event]="events[eventUnderEdittingId]"
        (closeDialog)="closeDialog()"
      ></app-dispatch-edit-event>
    </ng-template>
  </p-dialog>

  <p-dialog
    [modal]="true"
    [visible]="isEditCrewSalaryDialogOpen$ | async"
    [closable]="false"
    [draggable]="true"
    [breakpoints]="{ '1024px': '62vw', '510px': '93vw' }"
    [style]="{ width: '35vw' }"
  >
    <ng-template pTemplate="headless">
      <app-edit-crew-salary [user]="selectedUserForSalaryEdit$ | async"></app-edit-crew-salary>
    </ng-template>
  </p-dialog>

  <p-dialog
    [modal]="true"
    [(visible)]="isSendScheduleDialogVisible"
    [closable]="false"
    [draggable]="true"
    [breakpoints]="{ '1024px': '62vw', '510px': '93vw' }"
    [style]="{ width: '35vw' }"
  >
    <ng-template pTemplate="headless">
      <app-dispatch-send-schedule (closeDialog)="closeSendScheduleDialog()"></app-dispatch-send-schedule>
    </ng-template>
  </p-dialog>

  <!-- Reusable template for dropdown items -->
  <ng-template
    #dropdownItemTemplate
    let-item
    let-labelPrefix="labelPrefix"
  >
    <div class="flex align-items-center gap-2">
      <i
        class="pi p-mr-2"
        [ngClass]="item.icon"
      ></i>
      <div class="p-text-capitalize">{{ labelPrefix }} {{ item.label }}</div>
    </div>
  </ng-template>