import { gql } from 'graphql-tag';

import { RawUser, User } from './auth';
import { usersBaseGQL, jobsBaseGQL, locationBaseGQL, ZoneDirection } from './common.gql';
import { historyBaseGQL } from './history';
import { UserSearch } from './invoices';
import { Location } from './locations';
import { notificationsBaseGQL } from './notifications';
import { roleBaseGQL } from './roles';
import { accessesBaseGQL } from './shareAccess';
import { refreshTokensBaseGQL, tokensbaseGQL } from './tokens';
import { zonesBaseGql } from './zones';


export interface ListUsersInput extends ZoneDirection {
  limit?: number;
  skip?: number;
  search?: string;
  userIds?: string[];
  sort?: string;
  emails?: string[];
  phones?: string[];
  givenName?: string[];
  familyName?: string[];
  roles?: string[];
  rolesIntersect?: string[];
  skills?: string[];
  skillsIntersect?: string[];
  skillForm?: string;
  courseRole?: string;
  locations?: string[];
  getLocations?: boolean;
  showDeactivated?: boolean;
}

export interface SearchUsersInput {
  filter: ListUsersInput,
  limit: number,
  skip: number,
}

export interface ListUsersOutput {
  users: RawUser[];
}

export interface ListUsersV2Output {
  usersv2: {
    total: number;
    skipped: number;
    limited: number;
    users: RawUser[];
  };
}

export interface GenerateUsersQueryInput {
  roles?: boolean;
  skills?: boolean;
  times?: boolean;
  locations?: boolean;
  tokens?: boolean;
  quotes?: boolean;
  createdQuotes?: boolean;
  // Mutually exclusive with zonesWithParent
  zones?: boolean;
  // Mutually Exclusive with zones
  zonesWithParent?: boolean;
  refreshTokens?: boolean;
  notifications?: boolean;
  payment?: boolean;
  accesses?: boolean;
  history?: boolean;
  availability?: boolean;
  jobs?: boolean;
  twoFactorEnabled?: boolean;
}

export const usersTimeGQL = `
  createdAt {
    unix
  }
  updatedAt {
    unix
  }
  deletedOn {
    unix
  }
`;

export const userRolesGQL = `
  roles {
    ${roleBaseGQL}
    zones {
      nodes{
        id
        name
      }
    }
  }
`;

export const userSkillsGQL = `
  skills {
    id
    name
    shortName
    description
    skillType
  }
`;

export const userLocationsGQL = `
  locations {
    ${locationBaseGQL}
  }
`;

export const userTokensGQL = `
  tokens {
    ${tokensbaseGQL}
  }
`;

export const userQuotesGQL = `
quotes {
  quotes {
    ${tokensbaseGQL}
  }
}
`;

export const userCreatedQuotesGQL = `
createdQuotes {
  quotes {
    ${tokensbaseGQL}
  }
}
`;

export const userZonesGQL = `
  zones {
    nodes {
      ${zonesBaseGql}
    }
  }
`;

export const userZonesWithParentGQL = `
  zones {
    nodes {
      ${zonesBaseGql}
      parent {
        name
        id
      }
    }
  }
`;

export const userRefreshTokensGQL = `
refreshTokens {
  refreshTokens {
    ${refreshTokensBaseGQL}
  }
}
`;

export const userNotificationsGQL = `
  notifications {
    ${notificationsBaseGQL}
  }
`;

export const userAccessGQL = `
accesses {
  accesses {
    ${accessesBaseGQL}
  }
}
`;

export const userHistoryGQL = `
history {
  history {
    ${historyBaseGQL}
  }
}
`;

export const userJobsGQL = `
jobs{
  total
  jobs {
    ${jobsBaseGQL}
  }
}

`;

export function generateUsersQuery(input: GenerateUsersQueryInput) {
  const query = gql`
    query listUsers($filter: UserSearch, $limit: Int, $skip: Int, $sort: String) {
        usersv2(filter: $filter limit: $limit skip: $skip sort: $sort) {
          total
          skipped
          limited
          users{
            ${usersBaseGQL}
            ${input.roles ? userRolesGQL : ``}
            ${input.times ? usersTimeGQL : ``}
            ${input.skills ? userSkillsGQL : ``}
            ${input.locations ? userLocationsGQL : ``}
            ${input.tokens ? userTokensGQL : ``}
            ${input.quotes ? userQuotesGQL : ``}
            ${input.createdQuotes ? userCreatedQuotesGQL : ``}
            ${input.zones ? userZonesGQL : ``}
            ${input.zonesWithParent ? userZonesWithParentGQL : ``}
            ${input.refreshTokens ? userRefreshTokensGQL : ``}
            ${input.notifications ? userNotificationsGQL : ``}
            ${input.accesses ? userAccessGQL : ``}
            ${input.history ? userHistoryGQL : ``}
            ${input.jobs ? jobsBaseGQL : ``}
            ${input.twoFactorEnabled ? `twoFactorEnabled` : ``}
            deletedAt {
              date
            }
          } 
      }
    }
  `;

  return query;
}

export const listUsersQueryV2 = gql`
query listUsers($filter: UserSearch, $limit: Int, $skip: Int, $sort: String) {
    usersv2(filter: $filter limit: $limit skip: $skip sort: $sort) {
     total
     users{
      id
      email
      phone
      givenName
      familyName
      roles {
        id
        name
        zones {
            nodes {
            id
            name
            }
        }
      }
      zones {
        nodes {
          id
          name
        }
      }
    }
  }
}
`;

export interface SetUserProfileInput {
  filter: ListUsersInput;
  givenName: string;
  familyName: string;
  phone: string;
}

export const setUserProfile = gql`
  mutation editUserInformation($filter: UserSearch, $givenName: String, $familyName: String, $phone: String) {
    users(filter: $filter){
      setProfile(givenName: $givenName, familyName: $familyName, phone: $phone)
    }
  }
`;

export const deleteUsers = gql`
  mutation deleteUsers($filter: UserSearch){
    users(filter: $filter){
      delete
    }
  }
`;

export const addRole = gql`
  mutation addRole($filter: UserSearch, $roleId: String!, $addToRoleZones: Boolean) {
    users(filter: $filter){
      addRole(id: $roleId, addToRoleZones: $addToRoleZones)
    }
  }
`;

export const removeRole = gql`
  mutation addRole($filter: UserSearch, $roleId: String!, $removeFromRoleZones: Boolean) {
    users(filter: $filter){
      removeRole(id: $roleId, removeFromRoleZones: $removeFromRoleZones)
    }
  }
`;

// Deprecated
export const listUsersQuery = gql`
query listUsers($filter: UserSearch) {
    users(filter: $filter) {
      id
      email
      phone
      givenName
      familyName
      roles {
        id
        name
        zones {
            nodes {
            id
            name
            }
        }
      }
      zones {
        nodes {
          id
          name
        }
      }
    }
  }
`;

export const editProfileMutation = gql`
mutation editProfile(
  $id: String!,
  $givenName: String,
  $familyName: String,
  $phone: String,
  $salary: Int,
  $shiftAvailability: String
) {
  users(filter: {
    userIds: [ $id ]
    showDeactivated: true
  }) {
    setProfile(givenName: $givenName, familyName: $familyName, phone: $phone, salary: $salary, shiftAvailability: $shiftAvailability)
  }
}
`;

export interface EditProfileInput {
  id: string;
  givenName?: string;
  familyName?: string;
  phone?: string;
}

export interface EditProfileOutput {
  users: { setProfile: number }[];
}

export const deleteUsersMutation = gql`
mutation deleteUsers($userIds: [String!]!) {
  users(filter: {
    userIds: $userIds
    showDeactivated: true
  }) {
    delete
  }
}
`;

export interface DeleteUsersInput {
  userIds: string[];
}

export interface DeleteUsersOutput {
  users: {
    delete: boolean;
  }[];
}


export const deactivateMutation = gql`
  mutation deativate($filter: UserSearch, $reactivate: Boolean) {
    users(filter: $filter){
      deactivate(reactivate: $reactivate) {
        ${usersBaseGQL}
      }
    }
  }
`;

export interface DeactivateUsersInput{
  filter: UserSearch;
  reactivate?: boolean;
}

export interface DeactivateUsersOutput{
  users: [{
    deactivate: User;
  }];
}
