import Quill from 'quill';
import Embed from 'quill/blots/embed';

export const VARIABLE_BLOT_NAME = 'variable';

export enum VariableType {
	TEXT = 'text',
	DATE = 'date',
	CURRENCY = 'currency',
}

export interface VariableBlotData {
	title: string;
	variable: string;
	variableType?: string;
	variableFormat?: string;
	description?: string;
}

export class TemplateVariable extends Embed {
	static blotName = 'variable';
	static tagName = 'span';

	static create(value: VariableBlotData) {
		const node = super.create(value) as HTMLElement;

		node.setAttribute('class', 'chip');

		const { title, variable, variableType = VariableType.TEXT, variableFormat = '', description = '' } = value;

		node.setAttribute('data-title', title);
		node.setAttribute('data-variable', variable);
		node.setAttribute('data-variable-type', variableType);
		node.setAttribute('data-variable-format', variableFormat);
		node.setAttribute('title', description);

		//The title is what the user sees in their editor
		node.innerHTML = title;

		return node;
	}

	static value(node: HTMLElement) {
		return {
			title: node.getAttribute('data-title'),
			variable: node.getAttribute('data-variable'),
			variableType: node.getAttribute('data-variable-type'),
			variableFormat: node.getAttribute('data-variable-format'),
		}
	}
}
