<div class="container">
  <div class="p-grid" [formGroup]="feedbackForm">
    <div class="p-col-12">
      <label>Feedback Type: </label>
      <br />
      <p-dropdown
        class="full-width-dropdown"
        appendTo="body"
        placeholder="Feedback Type"
        [options]="feedbackOptions"
        optionLabel="label"
        optionValue="value"
        formControlName="feedbackType"
      >
        <ng-template let-item pTemplate="item">
          <div class="dropdownItem">
            <label>{{ item.label }}</label>
            <small>{{ item.description }}</small>
          </div>
        </ng-template>
      </p-dropdown>
      <app-validation-messages
        [control]="feedbackForm.get('feedbackType')"
        [requireTouched]="false"
      ></app-validation-messages>
    </div>
    <div class="p-col-12">
      <label>Subject: </label>
      <br />
      <input
        type="text"
        pInputText
        formControlName="subject"
        [maxLength]="250"
        class="p-inputtext-lg full-width"
        placeholder="In summary, how can we help?"
      />
      <app-validation-messages
        [control]="feedbackForm.get('subject')"
        [requireTouched]="true"
      ></app-validation-messages>
    </div>
    <div class="p-col-12">
      <label>Details: </label>
      <br />
      <textarea
        pInputTextarea
        rows="8"
        type="text"
        [maxLength]="2000"
        class="full-width"
        placeholder="(Optional) Please provide any additional information here..."
        formControlName="details"
      >
      </textarea>
      <app-validation-messages
        [control]="feedbackForm.get('details')"
        [requireTouched]="true"
      ></app-validation-messages>
      <p>
        An email will be sent to your address following submission where you can
        reply with attachments such as screenshots or videos. Submitting
        feedback via this form, as opposed to via email, helps us troubleshoot
        problems faster. However, please be as specific as possible so we can
        properly address your feedback.
        {{ frontendVersion }} /
        {{ backendVersion }}
      </p>
    </div>
  </div>
</div>

<div class="footer">
  <button
    pButton
    label="Cancel"
    class="p-button-text"
    [disabled]="formSubmitted"
    icon="pi pi-times"
    (click)="closeDialog()"
  ></button>
  <button
    class="p-button-success"
    pButton
    [disabled]="feedbackForm.invalid || formSubmitted"
    label="{{ formSubmitted ? 'Submitting.....' : 'Submit Feedback' }}"
    icon="pi pi-send"
    (click)="submitFeedback()"
  ></button>
</div>
