import { inject } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { BulkEditCalendarEventOnScheduleGQL, DiscountsGQL, Expense, GenerateChargesWithAiGQL, ListExpensesGQL, ListTaxesGQL, Tax, UpdateJobGQL } from "graphql.generated";
import { DialogService } from 'primeng/dynamicdialog';
import { catchError, distinctUntilChanged, exhaustMap, filter, from, map, of, switchMap, tap, withLatestFrom } from "rxjs";

import { DocumentHelperService } from "src/app/services/document-helper.service";
import { FreyaNotificationsService } from "src/app/services/freya-notifications.service";

import { brandingFeature } from "src/app/state/branding.store";


import { baseEnvironment } from "../../../../environments/environment.base";
import { generateMutationContext } from '../../../core/auth/session';
import { generateUUID } from '../../../utilities/state.util';
import { InventoryActions } from '../../job-inventory/inventory.actions';
import { JobToolActions } from "../../job-tool.actions";
import { jobToolFeature } from "../../job-tool.reducer";

import { UpsellProductsDialogComponent } from '../../job-workorders/upsell-products-dialog/upsell-products-dialog.component';
import { generateListDiscountsQueryVariables, getJobUnsavedChanges } from "../../jobsv2-charges-helpers";
import { JobSummaryActions } from "../summary-state/summary.actions";

import { WorkOrdersActions } from "./workorders.actions";

//load discounts, expenses and taxes every time when state?.job?.id changed
export const triggerLoadAvailableDiscountsOnZoneChangeEffect = createEffect(() => {
  const store = inject(Store);

  return store.select(state => state?.jobTool?.job?.zone?.id).pipe(
    distinctUntilChanged(),
    map(() => WorkOrdersActions.availableDiscountsLoading())
  );
}, { functional: true, dispatch: true });

export const loadTaxesOnZoneChangeEffect = createEffect(() => {
  const store = inject(Store);
  const listTaxesGQL = inject(ListTaxesGQL);

  return store.select(state => state?.jobTool?.job?.zone?.id).pipe(
    distinctUntilChanged(),
    switchMap(() =>
      listTaxesGQL.fetch({}).pipe(
        filter(res => !res.loading),
        map(res => {
          const taxes = res.data?.taxes?.taxes || [];
          return WorkOrdersActions.taxesLoaded({ taxes: taxes as Tax[] });
        })
      )
    )
  );
}, { functional: true, dispatch: true });

export const loadExpensesOnZoneChangeEffect = createEffect(() => {
  const store = inject(Store);
  const listExpensesGQL = inject(ListExpensesGQL);

  return store.select(state => state?.jobTool?.job?.zone?.id).pipe(
    distinctUntilChanged(),
    switchMap(() =>
      listExpensesGQL.fetch({}).pipe(
        filter(res => !res.loading),
        map(res => {
          const expenses = res.data?.expenses?.expenses || [];
          return WorkOrdersActions.expensesLoaded({ expenses: expenses as Expense[] });
        })
      )
    )
  );
}, { functional: true, dispatch: true });


export const loadAvailableDiscountsEffect = createEffect(() => {
  const actions$ = inject(Actions);
  const discountsGQL = inject(DiscountsGQL);
  const store = inject(Store);

  return actions$.pipe(
    ofType(WorkOrdersActions.availableDiscountsLoading),
    withLatestFrom(
      store.select(jobToolFeature.selectJob),
    ),
    filter(([_, job]) => !!job?.zone?.id),
    switchMap(([_, job]) => {

      const {
        listDiscountsQueryVariables,
        headers
      } = generateListDiscountsQueryVariables(job?.zone?.id);

      return from(discountsGQL.fetch(listDiscountsQueryVariables, headers)).pipe(
        map(response => {
          const discounts = response.data.discounts.discounts || [];

          return WorkOrdersActions.availableDiscountsLoadedSuccess({ discounts });
        }),
        catchError(error =>
          of(WorkOrdersActions.availableDiscountsLoadedError({ error }))
        )
      );
    })
  );
}, { functional: true, dispatch: true });

export const saveChangesEffect = createEffect(() => {
  const actions$ = inject(Actions);
  const updateJobGQL = inject(UpdateJobGQL);
  const store = inject(Store);
  const localNotify = inject(FreyaNotificationsService);

  return actions$.pipe(
    ofType(
      WorkOrdersActions.saveButtonClicked,
      InventoryActions.saveButtonClicked,
      WorkOrdersActions.addDocumentButtonClicked,
      // WorkOrdersActions.generateChargesWithAISuccess,
    ),
    withLatestFrom(
      store.select(jobToolFeature.selectJob),
      store.select(jobToolFeature.selectChargesUpdates),
      store.select(jobToolFeature.selectSummaryUpdates),
      store.select(brandingFeature.selectProducts),
      store.select(jobToolFeature.selectUser),
    ),
    // do not execute save until this has been fully saved
    exhaustMap(([action, job, chargesUpdates, summaryUpdates, products, user]) => {
      const updateJobQueryVariables = getJobUnsavedChanges(job, chargesUpdates, summaryUpdates, products, user);

      if (updateJobQueryVariables) {
        const mutationId = generateUUID();
        store.dispatch(WorkOrdersActions.changesSavedInitiated({ mutationId }));
        return updateJobGQL.mutate(updateJobQueryVariables, {context: generateMutationContext()}).pipe(
          map((result) => {
            const {isSuccess, jobs: [updatedJob]} = result.data?.updateJobs;
            if (!isSuccess) {
              throw new Error(`Failed to update job`);
            }

            localNotify.addToast.next({ severity: 'success', summary: 'Job saved' });

            return WorkOrdersActions.changesSavedSuccess({
              mutationId,
              updatedJob,
              originalAction: action,
            });
          }),
          catchError((error) => {
            // Handle error and dispatch error action
            localNotify.error(`Cannot save job changes. Error occurred: ${error}`);
            return of(WorkOrdersActions.changesSavedError({
              error,
              mutationId,
            }));
          })
        );
      } else {
        return of(WorkOrdersActions.changesSavedNoChanges({
          originalAction: action,
        }));
      }
    })
  );
}, { functional: true, dispatch: true });

export const CreateDocumentAfterChangesSavedEffect = createEffect(() => {
  const actions$ = inject(Actions);
  const store = inject(Store);
  const documentHelper = inject(DocumentHelperService);

  return actions$.pipe(
    ofType(
      WorkOrdersActions.changesSavedSuccess,
      WorkOrdersActions.changesSavedNoChanges,
    ),
    filter((action) => action?.originalAction?.type === WorkOrdersActions.addDocumentButtonClicked.type),
    withLatestFrom(
      store.select(jobToolFeature.selectJob),
    ),
    tap(([action, job]) => {
      const originalAction = action.originalAction as ReturnType<typeof WorkOrdersActions.addDocumentButtonClicked>;

      switch (originalAction?.createDocument) {
        case 'estimate':
          documentHelper.openDocumentsDialog({
            jobId: job.id,
            jobCode: job.code,
            preselectTemplateKey: 'standard-documents.estimate',
            autogenerate: true,
          });
          break;
        case 'invoice':
          documentHelper.openCreateInvoiceDialog(job?.id);
          break;
        default:
        case 'all-documents':
          documentHelper.openDocumentsDialog({
            jobId: job.id,
            jobCode: job.code,
          });
          break;
      }
    }),
  );
}, { functional: true, dispatch: false });

// local storage
export const saveUnsavedChangesToLocalStorageEffect = createEffect(() => {
  const actions$ = inject(Actions);
  const store = inject(Store);
  return actions$.pipe(
    ofType(
      WorkOrdersActions.productForAddingSelected,
      WorkOrdersActions.productsForAddingSubmitted,
      WorkOrdersActions.existingChargesUpdated,
      WorkOrdersActions.updateChargesOrder,
      WorkOrdersActions.reorderEvents,
      WorkOrdersActions.removeCharge,
      WorkOrdersActions.addDiscount,
      WorkOrdersActions.createSingleUseDiscount,
      WorkOrdersActions.createCustomCharge,
      WorkOrdersActions.removeDiscount,
      WorkOrdersActions.reorderEvents,
      WorkOrdersActions.cancelEvent,
      WorkOrdersActions.deleteEvent,
      WorkOrdersActions.createEvent,
      WorkOrdersActions.duplicateEvent,
      WorkOrdersActions.cancelButtonClicked,
      WorkOrdersActions.changesSavedSuccess,
      WorkOrdersActions.generateChargesWithAI,
      WorkOrdersActions.generateChargesWithAISuccess,
      JobSummaryActions.updateSummary,
      JobToolActions.jobUpdateSuccess,
      JobToolActions.summaryInPlaceEditingCancelled,
    ),
    withLatestFrom(
      store.select(jobToolFeature.selectJob),
      store.select(jobToolFeature.selectChargesUpdates),
      store.select(jobToolFeature.selectSummaryUpdates),
    ),
    tap(([_, job, chargesUpdates, summaryUpdates]) => {

      if (!job) { return; }

      const existingChanges = JSON.parse(localStorage.getItem(baseEnvironment.lskeys.unsavedChanges) || '{}');

      const customer = job?.users?.find(u => u.role === 'customer').user;

      if(chargesUpdates.length === 0 && summaryUpdates.length === 0) {
        delete existingChanges[job.id];
        localStorage.setItem(baseEnvironment.lskeys.unsavedChanges, JSON.stringify(existingChanges));
        return;
      }

      const updatedChanges = {
        ...existingChanges,
        [job.id]: {
          jobCode: job?.code,
          jobCustomer: (customer.givenName || '') + ' ' + (customer.familyName || ''),
          changes: chargesUpdates,
          summaryUpdates,
        }
      };

      localStorage.setItem(baseEnvironment.lskeys.unsavedChanges, JSON.stringify(updatedChanges));
    }),
    map(() => WorkOrdersActions.workOrderUnsavedChangesDumpedToLS())
  );
}, { functional: true, dispatch: true });

export const loadUnsavedWorkOrdersChangesFromLocalStorageEffect = createEffect(() => {
  const actions$ = inject(Actions);
  const store = inject(Store);

  return actions$.pipe(
    ofType(JobToolActions.paramsSet),
    withLatestFrom(store.select(jobToolFeature.selectJob)),
    map(([_, job]) => {
      const unsavedChanges = JSON.parse(localStorage.getItem(baseEnvironment.lskeys.unsavedChanges) || '{}');
      const jobUnsavedChanges = unsavedChanges[job?.id] || { changes: [], summaryUpdates: [] };

      return WorkOrdersActions.applyUnsavedChangesFromLS({
        unsavedChanges: jobUnsavedChanges.changes,
        unsavedSummaryChanges: jobUnsavedChanges.summaryUpdates,
      });
    })
  );
}, { functional: true, dispatch: true });

//unsaved changes toasts
export const generateUnsavedChangesRemindersEffect = createEffect(() => {
  const actions$ = inject(Actions);

  return actions$.pipe(
    ofType(JobToolActions.jobLoaded),
    map(() => {
      const unsavedChanges = JSON.parse(localStorage.getItem(baseEnvironment.lskeys.unsavedChanges) || '{}');

      const unsavedChangesToastsInfo = Object.keys(unsavedChanges).map(jobId => {
        const { jobCode, jobCustomer } = unsavedChanges[jobId];
        return { jobId, jobCode, jobCustomer };
      });

      return WorkOrdersActions.generateUnsavedChangesToasts({ unsavedChangesToastsInfo });
    })
  );
}, { functional: true, dispatch: true });

export const promoteEventOrOpenInvoiceDialog = createEffect(() => {
  const actions$ = inject(Actions);
  const bulkEditCalendarEventGQL = inject(BulkEditCalendarEventOnScheduleGQL);
  const notify = inject(FreyaNotificationsService);
  return actions$.pipe(
    ofType(WorkOrdersActions.promoteEventButtonClicked),
    switchMap(({ event }) => {
      if ([ 'booked', 'confirmed' ].includes(event.status)) {
        return bulkEditCalendarEventGQL.mutate({
          edits: {
            id: event.id,
            edit: {
              status: 'completed',
            },
          }
        }).pipe(
          map(() => {
            notify.success('Event marked completed');
            return WorkOrdersActions.promoteEventSuccess({ eventId: event.id, status: 'completed' });
          }),
          catchError((error) => of(WorkOrdersActions.promoteEventError({ error }))),
        );
      } else if (event.status === 'completed') {
        return of(WorkOrdersActions.addDocumentButtonClicked({ createDocument: 'invoice' }));
      }
    }),
  );
}, { functional: true, dispatch: true });

export const openInvoiceDialogAfterPromoteEventSuccess = createEffect(() => {
  const actions$ = inject(Actions);
  return actions$.pipe(
    ofType(WorkOrdersActions.promoteEventSuccess),
    switchMap(() => of(WorkOrdersActions.addDocumentButtonClicked({ createDocument: 'invoice' }))),
  );
}, { functional: true, dispatch: true });


export const generateChargesWithAI = createEffect(() => {
  const actions$ = inject(Actions);
  const store = inject(Store);
  const generateChargesWithAIGQL = inject(GenerateChargesWithAiGQL);

  return actions$.pipe(
    ofType(
      WorkOrdersActions.generateChargesWithAI,
      WorkOrdersActions.regenerateChargesWithAI
    ),
    withLatestFrom(
      store.select(jobToolFeature.selectJobId),
    ),
    exhaustMap(([{eventId, type}, jobId]) => {

      // const fetchPolicy = type === WorkOrdersActions.regenerateChargesWithAI.type ? 'network-only' : 'cache-first';

      return generateChargesWithAIGQL.fetch({ input: {
        jobId, eventId,
      }}, {
        fetchPolicy: 'network-only',
      }).pipe(
        map((result) => {
          const {
            selectedProducts = [],
            salesAgentInstructions,
            itinerary,
            crewSize,
          } = result?.data?.generateChargesWithAI;
  
          console.log(`AI Charges Generated`, result.data.generateChargesWithAI);

          // const productsForCharges = [].filter(product => 
            // selectedProducts.some(selectedProduct => selectedProduct.id === product.id));
          return WorkOrdersActions.generateChargesWithAISuccess({
            eventId,
            selectedProducts,
            agentInstructions: salesAgentInstructions,
            itinerary,
            crewSize
          });
        }),
        catchError(error => of(WorkOrdersActions.generateChargesWithAIError({ error })))
      );
    }),
  );
}, { functional: true, dispatch: true });

export const openUpsellProductsDialogEffect = createEffect(() => {
  const actions$ = inject(Actions);
  const dialogService = inject(DialogService);

  return actions$.pipe(
    ofType(WorkOrdersActions.generateChargesWithAISuccess, WorkOrdersActions.openChargesGeneratedWithAISummary),
    map(({eventId}) => {
      dialogService.open(UpsellProductsDialogComponent, {
        header: 'Charges generated with AI',
        data: {eventId}
      });
    }),
  );
}
, { functional: true, dispatch: false });
