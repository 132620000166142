<p-inplace
  closable="closable"
  closeIcon="pi pi-check"
  (onDeactivate)="onDeactivate()"
>
  <ng-template pTemplate="display">
    <span
      class="tag-container"
      *ngIf="hasJobTags$ | async; else clickToAddTags"
    >
      <p-tag
        [rounded]="true"
        *ngFor="let tag of tags$ | async"
        [style]="{ backgroundColor: tag.color }"
        [colorForBackground]="{
          elementSelector: 'span',
          backgroundColor: tag.color
        }"
        [value]="tag.formattedName"
      >
      </p-tag>
    </span>
  </ng-template>
  <ng-template pTemplate="content">
    <p-autoComplete
      class="fixed-width"
      styleClass="p-mr-3"
      [multiple]="true"
      [(ngModel)]="selectedTags"
      [suggestions]="filteredTags$ | async"
      (completeMethod)="searchTags($event)"
      (onSelect)="onTagSelect($event)"
      field="name"
      placeholder="Type tag category or name"
      appendTo="body"
    >
      <ng-template let-tag pTemplate="item">
        <p-tag
          [rounded]="true"
          [style]="{ backgroundColor: tag.color }"
          [colorForBackground]="{
            elementSelector: 'span',
            backgroundColor: tag.color
          }"
          [value]="tag.formattedName"
        >
        </p-tag>
      </ng-template>
      <ng-template let-selectedTagItem pTemplate="selectedItem">
        <p-tag
          [rounded]="true"
          [style]="{ backgroundColor: selectedTagItem.color }"
          [colorForBackground]="{
            elementSelector: 'span',
            backgroundColor: selectedTagItem.color
          }"
          [value]="selectedTagItem.formattedName"
        >
        </p-tag>
      </ng-template>
    </p-autoComplete>
  </ng-template>
</p-inplace>

<ng-template #clickToAddTags>
  <span class="link"> + Click to add status tags </span>
</ng-template>
