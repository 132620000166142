import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ZoneService } from '@karve.it/core';
import { ListZonesFunctionOutput, Zone } from '@karve.it/interfaces/zones';
import {QueryRef} from 'apollo-angular';

import { clone } from 'lodash';
import { filter } from 'rxjs/operators';

import { SubSink } from 'subsink';

import { UpdateZonesGQL } from '../../../generated/graphql.generated';
import { OBJECT_ICON_MAP } from '../../global.constants';
import { AvailableZonesService } from '../../services/available-zones.service';
import { DetailsHelperService } from '../../services/details-helper.service';
import { FreyaMutateService } from '../../services/freya-mutate.service';
import { FreyaNotificationsService } from '../../services/freya-notifications.service';
import { PermissionService } from '../../services/permission.service';
import { RecentItemsService } from '../../services/recent-items.service';
import { parseMenuItemCategoriesVisible } from '../../utilities/menu-item.util';

@Component({
  selector: 'app-franchise-details',
  templateUrl: './franchise-details.component.html',
  styleUrls: ['./franchise-details.component.scss']
})
export class FranchiseDetailsComponent implements OnInit, OnChanges, OnDestroy {

  @Input() franchise: Zone;

  showChangeParent = false;

  updateFranchisetAction = {
    label: 'Edit',
    visible: false,
    icon: 'pi pi-pencil',
    command: () => {
      this.freyaMutate.openMutateObject({
        mutateType: 'update',
        objectType: 'zone',
        object: this.franchise
      });
    },
    disabled: false
  };

  deleteFranchiseAction = {
    label: 'Delete',
    visible: false,
    icon: 'pi pi-trash',
    command: () => {
      console.log('delete');
    },
    disabled: true
  };
  changeParentAction = {
    label: 'Change Parent',
    visible: false,
    icon: 'pi pi-pencil',
    command: () => {
      console.log('Change Parent');
      this.showChangeParent = true;
    },
  };

  subs = new SubSink();

  franchisesQueryRef: QueryRef<ListZonesFunctionOutput>;

  franchiseIcon = `${OBJECT_ICON_MAP.franchise} large-icon`;

  franchiseActions = [{
    label: 'Event Actions',
    items: [
      this.updateFranchisetAction,
      this.deleteFranchiseAction,
      this.changeParentAction,
    ]
  }];

  constructor(
    private zoneService: ZoneService,
    private recentItems: RecentItemsService,
    private detailsHelper: DetailsHelperService,
    private permissionHandler: PermissionService,
    public availableZones: AvailableZonesService,
    public updateZoneGQL: UpdateZonesGQL,
    private localNotif: FreyaNotificationsService,
    private freyaMutate: FreyaMutateService,
  ) { }

  ngOnInit(): void {
    this.initializePermissions();
    this.subs.sink = this.detailsHelper.getObjectUpdates('Franchises')
    .pipe(filter((update) => update.id === this.franchise.id))
    .subscribe(() => this.recentItems.setPinAction(this.franchiseActions, this.franchise, 'franchise'));
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  initializePermissions() {
    this.subs.sink = this.permissionHandler.watchPermissions(
      ['zones.update', 'zones.delete', 'zone.changeStructure'])
      .subscribe((res) => {
        this.updateFranchisetAction.visible = res[0];
        this.deleteFranchiseAction.visible = res[1];
        this.changeParentAction.visible = res[2];

        parseMenuItemCategoriesVisible(this.franchiseActions);
        this.franchiseActions = clone(this.franchiseActions);
      });
  }
  ngOnChanges(): void {
    if (!this.franchise.areas) {
      this.fetchFranchise();
      return;
    };
    this.recentItems.setPinAction(this.franchiseActions, this.franchise, 'franchise');
  }

  fetchFranchise() {
    if (this.franchisesQueryRef) {
      this.franchisesQueryRef.refetch();
      return;
    };
    this.initZoneQuery();
  }

  changeParent(val) {
    console.log(`Changing parent of ${ this.franchise.id } to: `, val);

    this.subs.sink = this.updateZoneGQL.mutate({
      ids: [ this.franchise.id ],
      edit: {},
      parent: val,
    }).subscribe((res) => {
      console.log('Parent changed', res);
      location.reload();
    }, (err) => {
      console.error(`Error changing zone parent`, err);
      this.localNotif.error(`Error changing zone parent`, err.message);
    });
  }

  initZoneQuery() {
    if (!this.franchise?.id) {
      // this.router.navigate(['/']);
      return;
    }
    this.franchisesQueryRef = this.zoneService
      .watchZones({ filter: {ids: [this.franchise?.id]} }, { areas: true, children: true });

    this.subs.sink = this.franchisesQueryRef.valueChanges
    .subscribe((res) => {
      if (res.loading || !res.data?.zones.nodes.length) { return; };
      this.franchise = res.data.zones.nodes[0];
      this.recentItems.setPinAction(this.franchiseActions, this.franchise, 'franchise');
    });
  }

}
