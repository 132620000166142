import { on } from '@ngrx/store';

import { cloneDeep } from 'lodash';
import { LoadingState } from 'src/app/utilities/state.util';

import { JobToolState } from '../../job-tool.reducer';

import { defaultJobState, generateFieldsInputFromLatestChanges, generateInputFromLatestCustomerChanges, generateInventoryInputFromLatestChanges, generateJobInputFromLatestChanges, generateJobInputMetadataFromLatestChanges, generateLocationsInputsFromLatestChanges, generateStateForUpdateJob, generateTimeSlotFromLatestChanges } from '../../jobsv2-helpers';

import { JobEditActions } from './jobv2-edit.actions';

export const jobEditReducers = [
    on(JobEditActions.editJobToolOpened, (state: JobToolState, res): JobToolState => {
        return {
            ...state,
           jobFormMode: 'edit',
        };
    }),
    on(JobEditActions.stateForEditJobGenerated, (state: JobToolState, { retrievedJobState }): JobToolState => {

        const storedServerCopy = retrievedJobState?.job || undefined;
        const changes = retrievedJobState?.changes || [];

        const {
            jobInput,
            customerInput,
            fieldsInput,
            inventoryInput,
            locationsInputs,
            resolvedServiceArea,
            selectedTimeSlot,
        } = generateStateForUpdateJob(state?.job || storedServerCopy) as any;

        if (!changes?.length) {
            return {
                ...state,
                ...defaultJobState as Partial<JobToolState>,
                ...(storedServerCopy ? { job: storedServerCopy } : {}),
                jobInput: cloneDeep(jobInput),
                customerInput: cloneDeep(customerInput),
                fieldsInput: cloneDeep(fieldsInput),
                inventoryInput: inventoryInput && JSON.parse(inventoryInput),
                locationsInputs: cloneDeep(locationsInputs),
                resolvedServiceArea,
                selectedTimeSlot,
            }
        } else {
            const customerFromChanges = generateInputFromLatestCustomerChanges(changes || []);
            const jobFromChanges = generateJobInputFromLatestChanges(changes || []);
            const jobInputMetaFromChanges = generateJobInputMetadataFromLatestChanges(changes || []);
            const locationsInputsFromChanges = generateLocationsInputsFromLatestChanges(changes || []);
            const inventoryInputFromChanges = generateInventoryInputFromLatestChanges(changes || []);
            const fieldsInputFromChanges = generateFieldsInputFromLatestChanges(changes || []);
            const timeSlotFromChanges = generateTimeSlotFromLatestChanges(changes || []);

            return {
                ...state,
                ...defaultJobState as Partial<JobToolState>,
                ...(storedServerCopy ? { job: storedServerCopy } : {}),
                customerInput: {
                    ...cloneDeep(customerInput),
                    ...customerFromChanges,
                },
                jobInput: {
                    ...cloneDeep(jobInput),
                    ...jobFromChanges,
                    metadata: {
                        ...cloneDeep(jobInput.metadata),
                        ...jobInputMetaFromChanges,
                    },
                },
                locationsInputs: {
                    ...cloneDeep(locationsInputs),
                    ...locationsInputsFromChanges,
                },
                inventoryInput: {
                    ...inventoryInput && JSON.parse(inventoryInput),
                    ...(inventoryInputFromChanges.inventory ? inventoryInputFromChanges.inventory : {}),
                },
                fieldsInput: {
                    ...cloneDeep(fieldsInput),
                    ...fieldsInputFromChanges,
                },
                availableTimeSlots: retrievedJobState?.availableTimeSlots
                    ? [...retrievedJobState.availableTimeSlots]
                    : [],
                selectedTimeSlot: timeSlotFromChanges.selectedTimeSlot ?? state.selectedTimeSlot,
                resolvedServiceArea: retrievedJobState?.resolvedServiceArea,
                addableAdditionalLocations: state.addableAdditionalLocations?.filter(location => {
                    return !state.locationsInputs?.hasOwnProperty(location.key);
                }),
                changes: changes || [],
            }
        }
    }),
    on(JobEditActions.updateJob, (state: JobToolState, res): JobToolState => {
        return {
            ...state,
            callState: {
                ...state.callState,
                updateJob: LoadingState.LOADING,
            }
        }
	}),
    on(JobEditActions.updateJobSuccess, (state: JobToolState): JobToolState => {
        return {
            ...state,
            callState: {
                ...state.callState,
                updateJob: LoadingState.LOADED,
            }
        }
	}),
    on(JobEditActions.updateJobError, (state: JobToolState, res): JobToolState => {
        return {
            ...state,
            callState: {
                ...state.callState,
                updateJob: {
                    error: 'An error occurred during job updating. Changes are not saved' + res.error.message,
                }
            }
        }
	}),
    on(JobEditActions.discardChanges, (state: JobToolState, res): JobToolState => {
        return {
            ...state,
            changes: [],
        }
	}),
] as const;
