<!-- <div class="avatar" [class.message]="true" [class.system]="false">
    <p-avatar
        *ngIf="true"
        class="avatar-hover"
        [label]="comment.author | userInitials"
        (click)="viewAuthorInSidePanel(comment.author)"
    ></p-avatar>

    <span class="pin" *ngIf="false"></span>
</div> -->

<div class="system-content-container" *ngIf="(commentMode$ | async).system">
  <quill-editor
    #editor
    class="content viewMode"
    [readOnly]="true"
    [(ngModel)]="systemContents"
    format="object"
    [modules]="viewModules"
  ></quill-editor>

  <!-- <span>&nbsp;</span> -->

  <!-- <app-time-since [unixTime]="comment.createdAt"></app-time-since> -->
</div>

<div
  class="content-container message"
  *ngIf="!(commentMode$ | async).system"
  [class.reply]="!!parent"
>
  <div class="content-header">
    <div>
      <a
        class="userName"
        *ngIf="comment.author"
        (click)="viewAuthorInSidePanel(comment.author)"
      >
        {{ comment.author | userName }}
        <span
          *ngIf="
            comment.authorName &&
            (comment.author | userName) !== comment.authorName
          "
        >
          &nbsp;({{ comment.authorName }})
        </span>
      </a>
      <a class="authorName" *ngIf="!comment.author && comment.authorName">
        {{ comment.authorName }}
      </a>
      ·
      <app-time-since [unixTime]="comment.createdAt"></app-time-since>

      <i
        class="pi pi-spin pi-spinner"
        *ngIf="comment.callState === 'MUTATING'"
      ></i>
      <i
        class="pi pi-exclamation-triangle"
        *ngIf="isErrorState(comment.callState)"
      ></i>
    </div>

    <div *ngIf="!isEditing">
      <span *ngFor="let jobRole of authorJobRoles" class="jobRole">{{
        jobRole.role
      }}</span>
      <span *ngIf="comment.attributes?.length" class="comment-attribute">
        <!-- spamreport -->
        <i
          *ngIf="comment.attributes.includes('spamreport')"
          class="pi pi-ban"
          [ngStyle]="{ color: '#e11d48' }"
          [pTooltip]="
            'Marked as spam by: ' +
            (comment.metadata?.email_spamreport || []).join(', ')
          "
          tooltipPosition="top"
        ></i>

        <!-- unsubscribe -->
        <i
          *ngIf="comment.attributes.includes('unsubscribe')"
          class="pi pi-user-minus"
          [ngStyle]="{ color: '#f97316' }"
          [pTooltip]="
            'Unsubscribed by: ' +
            (comment.metadata?.email_unsubscribe || []).join(', ')
          "
          tooltipPosition="top"
        ></i>

        <!-- group_unsubscribe -->
        <i
          *ngIf="comment.attributes.includes('group_unsubscribe')"
          class="pi pi-user-minus"
          [ngStyle]="{ color: '#f59e0b' }"
          [pTooltip]="
            'Group unsubscribed by: ' +
            (comment.metadata?.email_group_unsubscribe || []).join(', ')
          "
          tooltipPosition="top"
        ></i>

        <ng-container *ngIf="emailStatus$ | async as status">
          <i
            [class]="status.icon"
            [ngStyle]="{ color: status.color }"
            [pTooltip]="customTooltip"
            tooltipPosition="top"
            tooltipStyleClass="email-tooltip"
          ></i>

          <ng-template #customTooltip>
            <div class="tooltip-body">
              <div *ngFor="let group of status.tooltip">
                <strong>{{ group.status | titlecase }}:</strong>
                <div class="ml-2 text-sm">
                  {{ group.emails.join(', ') }}
                </div>
              </div>
            </div>
          </ng-template>
        </ng-container>
      </span>

      <button
        class="pi pi-reply action"
        *ngIf="replyEnabled"
        [disabled]="isErrorState(comment.callState)"
        (click)="openReply()"
      ></button>
      <button
        class="pi pi-pen-to-square action"
        *ngIf="canEdit"
        [disabled]="isErrorState(comment.callState)"
        (click)="setEditMode(true)"
      ></button>
      <button
        class="pi pi-trash action"
        *ngIf="canDelete"
        [disabled]="isErrorState(comment.callState)"
        (click)="delete(this.comment)"
      ></button>

      <button
        class="action pi pi-ellipsis-v"
        *ngIf="showMenu"
        (click)="commentMenu.toggle($event)"
      ></button>
      <p-menu #commentMenu [model]="commentMenuItems" [popup]="true" />
    </div>
  </div>

  <p-message
    *ngIf="isErrorState(comment.callState)"
    severity="error"
    [text]="comment.callState?.error"
  ></p-message>

  <div *ngIf="comment.thread?.subject && !parent" class="subject">
    Subject: {{ comment.thread?.subject }}
  </div>

  <!--
	We need to create two instances with different modules
	because quill doesnt know to reload modules
	-->
  <quill-editor
    #editor
    *ngIf="!isEditing"
    class="content viewMode"
    [readOnly]="true"
    [(ngModel)]="contents"
    [format]="format"
    [modules]="viewModules"
  ></quill-editor>

  <quill-editor
    #editor
    *ngIf="isEditing"
    class="content editMode"
    [readOnly]="false"
    [(ngModel)]="contents"
    [format]="format"
    [modules]="editModules"
  ></quill-editor>

  <div *ngIf="isEditing" class="commentButtons">
    <p-button label="Save Comment" severity="primary" (click)="save()" />
    <p-button
      label="Cancel"
      severity="secondary"
      (click)="setEditMode(false)"
    />
  </div>

  <!-- <span
		class="preserve-whitespace"
	>{{ contents }}</span> -->
  <p-button
    (click)="repliesExpanded = !repliesExpanded"
    class="replyExpander"
    [link]="true"
    *ngIf="showReplies && replies.length && replyEnabled"
  >
    {{ repliesExpanded ? 'Collapse' : 'Expand' }} Replies
  </p-button>

  <div class="replies" *ngIf="showReplies && repliesExpanded && replies">
    <!--
			Show replies here but only if we are the parent
		-->
    <app-job-comment
      *ngFor="let reply of replies"
      class="comment"
      [class.system]="!(comment$ | async).author"
      [class.topLevel]="false"
      [parent]="comment"
      [replyEnabled]="false"
      [showReplies]="false"
      [comment]="reply"
      [job]="job"
    ></app-job-comment>
  </div>

  <div
    class="content-reply"
    *ngIf="replyEnabled && repliesExpanded && !isErrorState(comment.callState)"
  >
    <input
      class="replyInput"
      pInput
      *ngIf="!replyOpened"
      placeholder="Reply..."
      (focus)="openReply()"
    />
    <quill-editor
      #replyEditor
      [class.hidden]="!replyOpened"
      class="content editMode"
      [readOnly]="(mutating$ | async) || isErrorState(comment.callState)"
      [(ngModel)]="replyContents"
      format="object"
      [modules]="editModules"
    ></quill-editor>

    <div class="commentButtons" *ngIf="replyOpened">
      <p-button
        [disabled]="
          (mutating$ | async) ||
          addReplyIsEmpty() ||
          isErrorState(comment.callState)
        "
        label="Add Comment"
        severity="primary"
        (click)="addReply()"
      />
      <p-button
        [disabled]="(mutating$ | async) || isErrorState(comment.callState)"
        label="Cancel"
        severity="secondary"
        (click)="closeReply()"
      />
    </div>
  </div>
</div>
