/* eslint-disable no-fallthrough */
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ZoneService } from '@karve.it/core';
import { Zone } from '@karve.it/interfaces/zones';
import {QueryRef} from 'apollo-angular';

import { cloneDeep } from 'lodash';
import { DialogService } from 'primeng/dynamicdialog';
import { filter } from 'rxjs/operators';
import { EventAttendeeRoles, JOB_SUMMARIES, JobEventStatus, OBJECT_ICON_MAP } from 'src/app/global.constants';
import { HistoryService } from 'src/app/history/history.service';
import { FullJobFragmentWithFields, JobSummaries, parseSummary } from 'src/app/jobsv2/job-tool.reducer';
import { DetailsHelperService } from 'src/app/services/details-helper.service';
import { FreyaHelperService } from 'src/app/services/freya-helper.service';
import { FreyaMutateService } from 'src/app/services/freya-mutate.service';
import { PermissionService } from 'src/app/services/permission.service';
import { RecentItemsService } from 'src/app/services/recent-items.service';
import { getArrivalTimeList } from 'src/app/shared/event-location/calendarevent.util';
import { SubSink } from 'subsink';

import { cmdFlags } from '../../../cmd';

import { BaseChargeFragment, CalendarEventDetailsGQL, CalendarEventDetailsQueryVariables, CalendarEventForDetailsFragment, CalendarEventForScheduleFragment, CalendarEvent_InvoicesFragment, CalendarEvent_JobFragment, Job, UpcomingEventsQuery, User } from '../../../generated/graphql.generated';

import { CommentsComponent } from '../../comments/comments.component';
import { isFinalizedInvoice } from '../../invoices/invoices.utils';

import { BrandingService } from '../../services/branding.service';

import { EventHelperService } from '../../services/event-helper.service';

import { FreyaNotificationsService } from '../../services/freya-notifications.service';
import { EventColorInfo, FullCalendarHelperService } from '../../services/full-calendar-helper.service';
import { YemboHelperService } from '../../services/yembo-helper.service';
import { CREW_IDENTIFIER } from '../../shared/product-categories/product-categories';
import { ShareEventZoneComponent } from '../../shared/share-event-zone/share-event-zone.component';
import { currentTimeSeconds } from '../../time';
import { DataError, parseGraphqlErrors } from '../../utilities/errors.util';
import { DisabledWhen, DisabledWhenTooltips, KarveMenuItem, setMenuItemDisabled } from '../../utilities/menu-item.util';
import { WatchQueryHelper } from '../../utilities/watchQueryHelper';

@Component({
  selector: 'app-calendar-event-details',
  templateUrl: './calendar-event-details.component.html',
  styleUrls: ['./calendar-event-details.component.scss']
})
export class CalendarEventDetailsComponent implements OnInit, OnChanges, OnDestroy {

  @ViewChild('comments') commentsRef: CommentsComponent;

  @Input() event: CalendarEventForDetailsFragment;

  invoice: CalendarEvent_InvoicesFragment['invoices'][number];
  eventDataErrors: DataError[];
  arrivalTimeList: number[] = [];

  // If true this event has been shared from another zone
  sharedFromAnotherZone = false;
  sharedMessage = '';

  mutating = false;

  zones: Zone[] = [];

  eventIcon = `${OBJECT_ICON_MAP['calendar-event']} large-icon`;

  subs = new SubSink();

  canEditLockedEvents = false;

  canEdit = false;

  eventAttendeeRoles = EventAttendeeRoles;
  eventAttendees: {[key in EventAttendeeRoles]: User};

  bookAction: KarveMenuItem = {
    id: 'booked',
    label: 'Mark booked',
    icon: 'pi pi-angle-double-up',
    visible: true,
    command: () => {
      this.updateEventStatus('booked');
    },
    disabledWhen: {
      objectArchived: true,
      eventUnscheduled: true,
      eventLocked: true,
      eventCancelled: true,
      eventInvoiced: true,
      objectDeleted: true,
    }
  };

  confirmAction: KarveMenuItem = {
    id: 'confirm',
    label: 'Mark confirmed',
    icon: 'pi pi-angle-double-up',
    visible: true,
    command: () => {
      this.updateEventStatus('confirmed');
    },
    disabledWhen: {
      objectArchived: true,
      eventUnscheduled: true,
      eventLocked: true,
      eventCancelled: true,
      eventInvoiced: true,
      objectDeleted: true,
    }
  };

  completeAction: KarveMenuItem = {
    id: 'complete',
    label: 'Mark complete',
    icon: 'pi pi-angle-double-up',
    command: () => {
      this.updateEventStatus('completed');
    },
    visible: true,
    disabledWhen: {
      objectArchived: true,
      eventUnscheduled: true,
      eventCancelled: true,
      eventInvoiced: true,
      objectDeleted: true,
    }
  };

  editAction: KarveMenuItem = {
    id: 'edit',
    label: 'Edit',
    icon: 'pi pi-pencil',
    command: () => {
      this.freyaMutate.openMutateObject({
        mutateType: 'update',
        objectType: 'calendarEvent',
        object: this.event,
      });
    },
    disabledWhen: {
      objectArchived: true,
      // eventLocked: true,
      eventCancelled: true,
      eventInvoiced: true,
      objectDeleted: true,
    }
  };

  viewHistoryAction: KarveMenuItem = {
    label: 'View History',
    icon: 'pi pi-book',
    command: () => {
      if (!this.event?.id) { return; };
      this.history.openHistory('CalendarEvent', [ this.event.id ]);
    },
    visible: false
  };

  cancelAction: KarveMenuItem =       {
    id: 'cancel',
    label: 'Cancel',
    icon: 'pi pi-ban',
    disabled: false,
    command: () => {
      this.eventHelperSvc.unscheduleEvent(this.event);
    },
    disabledWhen: {
      objectArchived: true,
      eventUnscheduled: true,
      eventLocked: true,
      eventCancelled: true,
      eventInvoiced: true,
      objectDeleted: true,
    }
  };

  uncancelAction: KarveMenuItem =       {
    id: 'uncancel',
    label: 'Undo Cancel',
    icon: 'pi pi-undo',
    disabled: false,
    command: () => {
      this.eventHelperSvc.uncancelEvent(this.event);
    }
  };

  deleteAction: KarveMenuItem = {
    id: 'delete',
    label: 'Delete',
    icon: 'pi pi-trash',
    disabled: false,
    command: () => {
      this.openDelete();
    },
    disabledWhen: {
      objectArchived: true,
      eventLocked: true,
      eventInvoiced: true,
      jobClosed: true,
      objectDeleted: true,
    }
  };

  shareWithZoneAction: KarveMenuItem = {
    id: 'share',
    label: 'Share',
    icon: 'pi pi-arrow-up-right',
    disabled: false,
    command: () => {
      this.dialogService.open(ShareEventZoneComponent, {
        header: 'Share Event',
        data: {
          event: this.event,
        },
      });
    },
    disabledWhen: {
      eventCancelled: true,
      eventLocked: true,
      eventInvoiced: true,
      objectDeleted: true,
    }
  };

  eventActions = [{
    label: 'Event Actions',
    items: [
      this.bookAction,
      this.confirmAction,
      this.completeAction,
      this.editAction,
      this.viewHistoryAction,
      this.cancelAction,
      this.uncancelAction,
      this.deleteAction,
      this.shareWithZoneAction,
    ]
  }];

  disableIfArchived = ['booked', 'confirm', 'complete', 'edit', 'cancel', 'delete'];

  disableIfUnscheduled = ['booked', 'confirm', 'complete', 'cancel'];

  disableIfLocked = [ 'booked', 'confirm', 'complete', 'edit', 'cancel', 'delete', 'share' ];

  disableIfCancelled = [ 'booked', 'confirm', 'complete', 'edit', 'cancel', 'share' ];

  allActionsDisabled: boolean;

  allActionsDisabledTooltip: string;

  // QUERY VARIABLES
  eventQueryRef: QueryRef<UpcomingEventsQuery>;
  eventQH: WatchQueryHelper = {
    loading: false,
  };

  // DISPLAY VARIABLES
  crewCharges: BaseChargeFragment[];

  colors: EventColorInfo;

  isLocked = true;

  summaries: JobSummaries | object = {};
  jobSummaryData = JOB_SUMMARIES;
  activeTabs: number[] = [];

  constructor(
    private eventHelperSvc: EventHelperService,
    private detailsHelper: DetailsHelperService,
    private freyaHelper: FreyaHelperService,
    private freyaMutate: FreyaMutateService,
    private localNotify: FreyaNotificationsService,
    private fcHelper: FullCalendarHelperService,
    private calendarEventsQuery: CalendarEventDetailsGQL,
    private history: HistoryService,
    private permissionHandler: PermissionService,
    private recentItems: RecentItemsService,
    private zoneService: ZoneService,
    public yemboHelper: YemboHelperService,
    private dialogService: DialogService,
    private brandingService: BrandingService,
  ) { }

  ngOnInit(): void {
    this.initializePermissions();
    this.setDisabledActions();
    this.subs.sink = this.detailsHelper.getObjectUpdates('Events').subscribe(() => {
      this.retrieveEvent();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('event')) {
      this.loadZones();
      this.setIsLocked();
      this.setDisabledActions();
    }
    if (!this.event?.id) { return; }
    this.retrieveEvent();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  filterPastStatuses() {
    if (!this.event?.status || !this.canEdit) { return; }
    this.completeAction.visible = true;
    this.confirmAction.visible = true;
    this.bookAction.visible = true;
    this.cancelAction.visible = true;

    if (cmdFlags.EventDetails_ShowAllEventActions) {
      return;
    }

    const deletableStatuses: JobEventStatus[] = [
      'required',
      'cancelled',
    ];

    this.deleteAction.visible = deletableStatuses.includes(this.event.status as JobEventStatus);

    // Only show one of delete or cancel
    this.cancelAction.visible = !this.deleteAction.visible;

    this.uncancelAction.visible = this.event.status === 'cancelled';

    switch (this.event.status) {
      case 'completed':
        this.completeAction.visible = false;
      case 'confirmed':
        this.confirmAction.visible = false;
      case 'booked':
        this.bookAction.visible = false;
    }
  }

  initializePermissions() {
    this.subs.sink = this.permissionHandler.watchPermissionsAndRestrictions([
      {
        permission: 'history.list',
      },
      {
        permission: 'calendarEvents.edit',
      },
      {
        permission: 'calendarEvents.edit',
        restriction: { modifyLockedEvents: true },
      },
      {
        permission: 'calendarEvents.edit',
        restriction: { shareEvents: true },
      }
    ])
    .pipe(filter((res): res is boolean[] => Array.isArray(res)))
    .subscribe(([ pHistoryList, pCalendarEventsEdit, pCalendarEventsEditLocked, pCalendarEventsShareEvents ]) => {
      this.canEdit = pCalendarEventsEdit;

      this.viewHistoryAction.visible = pHistoryList;
      this.bookAction.visible = pCalendarEventsEdit;
      this.confirmAction.visible = pCalendarEventsEdit;
      this.completeAction.visible = pCalendarEventsEdit;
      this.editAction.visible = pCalendarEventsEdit;
      this.cancelAction.visible = pCalendarEventsEdit;
      this.uncancelAction.visible = pCalendarEventsEdit;
      this.deleteAction.visible = pCalendarEventsEdit;
      this.shareWithZoneAction.visible = pCalendarEventsShareEvents;

      this.canEditLockedEvents = pCalendarEventsEditLocked;

      this.filterPastStatuses();

      if (this.canEditLockedEvents) {
        this.setDisabledActions();
      }
    });
  }

  retrieveEvent() {
    const eventInput: CalendarEventDetailsQueryVariables = {
      calendarEventId: this.event.id,
    };

    if (this.eventQueryRef) {
      this.eventQueryRef.refetch(eventInput);
      return;
    }

    this.eventQueryRef = this.calendarEventsQuery.watch(eventInput, {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
    });

    this.subs.sink = this.eventQueryRef.valueChanges.subscribe((res) => {
      this.eventQH.loading = res.loading;
      if (!res.data?.calendarEvents?.events?.length) { return; }
      this.event = cloneDeep(res.data.calendarEvents.events[0]);
      this.setInvoice();
      this.sharedFromAnotherZone = this.event.sharedZones?.includes(this.brandingService.currentZone().value.id);
      if (this.sharedFromAnotherZone){
        this.sharedMessage = `This event has been shared from ${this.event?.zones.nodes[0]?.name} and therefore can't be edited.`;
      }

      this.setIsLocked();

      this.recentItems.setPinAction(this.eventActions, this.event, 'calendar-event');
      this.crewCharges = this.event?.charges?.filter((c) => c?.product?.category === CREW_IDENTIFIER);

      this.arrivalTimeList = getArrivalTimeList(this.event as any);
      this.colors = this.fcHelper.determineColorsFromEvent(this.event);

      this.summaries = this.parseSummaries(this.event?.job as FullJobFragmentWithFields);
      this.setActiveTabs();

      this.categorizeAttendeesByRole(this.event?.attendees);
      this.filterPastStatuses();
      this.setDisabledActions();
      this.refetchComments();

      this.eventDataErrors = parseGraphqlErrors(res.errors, 'calendarEvents');

      if (!res.loading && res.errors?.length){
        this.localNotify.error(
          'An error has occurred on the following data',
          this.eventDataErrors.map((err) => err.property).join(', ')
        );
      }
    });

  }

  parseSummaries(job: FullJobFragmentWithFields) {
    return {
      crewSummary: parseSummary(job?.crewSummary, 'crew', true),
      adminSummary: parseSummary(job?.adminSummary, 'admin', true),
      customerSummary: parseSummary(job?.customerSummary, 'customer', true),
    };
  }

  summariesProvided() {
    return Object.values(this.summaries).some(summary => !summary.isEmpty);
  }

  setActiveTabs(): void {
    this.activeTabs = this.jobSummaryData
      .map((summary, index) => (!this.summaries[summary.key].isEmpty ? index : null))
      .filter(index => index !== null) as number[];
  }

  refetchComments(){
    // If the comments have not been initialized then it will fetch the data itself
    if (!this.commentsRef || !this.event?.job) { return; }
    this.commentsRef.objectId = this.event.job.id;
    this.commentsRef.fetchComments();
  }

  openJob() {
    this.detailsHelper.detailsItem.next({ item: { id: this.event.job.id }, type: 'job' });
  }

  openInvoice() {
    this.detailsHelper.detailsItem.next({ item: { id: this.invoice.id }, type: 'invoice' });
  }

  openCustomer() {
    const customer = this.freyaHelper.getEventCustomer(this.event);
    if (!customer) {
      this.localNotify.warning(`Cannot open deleted user`);
      return;
    }

    this.detailsHelper.detailsItem.next({
      item: {
        id: customer.id
      },
      type: 'users',
    });
  }

  eventEndIsInPast() {
    return this.event.end <= currentTimeSeconds();
  }

  loadZones() {
    this.subs.sink = this.zoneService.listZones({
      filter: {
        objects: [this.event?.id],
        objectLabel: 'CalendarEvent',
      },
    }, {
      areas: true,
    }).subscribe((res) => {
      this.zones = res.data?.zones.nodes;
    });
  }

  openDelete(){
    this.freyaMutate.openDeleteObject({
      objectId: this.event.id,
      objectName: this.event.title,
      objectType: 'calendarEvent',
    });
  }

  async updateEventStatus(status: JobEventStatus) {
    if (this.mutating) {
      return;
    }
    this.mutating = true;
    await this.eventHelperSvc.updateEventStatus(this.event.id, status).catch((err) => {
      console.error(`Error setting event status in details component`, err);
    });
    this.mutating = false;
  }

  setDisabledActions() {
    if (!this.eventActions?.length) { return; }

    const [ actionsMenu ] = this.eventActions;
    const { items: eventActions } = actionsMenu;

    if (!eventActions?.length) { return; }

    const disabledWhen: DisabledWhen = {
      objectArchived: !this.event?.job || Boolean(this.event.archivedAt),
      eventLocked: this.isLocked,
      eventUnscheduled: !this.event?.start,
      eventCancelled: this.event?.status === 'cancelled',
      eventInvoiced: this.event?.invoices?.some(isFinalizedInvoice) && !this.canEditLockedEvents,
      jobClosed: Boolean(this.event?.job?.closedAt),
      objectDeleted: Boolean(this.event?.deletedAt),
    };

    const disabledWhenTooltips: DisabledWhenTooltips = {
      objectArchived: 'This event belongs to a job that has been archived',
      eventLocked: `This event ended before ${ this.freyaHelper.getFormattedLockDate()}`,
      eventUnscheduled: 'This event has not been scheduled yet',
      eventCancelled: 'This event was cancelled',
      jobClosed: 'This event belongs to a job that was closed',
    };

    for (const action of eventActions) {
      setMenuItemDisabled(action, disabledWhen, disabledWhenTooltips);
    }

    this.allActionsDisabled = eventActions.every((a) => a.disabled);
    this.allActionsDisabledTooltip = this.allActionsDisabled ? 'No actions available' : undefined;
  }

  setInvoice() {
    if (!this.event?.invoices?.length) {
      this.invoice = undefined;
      return
    }
    this.invoice = this.event.invoices.find((i) => !i.voidedAt && !i.deletedAt);
  }

  setIsLocked() {
    if (!this.event?.end || !this.freyaHelper.lockDate){
      this.isLocked = false;
      return;
    }

    this.isLocked = this.freyaHelper.lockDate > this.event?.end;
  }

  categorizeAttendeesByRole(attendees: CalendarEventForDetailsFragment['attendees']) {
    if(!attendees || attendees.length === 0) { return; }

    this.eventAttendees = attendees.reduce((acc, attendee) => {

      if(attendee.role === EventAttendeeRoles.author){
        return acc;
      }

      if (!acc[attendee.role]) {
        acc[attendee.role] = [];
      }

      acc[attendee.role].push(attendee.user);
      return acc;
    }, {} as {[key in EventAttendeeRoles]: User});

    if(Object.keys(this.eventAttendees).length === 0){
      this.eventAttendees = null;
    }
  }

  editCrew() {
    this.freyaMutate.openMutateObject({
      mutateType: 'update',
      objectType: 'calendarEvent',
      object: this.event,
      openToStep: 'Attendees',
    });
  }

}
