import { on } from '@ngrx/store';


import { omit } from 'lodash';
import { ADDABLE_LOCATIONS_V2, JOB_FORM_FIELDS } from 'src/app/global.constants';
import { LoadingState } from 'src/app/utilities/state.util';

import { LocationBase } from '../../../../generated/graphql.generated';
import { JobToolState } from '../../job-tool.reducer';
import { getFilteredLocationInfo, trackChanges } from '../../jobsv2-helpers';

import { JobCreateLocationsActions } from './jobv2-create-locations-state.actions';

export const jobCreateLocationsReducers = [
    on(
        JobCreateLocationsActions.additionalLocationSelected,
        (state: JobToolState, res): JobToolState => {
            return {
                ...state,
                locationsInputs: {
                    ...state.locationsInputs,
                    [res.locationType]: {},
                },
                addableAdditionalLocations: state.addableAdditionalLocations.filter(
                    (location) => location?.key !== res.locationType
                ),
            };
        }
    ),
    on(
        JobCreateLocationsActions.additionalLocationRemoved,
        (state: JobToolState, res): JobToolState => {
            const { [res.locationType]: removed, ...remainingLocationsInputs } = state.locationsInputs;
            const location = ADDABLE_LOCATIONS_V2.find(loc => loc.key === res.locationType) || null;
            let changes;

            if (state.jobFormMode === 'create') {
                changes = state?.changes?.filter((item) => item.fieldName !== res.locationType);
            } else {
                changes = trackChanges(state.changes, {
                    fieldName: res.locationType,
                    namespace: 'locationsInputs',
                    value: state.locationsInputs[res.locationType],
                    remove: true,
                });
            }

            return {
                ...state,
                addableAdditionalLocations: [
                    ...state.addableAdditionalLocations,
                    location
                ],
                changes: changes,
                locationsInputs: {
                    ...remainingLocationsInputs,
                },
            };
        }
    ),
    //changes are being tracked when we select location address
    on(
        JobCreateLocationsActions.additionalLocationChangedType,
        (state: JobToolState, { initialLocationType, updatedLocationType, location }): JobToolState => {
            // Remove initialLocationType
            const { [initialLocationType]: removed, ...remainingLocationsInputs } = state.locationsInputs;
            const initialLocation = ADDABLE_LOCATIONS_V2.find(loc => loc.key === initialLocationType) || null;

            // Filter out the initial location type from addable locations and add the updated location type
            const updatedAddableAdditionalLocations = [
                ...state.addableAdditionalLocations.filter(loc => loc.key !== initialLocationType),
                initialLocation,
            ].filter(loc => loc.key !== updatedLocationType);

            let locationValue = {};

            const initialLocationFromChanges = state?.changes?.find((item) =>
                item.fieldName === initialLocationType);

            if (initialLocationFromChanges) {
                locationValue = {
                    id: initialLocationFromChanges?.value?.id,
                    addressLineOne: initialLocationFromChanges?.value?.addressLineOne,
                    locationType: updatedLocationType,
                }
            }

            const initialLocationFromServerCopy = state?.job?.locations?.find(l => l.locationType === initialLocationType);

            if (!initialLocationFromChanges && initialLocationFromServerCopy) {
                locationValue = {
                    id: initialLocationFromServerCopy.locationId,
                    addressLineOne: initialLocationFromServerCopy.location.addressLineOne,
                    locationType: updatedLocationType,
                }
            }

            const filteredChanges = state?.changes?.filter((item) => item.fieldName !== initialLocationType);

            const changesWithUpdatedLocation = trackChanges(filteredChanges, {
                fieldName: updatedLocationType,
                namespace: 'jobInput',
                value: locationValue,
            });

            return {
                ...state,
                addableAdditionalLocations: updatedAddableAdditionalLocations,
                locationsInputs: {
                    ...remainingLocationsInputs,
                    [updatedLocationType]: locationValue,
                },
                changes: changesWithUpdatedLocation,
            };
        }
    ),
    on(JobCreateLocationsActions.setLocationFields, (state: JobToolState, res): JobToolState => {
        const changes = trackChanges(state.changes, {
            fieldName: res.fieldName,
            namespace: 'fieldsInput',
            key: res.fieldName,
            value: res.fieldValue,
        }, true);
        return {
            ...state,
            fieldsInput: {
                ...state.fieldsInput,
                [res.fieldName]: res.fieldValue,
            },
            changes,
        }

    }),
    on(JobCreateLocationsActions.locationResolveServiceArea, (state: JobToolState, res): JobToolState => {
        return {
            ...state,
            callState: {
                ...state.callState,
                resolveServiceArea: LoadingState.LOADING,
            },
        }
    }),
    on(JobCreateLocationsActions.locationResolveServiceAreaSuccess, (state: JobToolState, res): JobToolState => {
        const area = res?.resolveServiceArea?.matchedArea || res.resolveServiceArea?.closestAreas[0];
        const areaNameId = {
            name: area?.zoneName,
            id: area?.zoneId,
        }
        const changes = trackChanges(state.changes, {
            fieldName: JOB_FORM_FIELDS.resolvedServiceArea,
            namespace: 'jobInput',
            value: areaNameId,
        });
        return {
            ...state,
            resolvedServiceArea: areaNameId,
            zoneToContextInto: area?.zoneParentId,
            changes,
            callState: {
                ...state.callState,
                resolveServiceArea: LoadingState.LOADED,
            },
        }
    }),
    on(JobCreateLocationsActions.locationResolveServiceAreaError, (state: JobToolState, res): JobToolState => {
        return {
            ...state,
            callState: {
                ...state.callState,
                resolveServiceArea: {
                    error: res?.error?.message,
                },
            },
        }
    }),
    on(JobCreateLocationsActions.locationSelectAreaManually, (state: JobToolState, res): JobToolState => {
        const zoneWithoutParent = omit(res.zone, ['parent']);
        const resolvedZone = zoneWithoutParent?.type === 'area' ? {
            name: zoneWithoutParent.name,
            id: zoneWithoutParent.id,
        } : undefined;

        const changes = trackChanges(state.changes, {
            fieldName: JOB_FORM_FIELDS.resolvedServiceArea,
            namespace: 'jobInput',
            value: resolvedZone
        });

        return {
            ...state,
            resolvedServiceArea: resolvedZone,
            zoneToContextInto: res.zone.type === 'area' ? res.zone.parent?.id : res.zone.type === 'franchise' ? res.zone.id : undefined,
            changes,
        };
    }),
    on(
        JobCreateLocationsActions.unitNumberEntered,
        (state: JobToolState, res): JobToolState => {
            //might exist if unit number was added prior to location creation
            const existingLocationInfo = state.locationsInputs[res.locationType] || {};
            const filteredLocationInfo = getFilteredLocationInfo(existingLocationInfo as unknown as LocationBase);
            const changes = trackChanges(state.changes, {
                fieldName: res?.locationType,
                namespace: 'jobInput',
                value: {
                    ...filteredLocationInfo,
                    addressLineTwo: res?.unitNumber,
                    locationType: res?.locationType,
                },
            }, true);
            return {
                ...state,
                locationsInputs: {
                  ...state.locationsInputs,
                  [res.locationType]: {
                    ...(existingLocationInfo?.addressLineOne
                        && {...existingLocationInfo }),
                    addressLineTwo: res?.unitNumber
                  },
                },
                changes,
                callState: {
                  ...state.callState,
                  createLocation: LoadingState.MUTATED,
                },
              };
        }
    ),
    on(
        JobCreateLocationsActions.locationEntered,
        (state: JobToolState, res): JobToolState => {
            const areaCode = res?.location?.areaCode?.replace(' ', '');
            const locationWithFormattedAreaCode = {
                ...res?.location,
                areaCode
            }
            //might exist if unit number was added prior to location creation
            const existingLocationInfo = state.locationsInputs[res.locationType] || {};
            const changes = trackChanges(state.changes, {
                fieldName: res?.locationType,
                namespace: 'jobInput',
                value: {
                    ...locationWithFormattedAreaCode,
                    locationType: res?.locationType,
                    ...(existingLocationInfo?.addressLineTwo
                        && { addressLineTwo: existingLocationInfo?.addressLineTwo }),
                },
            }, true);
            return {
                ...state,
                locationsInputs: {
                  ...state.locationsInputs,
                  [res.locationType]: {
                    ...locationWithFormattedAreaCode,
                    ...(existingLocationInfo?.addressLineTwo
                        && { addressLineTwo: existingLocationInfo?.addressLineTwo }),
                  },
                },
                changes,
                callState: {
                  ...state.callState,
                  createLocation: LoadingState.MUTATED,
                },
              };
        }
    ),
    on(JobCreateLocationsActions.locationChangedForJobWithCharges, (state: JobToolState): JobToolState => {
        const updatedChanges = state?.changes?.filter(
            item => item.fieldName !== JOB_FORM_FIELDS.resolvedServiceArea);
        return {
            ...state,
            changes: updatedChanges,
        }
    }),
    on(JobCreateLocationsActions.restoreServerCopyLocationAndArea, (state: JobToolState): JobToolState => {
        const updatedChanges = state?.changes?.filter(
            item => item.fieldName !== JOB_FORM_FIELDS.resolvedServiceArea
            && item.fieldName !== JOB_FORM_FIELDS.start);
        const serverCopyArea = {
            id: state?.job?.zone?.id,
            name: state?.job?.zone?.name,
        }
        const serverCopyStartLocation = state?.job?.locations?.find(
            item => item.locationType === 'start')?.location
        return {
            ...state,
            changes: updatedChanges,
            resolvedServiceArea: serverCopyArea,
            locationsInputs: {
                ...state.locationsInputs,
                start: serverCopyStartLocation,
            }
        }
    }),
] as const;
