import { FullInvoiceFragment, FullJobFragment, InvoiceBasicDetailsFragment, InvoiceWithArtifactsFragment, } from '../../generated/graphql.generated';
import { getJobCustomer } from '../utilities/job-customer.util';

export type InvoiceStatus = 'generating' | 'draft' | 'finalized' | 'void' | 'deleted';

export function getInvoiceStatus(invoice: FullInvoiceFragment): InvoiceStatus {

    const [ associatedArtifact ] = invoice.artifacts;

    if (invoice.deletedAt) {
        return 'deleted';
    }

    if (invoice.voidedAt) {
      return 'void';
    }

    if (invoice.sentAt || invoice.openedAt) {
      return 'finalized';
    }

    const generated = associatedArtifact.attributes?.includes('status::draft');

    if (!generated) {
      return 'generating';
    }

    return 'draft';
}

export function isFinalizedInvoice<T extends InvoiceWithArtifactsFragment>(invoice: T) {

    if (invoice.deletedAt || invoice.voidedAt) {
        return false;
    }

    return Boolean(invoice.openedAt || invoice.sentAt);
}

export function isDraftInvoice<T extends InvoiceWithArtifactsFragment>(invoice: T) {
  if (invoice.voidedAt || invoice.deletedAt) {
    return false;
  }
  return !invoice.sentAt && !invoice.openedAt;
}

export function getInvoiceName(invoice: InvoiceBasicDetailsFragment, job: FullJobFragment, includeCustomerName?: boolean) {

    if (!invoice) {
      return 'None';
    }

    const fragments = [ job.code ];

    if (includeCustomerName) {
      fragments.push(getJobCustomer(job.users, true) as string);
    }

    fragments.push(`#${ invoice.invoiceNumber }`);

    return fragments.join(' ');
}
