
export interface ColorInfo {
    name: ColorName;
    color: string;
    // The color of text to show above that color
    textColor: string;
    // The lighter version of the color, used as an accent
    lightColor: string;
}

export const COLOR_NAMES = [
    'maroon',
    'brown',
    'red',
    'orange',
    'yellow',
    'pink',
    'beige',
    'green',
    'teal',
    'cyan',
    'navy',
    'blue',
    'lavender',
    'magenta',
    'grey',
    'white',
    'black',
    'orange-yellow'
] as const;

export type ColorName = typeof COLOR_NAMES[number];

export const COLORS: ColorInfo[] = COLOR_NAMES.map((name) => ({
    name,
    color: `var(--${name})`,
    textColor: `var(--${name}-color)`,
    lightColor: `var(--${name}-light)`,
}));

export const COLOR_MAP = COLORS.reduce((p, c) => {
    p[c.name] = c;
    return p;
}, {} as {
    [name in ColorName]: ColorInfo;
});

export function isColorName(str: string): str is ColorName {
    return COLOR_NAMES.includes(str as any);
}

export function isColorScheme(str: string): str is ColorScheme {
    return colorSchemes.includes(str as any);
}

export const appThemes = [
    'ymm',
] as const;

export type AppTheme = typeof appThemes[number];

export const colorSchemes = ['dark', 'light'] as const;
export type ColorScheme = typeof colorSchemes[number];
export let defaultColorScheme: ColorScheme = 'light';
if (isColorScheme(localStorage.getItem('color-scheme'))) {
    defaultColorScheme = localStorage.getItem('color-scheme') as ColorScheme;
}

export const darkenColor = (color: string, percent: number): string => {
    const num = parseInt(color.replace('#', ''), 16);
    const amt = Math.round(2.55 * percent);
    const R = (num >> 16) + amt;
    const G = ((num >> 8) & 0x00ff) + amt;
    const B = (num & 0x0000ff) + amt;
    return `#${(
      0x1000000 +
      (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
      (G < 255 ? (G < 1 ? 0 : G) : 255) * 0x100 +
      (B < 255 ? (B < 1 ? 0 : B) : 255)
    )
      .toString(16)
      .slice(1)}`;
  };