/* eslint-disable @typescript-eslint/naming-convention */
import { gql } from 'graphql-tag';
import { SingleEditInput } from 'graphql.generated';

import { Asset } from './assets';
import { User } from './auth';
import { Charge } from './charges';
import { jobsBaseGQL, locationBaseGQL, ZoneDirection } from './common.gql';
import { CalendarEventLocationInput } from './find';
import { Job } from './jobs';
import { Location } from './locations';
import { Metadata, metaDataGQL } from './metadata';
import { ZonePagination } from './zones';

export interface ListCalendarEventsOutput {
  calendarEvents: CalendarEventPagination;
}

export interface ListRecurringCalendarEventsOutput {
  recurringCalendarEvents: RecurringCalendarEventPagination;
}

export interface CreateCalendarEventOutput {
  createCalendarEvent: CalendarEventPagination;
}

export interface EditCalendarEventOutput {
  editCalendarEvent: boolean;
}

export interface EditRecurringCalendarEventOutput {
  editRecurringCalendarEvent: boolean;
}

export interface RemoveCalendarEventOutput {
  removeCalendarEvent: boolean;
}

export interface RemoveRecurringEventsOutput {
  removeRecurringEvents: boolean;
}

export interface Recurring {
  interval?: string;
  dayOfYear?: number;
  dayOfMonth?: number;
  daysOfWeek: number[];
  dailyInterval?: number;
  // The starting time of the event, in minutes since midnight.
  startTime?: number;
  // The ending time of the event, in minutes since midnight.
  endTime?: number;
}


export interface CalendarEvent {
  id: string;
  // Id shared between related calendar events
  associatedId?: string;
  title: string;
  shortTitle?: string;
  description?: string;
  status?: string;
  notes?: string;
  attributes?: string[];
  start: number;
  end: number;
  job?: Job;
  // The ids of roles affected by the event, if any.
  roles?: string[];
  type: string;
  colour?: string;
  // Information regarding the recurrsion of the event, if applicable
  recurring?: Recurring;
  createdAt: number;
  updatedAt: number;
  deletedOn?: number;
  attendees?: Attendee[];
  /** @deprecated in favour of locations*/
  location?: Location;
  locations?: CalendarEventLocation[];
  topics?: EventTopic[];
  topicText: string;
  metadata: Metadata[];
  assets?: Asset[];
  zones?: ZonePagination;
  // Information regarding the charges associated with an event
  subTotal?: number;
  discountTotal?: number;
  discountedSubTotal?: number;
  taxTotal?: number;
  total?: number;
  charges?: Charge[];
  sequentialOrder?: number;
}

export interface CalendarEventLocation {
  // The id of the relationship
  id: string;
  location: Location;
  type?: string;
  order: number;

  estimatedTimeAtLocation?: number;
  travelTimeToNextLocation?: number;
  travelTimeToNextLocationOffset?: number;

  actualTimeAtLocation?: number;
  actualTimeToNextLocation?: number;
  distanceToNextLocation?: number;
  distanceUnits: string;
  // If true the duration will not automatically be calcaulated
  ignoreInDuration: boolean;
}


export interface EventTopic {
  topicId: string;
  topicDetails?: string;
}


export interface Attendee {
  user: User;
  role: string;
}


export interface RecurringCalendarEventsFilter {
  assocIds: string[];
  eventIds: string[];
  minDate?: number;
  maxDate?: number;
  search?: string;
  locationId?: string;
}


export interface CalendarEventsFilter extends ZoneDirection {
  ids?: string[];
  associatedIds?: string[];
  min?: number;
  max?: number;
  type?: string;
  roles?: string[];
  users?: string[];
  status?: string;
  hasAllAttributes?: string[];
  hasAnyAttributes?: string[];
  search?: string;
  interval?: string;
  dayOfYear?: number;
  dayOfMonth?: number;
  dayOfWeek?: number;
  dailyInterval?: number;
  softDeleted?: boolean;
  locationId?: string;
  hasAttendees?: string[];
  hasTopics?: string[];
  usesAssets?: string[];
}


export interface recurringCreateInput {
  interval: string;
  dayOfYear?: number;
  dayOfMonth?: number;
  daysOfWeek: number[];
  dailyInterval?: number;
  startTime?: number;
  endTime?: number;
}


export interface createCalendarEventInput {
  title: string;
  shortTitle?: string;
  description?: string;
  start: number;
  end: number;
  order?: number;
  sequentialOrder?: number;
  roles?: string[];
  type: string;
  colour?: string;
  recurring?: recurringCreateInput;
  locationId?: string;
  attendees?: AddAttendeeInput[];
  topicIds?: string[];
  topicText?: string;
  duplicateFromOriginId?: string;
}


export interface AddTopicInput {
  topicId: string;
  topicDetails?: string;
}


export interface AddAttendeeInput {
  userId: string;
  role?: string;
}


export interface editCalendarEventInput {
  title?: string;
  shortTitle?: string;
  description?: string;
  status?: string;
  notes?: string;
  attributes?: string[];
  start?: number;
  end?: number;
  sequentialOrder?: number;
  updateEventSequentialOrder?: boolean;
  type?: string;
  colour?: string;
  associatedId?: string;
  locationId?: string;
  setLocations?: SetEventLocationsInput;
  setAttendees?: SetEventAttendeesInput;
  setTopics?: SetEventTopicsInput;
  setAssets?: SetAssets;
  metadata?: string;
}

export interface SetAssets {
  addAssets?: string[];
  removeAssets?: string[];
}

export interface SetEventLocationsInput{
  addLocations?: CalendarEventLocationInput[];
  editLocations?: EditCalendarEventLocationInput[];
  removeLocations?: string[];
}

export interface EditCalendarEventLocationInput{
  id: string;
  type?: string;
  order?: number;
  estimatedTimeAtLocation?: number;
  travelTimeToNextLocationOffset?: number;
  actualTimeAtLocation?: number;
  actualTimeToNextLocation?: number;
  ignoreInDuration: boolean;
}


export interface SetEventAttendeesInput {
  addAttendees?: AddAttendeesInput[];
  removeAttendees?: AddAttendeesInput[];
}


export interface AddAttendeesInput {
  userId: string;
  role?: string;
}


export interface SetEventTopicsInput {
  addTopics?: AddTopicsInput[];
  removeTopics?: string[];
}


export interface AddTopicsInput {
  topicId: string;
  topicDetails?: string;
}


export interface EditRecurringCalendarEventInput {
  title?: string;
  shortTitle?: string;
  description?: string;
  start?: number;
  end?: number;
  type?: string;
  colour?: string;
  startTime?: number;
  endTime?: number;
  locationId?: string;
  status?: string;
  notes?: string;
  attributes?: string[];
  interval?: string;
  dayOfYear?: number;
  dayOfMonth?: number;
  daysOfWeek: number[];
  dailyInterval?: number;
}


export interface RecurringCalendarEvent {
  id: string;
  associatedId?: string;
  title: string;
  type: string;
  shortTitle?: string;
  description?: string;
  status?: string;
  notes?: string;
  attributes?: string[];
  start: number;
  end: number;
  roles?: string[];
  startTime?: number;
  endTime?: number;
  colour?: string;
  location?: Location;
  interval?: string;
  dayOfYear?: number;
  dayOfMonth?: number;
  daysOfWeek: number[];
  dailyInterval?: number;
  createdAt: number;
  updatedAt: number;
  deletedOn?: number;
  calendarEventIds: string[];
  filter?: CalendarEventsFilter;
  skip?: number;
  limit?: number;
}


export interface RecurringCalendarEventPagination {
  total: number;
  skipped: number;
  limited: number;
  events: RecurringCalendarEvent[];
}


export interface CalendarEventPagination {
  total: number;
  skipped?: number;
  limited?: number;
  events: CalendarEvent[];
}


export interface CalendarEventIdPagination {
  total: number;
  ids: string[];
}


export interface RemoveRecurringOutput {
  eventsDetached: number;
  eventsRemoved: number;
  recurringRemoved: number;
}

export interface CalendarEventsInput {
  filter?: CalendarEventsFilter;
  skip?: number;
  limit?: number;
}



export interface RecurringCalendarEventsInput {
  filter?: RecurringCalendarEventsFilter;
  skip?: number;
  limit?: number;
}



export interface CreateCalendarEventInput {
  calendarEvents?: createCalendarEventInput[];
  effectSubZones?: boolean;
}



export interface EditCalendarEventInput {
  edit?: editCalendarEventInput;
  ids?: string[];
  assocIds?: string[];
}


export interface BulkEditCalendarEventInput {
  edits: SingleEditInput[];
}

export interface EditRecurringCalendarEventInput {
  edit?: EditRecurringCalendarEventInput;
  assocIds?: string[];
}



export interface RemoveCalendarEventInput {
  ids?: string[];
}



export interface RemoveRecurringEventsInput {
  assocIds?: string[];
  detach?: boolean;
}

export const baseCalendarEventsGQL = `
  id
  associatedId
  title
  shortTitle
  description
  status
  notes
  attributes
  start
  end
  roles
  type
  colour
`;

export const calendarEventsTopicsGQL = `
  topics {
    topicId
    topicDetails
  }
  topicText
`;

export const calendarEventAttendeesGQL = `
attendees {
  user {
      id
      email
      givenName
      familyName
      phone
  }
  role
}
`;

export const calendarEventsTimesGQL = `
  createdAt
  updatedAt
  deletedOn
`;

export const calendarEventsRecurringGQL = `
  recurring {
    interval
    dayOfYear
    dayOfMonth
    daysOfWeek
    dailyInterval
    startTime
    endTime
  }
`;

export const calendarEventsAssetsGQL = `
  assets {
    id
    name
    owner {
      id
      givenName
      familyName
      email
    }
    type
    description    
  }
`;

export const calendarEventJobGQL = `
  job {
    ${jobsBaseGQL}
  }
`;

export const calendarEventChargesGQL = `
  charges {
    id
    product {
        id
        name
        category
        metadata{
          key
          value
        }
    }
    price {
        id
        amount
        currency
        priceType
    }
    chargeSubTotal
    discountTotal
    discountedSubTotal
    taxTotal
    total
    quantity
    taxes { id }
    amount
    chargeSubTotal
    productName
    user {
        id
    }
    calendarEvent {
        id
    }
    createdAt
    updatedAt
    deletedAt
  }
`;

export const calendarEventZonesGQL = `
  zones{
    nodes {
      id
      name
      type
      color
    }
  }
`;

export const calendarEventTotals = `
  subTotal
  discountTotal
  discountedSubTotal
  taxTotal
  total
`;

export const calendarEventLocationGQL = `
  locations{
    id
    location {
        ${locationBaseGQL}
    }
    type
    order
    estimatedTimeAtLocation
    travelTimeToNextLocation
    travelTimeToNextLocationOffset
    actualTimeAtLocation
    actualTimeToNextLocation
    distanceToNextLocation
    distanceUnits
    ignoreInDuration
  }
`;

export interface GenerateCalendarEventsQueryInput {
  metadata?: boolean;
  attendees?: boolean;
  /** @deprecated */
  location?: boolean;
  locations?: boolean;
  topics?: boolean;
  times?: boolean;
  recurring?: boolean;
  assets?: boolean;
  job?: boolean; // Mutually Exclusive with JobId
  jobBasics?: boolean;
  zones?: boolean;
  charges?: boolean;
  totals?: boolean;
}

export function generateCalendarEventsQuery(input: GenerateCalendarEventsQueryInput) {
  const query = gql`
    query calendarEvents($filter:CalendarEventsFilter, $skip: Int, $limit: Int, ){
      calendarEvents(filter: $filter, skip: $skip, limit: $limit, ){
          total
          skipped
          limited
          events {
            ${baseCalendarEventsGQL}
            ${input.topics ? calendarEventsTopicsGQL : ``}
            ${input.recurring ? calendarEventsRecurringGQL : ``}
            ${input.topics ? calendarEventAttendeesGQL : ``}
            ${input.location ? `location { ${locationBaseGQL} }` : ``}
            ${input.locations ? calendarEventLocationGQL : ``}
            ${input.attendees ? calendarEventAttendeesGQL : ``}
            ${input.metadata ? metaDataGQL : ``}
            ${input.times ? calendarEventsTimesGQL : ``}
            ${input.assets ? calendarEventsAssetsGQL : ``}
            ${input.job ? calendarEventJobGQL : ``}
            ${input.jobBasics ? `job { id code }` : ``}
            ${input.zones ? calendarEventZonesGQL : ``}
            ${input.charges? calendarEventChargesGQL: ``}
            ${input.totals ? calendarEventTotals: ``}
          }
      }		
    }
  `;

  return query;
}


export const recurringCalendarEventsQuery = gql`
	query recurringCalendarEvents($filter: RecurringCalendarEventsFilter, $skip: Int, $limit: Int, ){
		recurringCalendarEvents(filter: $filter, skip: $skip, limit: $limit, ){
			  total
			  skipped
			  limited
			  events{ ${baseCalendarEventsGQL} }
	}		}
`;


export const createCalendarEventMutation = gql`
	mutation createCalendarEvent($calendarEvents: [createCalendarEventInput]!, $effectSubZones: Boolean, ){
		createCalendarEvent(calendarEvents: $calendarEvents, effectSubZones: $effectSubZones, ){
			  total
			  skipped
			  limited
			  events{ id }
	}		}
`;


export const editCalendarEventMutation = gql`
	mutation editCalendarEvent($edit: editCalendarEventInput!, $ids: [String!], $assocIds: [String!], ){
		editCalendarEvent(edit: $edit, ids: $ids, assocIds: $assocIds, ) 

	}
`;


export const editRecurringCalendarEventMutation = gql`
	mutation editRecurringCalendarEvent($edit: EditRecurringCalendarEventInput!, $assocIds: [String!]!, ){
		editRecurringCalendarEvent(edit: $edit, assocIds: $assocIds, ) 

	}
`;


export const removeCalendarEventMutation = gql`
	mutation removeCalendarEvent($ids: [String!]!, ){
		removeCalendarEvent(ids: $ids, ) 

	}
`;


export const removeRecurringEventsMutation = gql`
	mutation removeRecurringEvents($assocIds: [String!]!, $detach: Boolean, ){
		removeRecurringEvents(assocIds: $assocIds, detach: $detach, ) 

	}
`;

