<div class="card">
  <div class="p-grid">
    <div class="p-col">
      <h4 data-cy="products-card-header">Products</h4>
    </div>
    <div class="p-col">
      <div style="white-space: nowrap">
        <span class="p-input-icon-left" style="display: inline-block">
          <i *ngIf="!productsQH.loading" class="pi pi-search"></i>
          <i *ngIf="productsQH.loading" class="pi pi-spin pi-spinner"></i>
          <input
            type="text"
            #productSearchInput
            pInputText
            [(ngModel)]="productsQH.search"
            placeholder="Search"
            data-cy="search-text-field"
          />
        </span>
        <button
          type="button"
          pButton
          pRipple
          class="p-ml-1"
          label=""
          icon="pi pi-times"
          styleClass="p-button-danger"
          (click)="productsQH.search = ''; searchForProducts()"
        ></button>
      </div>
    </div>
    <div class="p-col p-d-flex p-ai-center">
      <label class="p-mr-1">Show Deleted</label>
      <p-inputSwitch
        [(ngModel)]="showDeleted"
        (onChange)="searchForProducts()"
      ></p-inputSwitch>
    </div>
    <div class="p-col">
      <span
        [pTooltip]="
          freyaHelper.inRootOrCorporateZone
            ? 'You cannot create products in your current zone'
            : undefined
        "
      >
        <button
          pButton
          *hasPermission="['products.create']"
          icon="pi pi-plus"
          label="Create Product"
          (click)="openCreateProduct()"
          [disabled]="freyaHelper.inRootOrCorporateZone"
          data-cy="create-button"
        ></button>
      </span>
    </div>
  </div>
</div>

<div class="card">
  <p-table
    [value]="products"
    dataKey="name"
    [paginator]="true"
    [lazyLoadOnInit]="true"
    (onLazyLoad)="retrieveMoreProducts($event)"
    [lazy]="true"
    [totalRecords]="productsQH.total"
    [(rows)]="productsQH.limit"
    [rowsPerPageOptions]="pagination.rowPerPageOptions"
    [loading]="productsQH.loading"
    [showCurrentPageReport]="!productsQH.loading"
    breakpoint="620px"
  >
    <ng-template pTemplate="header">
      <tr>
        <th></th>
        <th>Status</th>
        <th></th>
        <th>Name</th>
        <th>Category</th>
        <th>Zone</th>
        <th>Active Price</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-product let-expanded="expanded">
      <tr class="selectable-row" (click)="viewProductDetails(product)">
        <td class="show-on-stack">
          <span class="p-column-title">Name</span>
          <span class="bold-on-mobile">
            {{ product.name }}
            <ng-container *ngIf="product.deletedAt"> (Deleted)</ng-container>
          </span>
        </td>
        <td class="hide-on-stack">
          <button
            type="button"
            pButton
            pRipple
            [pRowToggler]="product"
            class="p-button-text p-button-rounded p-button-plain"
            [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
            (click)="$event.stopPropagation()"
          ></button>
        </td>
        <td>
          <span class="p-column-title">Status</span>
          <!-- <span class="indicator p-ml-2" [class.active-indicator]="product.active"></span> -->
          <i
            *ngIf="product.active"
            class="pi pi-check status-padding"
            [class.success-text]="true"
          ></i>
          <i
            *ngIf="!product.active"
            class="pi pi-ban status-padding"
            [class.error-text]="true"
          ></i>
        </td>
        <td>
          <span class="p-column-title">Color</span>
          <div
            class="color-square"
            [style.background-color]="product.metadata?.color"
          ></div>
        </td>
        <td class="hide-on-stack" data-cy="span-product-name">
          <span class="p-column-title">Name</span>
          {{ product.name }}
          <span *ngIf="product.deletedAt"> (Deleted)</span>
        </td>
        <td>
          <span class="p-column-title">Category</span>
          {{ product.category ? product.category : 'None' }}
        </td>
        <td>
          <span class="p-column-title">Zone</span>
          <span *ngIf="product.zones && product.zones.length > 0">{{
            product.zones[0].name
          }}</span>
          <span *ngIf="!product.zones || product.zones.length === 0"
            >Zone Not Set</span
          >
        </td>
        <td *ngIf="(product.prices | activePrice)?.priceType !== 'percentage'">
          <span class="p-column-title">Active prices</span>
          {{
            (product.prices | activePrice)
              ? ((product.prices | activePrice).amount / 100
                | currency : (product.prices | activePrice).currency)
              : 'None'
          }}
        </td>
        <td *ngIf="(product.prices | activePrice)?.priceType === 'percentage'">
          <span class="p-column-title">Active Prices</span>
          {{ (product.prices | activePrice).amount }}%
        </td>
        <td class="show-on-stack">
          <span class="p-column-title">Prices</span>
          <button
            type="button"
            pButton
            pRipple
            [pRowToggler]="product"
            class="p-button-text p-button-rounded p-button-plain"
            [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
            (click)="$event.stopPropagation()"
          ></button>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="7">{{ pagination.emptyMessage }}</td>
      </tr>
    </ng-template>
    <ng-template pTemplate="rowexpansion" let-product>
      <tr>
        <td colspan="7" class="indent-left">
          <div class="p-pl-5 p-pl-md-6">
            <h6>Prices</h6>
            <small
              >These are all the historical or alternate prices for this
              product.</small
            >
            <p-table [value]="product.prices" dataKey="id">
              <ng-template pTemplate="header">
                <tr>
                  <th>Status</th>
                  <th>Name</th>
                  <th>Area</th>
                  <th>Amount</th>
                  <th>Currency</th>
                  <!-- <th style="width: 4rem"></th> -->
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-price>
                <tr
                  class="selectable-row"
                  (click)="viewProductDetails(product, price)"
                >
                  <td class="show-on-stack">
                    <span class="p-column-title">Name</span>
                    <span class="bold-on-mobile">
                      {{ price.name }}
                      <ng-container *ngIf="price.deletedAt">
                        (Deleted)</ng-container
                      >
                    </span>
                  </td>
                  <td>
                    <span class="p-column-title">Status</span>
                    <!-- <span class="indicator p-ml-2" [class.active-indicator]="price.active"></span> -->
                    <i
                      *ngIf="price.active"
                      class="pi pi-check status-padding"
                      [class.success-text]="product.active"
                    ></i>
                    <i
                      *ngIf="!price.active"
                      class="pi pi-ban status-padding"
                      [class.error-text]="product.active"
                    ></i>
                  </td>
                  <td class="hide-on-stack">
                    <span class="p-column-title">Name</span>
                    {{ price.name }}
                    <span *ngIf="price.deletedAt"> (Deleted)</span>
                  </td>
                  <td>
                    <span class="p-column-title">Area</span>
                    <span>{{ price.zone?.name }}</span>
                  </td>
                  <td *ngIf="price?.priceType !== 'percentage'">
                    <span class="p-column-title">Amount</span>
                    {{ price.amount / 100 | currency }}
                  </td>
                  <td *ngIf="price?.priceType === 'percentage'">
                    <span class="p-column-title">Amount</span>
                    {{ price.amount }}%
                  </td>
                  <td>
                    <span class="p-column-title">Currency</span>
                    {{ price.currency }}
                  </td>
                  <!-- <td><p-button type="button" icon="pi pi-search"></p-button></td> -->
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td colspan="'6'">
                    There are no prices for this product yet.
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>

<div class="card pricing-engine" *ngIf="pricingEngine?.enabled">
  <div class="p-fluid">
    <div class="field">
      <label for="crewSizePrompt" class="block">Crew Size Prompt</label>
      <textarea
        id="crewSizePrompt"
        rows="5"
        cols="30"
        pInputTextarea
        [(ngModel)]="pricingEngine.crewSizePrompt"
        [autoResize]="true"
      ></textarea>
    </div>

    <div class="field">
      <label for="itineraryPrompt" class="block">Itinerary Prompt</label>
      <textarea
        id="itineraryPrompt"
        rows="5"
        cols="30"
        pInputTextarea
        [(ngModel)]="pricingEngine.itineraryPrompt"
        [autoResize]="true"
      ></textarea>
    </div>

    <div class="field">
      <label for="pricingPrompt" class="block">Pricing Prompt</label>
      <textarea
        id="pricingPrompt"
        rows="5"
        cols="30"
        pInputTextarea
        [(ngModel)]="pricingEngine.pricingPrompt"
        [autoResize]="true"
      ></textarea>
    </div>

    <div class="field">
      <label for="agentInstructionsPrompt" class="block"
        >Agent Instructions Prompt</label
      >
      <textarea
        id="agentInstructionsPrompt"
        rows="5"
        cols="30"
        pInputTextarea
        [(ngModel)]="pricingEngine.agentInstructionsPrompt"
        [autoResize]="true"
      ></textarea>
    </div>

    <div class="field">
      <label for="agentInstructionsPrompt" class="block"
        >Validation Prompt</label
      >
      <textarea
        id="validationPrompt"
        rows="5"
        cols="30"
        pInputTextarea
        [(ngModel)]="pricingEngine.validationPrompt"
        [autoResize]="true"
      ></textarea>
    </div>
  </div>

  <p-button
    label="Update Pricing Engine"
    (click)="updatePricingEngine()"
  ></p-button>
</div>

<app-mutate-product #mutateProduct mutateType="create"></app-mutate-product>
