
import { AfterContentChecked, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TaxService } from '@karve.it/features';
import { CreateTaxInput, Tax, UpdateTaxInput } from '@karve.it/interfaces/taxes';
import { taxTypes } from 'src/app/global.constants';
import { DetailsHelperService } from 'src/app/services/details-helper.service';
import { FreyaHelperService } from 'src/app/services/freya-helper.service';
import { FreyaNotificationsService } from 'src/app/services/freya-notifications.service';
import { SubSink } from 'subsink';

import { MutateObjectComponent, MutateObjectElement } from '../mutate-object/mutate-object.component';

@Component({
  selector: 'app-mutate-tax',
  templateUrl: './mutate-tax.component.html',
  styleUrls: ['./mutate-tax.component.scss']
})
export class MutateTaxComponent implements OnInit, OnDestroy, AfterContentChecked {

  @ViewChild('mutate') mutateRef: MutateObjectComponent;

  // Template Refs
  @ViewChild('name') nameRef: TemplateRef<any>;
  @ViewChild('percentage') percentageRef: TemplateRef<any>;
  @ViewChild('active') activeRef: TemplateRef<any>;
  @ViewChild('zoneDefault') defaultRef: TemplateRef<any>;
  @ViewChild('type') typeRef: TemplateRef<any>;
  @ViewChild('description') descriptionRef: TemplateRef<any>;
  @ViewChild('zones') zonesRef: TemplateRef<any>;

  @Input() mutateType: 'update' | 'create';
  @Input() tax: Tax;

  steps: MutateObjectElement[];

  subs = new SubSink();

  taxForm = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.required, Validators.minLength(2)]),
    active: new UntypedFormControl(true, [Validators.required]),
    isZoneDefault: new UntypedFormControl(true, [Validators.required]),
    percentage: new UntypedFormControl(0, [Validators.required]),
    type: new UntypedFormControl('', [Validators.required]),
    description: new UntypedFormControl('', []),
    zones: new UntypedFormControl([], [Validators.required])
  });
  taxFormValues = this.taxForm.value;

  taxTypes = taxTypes;

  constructor(
    private cd: ChangeDetectorRef,
    private taxService: TaxService,
    private detailsHelper: DetailsHelperService,
    private localNotify: FreyaNotificationsService,
    private freyaHelper: FreyaHelperService
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  ngAfterContentChecked(){
    this.cd.detectChanges();
  }

  openDialog() {
    if (this.mutateType === 'create') {
      this.taxForm.reset(this.taxFormValues);
      this.steps = [
        {name: 'Name', ref: this.nameRef, control: 'name', type: 'text'},
        {name: 'Type', ref: this.typeRef, control: 'type', type: 'text'},
        {name: 'Percentage', ref: this.percentageRef, control: 'percentage', type: 'text'},
        {name: 'Active', ref: this.activeRef, control: 'active', type: 'boolean'},
        {name: 'Zone Default', ref: this.defaultRef, control: 'isZoneDefault', type: 'boolean'},
        {name: 'Description', ref: this.descriptionRef, control: 'description', type: 'text'},
        { name: 'Zones', ref: this.zonesRef, control: 'zones', type: 'array' },
      ];
    } else if (this.mutateType === 'update') {
      this.assignDefaultValues();
      this.steps = [
        {name: 'Name', ref: this.nameRef, control: 'name', type: 'text'},
        {name: 'Type', ref: undefined, control: 'type', type: 'text'},
        {name: 'Percentage', ref: undefined, control: 'percentage', type: 'text'},
        {name: 'Active', ref: this.activeRef, control: 'active', type: 'boolean'},
        {name: 'Zone Default', ref: this.defaultRef, control: 'isZoneDefault', type: 'boolean'},
        {name: 'Description', ref: this.descriptionRef, control: 'description', type: 'text'},
        { name: 'Zones', ref: this.zonesRef, control: 'zones', type: 'array' },
      ];
    }

    this.mutateRef.steps = this.steps;
    this.mutateRef.mutateType = this.mutateType;

    this.mutateRef.openDialog();
  }

  assignDefaultValues(){
    this.taxForm.setValue({
      name: this.tax.name,
      active: this.tax.active,
      isZoneDefault: this.tax.isZoneDefault,
      percentage: this.tax.percentage,
      type: this.tax.type,
      description: this.tax.description,
      zones: this.tax.zones.nodes,
    });
  }

  mutateObject() {
    if (this.mutateType === 'create') {
      this.createTax();
    } else if (this.mutateType === 'update') {

      if (this.taxForm.dirty) {
        this.updateTax();
      }
    }
  }

  createTax(){
    const createInput = {
      ...this.taxForm.value,
      zoneIds: this.taxForm.value.zones.map((zone: any) => zone.id),
    } as CreateTaxInput;

    this.subs.sink = this.taxService.createTax(createInput).subscribe(async (res) => {
      this.detailsHelper.pushUpdate({
        id: res.data.createTax.id,
        type: 'Taxes',
        action: 'create'
      });
      this.mutateRef.closeDialog();
      this.localNotify.addToast.next({severity: 'success', summary: 'Tax created'});
    }, (err) => {
      this.mutateRef.loading = false;
      this.localNotify.apolloError(`Failed to create Tax`,err);
    });
  }

  updateTax(){
    const val = this.taxForm.value;

    const updateInput = {
      taxId: this.tax.id,
      active: val.active,
      description: val.description,
      isZoneDefault: val.isZoneDefault,
      name: val.name,
      zoneIds: val.zones.map((zone: any) => zone.id),
    } as UpdateTaxInput;

    this.subs.sink = this.taxService.updateTax(updateInput).subscribe(async (res) => {
      this.detailsHelper.pushUpdate({
        id:updateInput.taxId,
        type: 'Taxes',
        action: 'update',
      });
      this.mutateRef.closeDialog();
      this.localNotify.addToast.next({severity: 'success', summary: 'Tax updated'});
    }, (err) => {
      this.localNotify.apolloError(`Failed to update tax`,err);
      this.mutateRef.loading = false;
    });
  }


}
