import { AfterContentChecked, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { JobService } from '@karve.it/features';
import { RawUser } from '@karve.it/interfaces/auth';
import { Job, ListJobsFunctionOutput } from '@karve.it/interfaces/jobs';
import { ListTransactionsOutput } from '@karve.it/interfaces/transactions';
import { Store } from '@ngrx/store';
import { QueryRef } from 'apollo-angular';

import { cloneDeep } from 'lodash';
import { JOB_ROLE_MAP, transactions } from 'src/app/global.constants';
import { DetailsHelperService } from 'src/app/services/details-helper.service';
import { FreyaHelperService } from 'src/app/services/freya-helper.service';
import { FreyaNotificationsService } from 'src/app/services/freya-notifications.service';
import { SubSink } from 'subsink';

import { BaseInvoiceFragment, CreateTransactionGQL, FullJobFragment, FullTransactionFragment, GetConfigValuesGQL, InvoicesGQL, InvoicesQueryVariables, MutationCreateTransactionArgs, MutationUpdateTransactionArgs, UpdateTransactionGQL } from '../../../generated/graphql.generated';
import { getInvoiceName } from '../../invoices/invoices.utils';
import { remainingBalance } from '../../jobs/jobs.util';
import { JobToolActions } from '../../jobsv2/job-tool.actions';
import { convertCentsToDollars, convertDollarsToCents } from '../../lib.ts/currency.util';
import { DocumentHelperService } from '../../services/document-helper.service';

import { getApolloErrorMessage } from '../../utilities/errors.util';
import { MutateObjectComponent, MutateObjectElement } from '../mutate-object/mutate-object.component';

@Component({
  selector: 'app-mutate-transaction',
  templateUrl: './mutate-transaction.component.html',
  styleUrls: ['./mutate-transaction.component.scss']
})
export class MutateTransactionComponent implements OnInit, OnDestroy, AfterContentChecked {

  @ViewChild('mutate') mutateRef: MutateObjectComponent;

  // Template Refs
  @ViewChild('amount') amountRef: TemplateRef<any>;
  @ViewChild('transactionType') transactionTypeRef: TemplateRef<any>;
  @ViewChild('customer') customerRef: TemplateRef<any>;
  @ViewChild('job') jobRef: TemplateRef<any>;
  @ViewChild('paymentType') paymentTypeRef: TemplateRef<any>;
  @ViewChild('stage') stageRef: TemplateRef<any>;
  @ViewChild('sendReceipt') sendReceiptRef: TemplateRef<any>;

  @ViewChild('customerReview') customerReviewRef: TemplateRef<any>;
  @ViewChild('jobReview') jobReviewRef: TemplateRef<any>;


  @Input() mutateType: 'update' | 'create';
  @Input() transaction: FullTransactionFragment;
  @Input() user: RawUser;
  @Input() job: FullJobFragment;
  @Input() invoice: BaseInvoiceFragment;
  @Input() defaultAmount: number;
  @Input() canEditAmount = true;

  steps: MutateObjectElement[];

  subs = new SubSink();
  possibleTimes = [];

  transactionForm = new UntypedFormGroup({
    amount: new UntypedFormControl(undefined, [ Validators.required ]),
    customer: new UntypedFormControl('', [ Validators.required,]),
    job: new UntypedFormControl('', [ Validators.required,]),
    paymentType: new UntypedFormControl(undefined, [ Validators.required,]),
    stage: new UntypedFormControl('paid', [ Validators.required,]),
    type: new UntypedFormControl('payment'),
    invoice: new UntypedFormControl(undefined, [ Validators.required ]),
    sendReceipt: new UntypedFormControl(true),
  }, {
    validators: [this.transactionAmountValidator()]
  });

  transactionFormValues = this.transactionForm.value;

  transactionQueryRef: QueryRef<ListTransactionsOutput>;

  // Jobs Variables
  jobQueryRef: QueryRef<ListJobsFunctionOutput>;
  jobSuggestions: Job[];

  invoices: Record<string, BaseInvoiceFragment> = {};

  invoiceOptions: { label: string; value: string }[] = [];

  // invoicesQuery: QueryRef<InvoicesQuery, InvoicesQueryVariables>;

  paymentTypes = [];
  transactionStages = transactions.stages;

  requireInvoice = [ 'payment', 'refund' ];

  open = false;

  transactionTypeOptions = [
    {
      label: 'Payment',
      value: 'payment',
      disabled: true,
    },
    {
      label: 'Deposit',
      value: 'deposit',
    },
    {
      label: 'Refund',
      value: 'refund',
      disabled: true,
    },
  ];

  constructor(
    private detailsHelper: DetailsHelperService,
    private localNotify: FreyaNotificationsService,
    private jobService: JobService,
    private cd: ChangeDetectorRef,
    private getConfigGQL: GetConfigValuesGQL,
    private freyaHelper: FreyaHelperService,
    private createTransactionGQL: CreateTransactionGQL,
    private updateTransactionGQL: UpdateTransactionGQL,
    private invoicesGQL: InvoicesGQL,
    private documentHelper: DocumentHelperService,
    private store: Store,
  ) { }

  ngOnInit(): void {
    this.watchPaymentTypes();
    this.watchInvoices();
    this.watchTransactionTypeChanges();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  ngAfterContentChecked() {
    this.cd.detectChanges();
  }

  watchPaymentTypes() {
    this.subs.sink = this.getConfigGQL.watch({keys: ['payment-type.*']}).valueChanges
    .subscribe((res) => {
      if (!res?.data?.getConfigValues) { return; }
      for (const configValue of res.data.getConfigValues) {
        try {
          const [_namespace, key] = configValue.key.split('.');

          const paymentTypeConfig = JSON.parse(configValue.value);

          if (paymentTypeConfig.name && paymentTypeConfig.enabled) {
            this.paymentTypes.push({
              id: configValue.id,
              key,
              value: paymentTypeConfig.name
            });
          }
        } catch (e) {
          console.error(e);
          continue;
        }
      }
    });
  }

  watchTransactionTypeChanges() {

    const invoiceControl = this.transactionForm.controls.invoice;

    this.subs.sink = this.transactionForm.controls.type.valueChanges.subscribe((transactionType) => {

      if (this.requireInvoice.includes(transactionType)) {
        invoiceControl.setValidators(Validators.required);
      } else {
        invoiceControl.clearValidators();
      }

      invoiceControl.updateValueAndValidity();
    });
  }

  watchInvoices() {
    this.subs.sink = this.detailsHelper.getObjectUpdates([ 'Invoice', 'Jobs' ]).subscribe((update) => {
      const invoiceJobUpdated = update.type === 'Jobs' && update.id === this.job?.id;

      if (update.type === 'Invoice' || invoiceJobUpdated) {
        this.retrieveInvoices();
      }

    });
  }

  mutateObject() {
    if (this.mutateType === 'create') {
      // Create
      this.createTransaction();
    } else if (this.mutateType === 'update') {
      // Update
      this.updateTransaction();
    }
  }

  openDialog() {

    this.open = true;
    this.retrieveInvoices();

    if (this.mutateType === 'create') {
      this.steps = [
        { name: 'Customer', ref: this.customerRef, control: 'customer', type: 'complex', reviewRef: this.customerReviewRef},
        { name: 'Job', ref: this.jobRef, control: 'job', type: 'complex', reviewRef: this.jobReviewRef },
        { name: 'Type and Invoice', ref: this.transactionTypeRef, control: 'type', type: 'func',
          // eslint-disable-next-line max-len
          reviewFunc: () => (`${ this.transactionForm.value.type } / ${ this.transactionForm.value.invoice ? getInvoiceName(this.invoices[this.transactionForm.value.invoice], this.job) : 'No Invoice' }`),
          invalidFunc: () => this.transactionForm.controls.type.invalid || this.transactionForm.controls.invoice.invalid,
        },
        { name: 'Amount', ref: this.canEditAmount ? this.amountRef : undefined, control: 'amount', type: 'currency' },
        { name: 'Payment Type', ref: this.paymentTypeRef, control: 'paymentType', type: 'func',
          reviewFunc: ((val) => this.paymentTypes.find((pt) => pt.id === val)?.value),
        },
        { name: 'Stage', ref: this.stageRef, control: 'stage', type: 'text', reviewOnly: true },
        {
          name: 'Send Receipt', ref: this.sendReceiptRef, control: 'sendReceipt', type: 'boolean',
          reviewOnly: true,
          removed: !this.user?.email,
        },
      ];

      this.transactionForm.reset({
        ...this.transactionFormValues,
        sendReceipt: Boolean(this.user?.email),
      });
    } else if (this.mutateType === 'update') {
      this.steps = [{ name: 'Stage', ref: this.stageRef, control: 'stage', type: 'text' },];
      this.setFormValues();
    }

    this.mutateRef.mutateType = this.mutateType;
    this.mutateRef.steps = this.steps;

    // Assign Defaults and hide unecessary steps
    if (this.user) {
      this.transactionForm.controls.customer.setValue(this.user);
      this.mutateRef.removeStep('Customer');
    }
    if (this.job) {
      this.transactionForm.controls.job.setValue(this.job);
      this.mutateRef.removeStep('Job');
    }

    if(this.defaultAmount) {
      this.transactionForm.controls.amount.setValue(convertCentsToDollars(this.defaultAmount));
    }

    this.setPaymentTypeOptionsDisabled();

    this.setPreselectedInvoice();

    this.mutateRef.openDialog();
  }

  setFormValues() {
    this.transactionForm.reset({
      amount: convertCentsToDollars(this.transaction.amount),
      customer: this.transaction.customer,
      job: this.transaction.job,
      paymentType: this.transaction.paymentType.name,
      stage: this.transaction.stage,
      sendReceipt: Boolean(this.transaction.customer.email),
    });
  }

  checkType() {
    if(this.transactionForm.value.paymentType === 'stripe'){
      this.mutateRef.removeStep('Stage', 'pending');
    } else {
      this.mutateRef.addStep('Stage');
    }
  }

  async createTransaction(){
    const value = this.transactionForm.value;

    const createTransactionInput: MutationCreateTransactionArgs = {
      amount: convertDollarsToCents(value.amount),
      customerId: this.user.id,
      jobId: value.job.id,
      stage: value.stage,
      type: value.type,
      paymentTypeId: value.paymentType,
      disableSendReceipt: !this.transactionForm.value.sendReceipt,
      invoiceId: value?.invoice,
    };

    const [jobZone] = await this.freyaHelper.getZones({filter: {
      objects: [value.job.id],
      objectLabel: 'Job',
    }});

    this.subs.sink = this.createTransactionGQL.mutate(
      createTransactionInput,
      {
        context: {
          headers: {'x-zone': jobZone.id},
        }
      }
    ).subscribe((res) => {

      this.detailsHelper.pushUpdate({
        id:'this.transaction.id',
        type:'Transactions',
        action:'create',
      });

      this.closeDialog();
      this.localNotify.addToast.next({severity: 'success', summary: 'Transaction added'});

      this.store.dispatch(JobToolActions.createTransactionSuccess({ jobId: value.job.id }));

      if (value.invoice) {
        this.detailsHelper.pushUpdate({
          id: value.invoice,
          type: 'Invoice',
          action: 'update',
        });
      }

    }, (error) => {

      // If the transaction fails display that message, otherwise display success and the other error
      if (getApolloErrorMessage(error) === 'Failed to create transaction'){
        this.localNotify.apolloError(`Failed to create transaction`, error);
      } else {
        this.localNotify.success(`Transaction created`);
        this.closeDialog();
        this.detailsHelper.pushUpdate({
          id: '',
          type:'Transactions',
          action:'create',
        });
      }

      this.store.dispatch(JobToolActions.createTransactionError({ error, jobId: value.job.id }));

      this.mutateRef.loading = false;
      this.localNotify.apolloError(`Error after creation`, error);
    });
  }

  updateTransaction() {
    const updateTransactionInput = {
      transactionId: this.transaction.id,
      stage: this.transactionForm.value.stage
    } as MutationUpdateTransactionArgs;

    this.subs.sink = this.updateTransactionGQL.mutate(updateTransactionInput).subscribe((res) => {
      this.detailsHelper.pushUpdate({
        id:'this.transaction.id',
        type:'Transactions',
        action:'update'
      });

      this.closeDialog();
      this.localNotify.addToast.next({severity: 'success', summary: 'Transaction updated'});
      this.transaction = {
        ...this.transaction,
        stage: this.transactionForm.value.stage,
      }
      this.store.dispatch(JobToolActions.updateTransactionSuccess({ transaction: res.data.updateTransaction }))
    }, (error) => {
      this.mutateRef.loading = false;
      this.localNotify.apolloError(`Failed to update transaction`,error);
      this.store.dispatch(JobToolActions.updateTransactionError({ error }))
    });
  }

  closeDialog() {
    this.open = false;
    this.mutateRef.closeDialog();
  }

  searchJobs(event) {
    const input = {
      filter: {
        userSearch: {
          role: JOB_ROLE_MAP.customerRole,
          userId: this.transactionForm.value.customer.id
        }
      }, limit: 10
    };

    if (this.jobQueryRef) {
      this.jobQueryRef.resetLastResults();
      this.jobQueryRef.setVariables(input);
    } else {
      this.jobQueryRef = this.jobService.watchJobs(
        input,
        { users: true, }
      );

      this.subs.sink = this.jobQueryRef.valueChanges.subscribe((res) => {
        if (res.networkStatus === 7) {
          this.jobSuggestions = cloneDeep(res.data.jobs.jobs);
        } else {
          this.jobSuggestions = cloneDeep(this.jobSuggestions);
        }
      });
    }
  }

  setMaxValue() {
    const invoiceBalance = remainingBalance(this.invoices[this.transactionForm.value.invoice]);
    const jobBalance = remainingBalance(this.job);
    let value = Math.min(invoiceBalance, jobBalance);
    value = Math.max(value, 0);
    this.transactionForm.controls.amount.setValue(value);
  }

  /**
   * Validates the form based on which transaction type is selected
   *
   * @param formGroup The form to validate
   * @returns Errors for the form is applicable
   */
  transactionAmountValidator(): ValidatorFn {
    return (form: UntypedFormGroup): ValidationErrors | null => {

      const formValue = form.value;

      switch (formValue.type){
        case 'payment':
            if (formValue.amount > remainingBalance(formValue?.invoice)){
              return { transactionExceedsRemaining: 'Payment cannot exceed the remaining balance of the selected invoice' };
            }
            if (formValue.amount > remainingBalance(this.job)) {
              return { transactionExceedsRemaining: 'Payment cannot exceed the remaining balance of the job' };
            }
          break;
        case 'deposit':
            const invoiceSelected = formValue?.invoice !== null && formValue?.invoice !== undefined;

            if (invoiceSelected && formValue.amount > remainingBalance(formValue?.invoice)){
              return { transactionExceedsRemaining: 'Deposit cannot exceed the remaining balance of the selected invoice' };
            }
            break;
        case 'refund':
            if (formValue.amount > 0){
              return { transactionRefundNegative: true };
            }
          break;
      }

      return null;
    };
  }

  retrieveInvoices() {

    if (!this.job) { return; }
    if (!this.open) { return; }
    
    this.subs.sink = this.invoicesGQL.fetch(this.generateVariables(), {fetchPolicy: 'network-only'}).subscribe((res) => {

      if (res.loading) { return; }

      const invoiceMap = {};

      for (const invoice of res.data.invoices.invoices) {
        invoiceMap[invoice.id] = invoice;
      }

      this.invoices = invoiceMap;

      this.invoiceOptions = res.data.invoices.invoices.map((i) => ({
        label: getInvoiceName(i, this.job),
        value: i.id,
      })).concat({
        label: 'None',
        value: undefined,
      });

      this.setPaymentTypeOptionsDisabled();
      this.setPreselectedInvoice();
    });
  }

  setPreselectedInvoice() {

    let preselectedInvoice = this.invoiceOptions.find((i) => i.value === this.invoice?.id);

    // If selected transaction type requires invoice, try defaulting to first invoice
    if (this.requireInvoice.includes(this.transactionForm.value.type)) {

      const [ firstInvoice ] = this.invoiceOptions;

      preselectedInvoice = preselectedInvoice || firstInvoice;
    }

    this.transactionForm.controls.invoice.setValue(preselectedInvoice?.value);
    this.transactionForm.updateValueAndValidity();
  }

  setPaymentTypeOptionsDisabled() {

      const updatedTransactionTypes = [ ...this.transactionTypeOptions ];

      const paymentOption = updatedTransactionTypes.find((t) => t.value === 'payment');

      const refundOption = updatedTransactionTypes.find((t) => t.value === 'refund');

      // If there are any options other than 'None'
      if (this.invoiceOptions.length > 1) {

        paymentOption.disabled = false;

        refundOption.disabled = false;

        this.transactionForm.controls.type.setValue('payment');

      } else {

        paymentOption.disabled = true;

        refundOption.disabled = true;

        this.transactionForm.controls.type.setValue('deposit');

      }

      this.transactionTypeOptions = updatedTransactionTypes;

      this.transactionForm.updateValueAndValidity();

  }
  
  generateVariables(): InvoicesQueryVariables {

    if (!this.job) {
      throw new Error('generateVariables called before job was set');
    }

    return {
      filter: {
        jobId: this.job.id,
      },
      skip: 0,
      limit: -1,
    };
  }

  openCreateInvoice() {

    if (!this.job) {
      throw new Error('generateVariables called before job was set');
    }

    this.closeDialog();

    this.documentHelper.openCreateInvoiceDialog(this.job.id);
  }

}
