<section class="p-mt-4">
	<header>
		<h2>Summary</h2>
		<ng-container *ngIf="(isEditMode$ | async) === false; else cancelButton">
			<p-button
				class="editButton"
				icon="pi pi-pencil"
				[text]="true"
				(onClick)="editSummary()"
				[disabled]="(jobLoaded$ | async) === false"
			/>
		</ng-container>
	</header>
	<ng-container *ngIf="jobLoaded$ | async; else loading">

		<main *ngIf="(isEditMode$ | async) === false; else summaryEditForm">
			<p-tabView
				(onChange)="selectSummaryIndex($event.index)"
				[activeIndex]="selectSummaryIndex$ | async"
				>
				<p-tabPanel
					*ngFor="let summary of jobSummaryData"
					>
					<ng-template pTemplate="header">
						<div>
							<span>{{ summary.name }}</span>
						</div>
					</ng-template>
					<quill-editor
						#editor
						class="summary"
						[readOnly]="true"
						[(ngModel)]="jobSummaries[summary.key].summary"
						[format]="jobSummaries[summary.key].format"
						[modules]="{ toolbar: false }"
						[class.clickable]="jobSummaries[summary.key].isEmpty"
						(click)="jobSummaries[summary.key].isEmpty && editSummary()"
				></quill-editor>
				</p-tabPanel>
			</p-tabView>
		</main>
	</ng-container>
</section>

<ng-template #summaryEditForm>
	<app-jobv2-summary
		[renderHeadless]="true"
		[defaultActiveTabIndex]="selectSummaryIndex$ | async"
		*ngIf="isEditMode$ | async"
	></app-jobv2-summary>
</ng-template>

<ng-template #cancelButton>
	<div class="flex">
		<p-button
			class="cancelButton p-mr-2"
			[outlined]="true"
			label="Cancel"
			(onClick)="cancelEditSummary()"
		/>
		<p-button
			class="saveButton"
			icon="pi pi-check"
			label="Save"
			[disabled]="saveButtonDisabled$ | async"
			(onClick)="updateJob()"
		/>
	</div>
</ng-template>

<ng-template #loading>
	<main class="p-py-3">
		<p-skeleton
			width="80%"
			height="3rem"
		/>
	</main>
</ng-template>
