import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { createSelector, Store } from "@ngrx/store";

import { isEmpty } from "lodash";

import { DialogService } from "primeng/dynamicdialog";
import { FreyaCommonModule } from "src/app/freya-common/freya-common.module";
import { JobCreationFormAction, requiredFieldsValidationMessages } from "src/app/global.constants";
import { FreyaNotificationsService } from "src/app/services/freya-notifications.service";
import { SubSink } from "subsink";

import { createConfigSelector } from '../../../state/branding.store';
import { JobChange, jobToolFeature } from "../../job-tool.reducer";
import { JobEditActions } from "../../jobv2-edit/jobv2-edit-state/jobv2-edit.actions";
import { selectJobUpdatingInProgress } from "../../jobv2-edit/jobv2-edit-state/jobv2-edit.selectors";
import { CloseJobV2Component } from "../close-job-componentV2/close-job-componentV2.component";
import { JobCreateLocationsActions } from '../jobv2-create-locations-state/jobv2-create-locations-state.actions';
import { JobCreateActions } from "../jobv2-create-state/jobv2-create.actions";
import { selectFullNameSelected, selectHowHeardSelected, selectJobSavingInProgress, selectRequiredLocationDetailsSelected } from "../jobv2-create-state/jobv2-create.selectors";


@Component({
    selector: 'app-form-action-button',
    standalone: true,
    imports: [
        FreyaCommonModule,
    ],
    templateUrl: './jobv2-form-action-button.html',
    styleUrls: ['./jobv2-form-action-button.scss']
})
export class JobV2FormActionButtonComponent implements OnInit, OnDestroy {

    @Input() action: JobCreationFormAction;
    @Output() buttonClicked = new EventEmitter();

    @HostListener('window:keydown.control.s', ['$event'])
    onControlS(event: KeyboardEvent) {
        event.preventDefault();
        if (!this.buttonDisabled) {
            this.create();
        } else {
            this.localNotify.addToast.next({ severity: 'warn', summary: 'Fill out required fields to create job' });
        }
    }

    @HostListener('window:keydown.control.shift.s', ['$event'])
    onControlShiftS(event: KeyboardEvent) {
        event.preventDefault();
        if (!this.buttonDisabled) {
            this.createClose();
        } else {
            this.localNotify.addToast.next({
                severity: 'warn',
                summary: 'Fill out required fields to create and close job',
            });
        }
    }

    buttonDisabled = true;
    changes: JobChange[] = [];
    jobId: string;
    private subs = new SubSink();

    //observables
    changes$ = this.store.select(jobToolFeature.selectChanges);
    jobInput$ = this.store.select(jobToolFeature.selectJobInput);
    jobMutating$ = this.store.select(
        createSelector(
            selectJobUpdatingInProgress,
            selectJobSavingInProgress,
            (updating, saving) => {
                return updating || saving;
            }
        )
    );

    selectFormIsValid = createSelector(
        jobToolFeature.selectValidationErrors,
        (validationErrors) => {
            return isEmpty(validationErrors);
        }
    );

    selectIsHowHeardMissingWhenRequired = createSelector(
        createConfigSelector('jobs.howHeardMandatory'),
        selectHowHeardSelected,
        (howHeardMandatory, howHeardSelected) => {
            return howHeardMandatory && !howHeardSelected;
        }
    );

    selectButtonDisabledMessage = createSelector(
        selectJobSavingInProgress,
        selectFullNameSelected,
        this.selectIsHowHeardMissingWhenRequired,
        this.selectFormIsValid,
        selectRequiredLocationDetailsSelected,
        (
            jobSaving,
            fullNameSelected,
            isHowHeardMissingWhenRequired,
            formIsValid,
            requiredLocationDetailsSelected,
        ) => {

            if (jobSaving) {
                return 'The job is saving...'
            }

            if (!fullNameSelected) {
                return 'A customer name is required to create this job.';
            }

            if (isHowHeardMissingWhenRequired) {
                return 'A referral source is required to create this job.';
            }

            if (!formIsValid) {
                return 'The form is not valid.';
            }
            
            if(requiredLocationDetailsSelected.formInvalid
                && requiredLocationDetailsSelected.bedroomsMandatory
            ) {
                return requiredFieldsValidationMessages.bedrooms;
            } else if(requiredLocationDetailsSelected.formInvalid
                && requiredLocationDetailsSelected.dwellingMandatory
            ) {
                return requiredFieldsValidationMessages.dwellingType;
            } else if (requiredLocationDetailsSelected.formInvalid) {
                return 'Required location fields must be provided.';
            }

            return null;

        }
    );

    buttonDisabled$ = this.store.select(createSelector(
        this.selectButtonDisabledMessage,
        (message) => Boolean(message),
    ));

    buttonDisabledMessage$ = this.store.select(this.selectButtonDisabledMessage);
    
    constructor(
        private store: Store,
        private dialogService: DialogService,
        public localNotify: FreyaNotificationsService,
        public router: Router,
    ) { }

    ngOnInit(): void {

        this.subs.sink = this.jobInput$.subscribe((jobInput) => {
            this.jobId = jobInput.id;
        });

        this.subs.sink = this.changes$.subscribe((changes) => {
            this.changes = changes;
        });

        this.subs.sink = this.buttonDisabled$.subscribe((disabled) => {
            this.buttonDisabled = disabled;
        });

    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    create() {
        this.store.dispatch(JobCreateActions.saveJobForm());
        this.buttonClicked.emit();
    }

    createClose() {
        this.buttonClicked.emit();
        return this.store.dispatch(JobCreateActions.createAndCloseJob());
    }

    updateJob() {
        if (this.changes?.length) {
            this.store.dispatch(JobEditActions.updateFormSaved({ jobId: this.jobId }));
        } else {
            this.router.navigate([`/jobs/${this.jobId}/overview`]);
        }
    }

    updateClose() {
        this.buttonClicked.emit();
        return this.dialogService.open(CloseJobV2Component, {
            header: 'Update job and close it right away',
            width: '42rem',
            data: {
                jobId: this.jobId,
                saveFormChanges: true,
            }
        });
    }
}
