import { Pipe, PipeTransform } from '@angular/core';
import {dayjs} from '@karve.it/core';

export const JUST_NOW_MAX_SHOW = 30;

export function appropriateTimeMeasurement(
  value: number, showJustNow = false,
) {
  if (Date.now() - value < JUST_NOW_MAX_SHOW && showJustNow) {
    return 'Just Now';
  }

  return dayjs(value).fromNow();
}

@Pipe({
  name: 'appropriateTimeMeasurement'
})
export class AppropriateTimeMeasurementPipe implements PipeTransform {
  // justNowMaxShow = JUST_NOW_MAX_SHOW;

  // secsMaxShow = 60;
  // minsMaxShow = 3600;
  // hrsMaxShow = 172800;

  transform(value: number, showJustNow = false): string {
    return appropriateTimeMeasurement(value, showJustNow);
  
    // } else if (value >= this.secsMaxShow && value < this.minsMaxShow) {
    //   return Math.trunc(value / 60) + ' minutes ago';
    // } else if (value >= this.minsMaxShow && value < this.hrsMaxShow){
    //   return Math.trunc(value / 3600) + ' hours ago';
    // } else if (value >= this.hrsMaxShow) {
    //   return Math.trunc(value / 86400) + ' days ago';
    // } else { return ''; }
  }

}
