<main>
  <div class="itinerary" *ngIf="itinerary$ | async">
    <div class="itinerary-item" *ngFor="let item of itinerary$ | async">
      <h5>{{ item.name }} - {{ item.duration }} minutes</h5>

      <markdown [data]="item.description"></markdown>
      <hr />
    </div>

    <h4>Total duration: {{ (itineraryDuration$ | async).total }}</h4>
    <h4>Travel duration: {{ (itineraryDuration$ | async).travel }}</h4>
    <h4>Labor duration: {{ (itineraryDuration$ | async).labor }}</h4>
  </div>

  <div class="products-list">
    <div class="product-card" *ngFor="let product of selectedProducts$ | async">
      <div class="product-card-header">
        <h4>{{ product.name }}</h4>
      </div>
      <div class="product-card-body">
        <p>{{ product.reason }}</p>
      </div>
    </div>
  </div>

  <div class="sales-pitch">
    <markdown [data]="salesPitch$ | async"></markdown>
  </div>
</main>
