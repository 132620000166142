import { createSelector } from '@ngrx/store';

import { brandingFeature } from '../../state/branding.store';
import { jobToolFeature } from '../job-tool.reducer';

export const selectCommentsWithMode = createSelector(
	jobToolFeature.selectComments,
	brandingFeature.selectCommentModes,
	(comments = [], modes = []) => {

		return (comments || []).map((c) => {
			const mode = modes.find((m) => m.id === c.thread?.mode);

			return {
				...c,
				mode,
			};
		})

	},
);
