import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { createSelector, Store } from '@ngrx/store';
import { FreyaCommonModule } from 'src/app/freya-common/freya-common.module';

import { YemboHelperService } from '../../services/yembo-helper.service';
import { OverviewChipComponent } from '../job-overview/overview-header/overview-chip/overview-chip.component';
import { jobToolFeature } from '../job-tool.reducer';

import { InventoryActions } from './inventory.actions';
import { inventoryFeature, InventoryItem, InventoryRoom } from './inventory.feature';
import { eventTypeInfoMapV2 } from 'src/app/global.constants';
import { differenceBy, every, includes } from 'lodash';
import { take } from 'rxjs';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-job-inventory',
  standalone: true,
  imports: [
    CommonModule,
    FreyaCommonModule,
    OverviewChipComponent,
  ],
  templateUrl: './job-inventory.component.html',
  styleUrl: './job-inventory.component.scss'
})
export class JobInventoryComponent implements OnInit {

  job$ = this.store.select(jobToolFeature.selectJob);
  jobLoaded$ = this.store.select(jobToolFeature.jobLoaded);

  search$ = this.store.select(inventoryFeature.selectSearch);
  searchResults$ = this.store.select(inventoryFeature.selectSearchResults);
  totalVolume$ = this.store.select(inventoryFeature.selectTotalVolume);
  totalWeight$ = this.store.select(inventoryFeature.selectTotalWeight);
  totalItems$ = this.store.select(inventoryFeature.selectTotalItems);
  totalBoxes$ = this.store.select(inventoryFeature.selectTotalBoxes);

  weightUnit$ = this.store.select(inventoryFeature.selectWeightUnit);
  volumeUnit$ = this.store.select(inventoryFeature.selectVolumeUnit);

  rooms$ = this.store.select(inventoryFeature.selectRooms);

  addRoomDialogVisible$ = this.store.select(inventoryFeature.selectAddRoomDialogVisible);
  addRoomOptions$ = this.store.select(inventoryFeature.selectAddRoomOptions);

  editItemDialogVisible$ = this.store.select(inventoryFeature.selectEditItemDialogVisible);
  editItem$ = this.store.select(inventoryFeature.selectEditItem);
  editItemRoom$ = this.store.select(inventoryFeature.selectEditItemRoom);
  inventoryNotes$ = this.store.select(inventoryFeature.selectNotes);

  focus$ = this.store.select(inventoryFeature.selectFocus);
  hasChanges$ = this.store.select(jobToolFeature.selectChargesUpdates);

  isYemboMoveCreated$ = this.store.select(jobToolFeature.selectIsYemboMoveCreated);
  isCreatingSelfSurvey$ = this.store.select(jobToolFeature.yemboSelfSurveyLoading);
  isStartingOnsiteEstimate$ = this.store.select(jobToolFeature.yemboStartOnsiteLoading);
  isBookingYemboSmartConsult$ = this.store.select(jobToolFeature.eventCreationLoading);
  InventoryActions = InventoryActions;

  selectYemboSurveyAndOnsiteDisabled = createSelector(
    jobToolFeature.selectJob,
    (job) => {
      const locations = job?.locations?.map(l => l.locationType) || [];
      const requiredLocations = eventTypeInfoMapV2?.virtualEstimate?.requiredLocations || [];

      const hasAllRequired = every(requiredLocations, loc => includes(locations, loc));
      const missingLocations = differenceBy(requiredLocations, locations);

      return {
        disabled: !hasAllRequired,
        disabledMessage: !hasAllRequired ? `Missing locations: ${missingLocations.join(', ')}` : '',
      };
    }
  );

  splitMenuItems: MenuItem[] = [];

  constructor(
    public store: Store,
    public router: Router,
    public yemboHelper: YemboHelperService,
  ) { }

  ngOnInit(): void {
    this.store.dispatch(InventoryActions.inventoryPageInitialized());
    this.setSplitMenuItems();
  }

  viewMoveInYembo() {
    this.store.dispatch(InventoryActions.viewMoveInYemboClicked());
  }

  createSelfSurveyLink() {
    this.store.dispatch(InventoryActions.createSelfSurveyLinkClicked());
  }

  startOnsiteEstimate() {
    this.store.dispatch(InventoryActions.startOnsiteButtonClicked());
  }

  bookSmartConsult(){
    this.store.dispatch(InventoryActions.bookYemboSmartConsultClicked());
  }

  trackByRoomId(index: number, room: InventoryRoom) {
    return room.id;
  }

  trackByItemId(index: number, item: InventoryItem) {
    return item.id;
  }

  setSplitMenuItems() {
    this.store.select(this.selectYemboSurveyAndOnsiteDisabled).pipe(take(1)).subscribe(({ disabled, disabledMessage }) => {
      this.splitMenuItems = [
        {
          label: 'Send Yembo Self Survey',
          icon: 'pi pi-send',
          command: () => this.createSelfSurveyLink(),
          disabled,
          tooltip: disabled ? disabledMessage : '',
        },
        {
          label: 'Start Yembo Onsite Estimate',
          icon: 'pi pi-play',
          command: () => this.startOnsiteEstimate(),
          disabled,
          tooltip: disabled ? disabledMessage : '',
        }
      ];
    });
  }
}
