import { Component, OnDestroy, OnInit } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store, createSelector } from '@ngrx/store';

import { QuillEditorComponent } from 'ngx-quill';

import { SubSink } from 'subsink';

import { FreyaCommonModule } from '../../../freya-common/freya-common.module';
import { JOB_SUMMARIES } from '../../../global.constants';
import { SharedModule } from '../../../shared/shared.module';
import { JobToolActions } from '../../job-tool.actions';
import { JobSummaries, JobSummary, jobToolFeature } from '../../job-tool.reducer';
import { SummaryUpdate } from '../../jobv2-create/jobv2-interfaces';
import { Jobv2SummaryComponent } from "../../jobv2-create/jobv2-summary/jobv2-summary.component";

export const selectSummaries = createSelector(
  jobToolFeature.selectJobSummaries,
  jobToolFeature.selectSummaryUpdates,
  (savedSummaries, unsavedSummaries) => {

    const customerSummaryUpdate = unsavedSummaries.find((u) => u.key === 'customerSummary');
    const adminSummaryUpdate = unsavedSummaries.find((u) => u.key === 'adminSummary');
    const crewSummaryUpdate  = unsavedSummaries.find((u) => u.key === 'crewSummary');

    return {
      crewSummary: getJobSummary(crewSummaryUpdate) || savedSummaries.crewSummary,
      adminSummary: getJobSummary(adminSummaryUpdate) || savedSummaries.adminSummary,
      customerSummary: getJobSummary(customerSummaryUpdate) || savedSummaries.customerSummary,
    }
  }

);

export const selectSaveSummaryChangesDisabled = createSelector(
  jobToolFeature.isJobBeingUpdated,
  jobToolFeature.isJobLoading,
  jobToolFeature.selectSummaryUpdates,
  (isJobBeingUpdated, isJobLoading, summaryUpdates) => {

    if (isJobLoading) { return true; }

    if (!isJobBeingUpdated) { return false; }

    const updatesNotBeingSaved = summaryUpdates.filter((u) => !u.mutationId);

    return !updatesNotBeingSaved.length;
  }
);

function getJobSummary(summaryUpdate?: SummaryUpdate): JobSummary | undefined {
  if (!summaryUpdate) { return; }
  return {
    summary: summaryUpdate.contents,
    format: 'Object',
    isEmpty: false,
  }
}

@Component({
  selector: 'app-overview-summary',
  standalone: true,
  imports: [
    FreyaCommonModule,
    SharedModule,
    QuillEditorComponent,
    Jobv2SummaryComponent
  ],
  templateUrl: './overview-summary.component.html',
  styleUrl: './overview-summary.component.scss'
})
export class OverviewSummaryComponent implements OnInit, OnDestroy {

  private jobSummaries$ = this.store.select(selectSummaries);

  public jobLoaded$ = this.store.select(jobToolFeature.jobLoaded);

  public jobSummaries: JobSummaries;
  public selectSummaryIndex$ = this.store.select(jobToolFeature.selectSummaryIndex);
  public saveButtonDisabled$ = this.store.select(selectSaveSummaryChangesDisabled);
  public summaryOptions = [
    {
      label: 'Crew Summary',
      value: 'crewSummary'
    }, {
      label: 'Admin Summary',
      value: 'adminSummary'
    },
    {
      label: 'Customer Summary',
      value: 'customerSummary'
    }
  ]

  jobSummaryData = JOB_SUMMARIES;

  isEditMode$ = this.store.select(jobToolFeature.selectIsSummaryEditMode);

  private subs = new SubSink();

  constructor(
    private store: Store,
    private actions: Actions,
  ) { }

  ngOnInit(): void {
    this.watchJobSummaries();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  private watchJobSummaries() {

    this.subs.sink = this.jobSummaries$.subscribe(summaries => {
      if (summaries) {
        this.jobSummaries = summaries;
      }
    });
  }

  public editSummary() {
    this.store.dispatch(JobToolActions.summaryInPlaceEditingActivated());
  }

  public cancelEditSummary() {
    this.store.dispatch(JobToolActions.summaryInPlaceEditingCancelled());
  }

  public updateJob() {
    this.store.dispatch(JobToolActions.jobUpdateRequested({ deactivateSummaryInPlaceEditing: true }));
  }

  selectSummaryIndex(summaryIndex: number) {
    this.store.dispatch(JobToolActions.summaryIndexChanged({ summaryIndex }));
  }
}
