/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { PlusApollo } from '@karve.it/core';
import * as DMI from '@karve.it/interfaces/tag';
import { ListTagsInput, ListTagsOutput, listTagsQuery } from '@karve.it/interfaces/tag';

import { Subject } from 'rxjs';

import { KarveRequestOptions, KarveWatchRequestOptions, parseRequestOptions } from '../options';

export const colourList =  {
  Red: '#fc5c65',
  Orange: '#fd9644',
  Yellow: '#fed330',
  Green: '#26de81',
  Teal: '#2bcbba',
  'Light-Blue': '#45aaf2',
  Blue: '#4b7bec',
  Purple: '#a55eea',
  'Light-Grey': '#d1d8e0',
  Grey: '#778ca3'
};

@Injectable({
  providedIn: 'root'
})
export class TagsService {

  queryRefetch = new Subject<string>();

  constructor(public apollo: PlusApollo,) {}

   /**
    * Adds the relationship HAS_TAG to an object.
    *
    * @param objectId Id of the object you want to 'tag'.
    * @param tagIds Ids of the tags you want to apply.
    * @param isPrivate True if other users shouldn't be able to see this tag.
    * @param order A float value for the position the tag should display in reletive to other tags.
    *   eg. '2.0' Will appear after 1.0 but if nothing is lower it will appear first.
    * @param objectLabel The database label of the objects being tagged. Omit if multiple object types are
    *   being tagged. Improves performance if used.
    * @returns QueryRef, The tag and object ids used in this operation.
    */
   addTagToObject(objectId: string, tagIds: string[], isPrivate: boolean, order: number, objectLabel?: string){
    return this.apollo.mutate<DMI.AddTagToObjectOutput>({
      mutation: DMI.addTagToObject,
      variables: {
        tagsToUse: tagIds,
        order,
        objectToTag: objectId,
        private: isPrivate,
        objectLabel,
      }
    });
   }

   /**
    * List tags by a type.
    *
    * @param objectType The object type for the tag.
    * @returns QueryRef, List of Tags
    */
   listTagsByObjectType( objectType?: DMI.TAG_TYPES ){
     return this.apollo.query<DMI.FindTagsByTypeOutput>({
       query: DMI.findTagsByType,
       variables: {
         objectTypes: [objectType],
       },
     });
   }

   /**
    * List tags by a type,watch for changes.
    *
    * @param objectType The object type for the tag.
    * @returns QueryRef, List of Tags
    */
   watchTagsByObjectType( objectType?: string ){
    return this.apollo.watchQuery<DMI.FindTagsByTypeOutput>({
      query: DMI.findTagsByType,
      variables: {
        objectTypes: [objectType],
      },
    });
  }

   /**
    * Create a new tag.
    *
    * @param tagname How you would refer to the tag.
    * @param tagType What type of object should this tag be used for.
    * @param tagColour What is the hexidecimal code for the color of this tag. eg. '#ffffff'.
    * @param isPrivate Can others see and use this tag?
    * @param owner Who owns this tag, the person can edit it.
    * @returns QueryRef, The newly created tag.
    */
   createTag(tagname: string, tagType: string, tagColour: string, isPrivate: boolean, owner: string){
    return this.apollo.mutate<DMI.CreateTagOutput>({
      mutation: DMI.createTag,
      variables: {
        name: tagname,
        objectType: tagType,
        color: tagColour,
        private: isPrivate,
        owner
       }
    });
   }

   /**
    * List tags that are applied to a specific object.
    *
    * @param objectId The id of the object whose tags you want to list.
    * @returns QueryRef, List of tags
    */
   listObjectTags(objectId: string){
    return this.apollo.query<DMI.FindTagsByObjectOutput>({
      query: DMI.findTagsByObject,
      variables: {
        objectIds: [objectId]
      },
    });
   }

   /**
    * List tags that are applied to a specific object,watch for changes.
    *
    * @param objectId The id of the object whose tags you want to list.
    * @returns QueryRef, List of tags
    */
    watchObjectTags(objectId: string){
      return this.apollo.watchQuery<DMI.FindTagsByObjectOutput>({
        query: DMI.findTagsByObject,
        variables: {
          objectIds: [objectId]
        },
      });
     }

   /**
    * Sends all the tags on an object and removes or adds as needed to match the input.
    *
    * @param tags All of the tags that should be applied to the object.
    * @param objectId Id of the object.
    * @returns QueryRef, True if the operation is successful
    */
   setObjectTags(tags: DMI.SetTagsRelationship[], objectId: string, objectLabel: string){
    return this.apollo.mutate<DMI.SetObjectTagsOutput>({
      mutation: DMI.setObjectTags,
      variables: {
        tags,
        objectId,
        objectLabel
      }
    });
   }

   /**
    * Reorders the tags to match the array provided.
    *
    * @param tags Ids of the tags in the order you want to set.
    * @param objectId Id of the object.
    * @returns QueryRef, True if the operation is successful
    */
   orderTags(tags: string[], objectId: string){
     const tagRelationships = [];
     for (const tag of tags){
      tagRelationships.push({tagId: tag, private: undefined});
     }
     return this.apollo.mutate<DMI.SetObjectTagsOutput>({
       mutation: DMI.orderTags,
       variables: {
        tags: tagRelationships,
        objectId
       }
     });
   }

   /**
    * Remove the realtionship HAS_TAG between the tags and object.
    *
    * @param objectId The id of the object.
    * @param tags The id of the tags you want to remove.
    * @returns QueryRef, The ids of the object and tags in this operation.
    */
   removeTagsFromObject(objectId: string, objectLabel: string ,tags: string[]){
     return this.apollo.mutate<DMI.RemoveTagsFromObjectOutput>({
       mutation: DMI.removeTagFromObject,
       variables: {
         objects: [objectId],
         objectLabel,
         tags
       }
     });
   }

   /**
    * Edit the values of a tag.
    *
    * @param tagId Id of the tag to edit
    * @param name The new name for the tag.
    * @param color The new color for the tag.
    * @param attributes The new attributes for the tag
    * @returns QueryRef, True if the operation is successful.
    */
   editTag(tagId: string, name: string, color: string, attributes: string[]){
    return this.apollo.mutate<DMI.EditTagOutput>({
      mutation: DMI.editTag,
      variables: {
        tagId,
        name,
        color,
        attributes,
      }
    });
   }

   /**
    * Delete Tags.
    *
    * @param tagIds Ids of the tags to delete.
    * @returns QueryRef, True if the delete is successful
    */
   deleteTag(tagIds: string[]){
      return this.apollo.mutate<DMI.DeleteTagsOutput>({
        mutation: DMI.deleteTag,
        variables: {
          tagIds
        },
      });
   }


   // Simpilified Calls
   listTags(input: ListTagsInput, opts?: KarveRequestOptions){
     return this.apollo.query<ListTagsOutput>({
       query: listTagsQuery,
       variables: {
         ...input,
       },
       ...parseRequestOptions(opts),
     });
   }


   watchTags(input: ListTagsInput, opts?: KarveWatchRequestOptions){
    return this.apollo.watchQuery<ListTagsOutput>({
      query: listTagsQuery,
      variables: {
        ...input,
      },
      ...parseRequestOptions(opts),
    });
  }





}

