import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as IO from '@karve.it/interfaces/artifacts';
import { ListArtifactInput, ListArtifactOutput } from '@karve.it/interfaces/artifacts';
import { Apollo } from 'apollo-angular';

import { map } from 'rxjs/operators';

import { FreyaFile } from '../../shared/upload-file/upload-file.component';

@Injectable({
  providedIn: 'root'
})
export class ArtifactService {

  constructor(
    private apollo: Apollo,
    private httpClient: HttpClient,
  ) { }

  /**
   * Create new artifacts and upload them to google bucket.
   *
   * @param artifacts Array of artifacts to be created.
   * @returns QueryRef, Total number created, and the created objects.
   */
  createArtifact(artifacts: IO.ArtifactInput[]) {
    return this.apollo.mutate<IO.CreateArtifactOutput>({
      mutation: IO.createArtifacts,
      variables: { artifacts },
    });
  }

  /**
   * Gets a list of all artifacts that match the input.
   *
   * @param input Artifact input
   * @returns QueryRef, List of artifacts that match the fitler.
   */
  listArtifacts(input: ListArtifactInput) {
    return this.apollo.query<ListArtifactOutput>({
      query: IO.viewArtifacts,
      variables: { ...input },
    });
  }

  /**
   * Gets a list of all artifacts that match the input.
   *
   * @param input Artifact input
   * @returns QueryRef, List of artifacts that match the fitler.
   */
  watchArtifacts(input: ListArtifactInput) {
    return this.apollo.watchQuery<ListArtifactOutput>({
      query: IO.viewArtifacts,
      variables: { ...input },
    });
  }

  /**
   * List how much sapce has been used by the system and system users.
   *
   * @param input Filter which/whose usage you want to list.
   * @returns QueryRef, Usage of the system and users by uploading/interacting with artifacts.
   */
  listArtifactUsage(input: IO.ArtifactUsageInput) {
    return this.apollo.watchQuery<IO.ArtifactsUsageOutput>({
      query: IO.artifactUsageQuery,
      variables: {
        ...input
      }
    });
  }

  // Deletes artifacts based on Ids, deleteResource - also removes it from the google cloud bucket if true
  /**
   * Delete artifacts from the system
   *
   * @param artifactIds Ids of the artifacts you want to delete.
   * @param deleteResource If true the artifact will also be removed from the google cloud bucket.
   * @returns True if the operation was successful, false if it failed.
   */
  deleteArtifacts(artifactIds: string[], deleteResource: boolean = false) {
    return this.apollo.mutate<IO.DeleteArtifactOutput>({
      mutation: IO.deleteArtifacts,
      variables: {
        artifactIds,
        deleteResource
      }
    }).pipe(
      // console.log(result);
      // @ts-ignore
      map(res => res.data.deleteArtifacts)
    );
  }


  uploadFile(file: FreyaFile, policy) {
    const formData = new FormData();

    for (const f of Object.keys(policy.fields)) {
      const value = policy.fields[f];
      formData.append(f, value);
    }

    formData.append('content-type', file.type);
    formData.append('file', file);

    return this.httpClient.post(policy.url, formData);
  }

  // Not Functional
  // async uploadArtifactFile(policyString: string, file: Buffer, contentType: string) {

  //   const signedPolicy = JSON.parse(policyString);

  //   const formData = {
  //       file,
  //       ...signedPolicy.fields,
  //       'content-type': contentType,
  //   };
  //   await this.http.post(signedPolicy.url, formData);

  //   await request.post({
  //     url: signedPolicy.url,
  //     formData,
  // });
  // }
}
