import { AfterContentChecked, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Zone } from '@karve.it/interfaces/zones';
import { catchError, forkJoin, of } from 'rxjs';
import { countries } from 'src/app/global.constants';
import { DetailsHelperService } from 'src/app/services/details-helper.service';
import { FreyaNotificationsService } from 'src/app/services/freya-notifications.service';
import { SubSink } from 'subsink';

import { ListZonesWithAreasGQL, UpdateZonesGQL, UpdateZonesMutationVariables } from '../../../generated/graphql.generated';
import { BrandingService } from '../../services/branding.service';
import { GoogleHelperService } from '../../services/google-helper.service';

import { MutateObjectComponent, MutateObjectElement } from '../mutate-object/mutate-object.component';

@Component({
  selector: 'app-mutate-zone',
  templateUrl: './mutate-zone.component.html',
  styleUrls: ['./mutate-zone.component.scss']
})
export class MutateZoneComponent implements OnInit, OnDestroy, AfterContentChecked {

  @ViewChild('mutate') mutateRef: MutateObjectComponent;

  // Template Refs
  @ViewChild('name') nameRef: TemplateRef<any>;

  @Input() mutateType: 'update';
  @Input() zone: Partial<Zone>;

  zoneQueryRef: ReturnType<typeof this.listZonesWithAreasGQL.watch>;

  steps: MutateObjectElement[];

  country: string;
  timezone: string;

  subs = new SubSink();

  currentZoneId: string;
  dockSetForZoneId: string;


  showColorTags = true;

  areaForm = new UntypedFormGroup({
	name: new UntypedFormControl('', [Validators.required, Validators.minLength(2)]),
  });
  areaFormValues = this.areaForm.value;

  countries = countries;

  locationId: string | undefined;
  dockLocationObject: google.maps.places.PlaceResult;

  constructor(
	private localNotify: FreyaNotificationsService,
	private updateZonesGQL: UpdateZonesGQL,
	private listZonesWithAreasGQL: ListZonesWithAreasGQL,
	private detailHelper: DetailsHelperService,
	private cd: ChangeDetectorRef,
	private brandingSvc: BrandingService,
	public googleHelper: GoogleHelperService,
  ) { }

  ngOnInit(): void {
	this.subs.sink = this.brandingSvc.currentZone().subscribe(zone => {
	  this.currentZoneId = zone?.id;
	});
  }

  ngOnDestroy() {
	this.subs.unsubscribe();
  }

  ngAfterContentChecked() {
	this.cd.detectChanges();
  }

  openDialog() {

	this.assignDefaultValues();

	this.steps = [
	  { name: 'Name', ref: this.nameRef, control: 'name', type: 'text' },
	];

	this.mutateRef.steps = this.steps;
	this.mutateRef.mutateType = this.mutateType;

	this.mutateRef.openDialog();
  }

  mutateObject() {
	if (!this.areaForm.dirty) {
		this.localNotify.success('Area already up to date');
		this.mutateRef.closeDialog();
		return;
	  }

	  this.editZone();
  }

  retrieveZone() {
	if (!this.zoneQueryRef) {
	  this.zoneQueryRef = this.listZonesWithAreasGQL.watch({
		filter: { ids: [this.zone.id] },
		objectFilter: {
		  label: 'Location',
		  limit: -1,
		},
	  }, { fetchPolicy: 'network-only' });
	} else {
	  this.zoneQueryRef.refetch({ filter: { ids: [this.zone.id] } });
	}

	this.subs.sink = this.zoneQueryRef.valueChanges.subscribe((res) => {
	  if (res.networkStatus === 7) {
		this.zone = res.data.zones.nodes[0] as Partial<Zone>;
		this.detailHelper.detailsItem.next({ type: 'area', item: this.zone });
	  }
	});
  }

  assignDefaultValues() {
	this.areaForm.setValue({
	  name: this.zone.name,
	});
  }

  // Edit the Zone Properties
  async editZone() {
	const editZoneInput = this.buildEditZoneInput();

	if (Object.keys(editZoneInput.edit).length === 0) {
	  this.mutateRef.closeDialog();
	  return this.localNotify.success('Zone already up to date');
	}

	this.updateZone(editZoneInput).subscribe({
	  next: () => {
		this.mutateRef.closeDialog();
		this.localNotify.success('Zone updated');
		this.retrieveZone();
	  },
	  error: (err) => {
		this.mutateRef.closeDialog();
		this.localNotify.apolloError('Failed to update zone', err);
	  }
	});
  }

  private updateZone(editZoneInput: UpdateZonesMutationVariables) {

	if (Object.keys(editZoneInput.edit).length === 0) {
	  return of(undefined); // No updates needed
	}

	return this.updateZonesGQL.mutate(editZoneInput).pipe(
	  catchError((err) => {
		this.localNotify.apolloError('Failed to update zone', err);
		return of(undefined); // Continue with forkJoin
	  })
	);
  }

  private buildEditZoneInput(): UpdateZonesMutationVariables {
	const editZoneInput: UpdateZonesMutationVariables = {
	  ids: [this.zone.id],
	  edit: {},
	};

	const { name } = this.areaForm.controls;
	const formValue = this.areaForm.value;

	// Only add properties to edit if they have changed
	if (name.dirty) {
	  editZoneInput.edit.name = formValue.name;
	}

	return editZoneInput;
  }
}
