<ng-container *ngIf="!renderHeadless; else headlessLocationForm">
  <p-card>
    <div
      class="header-container"
      (click)="collapseCard()"
    >
      <div class="header-with-icon">
        <i class="pi pi-map p-mr-2"></i>
        <h2>Location details</h2>
      </div>
      <div class="right-side-buttons">
        <p-button
          [outlined]="true"
          (click)="showGoogleMapsRoute()"
          icon="pi pi-directions"
          label="View Route"
          [class.hidden]="viewRouteHidden"
        ></p-button>
        <div class="collapse-container">
          <i
            *ngIf="isCardCollapsed"
            class="pi pi-chevron-down p-mr-2"
          ></i>
          <i
            *ngIf="!isCardCollapsed"
            class="pi pi-chevron-up p-mr-2"
          ></i>
        </div>
      </div>
    </div>
    <div
      class="main-content"
      *ngIf="!isCardCollapsed"
    >
      <ng-container *ngTemplateOutlet="locationForm"></ng-container>
    </div>
  </p-card>
</ng-container>

<app-jobv2-manually-add-location
  #addLocation
  [formType]="locationTypeToOpenInManual"
></app-jobv2-manually-add-location>

<ng-template #headlessLocationForm>
  <div class="headless">
    <ng-container *ngTemplateOutlet="locationForm"></ng-container>
  </div>
</ng-template>

<ng-template #locationForm>
  <div class="area-dock-container">
    <div>
      <span [ngClass]="resolvedAreaName ? 'resolved-area' : null">
        {{ resolvedAreaName || 'No area detected' }}
      </span>
      <span
        class="set-area-btn"
        *ngIf="resolvedAreaName && !(startAddress && mode === 'edit')"
        (click)="setArea()"
      >
        Change
      </span>
      <span
        class="set-area-btn"
        *ngIf="!resolvedAreaName && !(startAddress && mode === 'edit')"
        (click)="setArea()"
      >
        Set area manually
      </span>
      <span
        *ngIf="locations?.dock"
        class="dock-container"
      >
        Dock: {{ locations?.dock?.addressLineOne }}
      </span>
    </div>
  </div>
  <div class="two-columns-container">
    <div class="address-with-unit-container">
      <div>
        <label for="startUnitNumber">
          Unit #
        </label>
        <p-inputNumber
          [(ngModel)]="startUnit"
          [style]="{ 'marginTop': '0.5rem' }"
          (ngModelChange)="onUnitNumberEntered($event, 'start')"
          min="0"
          max="10000"
          class="no-min-width"
        ></p-inputNumber>
      </div>
      <div>
        <label for="startLocation">Starting Location</label>
        <div class="p-inputgroup mt-05">
          <input
            [tabindex]="jobCreateIndexes['startLocation']"
            #startLocation
            *ngIf="googleHelper.loaded | async"
            [(ngModel)]="startAddress"
            (input)="onInput('start')"
            (blur)="onBlur('start')"
            #googleAutocomplete="ngx-places"
            ngx-gp-autocomplete
            pInputText
            autocomplete="off"
            (onAddressChange)="handleAddressChange($event, 'start')"
            [inputStyle]="{ width: '100%', 'margin-top': '0.5rem' }"
            [ngClass]="{
              'ng-invalid': inputState.start.showError
            }"
          />
          <button
            type="button"
            pButton
            pRipple
            (click)="openManualLocation('start')"
            icon="pi pi-map-marker"
            class="p-button-secondary p-button-outlined"
            pTooltip="Add a location without using the autocomplete"
          ></button>
        </div>
        <small *ngIf="inputState.start.showError">
          You must use autocomplete to set the location.
        </small>
      </div>
    </div>
    <div class="address-with-unit-container">
      <div>
        <label for="endUnitNumber">
          Unit #
        </label>
        <p-inputNumber
          [(ngModel)]="endUnit"
          [style]="{ 'marginTop': '0.5rem' }"
          (ngModelChange)="onUnitNumberEntered($event, 'end')"
          min="0"
          max="10000"
          class="no-min-width"
        ></p-inputNumber>
      </div>
      <div>
        <label for="#startLocation">Ending Location</label>
        <div class="p-inputgroup mt-05">
          <input
            [tabindex]="jobCreateIndexes['endLocation']"
            #endLocation
            *ngIf="googleHelper.loaded | async"
            [(ngModel)]="endAddress"
            (input)="onInput('end')"
            (blur)="onBlur('end')"
            #googleAutocomplete="ngx-places"
            ngx-gp-autocomplete
            pInputText
            autocomplete="off"
            (onAddressChange)="handleAddressChange($event, 'end')"
            [inputStyle]="{ width: '100%', 'margin-top': '0.5rem' }"
            [ngClass]="{
                  'ng-invalid': inputState.end.showError
                }"
          />
          <button
            type="button"
            pButton
            pRipple
            (click)="openManualLocation('end')"
            icon="pi pi-map-marker"
            class="p-button-secondary p-button-outlined"
            pTooltip="Add a location without using the autocomplete"
          ></button>
        </div>
        <small *ngIf="inputState.end.showError">
          You must use autocomplete to set the location.
        </small>
      </div>
    </div>
  </div>
  <div class="two-columns-container">
    <div>
      <label for="startBuildingType">
        Starting Location Building Type
        {{ startDwellingTypeRequired ? '(Required)' : '' }}
      </label>
      <p-dropdown
        [tabindex]="jobCreateIndexes['startBuildingType']"
        #startBuildingType
        [(ngModel)]="startBuildingTypeSelectedOption"
        [options]="dwellingDropdownOptions"
        [style]="{ width: '100%', marginTop: '0.5rem' }"
        (onChange)="onBuildingTypeSelect($event, 'start')"
        [required]="startDwellingTypeRequired"
      >
      </p-dropdown>
    </div>
    <div>
      <label for="endBuildingType">
        Ending Location Building Type
        {{ endDwellingTypeRequired ? '(Required)' : '' }}
      </label>
      <p-dropdown
        [tabindex]="jobCreateIndexes['endBuildingType']"
        #endBuildingType
        [(ngModel)]="endBuildingTypeSelectedOption"
        [options]="dwellingDropdownOptions"
        [style]="{ width: '100%', marginTop: '0.5rem' }"
        (onChange)="onBuildingTypeSelect($event, 'end')"
        [required]="endDwellingTypeRequired"
      >
      </p-dropdown>
    </div>
  </div>
  <app-jobv2-create-locations-details
    *ngIf="locations?.start"
    [locationType]="mainLocationsMap.start"
  ></app-jobv2-create-locations-details>
  <ng-container *ngFor="let additionalLocation of addedAdditionalLocations">
    <app-jobv2-additional-locations
      [locationType]="additionalLocation.key"
      [locationData]="additionalLocation.value"
    >
    </app-jobv2-additional-locations>
  </ng-container>
  <div class="add-stop-container">
    <div>
      <p-divider />
    </div>
    <div class="add-stop-btn">
      <!--<p-dropdown
                    [(ngModel)]="selectedLocation"
                    [tabindex]="jobCreateIndexes['addStop']"
                    [options]="addableAdditionalLocations"
                    optionLabel="label"
                    optionValue="key"
                    placeholder="Add Stop"
                    [disabled]="!addableAdditionalLocations.length"
                    (onChange)="addAdditionalLocation($event.value)"
                    appendTo="body"
                >
                </p-dropdown>-->
      <button
        [tabindex]="jobCreateIndexes['addStop']"
        label="Add Stop"
        pButton
        class="p-button-outlined"
        icon="pi pi-plus"
        [disabled]="!addableAdditionalLocations.length"
        (click)="addAdditionalLocation($event.value)"
      ></button>
    </div>
    <div>
      <p-divider />
    </div>
  </div>
  <app-jobv2-create-locations-details
    *ngIf="locations?.end"
    [locationType]="mainLocationsMap.end"
  ></app-jobv2-create-locations-details>
</ng-template>