import { createActionGroup, props } from '@ngrx/store';

import { AddCommentInput, BaseCommentFragment, BaseFieldFragment, BaseJobFragment, BaseZoneFragment, FullJobFragment, JobChangedEvent, JobDetailsV2Fragment, UpdateCommentPartial, UpdateJobInput } from '../../../generated/graphql.generated';

export type TypedJobChangedEvent<I, O> = {
	input?: I;
	output?: O;
} & Omit<JobChangedEvent, 'input' | 'output'>;

// These are called dynamically in jobsv2.component.ts
export const JobToolSubscriptionActions = createActionGroup({
	source: 'Job Tool Subscription',
	events: {
		'remote comment added': props<TypedJobChangedEvent<AddCommentInput, BaseCommentFragment>>(),
		'local comment added': props<TypedJobChangedEvent<AddCommentInput, BaseCommentFragment>>(),
		'remote comment updated': props<TypedJobChangedEvent<UpdateCommentPartial, BaseCommentFragment>>(),
		'remote job updated': props<TypedJobChangedEvent<UpdateJobInput, {
			job: JobDetailsV2Fragment;
			stageChanged: boolean;
			zone: BaseZoneFragment;
		}>>(),
	},
});
