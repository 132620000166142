import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from "@ngrx/store";
import { ContentChange, QuillEditorComponent, QuillModules } from 'ngx-quill';

import Quill, { Delta } from 'quill/core';
import { filter, first, withLatestFrom } from 'rxjs';
import { DistanceService } from "src/app/estimates/distance.service";
import { FreyaCommonModule } from 'src/app/freya-common/freya-common.module';
import { JOB_CREATE_INDEXES, JOB_SUMMARIES, jobSummaryDefaultToolbar } from 'src/app/global.constants';
import { LibModule } from 'src/app/lib.ts/lib.module';
import { FreyaNotificationsService } from 'src/app/services/freya-notifications.service';
import { SharedModule } from 'src/app/shared/shared.module';

import { SubSink } from "subsink";

import { brandingFeature } from '../../../state/branding.store';
import { selectFeature } from '../../../state/featureFlags.store';
import { selectSummaries } from '../../job-overview/overview-summary/overview-summary.component';
import { JobSummaryActions, SummaryType } from '../../job-state/summary-state/summary.actions';
import { JobToolActions } from '../../job-tool.actions';
import { JobSummary, Modes, jobToolFeature } from '../../job-tool.reducer';

import { selectJobUpdatingErrors } from '../../jobv2-edit/jobv2-edit-state/jobv2-edit.selectors';
import { selectJobSavingErrors, selectSavingDisabled } from '../jobv2-create-state/jobv2-create.selectors';
import { JobV2FormActionButtonComponent } from '../jobv2-form-action-button/jobv2-form-action-button';


const handleUndefined = (summary: JobSummary): Delta => {
  if (summary.isEmpty) {
    return new Delta([{ insert: '' }]);
  }
  if (summary.format === 'Object') {
    return summary.summary;
  }
  // Handle case where summary is in text format
  return new Delta([{ insert: summary.summary }]);
}

const setEditorContents = (editor: Quill, summary: JobSummary) => {
  const contents = handleUndefined(structuredClone(summary));
  editor.setContents(contents, 'api');
};


@Component({
  selector: 'app-jobv2-summary',
  standalone: true,
  imports: [
    FreyaCommonModule,
    LibModule,
    SharedModule,
    QuillEditorComponent,
    JobV2FormActionButtonComponent
  ],
  templateUrl: './jobv2-summary.component.html',
  styleUrls: ['./jobv2-summary.component.scss']
})
export class Jobv2SummaryComponent implements OnInit, OnDestroy {

  @Input() renderHeadless = false;

  constructor(
    private store: Store,
    public distanceService: DistanceService,
    public localNotify: FreyaNotificationsService,
  ) { }

  private subs = new SubSink();

  // Editors
  crewEditor: Quill;
  adminEditor: Quill;
  customerEditor: Quill;

  configs$ = this.store.select(brandingFeature.selectConfigs);
  selectSavingDisabled$ = this.store.select(selectSavingDisabled);

  //error handling
  savingDisabledMessage = 'A customer name is required to create this job.';
  jobSavingErrors$ = this.store.select(selectJobSavingErrors);

  jobUpdatingErrors$ = this.store.select(selectJobUpdatingErrors);
  errorUpdateMessage = '';
  creatingDisabled = false;

  //modes
  mode: Modes;
  jobFormMode$ = this.store.select(jobToolFeature.selectJobFormMode);
  jobsV2Enabled$ = this.store.select(selectFeature('jobs-v2'));

  summaries$ = this.store.select(selectSummaries);

  editModules: QuillModules = {
    toolbar: jobSummaryDefaultToolbar,
    keyboard: {
      bindings: {
        submit: {
          key: 'Enter',
          ctrlKey: true,
          handler: (range, context) => {
            this.moveBackToSummaryTab();
          },
        },
      },
    },
  };

  jobId: string;
  initialContentLoaded = false;

  jobSummaries = JOB_SUMMARIES;

  jobCreateIndexes = JOB_CREATE_INDEXES;
  selectSummaryIndex$ = this.store.select(jobToolFeature.selectSummaryIndex);

  ngOnInit(): void {

    this.subs.sink = this.jobFormMode$.subscribe((jobFormMode) => {
      this.mode = jobFormMode;
    });

    this.subs.sink = this.selectSavingDisabled$.subscribe((selectSavingDisabled) => {
      this.creatingDisabled = selectSavingDisabled;
    });

    this.subs.sink = this.configs$.subscribe((configs) => {
      if (configs['jobs.howHeardMandatory']) {
        this.savingDisabledMessage = 'A customer name and referral source are required to create this job.'
      }
    })
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  editorCreated(editor: Quill, key: SummaryType) {
    switch (key) {
      case 'crewSummary':
        this.crewEditor = editor;
        break;
      case 'adminSummary':
        this.adminEditor = editor;
        break;
      case 'customerSummary':
        this.customerEditor = editor;
        break;
    }

    this.watchJobSummaries();
  }

  watchJobSummaries(): void {

    // Wait untill all editors are ready
    if (!this.crewEditor || !this.adminEditor || !this.customerEditor) return;

    const editors = [ this.crewEditor, this.adminEditor, this.customerEditor ];

    this.subs.sink = this.summaries$
      .pipe(
        withLatestFrom(this.store.select(jobToolFeature.selectJobInput)),
        // Do not update editor contents if any editor has focus
        filter(() => editors.every((e) => !e.hasFocus())),
      )
      .subscribe(([ summaries, jobInput ]) => {
        this.jobId = jobInput.id;
        setEditorContents(this.crewEditor, summaries.crewSummary );
        setEditorContents(this.adminEditor, summaries.adminSummary);
        setEditorContents(this.customerEditor, summaries.customerSummary);
      });
  }

  contentChanged(event: ContentChange, key: SummaryType) {

    const {source, text, content} = event || {};

    if (source === 'api') return;

    this.store.dispatch(JobSummaryActions.updateSummary({
      text,
      contents: content,
      key,
    }));
  }

  moveBackToSummaryTab() {
    const focusableElements = Array.from(document.querySelectorAll<HTMLElement>('div[tabindex]'))
      .filter(el => el.tabIndex >= 0);

    focusableElements.sort((a, b) => a.tabIndex - b.tabIndex);

    const nextElement = focusableElements[0];
    nextElement.focus();
  }

  selectSummaryIndex(summaryIndex: number) {
    this.store.dispatch(JobToolActions.summaryIndexChanged({ summaryIndex }));
  }
}
