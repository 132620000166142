import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {QueryRef} from 'apollo-angular';

import { LazyLoadEvent } from 'primeng/api';
import { Table } from 'primeng/table';
import { Subject } from 'rxjs';
import { debounceTime, filter, first } from 'rxjs/operators';
import { SubSink } from 'subsink';

import { BaseReportTypeFragment, ReportTypesListGQL, ReportTypesListQuery, ReportTypesListQueryVariables, ZoneDir } from '../../../generated/graphql.generated';
import { FeedbackService } from '../../core/public-api';
import { pagination } from '../../global.constants';
import { DetailsHelperService } from '../../services/details-helper.service';
import { initQueryHelper, WatchQueryHelper } from '../../utilities/watchQueryHelper';

const newReportTypeMessage = `\
I'd like to request a new report type:

What would you like the report called?


What data do you want this report to return? What format?


How would you like the data summarized?


Who will be using this report?


Do you have an example report from another application?

`;

@Component({
  selector: 'app-report-types',
  templateUrl: './report-types.component.html',
  styleUrls: ['./report-types.component.scss']
})
export class ReportTypesComponent implements OnInit, OnDestroy {

  @ViewChild('table') table: Table;

  @Input() pagination = pagination;

  reportTypes: ReportTypesListQuery['reportTypes']['reportTypes'];
  reportTypesQH: WatchQueryHelper = {};

  filterUpdated = new Subject<void>();

  showCreateReportTypeDialog = false;

  tabs = [
    'dynamic',
    'static',
  ];

  activeIndex = 0;

  home = { icon: 'pi pi-home', routerLink: '/' };

  breadcrumb = [
    { label: 'Reports' },
  ];

  private subsink = new SubSink();
  private queryRef: QueryRef<ReportTypesListQuery, ReportTypesListQueryVariables>;

  constructor(
    private reportTypesService: ReportTypesListGQL,
    private detailsHelper: DetailsHelperService,
    private feedbackService: FeedbackService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.route.queryParams
      .pipe(
        filter(params => 'zone' in params),
        debounceTime(10),
        first(),
      )
      .subscribe((params) => {

        const { reportType } = params;

        if (this.tabs.includes(reportType)) {
          this.activeIndex = this.tabs.indexOf(reportType);
        }

      });

    this.reportTypesQH = initQueryHelper({
      skip: 0,
      limit: 50,
      search: '',
      sort: ['name:ASC'],
    });
    this.fetch();
    this.subsink.sink = this.filterUpdated.pipe(
      debounceTime(800)
    ).subscribe(() => {
      // ensure we set skip to zero when we apply a new filter
      this.setFirstPage();
      this.fetch();
    });
  }

  ngOnDestroy(): void {
    delete this.queryRef;
    this.subsink.unsubscribe();
  }

  getVariables() {
    return {
      filter: {
        zoneDir: ZoneDir.Any,
        getDeleted: false,
        search: this.reportTypesQH.search,
      },
      skip: this.reportTypesQH.skip,
      limit: this.reportTypesQH.limit,
      sort: this.reportTypesQH.sort.join(';'),
    } as ReportTypesListQueryVariables;

  }

  clearSearch() {
    this.reportTypesQH.search = '';
    this.fetch();
  }

  fetch() {
    if (this.queryRef) {
      this.queryRef.refetch(this.getVariables());
      return;
    }

    this.queryRef = this.reportTypesService.watch(this.getVariables(), {
      fetchPolicy: 'cache-and-network',
    });
    this.subsink.sink = this.queryRef.valueChanges.subscribe((res) => {
      this.reportTypesQH.loading = res.loading;
      this.reportTypesQH.total = res.data?.reportTypes?.total;
      this.reportTypes = res.data?.reportTypes?.reportTypes;
    });
  }

  onLazyLoad(ev: LazyLoadEvent) {
    this.reportTypesQH.limit = ev.rows;
    this.reportTypesQH.skip = ev.first;
    this.fetch();
  }

  select(rt: BaseReportTypeFragment) {
    this.detailsHelper.detailsItem.next({ type: 'reportType', item: rt });
  }

  setFirstPage() {
    this.table.first = 0;
    this.reportTypesQH.skip = 0;
  }

  openFeedback() {
    this.feedbackService.openFeedbackDialog({
      feedbackType: 'feedback',
      message: newReportTypeMessage,
    });
  }

}
