import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import MiniSearch from 'minisearch';

import { DialogService } from 'primeng/dynamicdialog';
import { Observable, catchError, debounceTime, exhaustMap, filter, map, mergeMap, of, tap, withLatestFrom } from 'rxjs';

import { CreateCalendarEventMutationVariables, CreateYemboMoveGQL, CreateYemboSelfSurveyMutationVariables } from '../../../generated/graphql.generated';
import { FIELD_CONFIG } from '../../global.constants';
import { safeParseJSON } from '../../js';
import { FreyaNotificationsService } from '../../services/freya-notifications.service';
import { brandingFeature } from '../../state/branding.store';

import { generateUUID } from '../../utilities/state.util';
import { ScheduleEventsActions } from '../job-state/event-schedule-state/event-schedule.actions';
import { ActionInitiator, JobToolActions } from '../job-tool.actions';
import { jobToolFeature } from '../job-tool.reducer';


import { CreateSelfSurveyLinkDialogComponent, CreateSelfSurveyDialogData } from './create-self-survey-link-dialog/create-self-survey-link-dialog.component';
import { InventoryActions } from './inventory.actions';
import { defaultInventory, inventoryFeature, InventoryItemDefinition, JobInventory } from './inventory.feature';
import { recalculateInventory } from './inventory.util';
import { ConfirmationService } from 'primeng/api';

export const loadInventoryFromJob = createEffect((
	actions$ = inject(Actions),
	store = inject(Store),
) => {
	return actions$.pipe(
		ofType(InventoryActions.inventoryPageInitialized, JobToolActions.jobLoaded),
		withLatestFrom(store.select(jobToolFeature.selectJob)),
		map(([{ }, job]) => {

			const fields = job?.fields;
			if (!fields?.length) {
				return InventoryActions.inventoryLoading();
			}

			// TODO: check if there is any change state we need to check

			const inventoryField = fields?.find((f) => f.name === FIELD_CONFIG.jobs.inventory.name);
			const inventoryNotesField = fields?.find((f) => f.name === FIELD_CONFIG.jobs.inventoryNotes.name);

			const inventory: JobInventory = safeParseJSON(inventoryField?.values[0]?.value, defaultInventory);
			const inventoryNotes = inventoryNotesField?.values[0]?.value;

			if (inventory) {
				// fix some default field values from the old inventory system
				for (const room of inventory.rooms) {
					room.id = room.id || generateUUID();
					for (const item of room.inventory) {
						item.id = item.id || generateUUID();
					}
				}
			}

			return InventoryActions.inventoryLoaded({
				inventory,
				notes: inventoryNotes,
			});

		}),
	);
}, { functional: true, dispatch: true });

export const searchForInventoryItem = createEffect((
	actions$ = inject(Actions),
	store = inject(Store),
) => {

	let loaded = false;
	const itemMiniSearch = new MiniSearch({
		fields: ['reviewerName', 'label', 'rooms', 'aliases'],
		storeFields: [
			'name',
			'reviewerName',
			'label',
			'packing',
			'quantity',
			'volume',
			'weight',
			'generated',
			'rooms'
		],
		idField: 'label',
	});

	const itemMap: {
		[id: string]: InventoryItemDefinition,
	} = {};

	return actions$.pipe(
		ofType(InventoryActions.itemSearchCompleteRequest),
		withLatestFrom(store.select(brandingFeature.selectConfigs)),
		map(([{ search, roomId }, configs]) => {
			if (!loaded) {
				const items = configs['inventory.items'] || [];
				loaded = true;
				itemMiniSearch.addAll(cloneDeep(items));
				for (const item of items) {
					itemMap[item.label] = item;
				}
			}

			const searchResults = itemMiniSearch.search(
				search, {
				prefix: true,
				fuzzy: 0.2,
				boost: {
					reviewerName: 2,
					label: 2,
				}
			});

			const items = searchResults
				.map((r) => itemMap[r.id])
				.filter(Boolean);

			return InventoryActions.itemSearchCompleteResult({
				items,
			})


			// if (!combinedResults?.length){
			//   filteredItemOptions = cloneDeep(configs['inventory.items']);
			//   return;
			// }

		}),
	);

}, { functional: true, dispatch: true });

export const inventoryChanged = createEffect((
	actions$ = inject(Actions),
	store = inject(Store),
) => {
	return actions$.pipe(
		ofType(
			InventoryActions.addRoomButtonClicked,
			InventoryActions.itemSearchSelected,
			InventoryActions.itemSearchSave,
			InventoryActions.removeRoomClick,
			InventoryActions.removeItemClick,
			InventoryActions.quantityUpdated,
			InventoryActions.itemSearchEnterPressed,
			InventoryActions.editItemSaved,
		),
		withLatestFrom(
			store.select(inventoryFeature.selectRooms),
			store.select(inventoryFeature.selectWeightUnit),
			store.select(inventoryFeature.selectVolumeUnit),
			store.select(inventoryFeature.selectChanges),
		),
		debounceTime(1),
		map(([action, rooms, weightUnit, volumeUnit, changes]) => {

			const inventory = recalculateInventory(rooms, weightUnit, volumeUnit);
			const lastRoomChanged = 'roomId' in action ? action.roomId : undefined;

			return InventoryActions.inventoryChanged({
				lastRoomChanged,
				action,
				inventory,
				doFocus: 'doFocus' in action ? action.doFocus : undefined,
			});

		}),
	);
}, { functional: true, dispatch: true });

export const viewMoveInYembo = createEffect((
	actions$ = inject(Actions),
	store = inject(Store),
) => {
	return actions$.pipe(
		ofType(
			InventoryActions.viewMoveInYemboClicked,
		),
		withLatestFrom(
			store.select(jobToolFeature.selectJob),
		),
		tap(([_, job]) => {

			const yemboEmployeeLink = job?.metadata?.yemboEmployeeLink;

			if (yemboEmployeeLink) {
				window.open(yemboEmployeeLink, '_blank');
			} else {
				console.error('Yembo link not found');
			}
		}),
	);
}, { functional: true, dispatch: false });

export const openStartOnsiteDialog = createEffect((
	actions$ = inject(Actions),
	confirmationService = inject(ConfirmationService),
  ) => {
	return actions$.pipe(
	  ofType(InventoryActions.startOnsiteButtonClicked),
	  mergeMap(() => {
		return new Observable<Action>(observer => {
		  confirmationService.confirm({
			header: `Start On-Site Estimate`,
			message: `You will be redirected to Yembo.`,
			accept: () => {
			  observer.next(InventoryActions.startOnsiteDialogClosed({ startOnsite: true }));
			  observer.complete();
			},
			reject: () => {
				observer.next(InventoryActions.startOnsiteDialogClosed({ startOnsite: false }));
			  observer.complete();
			},
		  });
		});
	  })
	);
  }, { functional: true, dispatch: true });

export const openSelfSurveyDialog = createEffect((
	actions$ = inject(Actions),
	store = inject(Store),
	dialogService$ = inject(DialogService),
) => {
	return actions$.pipe(
		ofType(
			InventoryActions.createSelfSurveyLinkClicked,
		),
		withLatestFrom(
			store.select(jobToolFeature.selectCustomer),
		),
		exhaustMap(([_, customer]) => {

			const ref = dialogService$.open(CreateSelfSurveyLinkDialogComponent, {
				header: 'Create Self Survey Link',
				data: {
					customer,
					yemboMoveCreated: false,
				} as CreateSelfSurveyDialogData,
			});

			return ref.onClose.pipe(
				map((result) => {
					const createSelfSurvey = result?.createSelfSurvey ?? false;
					return InventoryActions.createSelfSurveyLinkDialogClosed({ createSelfSurvey });
				})
			);
		}),
	);
}, { functional: true, dispatch: true });

export const startOnsiteEstimate = createEffect(() => {
	const actions$ = inject(Actions);
	const store = inject(Store);
	const startOnsiteGQL = inject(CreateYemboMoveGQL);
	const notify = inject(FreyaNotificationsService);

	return actions$.pipe(
		ofType(InventoryActions.startOnsiteDialogClosed),
		filter(({ startOnsite }) => startOnsite),
		withLatestFrom(store.select(jobToolFeature.selectJobId)),
		exhaustMap(([_, jobId]) => {

			const errorMessage = 'Failed to start onsite estimate';

			return startOnsiteGQL.mutate({ jobId }).pipe(
				map((res) => {
					const { customerLink = null, employeeLink = null } = res.data?.createYemboMove || {};

					if (!customerLink || !employeeLink) {

						notify.error(errorMessage);
						return InventoryActions.yemboMoveCreationFailed({
							error: new Error(errorMessage),
							callState: 'yemboStartOnsite'
						});
					}

					window.open(employeeLink, '_blank')?.focus();

					return InventoryActions.yemboMoveCreatedSuccessfully({
						employeeLink,
						customerLink,
						callState: 'yemboStartOnsite',
					});
				}),
				catchError((error) => {
					console.error(errorMessage, error);
					notify.error(errorMessage, error);
					return of(InventoryActions.yemboMoveCreationFailed({ error, callState: 'yemboStartOnsite' }));
				})
			);
		})
	);
}, { functional: true, dispatch: true });

export const createSelfSurvey = createEffect(() => {
	const actions$ = inject(Actions);
	const store = inject(Store);
	const createYemboSelfSurveyGQL = inject(CreateYemboMoveGQL);
	const notify = inject(FreyaNotificationsService);

	return actions$.pipe(
		ofType(InventoryActions.createSelfSurveyLinkDialogClosed),
		filter(({ createSelfSurvey }) => createSelfSurvey),
		withLatestFrom(store.select(jobToolFeature.selectJobId)),
		exhaustMap(([_, jobId]) => {

			const input: CreateYemboSelfSurveyMutationVariables = {
				jobId,
				sendEmail: true,
				sendSms: true,
			};

			const errorMessage = 'Failed to create self survey';

			return createYemboSelfSurveyGQL.mutate(input).pipe(
				map((res) => {
					const { customerLink = null, employeeLink = null } = res.data?.createYemboMove || {};

					if (!customerLink || !employeeLink) {

						notify.error(errorMessage);
						return InventoryActions.yemboMoveCreationFailed({
							error: new Error(errorMessage),
							callState: 'yemboSelfSurvey'
						});
					}

					notify.success('Self-survey link created successfully and sent to customer.');

					return InventoryActions.yemboMoveCreatedSuccessfully({
						employeeLink,
						customerLink,
						callState: 'yemboSelfSurvey',
					});
				}),
				catchError((error) => {
					console.error(errorMessage, error);
					notify.error(errorMessage, error);
					return of(InventoryActions.yemboMoveCreationFailed({
						error,
						callState: 'yemboSelfSurvey'
					}));
				})
			);
		})
	);
}, { functional: true, dispatch: true });

export const bookSmartConsult = createEffect(() => {
	const actions$ = inject(Actions);
	const store = inject(Store);

	return actions$.pipe(
		ofType(InventoryActions.bookYemboSmartConsultClicked),
		withLatestFrom(store.select(jobToolFeature.selectJobId), store.select(jobToolFeature.selectJobEvents)),
		exhaustMap(([_, jobId, jobEvents]) => {

			const createEventsInput: CreateCalendarEventMutationVariables = {
				calendarEvents: [
					{
						type: 'virtualEstimate',
						title: 'Virtual Estimate',
						status: 'required',
						jobId: jobId,
						sequentialOrder: (jobEvents?.length || 0) + 1,
					},
				],
			};

			return of(JobToolActions.eventCreationRequested({ createEventsInput, source: ActionInitiator.API }));
		}),
	);

}, { functional: true, dispatch: true });

export const openBookingDialog = createEffect(() => {
	const actions$ = inject(Actions);

	return actions$.pipe(
		ofType(JobToolActions.eventCreationSuccess),
		filter(({ source }) => source === ActionInitiator.API),
		map(({ events }) => {
			const [voseEvent] = events;

			return ScheduleEventsActions.openBookDialogButtonClicked({
				event: voseEvent,
				reschedule: false,
			});
		}),
	);
}, { functional: true, dispatch: true });

export const inventoryEffects = {
	searchForInventoryItem,
	loadInventoryFromJob,
	inventoryChanged,
	viewMoveInYembo,
	openSelfSurveyDialog,
	openStartOnsiteDialog,
	createSelfSurvey,
	startOnsiteEstimate,
	bookSmartConsult,
	openBookingDialog,
};
