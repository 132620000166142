import { AfterContentChecked, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ProductService } from '@karve.it/features';
import { ListPricesOutput } from '@karve.it/interfaces/prices';
import { Product } from '@karve.it/interfaces/products';
import { Zone } from '@karve.it/interfaces/zones';
import {QueryRef} from 'apollo-angular';
import * as pluralize from 'pluralize';

import { lastValueFrom } from 'rxjs';
import { currencies, PRICE_TYPES } from 'src/app/global.constants';
import { DetailsHelperService } from 'src/app/services/details-helper.service';
import { FreyaHelperService } from 'src/app/services/freya-helper.service';
import { FreyaNotificationsService } from 'src/app/services/freya-notifications.service';
import { environment } from 'src/environments/environment';
import { SubSink } from 'subsink';

import { BaseProductFragment, BaseTaxFragment, CreatePriceInput, CreatePricesGQL, Expense, FullPriceFragment, ListExpensesGQL, ListTaxesGQL, UpdatePriceInput, UpdatePricesGQL } from '../../../generated/graphql.generated';

import { convertCentsToDollars, convertDollarsToCents } from '../../lib.ts/currency.util';
import { PermissionService } from '../../services/permission.service';
import { permissionHasRestriction, permissionHasRestrictions } from '../../services/permissions.util';
import { ResponsiveHelperService } from '../../services/responsive-helper.service';
import { getPriceReadableAmount, getPriceSystemAmount } from '../../utilities/prices.util';

import { MutateObjectComponent, MutateObjectElement } from '../mutate-object/mutate-object.component';
import { uniqueExpenseValidator } from '../unique-expense.validator';

@Component({
  selector: 'app-mutate-price',
  templateUrl: './mutate-price.component.html',
  styleUrls: ['./mutate-price.component.scss']
})
export class MutatePriceComponent implements OnInit, OnDestroy, AfterContentChecked {

  @ViewChild('mutate') mutateRef: MutateObjectComponent;

  // Template Refs
  @ViewChild('name') nameRef: TemplateRef<any>;
  @ViewChild('active') activeRef: TemplateRef<any>;
  @ViewChild('effectExistingCharges') effectExistingCharges: TemplateRef<any>;
  @ViewChild('amount') amountRef: TemplateRef<any>;
  @ViewChild('currency') currencyRef: TemplateRef<any>;
  @ViewChild('zone') zoneRef: TemplateRef<any>;
  @ViewChild('taxes') taxesRef: TemplateRef<any>;
  @ViewChild('expenses') expensesRef: TemplateRef<any>;

  // Review Refs
  @ViewChild('amountReview') amountReviewRef: TemplateRef<any>;

  @Input() mutateType: 'update' | 'create';

  // set in the mutate container when updating
  @Input() price: FullPriceFragment;
  @Input() product: BaseProductFragment;

  steps: MutateObjectElement[];

  subs = new SubSink();

  priceForm = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(80)]),
    active: new UntypedFormControl(true, []),
    priceType: new UntypedFormControl('fixed', [Validators.required]),
    amount: new UntypedFormControl(undefined, [ Validators.required ]),
    currency: new UntypedFormControl(environment.defaultCurrency, [Validators.required]),
    zones: new UntypedFormControl(undefined, [Validators.required]),
    taxes: new UntypedFormControl([], []),

    effectExistingCharges: new UntypedFormControl(false, []),
    expenses: new UntypedFormArray([], uniqueExpenseValidator()),
  });

  priceFormValues = this.priceForm.value;

  taxOptions: BaseTaxFragment[] = [];

  currencyCodes = currencies;

  // selectableZones: Zone[];

  selectableProducts: Product[];

  priceQueryRef: QueryRef<ListPricesOutput>;

  priceTypes = PRICE_TYPES;

  hasAmountOverrideRestriction = false;
  hasEffectExistingChargesRestriction = false;

  expenseOptions: Expense[] = [];

  constructor(
    private createPriceGQL: CreatePricesGQL,
    private updatePricesGQL: UpdatePricesGQL,
    private productService: ProductService,
    private detailsHelper: DetailsHelperService,
    private localNotify: FreyaNotificationsService,
    private listTaxesGql: ListTaxesGQL,
    private listExpensesGQL: ListExpensesGQL,
    private cd: ChangeDetectorRef,
    private permissionHandler: PermissionService,
    private freyaHelper: FreyaHelperService,
    public responsiveHelper: ResponsiveHelperService,
  ) { }

  ngOnInit(): void {
    // this.retrieveZones();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  ngAfterContentChecked() {
    this.cd.detectChanges();
  }

  get expensesForm() {
    return this.priceForm.get('expenses') as UntypedFormArray;
  }

  get singularProductUnit(){
    const unitLabel = this.product?.unitLabel;
    return unitLabel ? `(per ${pluralize.singular(unitLabel)})` : '';
  }

  // Method to create an expense form group
  createExpense(expense: Pick<Expense, 'id' | 'unitCost'>): UntypedFormGroup {
    return new UntypedFormGroup({
        expenseId: new UntypedFormControl(expense?.id, Validators.required),
        unitCost: new UntypedFormControl(expense?.unitCost, [Validators.required, Validators.min(0.01)])
    });
  }
  
  mutateObject() {
    if (this.mutateType === 'create') {
      this.createPrice();
    } else if (this.mutateType === 'update') {
      this.updatePrice();
    }
  }

  async openDialog() {
    this.getExpenses();
    const updating = this.mutateType === 'update';
    this.hasAmountOverrideRestriction = false;
    this.hasEffectExistingChargesRestriction = false;

    if (updating) {
      const permission = this.permissionHandler.getPermission('prices.update');

      console.log(permission);
      this.hasAmountOverrideRestriction = permissionHasRestriction(permission, 'pricesOverrideAmounts');
      this.hasEffectExistingChargesRestriction = permissionHasRestriction(permission, 'pricesEffectExistingCharges');
    }


    this.steps = [
      {
        name: 'Name', ref: this.nameRef, control: 'name', type: 'text'
      },
      {
        name: 'Active', ref: this.activeRef, control: 'active', type: 'boolean',
      },
      {
        name: 'Amount',
        ref: this.amountRef,
        disabled: updating && !this.hasAmountOverrideRestriction,
        control: 'amount', type: 'complex',
        reviewRef: this.amountReviewRef,
      },
      {
        name: 'Currency ', ref: this.currencyRef, control: 'currency', type: 'text',
        disabled: updating && !this.hasAmountOverrideRestriction,
      },
      {
        name: 'Area ', ref: this.zoneRef, control: 'zones', type: 'func',
        reviewFunc: (val) => (val?.length ? val[0].name : 'None'),
        disabled: updating,
      },
      {
        name: 'Taxes', ref: this.taxesRef, control: 'taxes', type: 'array',
        disabled: updating && !this.hasAmountOverrideRestriction,
      },
      {
        name: 'Effect Existing Charges', ref: this.effectExistingCharges, control: 'effectExistingCharges', type: 'boolean',
        removed: !this.hasEffectExistingChargesRestriction,
      },
      {
        name: 'Costs', ref: this.expensesRef, control: 'expenses', type: 'func',
        reviewFunc: this.getExpenseTotal,
      },
    ];

    if (this.mutateType === 'create') {
      this.priceForm.reset(this.priceFormValues);
      // Reset expenses form array
      this.expensesForm.clear();
      this.resolveCurrency();
      if (this.product) {
        this.mutateRef.titleText = `Create Price for ${this.product.name}`;
        this.priceForm.get('name').setValue(this.product.name);
      }
    } else if (this.mutateType === 'update') {
      this.setFormValues();
      this.mutateRef.titleText = `Updating Price ${this.price.name} for ${this.product.name}`;
    }
    this.mutateRef.steps = this.steps;


    this.mutateRef.openDialog();
    this.retrieveTaxes();
  }

  setFormValues() {
    this.priceForm.reset({
      name: this.price.name,
      active: this.price.active,
      priceType: this.price.priceType,
      amount: getPriceReadableAmount(this.price),
      currency: this.price.currency,
      zones: this.price.zone ? [this.price.zone] : [],
      taxes: this.price.taxes,
    });

    // Reset expenses form array
    this.expensesForm.clear();
    (this.price.expensesV2 || []).map(expense => {
      const expenseFormGroup = this.createExpense({ id: expense.expense.id, unitCost: convertCentsToDollars(expense.unitCost)});
      this.expensesForm.push(expenseFormGroup); 
    })
  }

  retrieveProducts() {
    this.subs.sink = this.productService.listProducts({}, {}).subscribe((res) => {
      this.selectableProducts = res.data.products.products;
    });
  }

  createPrice() {
    const val = this.priceForm.value;

    const input: CreatePriceInput = {
      name: val.name,
      active: val.active,
      amount: getPriceSystemAmount(val),
      currency: val.currency,
      productId: this.product.id,
      priceType: val.priceType,
      taxIds: val.taxes ? val.taxes.map((t) => t.id) : [],
      expenses: this.convertExpensesToCents(),
    };

    this.subs.sink = this.createPriceGQL.mutate({
      prices: [input],
      zoneId: val.zones[0].id,
    }).subscribe((res) => {
      const [price] = res.data.createPrices;
      this.mutateRef.closeDialog();
      this.localNotify.addToast.next({ severity: 'success', summary: 'Price created' });
      this.detailsHelper.pushUpdate({
        id: price.id,
        type: 'Prices',
        action: 'create',
      });

      this.detailsHelper.open('price', price);
    }, (err) => {
      this.mutateRef.loading = false;
      this.localNotify.apolloError(`Failed to create price`, err.message);
    });
  }

  async updatePrice() {
    /**
     * const { added, removed } = this.freyaHelper.getAddedAndRemoved(this.product.taxes || [], val.taxes, true);
     *
      addTaxes: added ? added.map((a) => a.id) : [],
      removeTaxes: removed ? removed : [],
      force: true / false??
     *
     */

    const val = this.priceForm.value;

    const edit: UpdatePriceInput = {
      priceId: this.price.id,
    };

    let updated = false;

    // only add properties that have changed
    const onlyDirtyProperties = [ 'name', 'active', 'priceType', 'amount', 'currency', 'zones'];
    for (const property of onlyDirtyProperties) {
      const control = this.priceForm.get(property);
      if (control.dirty) {
        
        if (property === 'zones'){
         edit.zoneId = control?.value?.[0]?.id; 
        } else {
          edit[property] = control.value;
        }
        
        // this.price[property] = control.value;
        updated = true;
      }
    }

    // expenses
    if (this.expensesForm.dirty) {
      const expenses = this.convertExpensesToCents();
      
      const originalExpenses = this.price.expensesV2.map(e => ({...e.expense, unitCost: e.unitCost})) || [];
      const { added, removed, updated: updatedExpenses } = this.freyaHelper.getAddedRemovedUpdated(
      originalExpenses, expenses, true, ['unitCost'], 'id', 'expenseId'
      );

      if (added.length || removed.length || updatedExpenses.length) {
        edit.addExpenses = added;
        edit.removeExpenses = removed;
        edit.updateExpenses = updatedExpenses;
        updated = true;
      }
      
    }

    // taxes
    edit.addTaxes = val.taxes
      .filter((newTax) => !this.price.taxes.find((tax) => tax.id === newTax.id))
      .map((t) => t.id)
    ;

    if (!edit.addTaxes?.length) {
      delete edit.addTaxes;
    }

    edit.removeTaxes = this.price.taxes
      .filter((oldTax) => !val.taxes.find((tax) => tax.id === oldTax.id))
      .map((t) => t.id)
    ;

    if (!edit.removeTaxes?.length) {
      delete edit.removeTaxes;
    }

    if (edit.addTaxes?.length || edit.removeTaxes?.length) {
      updated = true;
      // this.price.taxes = val.taxes;
    }

    // don't add amount or currency if we don't have the restriction
    if (!this.hasAmountOverrideRestriction) {
      delete edit.amount;
      delete edit.currency;
      delete edit.priceType;
      delete edit.addTaxes;
      delete edit.removeTaxes;
    }

    let effectExistingCharges: boolean;
    if (val.effectExistingCharges && this.hasEffectExistingChargesRestriction) {
      effectExistingCharges = true;
    }

    // convert dollars to cents
    if (edit.amount) {
      edit.amount = getPriceSystemAmount(val);
      // this.price.amount = edit.amount;
    }

    console.log('prices.update', updated, edit);

    // if no changes, don't do anything
    if (!updated && !this.priceForm.value.zones.length) {
      this.localNotify.addToast.next({
        severity: 'info',
        summary: 'Price not updated',
        detail: 'No changes were made',
      });
      return;
    }

    try {
      let success = false;

      if (updated) {
        const { data } = await lastValueFrom(
          this.updatePricesGQL.mutate({
            prices: [edit],
            effectExistingCharges,
          }),
        );

        success = data.updatePrices;
        if (!success) {
          throw new Error(`Price not updated`);
        }
      }

      // If update fails/no changes and zone change fails; return
      if (!success) {
        return;
      }

      this.mutateRef.closeDialog();
      this.localNotify.addToast.next({ severity: 'success', summary: 'Price updated' });
      this.detailsHelper.open('price', this.price);
      // TODO: reload price in mutate price

      this.detailsHelper.pushUpdate({
        id: this.price.id,
        type: 'Prices',
        action: 'update',
      });
    } catch (err) {
      this.mutateRef.loading = false;
      // remove changes made if there was a failure
      // Object.assign(this.price, originalPrice);
      this.localNotify.apolloError(`Failed to update price`,err.message);
    }
  }

  retrieveTaxes() {
    this.subs.sink = this.listTaxesGql.fetch({}).subscribe((res) => {
      this.taxOptions = res.data.taxes.taxes;

      const defaultTaxes = this.taxOptions.filter((t) => t.isZoneDefault);

      if (!defaultTaxes?.length || this.mutateType === 'update') { return; } // No default taxes or taxes already set

      this.priceForm.controls.taxes.setValue(defaultTaxes);
    });
  }

  removeTax(tax: BaseTaxFragment) {
    const taxesAfterRemove = this.priceForm.value.taxes.filter((t) => t.id !== tax.id);
    this.priceForm.controls.taxes.setValue(taxesAfterRemove);
  }

  async resolveCurrency() {
    this.priceForm.controls.currency.setValue(await this.freyaHelper.getCurrency());
  }

  // Expenses CRUD
  getExpenses() {
    this.listExpensesGQL.fetch({}).subscribe((res) => {
      if(res.loading) return;
      this.expenseOptions = res.data?.expenses?.expenses || [];
    });
  }

  convertExpensesToCents() {
    return this.expensesForm.value.map((expense: Expense) => ({...expense, unitCost: convertDollarsToCents(expense.unitCost)}));
  }

  // Expenses CRUD UI Helpers

  onRowDelete(index: number) {
    this.expensesForm.removeAt(index);
    this.expensesForm.markAsDirty();
  }

  onRowAdd() {
      this.expensesForm.push(this.createExpense({ id: null, unitCost: 0 }));
  }

  getExpenseTotal(val: Pick<Expense, 'unitCost'>[]) {
    const amount = val.reduce((acc, expense) => acc + expense.unitCost, 0);
    return `${val.length || 0} ($${amount})`;
  }

}

