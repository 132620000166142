import {
  AppliedDiscountFragment,
  BaseChargeFragment,
  BaseDiscountFragment,
  BaseJobFragment,
  CalendarEventForCalculatorFragment,
  DiscountTypes,
  EstimatesJobFragment,
  FullJobFragment,
  Job_CalendarEventsFragment,
  Tag,
} from 'graphql.generated';
import { cloneDeep, partition } from 'lodash';

import { PERCENT } from '../global.constants';
import { firstNotEmptyValue, MAX_32_BIT_INT } from '../js';

import { isJobTaxExempt } from './jobsv2-charges-helpers';

type Charge = BaseChargeFragment & {
  discountTotals?: {
    [discountId: string]: number;
  };
  taxTotals?: {
    [taxId: string]: number;
  };
};
type AppliedDiscount = AppliedDiscountFragment;
type CalendarEvent = CalendarEventForCalculatorFragment & {
  discountTotals?: {
    [discountId: string]: number;
  };
  taxTotals?: {
    [taxId: string]: number;
  };
  charges?: Charge[];
};
type Job = BaseJobFragment & Job_CalendarEventsFragment;

export function calculateJobTotals(job: FullJobFragment) {
  const newJob: FullJobFragment = {
    ...job,
    subTotal: 0,
    discountTotal: 0,
    damageTotal: 0,
    taxTotal: 0,
    expenseTotal: 0,

    events: [],

    // paidTotal: 0,
    // pendingTotal: 0,
  };

  for (const event of job?.events) {
    if (event?.status === 'cancelled') {
      newJob.events.push(event);
      continue;
    }

    const newEvent = sumEvent(event, job?.tags);
    newJob.events.push(newEvent);
    newJob.subTotal += newEvent.subTotal;
    newJob.discountTotal += newEvent.discountTotal;
    newJob.damageTotal += newEvent.damageTotal;
    newJob.taxTotal += newEvent.taxTotal;
    newJob.expenseTotal += newEvent.expenseTotal;
  }

  newJob.total = newJob.subTotal + newJob.taxTotal - newJob.discountTotal;

  newJob.discountedSubTotal = newJob.subTotal - newJob.discountTotal;
  newJob.discountedSubTotalWithoutDamages =
    newJob.discountedSubTotal - newJob.damageTotal;

  // Validate that all totals are within the 32 bit integer range
  const totalKeys = [
    'subTotal',
    'discountTotal',
    'discountedSubTotal',
    'damageTotal',
    'discountedSubTotalWithoutDamages',
    'taxTotal',
    'total',
  ];

  for (const key of totalKeys) {
    if (newJob[key] > MAX_32_BIT_INT) {
      newJob[key] = MAX_32_BIT_INT;
    }
  }

  // TODO: update transaction totals

  return newJob;
}

export function sumEvent(_event: CalendarEvent, tags: Partial<Tag>[]) {
  const event = cloneDeep(_event);

  event.subTotal = 0;
  event.discountTotal = 0;
  event.damageTotal = 0;
  event.taxTotal = 0;
  event.expenseTotal = 0;

  event.discountTotals = {};
  event.taxTotals = {};

  const [percentageDiscounts, fixedDiscounts] = partition(
    event.discounts.sort(
      (a, b) => a.appliedAt - b.appliedAt
    ) as AppliedDiscount[],
    (d) => d.discount?.discountType === DiscountTypes.Percentage
  );

  const [percentageCharges, fixedAmountCharges] = partition(
    event.charges.sort((a, b) => a.order - b.order) as Charge[],
    (e) => e.price?.priceType === 'percentage'
  );

  const [normalCharges, damageCharges] = partition(
    event.charges.sort((a, b) => a.order - b.order) as Charge[],
    (charge) => !(charge.attributes || []).includes('damage')
  );

  let eventSubtotalWithoutDamages = 0;
  // used to calcualte percentage based charges
  let positiveFixedEventSubtotal = 0;

  /**
   * Set fixed charge totals, including damages
   */
  for (const charge of fixedAmountCharges) {
    charge.amount = firstNotEmptyValue(charge.amount, charge.price?.amount, 0);
    charge.chargeSubTotal = Math.ceil(charge.amount * charge.quantity);
    const isDamage = (charge.attributes || []).includes('damage');

    if (isDamage) {
      event.damageTotal += charge.chargeSubTotal;
    } else {
      eventSubtotalWithoutDamages += charge.chargeSubTotal;
    }

    if (!isDamage && charge.chargeSubTotal > 0) {
      positiveFixedEventSubtotal += charge.chargeSubTotal;
    }
  }

  // Sum charges with 'percentage' amounts, including damages
  for (const charge of percentageCharges) {
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    const priceAmount =
      firstNotEmptyValue(charge.amount, charge.price?.amount, 0) / 100;
    const percentageAmount =
      Math.ceil(charge.quantity * priceAmount * positiveFixedEventSubtotal) ||
      0;

    charge.chargeSubTotal = percentageAmount;
    const isDamage = (charge.attributes || []).includes('damage');
    if (isDamage) {
      event.damageTotal += percentageAmount;
    } else {
      eventSubtotalWithoutDamages += charge.chargeSubTotal;
    }
  }

  for (const charge of damageCharges) {

    charge.discountedSubTotal = charge.chargeSubTotal;
    charge.discountTotal = 0;
    charge.taxTotal = 0;
    charge.total = 0;
  }

  const discountRoundingError: { [discountId: string]: number } = {};
  const sumOfNormalCharges = normalCharges.reduce(
    (p, c) => p + c.chargeSubTotal,
    0
  );
  const discountsApplied: { [appliedId: string]: number } = {};

  // Calculate basic discounts to be applied to normal (non-damage) charges
  for (const charge of normalCharges) {
    charge.discountTotals = charge.discountTotals || {};
    charge.taxTotals = charge.taxTotals || {};
    charge.discountTotal = 0;
    charge.taxTotal = 0;
    charge.total = 0;

    /**
     * Event percentage discounts
     * Applied universally to all charges
     */
    for (const percentageDiscount of percentageDiscounts || []) {
      const { discountAmount: amount, roundingError } = resolveDiscountAmount(
        percentageDiscount.discount,
        charge.chargeSubTotal,
        percentageDiscount.appliedCustomAmount,
        discountRoundingError[percentageDiscount.appliedId] || 0
      );
      charge.discountTotal += amount;
      charge.discountTotals[percentageDiscount.appliedId] =
        charge.discountTotals[percentageDiscount.appliedId] || 0;
      charge.discountTotals[percentageDiscount.appliedId] += amount;
      discountRoundingError[percentageDiscount.appliedId] = roundingError;
    }

    /**
     * Charge specific discounts
     * These do not roll over into other charges
     */
    for (const chargeDiscount of charge.discounts || []) {
      const { discountAmount: amount } = resolveDiscountAmount(
        chargeDiscount.discount,
        charge.chargeSubTotal,
        chargeDiscount.appliedCustomAmount
      );
      charge.discountTotal += amount;
      charge.discountTotals[chargeDiscount.appliedId] =
        charge.discountTotals[chargeDiscount.appliedId] || 0;
      charge.discountTotals[chargeDiscount.appliedId] += amount;
    }

    santityCheckCharge(charge);

    const percentageOfNormalCharges =
      sumOfNormalCharges > 0 ? charge.chargeSubTotal / sumOfNormalCharges : 0;

    /**
     * Event fixed discounts
     * Apply equitably to each event
     */
    for (const fixedDiscount of fixedDiscounts || []) {
      const { appliedId } = fixedDiscount;

      discountsApplied[appliedId] = discountsApplied[appliedId] || 0;
      const discountAmount = Math.ceil(
        fixedDiscount.appliedCustomAmount || fixedDiscount.discount.amount
      );
      // applied amount is floored because any remainder will be
      // picked up by sequentially adding discounts
      let appliedAmount = Math.floor(
        discountAmount * percentageOfNormalCharges
      );

      // ensure applied amount is no greater than the total applied for this discount
      if (appliedAmount + discountsApplied[appliedId] > discountAmount) {
        appliedAmount = discountAmount - discountsApplied[appliedId];
      }

      // subtract the amount already applied to previous charges
      // appliedAmount -= discountsApplied[appliedDiscountHash];
      const chargeRemainingAmount =
        charge.chargeSubTotal - charge.discountTotal;
      // Prevent the applied amount from being greater than the remaining
      // amount for the charge
      if (Math.abs(appliedAmount) > Math.abs(chargeRemainingAmount)) {
        appliedAmount = chargeRemainingAmount;
      }

      // Add the applied amount to the applied amount for this discount
      // and to this charge.
      discountsApplied[appliedId] += appliedAmount;
      charge.discountTotals[appliedId] = charge.discountTotals[appliedId] || 0;
      charge.discountTotals[appliedId] += appliedAmount;
      charge.discountTotal += appliedAmount;
    }
  }

  const taxRoundingError: { [taxId: string]: number } = {};
  // Sequentially sum remaining amounts for fixed discounts
  // and calculate taxes
  for (const charge of normalCharges) {
    /**
     * Event fixed discounts
     * Apply sequentially to each charge
     * if we were unable to apply any fixed discount completely
     */
    for (const fixedDiscount of fixedDiscounts || []) {
      const { appliedId } = fixedDiscount;

      // discount each charge sequentually
      discountsApplied[appliedId] = discountsApplied[appliedId] || 0;
      let appliedAmount = Math.ceil(
        fixedDiscount.appliedCustomAmount || fixedDiscount.discount.amount
      );

      // subtract the amount already applied to previous charges
      appliedAmount -= discountsApplied[appliedId];
      const chargeRemainingAmount =
        charge.chargeSubTotal - charge.discountTotal;
      // Prevent the applied amount from being greater than the remaining
      // amount for the charge
      if (Math.abs(appliedAmount) > Math.abs(chargeRemainingAmount)) {
        appliedAmount = chargeRemainingAmount;
      }

      // Add the applied amount to the applied amount for this discount
      // and to this charge.
      discountsApplied[appliedId] += appliedAmount;
      charge.discountTotals[appliedId] = charge.discountTotals[appliedId] || 0;
      charge.discountTotals[appliedId] += appliedAmount;
      charge.discountTotal += appliedAmount;
    }

    santityCheckCharge(charge);

    charge.discountedSubTotal = charge.chargeSubTotal - charge.discountTotal;

    /**
     * Apply taxes for charges
     */
     if (!isJobTaxExempt(tags)) {
       for (const tax of charge.taxes || []) {
         // round up in the governments favor
         // eslint-disable-next-line @typescript-eslint/no-magic-numbers
         taxRoundingError[tax.id] = taxRoundingError[tax.id] || 0;
         let taxAmount =
           (tax.percentage / PERCENT) * charge.discountedSubTotal -
           taxRoundingError[tax.id];
         taxRoundingError[tax.id] = Math.ceil(taxAmount) - taxAmount;
         taxAmount = Math.ceil(taxAmount);

         charge.taxTotal += taxAmount;
         charge.taxTotals[tax.id] = taxAmount;
       }
     }
  }

  // Derive event totals from charges
  for (const charge of event.charges) {
    charge.total = charge.discountedSubTotal + charge.taxTotal || 0;

    event.subTotal += charge.chargeSubTotal;
    event.discountTotal += charge.discountTotal || 0;
    event.taxTotal += charge.taxTotal || 0;
    event.expenseTotal += charge.expenseTotal || 0;

    charge.discountTotals = charge.discountTotals || {};
    charge.taxTotals = charge.taxTotals || {};

    event.discountTotals = event.discountTotals || {};

    const chargeDiscounts = Object.keys(charge.discountTotals);
    const chargeTaxes = Object.keys(charge.taxTotals);

    for (const chargeDiscount of chargeDiscounts || []) {
      event.discountTotals[chargeDiscount] =
        event.discountTotals[chargeDiscount] || 0;
      event.discountTotals[chargeDiscount] +=
        charge.discountTotals[chargeDiscount];
    }

    for (const chargeTax of chargeTaxes || []) {
      event.taxTotals[chargeTax] = event.taxTotals[chargeTax] || 0;
      event.taxTotals[chargeTax] += charge.taxTotals[chargeTax];
    }
  }

  event.total = event.subTotal + event.taxTotal - event.discountTotal;

  event.discountedSubTotal = event.subTotal - event.discountTotal;
  event.discountedSubTotalWithoutDamages =
    eventSubtotalWithoutDamages - event.discountTotal;

  return event;
}

/**
 * Resolves the amount that is discounted from the total
 * @param discount The discount that is being applied
 * @param total The total of the object that the discount is being applied to
 * @param appliedCustomAmount The custom amount that is being applied along with the discount, if any. If none is given then the discount's
 *                      default amount will be used.
 * @returns The discounted amount in cents
 */
export function resolveDiscountAmount(
  discount: BaseDiscountFragment,
  total: number,
  appliedCustomAmount?: number,
  roundingError = 0
) {
  let discountAmount = appliedCustomAmount
    ? appliedCustomAmount
    : discount.amount;

  if (discount.discountType === 'percentage') {
    discountAmount = total * (discountAmount / 100);
  }

  discountAmount -= roundingError;

  roundingError = Math.ceil(discountAmount) - discountAmount;
  discountAmount = Math.ceil(discountAmount);

  return { discountAmount, roundingError };
}

function santityCheckCharge(charge: BaseChargeFragment) {
  // prevent the discount total from being greater than the charge total
  if (
    (charge.chargeSubTotal > 0 &&
      charge.discountTotal > charge.chargeSubTotal) ||
    (charge.chargeSubTotal < 0 && charge.discountTotal < charge.chargeSubTotal)
  ) {
    charge.discountTotal = charge.chargeSubTotal;
  }
}
