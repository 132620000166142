import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { ClearConfigValuesGQL, ConfigValue, GetConfigValuesGQL } from 'src/generated/graphql.generated';
import { SubSink } from 'subsink';

import { BRANDING_ICON_SIZES } from '../global.constants';

import { NICE_JOB_CONFIGS } from '../nice-jobs/nice-jobs.constants';
import { BrandingService } from '../services/branding.service';
import { DetailsHelperService } from '../services/details-helper.service';
import { FreyaNotificationsService } from '../services/freya-notifications.service';
import { PermissionService } from '../services/permission.service';
import { MutateTransactionTypeComponent } from '../shared/mutate-transaction-type/mutate-transaction-type.component';

export interface CardData {
  namespace?: string;
  configs?: ConfigValue[];
  config?: ConfigValue;
  type: 'single-config' | 'multi-config';
}

export interface ParsedTransactionType {
  key: string;
  name: string;
  provider: string;
  enabled: boolean;
  modified: boolean;
}

const logoConfigs = BRANDING_ICON_SIZES
  .map((size) => `branding.iconDark${size}`)
  .concat(
    BRANDING_ICON_SIZES
      .map((size) => `branding.iconLight${size}`)
  );

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit, OnDestroy {
  @ViewChild('mutateTransactionType') mutateTransactionTypeRef: MutateTransactionTypeComponent;

  subs = new SubSink();

  configValuesQueryRef: ReturnType<GetConfigValuesGQL['watch']>;

  loading = true;

  requestedConfigs = [
    'attention.*',
    'app.appName',
    'app.shortAppName',
    'app.logob64',
    'app.adminEmail',
    'app.notice',
  
    'calendarEvents.arrivalOffset',
    'calendarEvents.estimateLength',
    'calendarEvents.virtualEstimateLength',
    'calendarEvents.includeDockTravelTime',
    'calendarEvents.dockTravelTimeCheckBox',

    'calendarEvents.depositMinToBook',
  
    'role.*',
    'estimator.restrictionCode',
    'find.lockWindowTTL',
    'find.startTimeInterval',
    'jobs.invoiceCompanyName',
    'intake.redirectURL',
    'intake.generateJob',
    'intake.notificationRecipients',
    'intake.distanceLimit',
    'intake.interstateZone',
    'invoice.disableMultipleEvents',
    'jobs.zoneCode',
    'jobs.watchers',
    'jobs.notifyWatchersDocumentCompleted',
    'jobs.autoCloseLeadsAfterDaysOfInactivity',
    'jobs.autoCloseEstimatesAfterDaysOfInactivity',
    'search.allowCrossZoneQuery',
    'system-details.timezone',
    'system-details.country',
    'standard-documents.invoice',
    'standard-documents.estimate',
    'standard-documents.receipt',
    'standard-documents.moving-contract',
    'standard-documents.liability-release',
    'standard-documents.insurance-options',
    'standard-documents.customer-contract',
    'standard-documents.*',
    'transactions.autoSendReceipt',
    'rolling-lock-date.currentDate',
    'rolling-lock-date.supportEmail',
    'rolling-lock-date.emailBodyPrefix',
    'rolling-lock-date.emailBodySuffix',
    'pandadoc.apiKey',
    'pandadoc.webhookKey',
    'pandadoc.defaultSender',
    'pandadoc.sortChargesBy',
    'pandadoc.sendCopyToEmployee',
    'pricing.singleManHourPrice',
    'pricing.chargeSortMethod',
    'openai.apiKey',
    'document-defaults.doctypes',
    'franchise-info.email',
    'franchise-info.phone',
    'franchise-info.address',
    'branding.primaryColor',
    'branding.secondaryColor',
    'branding.ico',
    'branding.website',
    'branding.companyName',
    'branding.tagline',
    'area-resolver.radius',
    'area-resolver.isDomainParent',
    'email.sendgridApiKey',
    'email.sendgridEventKey',
  
    'email.sendgridSystemDomain',
    'email.sendgridInboundDomain',
  
    'email.enableSendgridEvents',
    'email.replyTo',
    'email.inboxDefault',
    'email.redirect',

    'email.header',
    'email.innerFooter',
    'email.enableClickTracking',
    // 'email.footer',

    'yembo-integration.enabled',
    'yembo-integration.apiEndpoint',
    'yembo-integration.url',
    'yembo-integration.sandboxIntegrationAccessToken',
    'yembo-integration.developerAccessToken',
    'yembo-integration.defaultEmail',
    'twilio.sid',
    'twilio.apiKey',
    'twilio.apiSecret',
    'twilio.messagingServiceId',
    'twilio.authToken',
    ...logoConfigs,
    'templates.disabled',

    /****
     * TALKDESK
     */
    'talkdesk.enabled',
    'talkdesk.legacy-callback',
    'talkdesk.clientID',
    'talkdesk.clientSecret',
    'talkdesk.accountName',
    'talkdesk.triggerCallbackFlowURL',
    'talkdesk.fromPhone',

    'talkdesk.initialRinggroup',
    'talkdesk.initialPriority',

    'talkdesk.callbackIfDispositions',
    'talkdesk.closeIfDispositions',
    'talkdesk.maxCalls',
    'talkdesk.callbackConfig',
    'talkdesk.sendSMSFlowURL',

    'autodialer.mode',
    'autodialer.enabled',
    // Todo: prompts here?

    'phoneForwarding.number',
    'phoneForwarding.transcribe',

    'prompt.aiName',
    'prompt.businessHours',
  
    'prompt.scheduleCommunicationsInstructions',
    'prompt.scheduleCommunicationsRingGroups',

    'prompt.inboundRouting',
    'prompt.inboundCallRoutingNumbers',
    'prompt.inboundSMSAIEnabled',

    'pricingEngine.enabled',
    'pricingEngine.crewSizePrompt',
    'pricingEngine.itineraryPrompt',
    'pricingEngine.pricingPrompt',
    'pricingEngine.agentInstructionsPrompt',
    'pricingEngine.validationPrompt',

    'sms.handler',

    'users.defaultEmailDomain',
    'jobs.closedReasons',
    'jobs.howHeardMandatory',
    'jobs.bedroomsMandatory',
    'jobs.dwellingTypeMandatory',
    'act-on.refresh-token',
    'act-on.contact-lists',
    'act-on.enabled',
    'act-on.client-secret',
    'act-on.access-token',
    'act-on.client-id',
    'act-on.token-valid-until',

    NICE_JOB_CONFIGS.clientId,
    NICE_JOB_CONFIGS.clientSecret,
    NICE_JOB_CONFIGS.accessToken,
    NICE_JOB_CONFIGS.refreshToken,

    'support.tawkToApiKey',
  ];

  retrievedConfigValues: ConfigValue[];

  generalSettings: CardData[] = [];

  // Used for discard changes functionality
  defaultValuesLookup: { [transactionTypeKey: string]: ParsedTransactionType } = {};

  restrictedKeys = [
    'act-on.client-id',
    'act-on.client-secret',
    NICE_JOB_CONFIGS.clientId,
    NICE_JOB_CONFIGS.clientSecret,
  ];

  showRestricted = false;

  currentZoneId?: string;

  constructor(
    public branding: BrandingService,
    private getConfigGQL: GetConfigValuesGQL,
    private detailsHelper: DetailsHelperService,
    private permissionHandler: PermissionService,
    private clearConfigValuesGQL: ClearConfigValuesGQL,
    private localNotify: FreyaNotificationsService,
    private confirmationService: ConfirmationService,
  ) { }


  ngOnInit(): void {

    // Retrieve new config values every time zone changes
    this.subs.sink = this.branding.currentZone().subscribe((z) => {
      this.currentZoneId = z.id;
      this.retrieveConfigValues();
    });

    this.subs.sink = this.detailsHelper.getObjectUpdates('Configs')
      .subscribe(() => {
        this.retrieveConfigValues();
      });

  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  /**
   * Retrieves config values and uses them to update cards and transaction type table.
   */
  retrieveConfigValues() {
    if (this.configValuesQueryRef) {
      this.configValuesQueryRef.resetLastResults();
      this.configValuesQueryRef.refetch();
      return;
    }

    this.configValuesQueryRef = this.getConfigGQL
      .watch({ keys: this.requestedConfigs }, { fetchPolicy: 'cache-and-network' });

    // Fetch permissions first to determine if restricted settings should be shown
    this.subs.sink = this.permissionHandler.watchPermissionsAndRestrictions([{
      permission: 'settings.view',
      restriction: { viewAll: true },
    }]).pipe(
      // Only emit if specified permission actually changes
      distinctUntilChanged((a, b) => a[0] === b[0]),
      // Set showRestricted
      tap(([canView]) => this.showRestricted = canView),
      // Once permissions are retrieved, switch to value changes observable to retrieve config values
      switchMap(() => this.configValuesQueryRef.valueChanges),
    ).subscribe((res) => {
      this.loading = res.loading;

      if (res.loading) { return; };

      this.retrievedConfigValues = res.data.getConfigValues;
      const missingConfigs = this.getMissingConfigs(this.retrievedConfigValues);

      this.updateCardData([...this.retrievedConfigValues, ...missingConfigs]);

      this.sortPanel(this.generalSettings);
    });
  }

  /**
   * Uses provided configValues to populate/update setting cards,
   * adding them to existing cards or generating new cards dynamically based on namespace.
   *
   * @param configValues the configValues to use to populate the cards.
   */
  updateCardData(configValues: ConfigValue[]) {
    this.clearPreviousData();

    for (const config of configValues) {

      if (this.restrictedKeys.includes(config.key) && !this.showRestricted) { continue; };

      const [namespace] = config.key.split('.');

      switch (namespace) {
        case 'standard-documents':
        case 'attention':
          this.generateSingleConfigCard(config, this.generalSettings);
          break;
        case 'nice-jobs':
          this.addToMultiConfigCard(config, 'intake', this.generalSettings);
          break;
        default:
          this.addToMultiConfigCard(config, namespace, this.generalSettings);
      }
    }
  }

  /**
   * Generates a new card that holds a single config value of type JSON.
   * Adds resulting card to specified tabPanel.
   *
   * @param config the configValue used to populate card.
   * @param tabPanel the tapPanel where you want the card displayed.
   */
  generateSingleConfigCard(config: ConfigValue, tabPanel: CardData[]) {
    const cardData: CardData = {
      config,
      type: 'single-config'
    };
    tabPanel.push(cardData);
  }

  /**
   * Groups config values with the same namespace into a single card.
   * Searches for card by namespace in specified tabPanel.
   * If no such card exists, it creates a new one in the tabPanel in question.
   * Adds provided config value as a new field.
   *
   * @param config the configValue to be added as a new field.
   * @param namespace the namespace of the card where you want to add a new field.
   * @param tabPanel the tapPanel where you want the card displayed.
   */
  addToMultiConfigCard(config: ConfigValue, namespace: string, tabPanel: CardData[]) {
    let desiredCard: CardData = tabPanel
      .find((c) => c.namespace === namespace);

    if (!desiredCard) {

      desiredCard = {
        namespace,
        configs: [],
        type: 'multi-config'
      };

      tabPanel.push(desiredCard);
    };

    desiredCard.configs.push(config);
  }

  /**
   * Generates dummy config values in place of any config values that the backend failed to return.
   */
  getMissingConfigs(retrievedConfigs: ConfigValue[]): ConfigValue[] {
    const keys = retrievedConfigs.map((c) => c.key);

    return this.requestedConfigs
      .filter((c) => !c.endsWith('*'))
      .filter((c) => !keys.includes(c))
      .map((c) => ({
        key: c,
        value: ''
      } as ConfigValue));
  }

  /**
   * Uses provided key to create a dummy config with its value set to an empty string.
   * Adds resulting config to the appropriate multiConfigCard.
   * Used to create cards for configs that are not returned by the backend.
   *
   * @param key The key to be added as a new field to the card.
   * @param tabPanel where you want the card displayed.
   */
  addMissingMultiConfigCard(key: string, tabPanel: CardData[]) {
    const [namespace] = key.split('.');
    const emptyConfig = {
      key,
      value: ''
    };
    this.addToMultiConfigCard(emptyConfig, namespace, tabPanel);
  };


  /**
   * Sorts multi-config cards alphabetically by namespace.
   * Makes sure mult-config cards come before single-config cards.
   *
   * @param tabPanel the tabPanel whose cards you want sorted.
   */
  sortPanel(tabPanel: CardData[]) {
    tabPanel.sort((a, b) => {
      if (a.namespace && b.namespace) {
        return a.namespace.localeCompare(b.namespace);
      };
      if (a.type === 'multi-config') {
        return -1;
      };
    });
  }

  clearPreviousData() {
    this.generalSettings = [];
  }

  confirmResetZoneSettings() {
    this.confirmationService.confirm({
      header: 'Reset All Zone Settings?',
      message: 'This will clear all config values associated with the current zone. Do you want to proceed?',
      acceptIcon: 'pi pi-refresh',
      acceptLabel: 'Reset Zone Settings',
      acceptButtonStyleClass: 'p-button-warning',
      accept: () => this.resetZoneSettings(),
      rejectLabel: 'Cancel',
    });
  }

  resetZoneSettings() {

    const zoneConfigKeys = this.retrievedConfigValues
      .filter((c) => c.zone === this.currentZoneId)
      .map((c) => c.key);

    this.clearConfigValuesGQL.mutate({ keys: zoneConfigKeys })
      .subscribe(() => {
        this.localNotify.success('Zone settings reset');
        this.retrieveConfigValues();
      }, (err) => {
        this.localNotify.apolloError('Failed to reset zone settings', err);
      });
  }

}
