import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { BehaviorSubject, combineLatest, defer, map, take } from "rxjs";
import { FreyaCommonModule } from "src/app/freya-common/freya-common.module";
import { FreyaMutateService } from "src/app/services/freya-mutate.service";
import { SharedModule } from "src/app/shared/shared.module";

import { SubSink } from "subsink";

import { workOrdersSelectors } from "../../job-state/workorders-state/workorders.selectors";
import { jobToolFeature } from "../../job-tool.reducer";

@Component({
  selector: 'app-financials-table-v2',
  standalone: true,
  imports: [
    SharedModule,
    FreyaCommonModule,
  ],
  templateUrl: './financials-table-v2.component.html',
  styleUrl: './financials-table-v2.component.scss',
})
export class FinancialsTableV2Component implements OnInit, OnDestroy {

  @Input() set eventId(value: string) {
    this.eventId$.next(value);
  }

  private eventId$ = new BehaviorSubject<string | null>(null);

  job$ = this.store.select(workOrdersSelectors.selectJobWithPendingChargesUpdates);
  customer$ = this.store.select(jobToolFeature.selectCustomer);

  event$ = combineLatest([this.eventId$, this.job$]).pipe(
    map(([eventId, job]) => job?.events?.find(e => e.id === eventId) ?? null)
  );

  invoice$ = combineLatest([this.eventId$, this.job$]).pipe(
    map(([eventId, job]) => {
      const event = job?.events?.find(e => e.id === eventId);
      return event?.invoices?.find(i => i.voidedAt == null && i.deletedAt == null) ?? null;
    })
  );

  showConfidentialInfo = false;

  constructor(
    public store: Store,
    public mutateService: FreyaMutateService,
  ) { }

  private subs = new SubSink();

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  onPayButtonClick() {
    combineLatest([
      this.job$.pipe(take(1)),
      this.customer$.pipe(take(1)),
      this.invoice$.pipe(take(1))
    ]).subscribe(([job, customer, invoice]) => {
      this.mutateService.openMutateObject({
        objectType: 'transaction',
        mutateType: 'create',
        additionalValues: [
          { property: 'job', value: job },
          { property: 'user', value: customer },
          ...(invoice ? [{ property: 'invoice', value: invoice }] : []),
        ],
      });
    });
  }

  getJobGrossProfitMargin(data: any): number {
    if (!data || !data.discountedSubTotal || !data.expenseTotal) {
      return 0;
    }
    return ((data.discountedSubTotal - data.expenseTotal) / data.discountedSubTotal) * 100;
  }
}