import { createActionGroup, emptyProps, props } from "@ngrx/store";

import { Delta } from 'quill/core';

import { AddQuillTemplateInput, Asset, AssignCrewWithAiOutput, CalendarEventForScheduleFragment, QuillTemplate, Role, User } from "../../../../generated/graphql.generated";

import { CrewRoleOptions, UpdateCrewActionPayload, DispatchSortOptions, EventStatusOptions } from "./dispatch.reducer";

export const DispatchActions = createActionGroup({
	source: "Dispatch",
	events: {
		"Component Hard Refreshed": emptyProps(),

		// Init
		"Init Crew": emptyProps(),

		// List events actions
		"Hard Refresh Events": props<{
			date: Date | null;
		}>(),
		"Events Loading": emptyProps(),
		"Events Loaded": props<{
			events: CalendarEventForScheduleFragment[];
		}>(),
		"Events Load Error": props<{
			error: Error;
		}>(),

		// List events filters
		"Set Dispatch Date": props<{
			date: Date;
		}>(),
		"Set Event Status": props<{
			eventStatus: EventStatusOptions;
		}>(),
		// Local sort
		"Set Sort By": props<{
			sortBy: DispatchSortOptions;
		}>(),

		// List users
		"Set User Search": props<{
			search: string;
		}>(),
		"Set User Role": props<{
			role: CrewRoleOptions;
		}>(),
		"Users Loading": emptyProps(),
		"Users Loaded": props<{
			users: User[];
		}>(),
		"Users Load Error": props<{
			error: Error;
		}>(),


		// List Roles
		"Roles Loading": emptyProps(),
		"Roles Loaded": props<{
			roles: Role[];
		}>(),
		"Roles Loaded On Component Hard Refresh": props<{
			roles: Role[];
		}>(),
		"Roles Load Error": props<{
			error: Error;
		}>(),


		// List Assets
		"Assets Loading": emptyProps(),
		"Assets Loaded": props<{
			assets: Asset[];
		}>(),
		"Assets Load Error": props<{
			error: Error;
		}>(),

		// Assign Crew Member
		"Update Crew": props<{
			edits: UpdateCrewActionPayload[]
		}>(),
		"Update Crew Success": props<{
			edits: UpdateCrewActionPayload[]
		}>(),
		"Update Crew Error": props<{
			error: string,
			edits: UpdateCrewActionPayload[]
		}>(),

		// crew schedule
		"Crew Schedule Sent": props<{
			contents: Delta,
			userIds: string[],
		}>(),
		"Crew Schedule Send Success": emptyProps(),
		"Crew Schedule Send Error": emptyProps(),

		// Get templates
		"Send Crew Schedule Dialog Opened": emptyProps(),
		"Templates Loaded": props<{
			templates: QuillTemplate[];
		}>(),
		"Templates Load Error": props<{
			error: Error;
		}>(),

		// Add Quill Template
        "Add Quill Template": props<{
            input: AddQuillTemplateInput;
        }>(),
        "Add Quill Template Success": props<{
            template: QuillTemplate;
        }>(),
        "Add Quill Template Error": props<{
            error: string;
        }>(),

		// Crew Salary and Availability
		"Edit Crew Salary Clicked": props<{
			user: User;
		}>(),
		"Crew Salary Changed": props<{
			salary: number;
		}>(),
		"Crew Availability Changed": props<{
			shiftAvailability: string;
		}>(),
		"Crew Salary Saved": emptyProps(),
		"Crew Salary Edited Successfully": emptyProps(),
		"Error Editing Crew Salary": props<{
			error: Error;
		}>(),
		"Edit Crew Salary Dialog Closed": emptyProps(),	
		
		// Assign Crew Members with AI
		"Assign Crew Members With AI Clicked": emptyProps(),
		"Crew Members Assigned with AI successfully": props<{
			output: AssignCrewWithAiOutput;
			edits: UpdateCrewActionPayload[];
		}>(),
		"Failed to assign crew members with AI": emptyProps(),
	},
});
