import { AfterContentChecked, ChangeDetectorRef, Component,
   ContentChild, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import {PlusAuthenticationService, RoleService } from '@karve.it/core';
import { AssetService, LocationService } from '@karve.it/features';
import { Asset, ListAssetsOutput } from '@karve.it/interfaces/assets';
import { CreateLocation, CreateLocationsFunctionInput, LocationCreateBase } from '@karve.it/interfaces/locations';
import {QueryRef} from 'apollo-angular';

import { validateSchema } from 'graphql';
import { cloneDeep } from 'lodash';
import { DetailsHelperService } from 'src/app/services/details-helper.service';
import { FreyaHelperService } from 'src/app/services/freya-helper.service';
import { FreyaNotificationsService } from 'src/app/services/freya-notifications.service';
import { GoogleHelperService } from 'src/app/services/google-helper.service';
import { SubSink } from 'subsink';

import { CreateAssetInput, UpdateAssetInput } from '../../../generated/graphql.generated';

import { YemboHelperService } from '../../services/yembo-helper.service';

import { assetTypesDropdown } from '../assets/assets';
import { MutateObjectComponent, MutateObjectElement } from '../mutate-object/mutate-object.component';


@Component({
  selector: 'app-mutate-asset',
  templateUrl: './mutate-asset.component.html',
  styleUrls: ['./mutate-asset.component.scss']
})
export class MutateAssetComponent implements OnInit, OnDestroy, AfterContentChecked {

  @ViewChild('mutate') mutateRef: MutateObjectComponent;

  // Template Refs
  @ViewChild('type') typeRef: TemplateRef<any>;
  @ViewChild('name') nameRef: TemplateRef<any>;
  @ViewChild('description') descriptionRef: TemplateRef<any>;
  @ViewChild('attributes') attributesRef: TemplateRef<any>;
  @ViewChild('location') locationRef: TemplateRef<any>;
  @ViewChild('zones') zonesRef: TemplateRef<any>;


  @Input() mutateType: 'update' | 'create';
  @Input() asset: Asset;

  steps: MutateObjectElement[];

  subs = new SubSink();

  assetForm = new UntypedFormGroup({
    type: new UntypedFormControl('Truck', [ Validators.required, Validators.minLength(1), Validators.maxLength(30) ]),
    name: new UntypedFormControl('', [ Validators.required, Validators.minLength(2), Validators.maxLength(30) ]),
    description: new UntypedFormControl('', [ Validators.minLength(0), Validators.maxLength(1000) ]),
    attributes: new UntypedFormControl([]),
    location: new UntypedFormControl('', []),
    zones: new UntypedFormControl([]),
    ownerId: new UntypedFormControl(this.auth.user.id, [ Validators.required ]),
    yemboEmail: new UntypedFormControl(undefined),
  });

  locationInput: LocationCreateBase & {id?: string};

  assetFormValues = this.assetForm.value;

  assetQueryRef: QueryRef<ListAssetsOutput>;

  assetTypes = assetTypesDropdown;

  constructor(
    private assetService: AssetService,
    private detailsHelper: DetailsHelperService,
    private localNotify: FreyaNotificationsService,
    private freyaHelper: FreyaHelperService,
    private cd: ChangeDetectorRef,
    private auth: PlusAuthenticationService,
    public googleHelper: GoogleHelperService,
    private locationService: LocationService,
    public yemboHelper: YemboHelperService,
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  ngAfterContentChecked() {
    this.cd.detectChanges();
  }

  mutateObject() {
    if (this.mutateType === 'create') {
      // Create
      this.createAsset();
    } else if (this.mutateType === 'update') {
      // Update
      this.editAsset();
    }
  }

  openDialog() {

    this.steps = [
      { name: 'Type', ref: this.typeRef, control: 'type', type: 'text' },
      { name: 'Name', ref: this.nameRef, control: 'name', type: 'text' },
      { name: 'Description', ref: this.descriptionRef, control: 'description', type: 'text' },
      { name: 'Location', ref: this.locationRef, control: 'location', type: 'func', reviewFunc: () => (this.locationInput?.addressLineOne)},
      { name: 'Zones', ref: this.zonesRef, control: 'zones', type: 'array' },
      // { name: 'Attributes', ref: this.attributesRef, control: 'attributes', type: 'array' },
    ];

    this.mutateRef.steps = this.steps;

    if (this.mutateType === 'create') {
      this.assetForm.reset(this.assetFormValues);
      this.mutateRef.removeStep('Zones');
      this.locationInput = undefined;
    } else if (this.mutateType === 'update') {
      this.setFormValues();
    }

    this.mutateRef.openDialog();
  }

  setFormValues() {
    this.assetForm.reset({
      type: this.asset.type,
      name: this.asset.name,
      description: this.asset.description,
      attributes: this.asset.attributes,
      ownerId: this.auth.user.id,
      location: this.asset.location?.addressLineOne,
      zones: this.asset.zones,
      yemboEmail: this.asset.metadata?.yemboEmail,
    });

    this.locationInput = this.asset.location;
  }

  async createAsset() {
    const val = this.assetForm.value;
    let locationId;

    if (this.locationInput){
      locationId = await this.createAssetLocation();
    }

    const asset: CreateAssetInput = {
      ownerId: val.ownerId,
      name: val.name,
      type: val.type,
      attributes: val.attributes,
      description: val.description,
      locationId,
      metadata: val.yemboEmail ? {yemboEmail: val.yemboEmail} : undefined,
    };

    asset.attributes = asset.attributes || [];
    if (typeof asset.attributes === 'string') {
      asset.attributes = [ asset.attributes ];
    }
    this.subs.sink = this.assetService
    .createAssets({assets: [ asset ]})
    .subscribe(async (res) => {
      this.asset = cloneDeep({
        ...this.asset,
        ...this.assetForm.value,
      });
      this.localNotify.addToast.next({severity: 'success', summary: 'Asset created'});
      this.detailsHelper.pushUpdate({
        id:this.asset.id,
        type:'Assets',
        action:'create',
      });
      this.mutateRef.closeDialog();
    }, (err) => {
      this.localNotify.apolloError('Failed to save changes', err);
      this.mutateRef.loading = false;
    });
  }

  async editAsset(){

    const val = this.assetForm.value;
    const locationControl = this.assetForm.get('location');
    let locationId: string;

    if (locationControl?.dirty && this.locationInput && !this.locationInput.id){
      locationId = await this.createAssetLocation();
    }

    const input: UpdateAssetInput = {
      id: this.asset.id,
      ownerId: val.ownerId,
      name: val.name,
      type: val.type,
      attributes: val.attributes,
      description: val.description,
      locationId,
      metadata: val.yemboEmail ? {yemboEmail: val.yemboEmail} : undefined,
      zoneIds: val.zones?.map((z) => z.id),
    };

    if (typeof input.attributes === 'string') {
      input.attributes = [ input.attributes ];
    }

    this.subs.sink = this.assetService.updateAssets({
      assets: [ input ],
    }).subscribe(async (res) => {
      this.asset = cloneDeep({
        ...this.asset,
        ...this.assetForm.value,
      });
      this.detailsHelper.pushUpdate({
        id:this.asset.id,
        type:'Assets',
        action:'update',
        });
      this.localNotify.addToast.next({severity: 'success', summary: 'Asset updated'});
      this.mutateRef.closeDialog();
    }, (err) => {
      this.mutateRef.loading = false;
      this.localNotify.apolloError(`Failed to Edit Asset`,err);
    });
  }

    // Gets the Location Info for the Input
    public handleAddressChange(address: any) {
      if(!this.googleHelper.isValidGooglePlacesAddress(address)){
        this.assetForm.controls.location.setErrors({notvalid: true});
        return;
      }
      this.assetForm.controls.location.setErrors(null);

      this.locationInput = this.googleHelper.convertGoogleLocationToCreateLocationInput(address);
    }

  async createAssetLocation(){
    return new Promise<string> ((resolve, reject) => {
      const createLocationsInput = {
        input: {
          locations: [this.locationInput]
        }
      } as CreateLocationsFunctionInput;

      this.locationService.CreateLocations(createLocationsInput).subscribe((res) => {
        if (!res.data) { return; }
        resolve(res.data.createLocations.locations[0].id);
      }, (err) => {
        reject(err);
      });
    });
  }

}
