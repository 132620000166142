<section class="card">
	<header class="event-header">
		<div class="event-details">
			<h5 class="event-customer-name">
				Send Crew Schedule
			</h5>
			<span class="event-time">Today, Jun 12 2024</span>
		</div>
		<div class="event-actions">
			<p-button
				label="Cancel"
				(onClick)="onClose()"
				[outlined]="true"
				severity="secondary"
			/>
			<p-button
				label="Save"
				icon="pi pi-check"
				(onClick)="onSave()"
			/>
		</div>
	</header>
	<p-divider></p-divider>
	<main>
		<div class="crewSelect">
			<span class="p-mr-2">To:</span>
			<p-multiSelect
				class="full-width-multiselect"
				[options]="workingCrew$ | async"
				[(ngModel)]="selectedWorkingCrew"
				optionLabel="name"
				placeholder="Select Crew"
				display="chip"
				[showClear]="true"
				[selectedItemsLabel]="'{0} Crew Selected'"
			/>
		</div>


		<div class="editor p-mt-3">
			<div id="toolbar">

				<!-- Add a bold button -->
				<span class="ql-formats">
					<button class="ql-bold"></button>
					<button class="ql-italic"></button>
					<button class="ql-underline"></button>
					<button class="ql-strike"></button>
				</span>

				<span class="ql-formats">
					<button
						class="ql-list"
						value="ordered"
					></button>
					<button
						class="ql-list"
						value="bullet"
					></button>
					<button
						class="ql-list"
						value="check"
					></button>
				</span>

				<span class="ql-formats">
					<select class="ql-color"></select>
					<select class="ql-background"></select>
				</span>

				<span
					class="ql-formats"
					style="margin-top: 1rem; display: flex; gap: 1rem;"
				>
					<p-dropdown
						optionLabel="title"
						[options]="templateVariables"
						placeholder="Pick variable"
						(onChange)="onTemplateVariableSelected($event)"
						size="small"
						[filter]="true"
						filterBy="title"
					>
						<ng-template
							let-editorVariable
							pTemplate="item"
						>
							<div class="editor-variable">
								<span><b> {{ editorVariable.title }} </b></span>
								<span class="variable-description">{{ editorVariable.description }}</span>
							</div>
						</ng-template>
					</p-dropdown>

					<p-dropdown
						[(ngModel)]="selectedTemplate"
						optionLabel="name"
						[options]="templates"
						placeholder="Select template"
						(onChange)="onTemplateSelected($event)"
						size="small"
						[filter]="true"
						filterBy="name"
					>
						<ng-template
							let-template
							pTemplate="item"
						>
							<span> {{ template.name || 'No Name' }}</span>
						</ng-template>
					</p-dropdown>

					<p-button
						class="custom-primeng-button"
						size="small"
						label="Save as template"
						(onClick)="saveAsTemplate()"
					/>
				</span>
			</div>

			<quill-editor
				#editor
				[(ngModel)]="editorContents"
				format="Object"
				[modules]="editorModules"
				(onEditorCreated)="onEditorCreated($event)"
			>
			</quill-editor>
		</div>
	</main>
</section>