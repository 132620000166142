<div [formGroup]="areaForm">
    <ng-template #name>
        <div *appVar="areaForm.get('name') as control">
            <label>Name</label>
            <br />
            <input
                type="text"
                pInputText
                placeholder="Name"
                formControlName="name"
                [class.p-invalid-input]="!control.valid && control.touched"
            >
            <app-validation-messages [control]="control">
            </app-validation-messages>
        </div>
    </ng-template>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
</div>

<app-mutate-object
    #mutate
    [fg]="areaForm"
    objectType="Zone"
    [mutateType]="mutateType"
    [steps]="steps"
    (objectMutated)="mutateObject()"
></app-mutate-object>