import { default as Quill } from 'quill';
// import { AttachmentHandler, ImageHandler, VideoHandler } from "quill-upload";

import { TemplateVariable } from '../../../schedules/dispatch/components/dispatch-send-schedule/TemplateVariable';

import { TimeBlot } from './time.blot';

// REGISTER QUILL MODULES HERE

export function registerQuillModules() {

	// register quill-upload
	// Quill.register("modules/imageHandler", ImageHandler);
	// Quill.register("modules/videoHandler", VideoHandler);
	// Quill.register("modules/attachmentHandler", AttachmentHandler);

	Quill.register(TimeBlot);

	// Quill.register({
	// 	'formats/variable': TemplateVariable
	// });
	
}

export function getQuillFromHtmlElement(node: HTMLElement) {
	let parent = node.parentElement;
	while (parent && !parent.classList.contains('ql-container')) {
	  parent = parent.parentElement;
	}
	if (!parent) { return undefined; }
	return Quill.find(parent) as Quill;
}
