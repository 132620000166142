<section
    class="job-financials"
    *ngIf="{
    jobLoaded: jobLoaded$ | async,
    latestInvoice: latestInvoice$ | async,
    olderInvoices: olderInvoices$ | async,
    invoicesEmpty: invoicesEmpty$ | async,
    transactions: transactions$ | async,
    damages: damages$ | async,
    transactionsEmpty: transactionsEmpty$ | async,
    damagesEmpty: damagesEmpty$ | async,
    } as state"
>
    <div>
        <header class="p-my-2">
            <h2>Invoices</h2>
            <p-button
                label="Invoice"
                icon="pi pi-plus"
                outlined="true"
                (onClick)="openCreateInvoiceDialog()"
                [disabled]="!state.jobLoaded"
            ></p-button>
        </header>
        <div class="document-container">
            <p-skeleton
                *ngIf="!state.jobLoaded"
                class="flex-1"
                borderRadius="0.571rem"
                width="100%"
                height="6.25rem"
            ></p-skeleton>
            <div
                class="empty-state"
                *ngIf="state.invoicesEmpty"
            >
                <i class="pi pi-file"></i>
                <span>No invoices for this job.</span>
            </div>
            <app-job-invoice
                *ngIf="state.latestInvoice"
                [invoice]="state.latestInvoice"
            ></app-job-invoice>
            <ng-container *ngIf="state.olderInvoices">
                <ng-container *ngIf="showMoreInvoices">
                    <app-job-invoice
                        *ngFor="let invoice of state.olderInvoices"
                        [invoice]="invoice"
                    ></app-job-invoice>
                </ng-container>
                <span
                    *ngIf="state.olderInvoices.length"
                    class="show-more"
                    (click)="showMoreInvoices = !showMoreInvoices"
                >{{ showMoreInvoices ? 'Hide' : 'Show'}} older invoices ({{ state.olderInvoices.length }})</span>
            </ng-container>
        </div>
    </div>
    <div>
        <header class="p-my-2">
            <h2>Transactions</h2>
            <p-button
                label="Transaction"
                icon="pi pi-plus"
                outlined="true"
                (onClick)="openCreateTransactionDialog()"
                [disabled]="!state.jobLoaded"
            ></p-button>
        </header>
        <p-skeleton
            *ngIf="!state.jobLoaded"
            width="100%"
            height="8rem"
            borderRadius="0"
        ></p-skeleton>
        <div
            class="empty-state"
            *ngIf="state.transactionsEmpty"
        >
            <i class="pi pi-credit-card"></i>
            <span>No transactions for this job.</span>
        </div>
        <p-table
            *ngIf="!state.transactionsEmpty"
            [value]="state.transactions"
            [lazy]="true"
            breakpoint="620px"
        >
            <ng-template pTemplate="header">
                <tr>
                    <th
                        class="p-text-center"
                        style="width: 1rem"
                    >Status</th>
                    <th>Amount</th>
                    <th>Payment Type</th>
                    <th>Date</th>
                    <th>Invoice</th>
                    <th></th>
                </tr>
            </ng-template>
            <ng-template
                pTemplate="body"
                let-t
            >
                <tr>
                    <td class="p-text-center">
                        <span class="p-column-title">Status</span>
                        <p-tag
                            rounded="true"
                            [style]="{
                                backgroundColor: colorMap[t.deletedAt ? 'deleted' : t.stage].backgroundColor,
                                color: colorMap[t.deletedAt ? 'deleted' : t.stage].text
                            }"
                            [value]="t.deletedAt? 'Deleted' : t.stage | titlecase"
                            styleClass="p-ml-2"
                        ></p-tag>
                    </td>
                    <td>
                        <span class="p-column-title">Amount</span>
                        {{t.amount / 100 | currency : t.currency}}
                    </td>
                    <td>
                        <span class="p-column-title">Payment Type</span>
                        <span>{{t.type | titlecase}} ({{ t.paymentType.name }}) </span>
                    </td>
                    <td>
                        <span class="p-column-title">Date</span>
                        <span>
                            {{
                            t.createdAt | freyaDate : 'h:mm a, MMM d, y'
                            }}
                        </span>
                    </td>
                    <td>
                        <span class="p-column-title">Invoice</span>
                        <p-tag
                            *ngIf="t.invoice"
                            [value]="t.invoiceName"
                            icon="pi pi-file"
                            [style]="{
                            backgroundColor: '#F0F4F8',
                            color: '#32383E'
                        }"
                            styleClass="clickable-tag"
                            (click)="openInvoiceDetails(t.invoice.id)"
                        ></p-tag>
                        <span *ngIf="!t.invoice">No Active Invoice</span>
                    </td>
                    <td style="text-align: right;">
                        <p-button
                            icon="pi pi-ellipsis-v"
                            text="true"
                            (onClick)="setTransactionActions(t); menu.toggle($event)"
                        ></p-button>
                        <p-menu
                            #menu
                            [popup]="true"
                            appendTo="body"
                            [model]="transactionActions"
                        ></p-menu>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <div>
        <header class="p-my-2">
            <h2>Damages</h2>
            <p-button
                *hasPermission="['charges.create']"
                label="Damages"
                icon="pi pi-plus"
                outlined="true"
                (onClick)="openCreateDamageDialog()"
                [disabled]="!state.jobLoaded"
            ></p-button>
        </header>
        <p-skeleton
            *ngIf="!state.jobLoaded"
            width="100%"
            height="8rem"
            borderRadius="0"
        ></p-skeleton>
        <div
            class="empty-state"
            *ngIf="state.damagesEmpty"
        >
            <i class="pi pi-exclamation-circle"></i>
            <span>No damages for this job.</span>
        </div>
        <p-table
            *ngIf="!state.damagesEmpty"
            [value]="state.damages"
            [lazy]="true"
            breakpoint="620px"
        >
            <ng-template pTemplate="header">
                <tr>
                    <th colspan="3">Name</th>
                    <th>Amount</th>
                    <th>Quantity</th>
                    <th>Subtotal</th>
                    <th></th>
                    <th></th>
                </tr>
            </ng-template>
            <ng-template
                pTemplate="body"
                let-d
            >
                <tr>
                    <td colspan="3">
                        <span class="p-column-title">Name</span>
                        {{ d?.product?.name || d?.productName }}
                    </td>
                    <td>
                        <span class="p-column-title">Amount</span>
                        {{ d | chargeAmount | currency : job?.currency || 'USD' }}
                    </td>
                    <td>
                        <span class="p-column-title">Quantity</span>
                        <span>
                            {{ d?.quantity }}
                        </span>
                    </td>
                    <td>
                        <span class="p-column-title">Subtotal</span>
                        {{ d?.chargeSubTotal / 100 | currency : job?.currency || 'USD' }}
                    </td>
                    <td></td>
                    <td style="text-align: right;">
                        <p-button
                            icon="pi pi-ellipsis-v"
                            text="true"
                            (onClick)="setDamageActions(d); menu.toggle($event)"
                            [disabled]="d?.calendarEvent?.locked || d?.calendarEvent?.invoiced"
                        ></p-button>
                        <p-menu
                            #menu
                            [popup]="true"
                            appendTo="body"
                            [model]="damageActions"
                        ></p-menu>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>