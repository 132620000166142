<div class="card" *ngIf="eventToBook$ | async">
  <div class="header">
    <h3>
      Book {{ (eventToBook$ | async)?.title }} for {{ customerName$ | async }}
    </h3>
    <div class="header-right">
      <button
        pButton
        class="p-button-text p-m-1"
        icon="pi {{
          (restrictionsDisabled$ | async) ? 'pi-unlock' : 'pi-lock'
        }}"
        type="button"
        pTooltip="{{
          (restrictionsDisabled$ | async) ? 'Enable' : 'Disable'
        }} Restrictions"
        tooltipPosition="left"
        (click)="store.dispatch(ScheduleEventsActions.bookingRestrictionsChanged({
          isBookingRestrictionDisabled: null,
          }))"
      ></button>
      <button
        pButton
        class="p-button-text p-m-1"
        icon="pi pi-times"
        type="button"
        pTooltip="Close Booking Dialog"
        tooltipPosition="left"
        (click)="close()"
      ></button>
    </div>
  </div>
  <div class="main-container">
    <div class="schedule-form">
      <article [style]="(eventToBook$ | async)?.style">
        <div class="eventDetails">
          <span class="title">{{ (eventToBook$ | async)?.title }}</span>
          <span class="info">{{ (eventToBook$ | async)?.details }}</span>
        </div>
      </article>
      <div class="warnings-container">
        <p-message
          *ngIf="(customerName$ | async) === 'No Customer'"
          severity="warn"
          text="Customer Required"
          styleClass="mr-2"
        ></p-message>
        <p-message
          *ngIf="(job$ | async)?.zone?.type !== 'area'"
          severity="warn"
          text="Your job needs to be in an area to book events"
          styleClass="mr-2"
        ></p-message>
        <p-message
          *ngIf="showMissingLocationsWarning$ | async"
          severity="warn"
          text="Missing Locations: {{ missingLocations$ | async | join }}"
          styleClass="cursor-pointer mr-2"
        >
          <ng-template pTemplate="content">
            Missing Locations:
            <ul>
              <li *ngFor="let location of missingLocations$ | async">
                {{ location | titlecase }}
              </li>
            </ul>
          </ng-template>
        </p-message>
        <p-message
          *ngIf="showTimeNotAvailableWarning$ | async"
          severity="warn"
          text="Start time is not available anymore. Please select another time."
          styleClass="mr-2"
        >
        </p-message>
        <p-message
          *ngIf="showAssetsNotAvailableWarning$ | async"
          severity="warn"
          text="Selected assets are not available for selected time. Please select another time or another asset"
          styleClass="mr-2"
        >
        </p-message>
        <p-message
          *ngIf="showDepositRequiredError$ | async"
          severity="error"
          text="A deposit of {{
            showDepositRequiredError$
              | async
              | currency : (job$ | async).currency
          }} is required to book this event."
          styleClass="mr-2"
        >
        </p-message>
      </div>
      <ng-container *hasPermission="['jobs.update']">
        <div
          *ngIf="(scheduleMode$ | async) === 'schedule'"
          class="event-form-flex"
        >
          <!---------------- DURATION / ITINERARY ---------------->
          <div class="include-container itinerary">
            <h4>Itinerary:</h4>
            <small>Update labor / travel charges to change duration</small>
            <span *ngIf="totalLocationTime$ | async as totalLocationTime">
              Labor: {{ totalLocationTime | secondsToTimeUnit }}
            </span>
            <span *ngIf="totalTravelTime$ | async as totalTravelTime">
              Travel: {{ totalTravelTime | secondsToTimeUnit }}
            </span>
            <p-checkbox
              *ngIf="showDockTravelTimeCheckbox$ | async"
              [binary]="true"
              label="Include dock travel time"
              tooltip="Include travel time for dock to start and end to dock in the duration of the event"
              [disabled]="dockTravelTimeDisabled$ | async"
              [ngModel]="includeDockTravel$ | async"
              (onChange)="store.dispatch(ScheduleEventsActions.includeDockTravelChecked({
                  includeDockTravel: $event.checked,
              }))"
              class="checkbox m-r-1"
            ></p-checkbox>
          </div>

          <!---------------- DATE ---------------->
          <div>
            <h4>Date:</h4>
            <!-- This is also disabled/enabled in the "reset" method -->
            <p-calendar
              class="full-width-calendar"
              [disabled]="dateDisabled$ | async"
              [ngModel]="selectedStartDate$ | async"
              (onSelect)="
                store.dispatch(
                  ScheduleEventsActions.dateSelected({
                    date: $event
                  })
                )
              "
              appendTo="body"
              [minDate]="freyaHelperService.lockDateObject$ | async"
            ></p-calendar>

            <small *ngIf="freyaHelperService.lockDate$ | async"
              >You must schedule events after
              {{ formattedLockDate.short }} (Lock Date)</small
            >
          </div>

          <!---------------- START TIME ---------------->
          <div>
            <h4>Start Time:</h4>
            <p-dropdown
              class="full-width-dropdown"
              [placeholder]="timePlaceholder"
              [disabled]="timeDisabled$ | async"
              [ngModel]="selectedTime$ | async"
              [loading]="loadingFindTime$ | async"
              (onChange)="store.dispatch(
              ScheduleEventsActions.timeSelected({
                  time: $event.value,
              })
            )"
              [options]="possibleTimes$ | async"
              appendTo="body"
            >
              <ng-template let-time pTemplate="item">
                <p *ngIf="time === 'Now'">Now</p>
                <p *ngIf="time !== 'Now'">
                  {{ time | freyaDate : 'h:mm a' : (timezone$ | async) }}
                </p>
              </ng-template>
              <ng-template let-time pTemplate="selectedItem">
                <p *ngIf="time === 'Now'">Now</p>
                <p *ngIf="time !== 'Now'">
                  {{ time | freyaDate : 'h:mm a' : (timezone$ | async) }}
                </p>
              </ng-template>
            </p-dropdown>
            <br />
            <strong *ngIf="(restrictionsDisabled$ | async) === true"
              >⚠️ Restrictions Disabled ⚠️</strong
            >
          </div>

          <!---------------- ASSETS ---------------->
          <div>
            <h4>{{ assetTypeName$ | async }}:</h4>
            <p-multiSelect
              #selectAsset
              class="full-width-multiselect"
              placeholder="Select Asset"
              [options]="possibleAssets$ | async"
              dataKey="id"
              optionLabel="name"
              optionValue="id"
              filterBy="name"
              appendTo="body"
              [disabled]="assetsDisabled$ | async"
              [autofocusFilter]="!responsiveHelper.isSmallScreen"
              [ngModel]="selectedAssets$ | async"
              (onChange)="store.dispatch(ScheduleEventsActions.assetsSelected({
              assets: $event.value,
            })); selectAsset.hide()"
            >
              <ng-template let-asset pTemplate="item">
                <p>{{ asset.name }}</p>
              </ng-template>
              <ng-template let-assets pTemplate="selectedItems">
                <p *ngFor="let a of assets">{{ a.name }}</p>
                <p *ngIf="!assets?.length">
                  Select {{ assetTypes?.join(', ') }}
                </p>
              </ng-template>
            </p-multiSelect>
          </div>

          <!---------------- BOOK BUTTONS ---------------->
          <div>
            <button
              pButton
              class="full-width quick-margin"
              [label]="(alreadyScheduled$ | async) ? 'Reschedule' : 'Book'"
              [icon]="
                (eventBeingScheduled$ | async)
                  ? 'pi pi-spin pi-spinner'
                  : 'pi pi-calendar-plus'
              "
              [disabled]="bookButtonDisabled$ | async"
              (click)="bookEventButtonClicked()"
            ></button>
          </div>

          <!-- <div class="update-cancel-buttons">
            <button
              pButton
              class="full-width quick-margin p-button-text"
              label="Cancel"
              icon="pi pi-times"
              (click)="close()"
            ></button>
            <button
              pButton
              class="full-width quick-margin"
              label="Update Event"
              icon="pi pi-check"
              [disabled]="
                (selectedTime$ | async) === undefined ||
                (selectedAssets$ | async).length === 0
              "
              (click)="updateEventButtonClicked()"
            ></button>
          </div> -->
        </div>

        <!---------------- VIEW MODE ---------------->
        <div *ngIf="(scheduleMode$ | async) === 'view'">
          <div>
            <h4>Date:</h4>
            <span class="freya-link-button">{{
              (eventToBook$ | async)?.start
                | freyaDate : 'MMM d, y' : (timezone$ | async)
            }}</span>
            <div class="p-mt-2">
              <app-object-state
                *ngIf="event?.event?.status === 'cancelled'"
                [object]="event"
                objectType="event"
                [colorful]="true"
              ></app-object-state>
            </div>
          </div>

          <div>
            <h4>Start / End:</h4>
            <p>
              {{
                eventToBook$
                  | async
                  | eventDuration : false : false : true : (timezone$ | async)
              }}
            </p>
          </div>
          <div>
            <h4>Start / End (Dock to Dock):</h4>
            <p>
              {{
                eventToBook$
                  | async
                  | eventDuration : true : false : true : (timezone$ | async)
              }}
            </p>
          </div>

          <div>
            <h4>Assets:</h4>
            <p *ngFor="let a of (eventToBook$ | async).assets">{{ a.name }}</p>
          </div>

          <div>
            <button
              *hasPermission="['calendarEvents.update']"
              pButton
              class="full-width quick-margin"
              label="Reschedule"
              icon="pi pi-calendar-plus"
              [disabled]="eventBeingScheduled$ | async"
              (click)="
                store.dispatch(ScheduleEventsActions.rescheduleButtonClicked())
              "
            ></button>
          </div>
        </div>
        <div *ngIf="event?.event?.start">
          <span
            [pTooltip]="
              event?.event?.locked
                ? 'This event ends before ' + formattedLockDate.short
                : undefined
            "
            tooltipPosition="bottom"
          >
          </span>

          <span
            [pTooltip]="
              event?.event?.locked
                ? 'This event ends before ' + formattedLockDate.short
                : undefined
            "
            tooltipPosition="bottom"
          >
          </span>
          <div
            *ngIf="
              (event.event.title === 'Time Lock' && !job?.users?.length) ||
              !job?.users?.[0].user
            "
          >
            <p-message
              severity="warn"
              text="Events cannot be booked until a customer has been set"
            ></p-message>
          </div>
        </div>
      </ng-container>
      <p-message
        *ngIf="showJobInDifferentTimezoneWarning$ | async"
        severity="warn"
        text="You are selecting this events start time in a different timezone"
      >
      </p-message>
      <p-message
        *ngIf="showYemboEstimatorNoEmailWarning$ | async"
        severity="warn"
        text="You are selecting this events start time in a different timezone"
      >
      </p-message>
    </div>
    <div class="schedule-container">
      <app-schedule-v2 #schedule></app-schedule-v2>
    </div>
    <div></div>
  </div>
</div>
