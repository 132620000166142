import { Injectable } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, filter, firstValueFrom } from 'rxjs';
import { SubSink } from 'subsink';

import { TawkToSecretGQL } from '../../generated/graphql.generated';
import { PlusAuthenticationService } from '../core/public-api';

import { MS_ONE_MINUTE } from '../time';

import { BrandingService } from './branding.service';
import { DatadogService } from './datadog.service';

@Injectable({
  providedIn: 'root'
})
export class TawkToService {

  loaded = new BehaviorSubject(false);
  secret: string;
  datadogLastSent?: number;
  datadogSendEventInterval = MS_ONE_MINUTE;

  get Tawk_API() {
    return (window as any).Tawk_API as {
      // start(): void;
      login(data: {
        hash?: string;
        userId?: string;
        email?: string;
        phone?: string;
        zone?: string;
        name?: {
          first?: string;
          last?: string;
        }

        datadog?: string;
      }, callback?: (error?) => void): void;
      logout(callback?: (error?) => void): void;

      onLoad?: () => void;

      addEvent(eventName: string, metadata: any, callback?: (err) => void);
      setAttributes(attributes: any, callback?: (err) => void);

      onChatMessageVisitor?: (message: string) => void;
      onPrechatSubmit?: (data: any) => void;
    };
  }

  subs = new SubSink();

  constructor(
    private brandingSvc: BrandingService,
    private authSvc: PlusAuthenticationService,
    private tawkToSecretGQL: TawkToSecretGQL,
    private datadogSvc: DatadogService,
  ) { }

  init() {
    this.subs.unsubscribe();

    // TODO: on chat started add datadog information

    // on store updated update login information

    console.log('[Tawk.To] Initializing');
    this.Tawk_API.onLoad = () => {
      console.log('[Tawk.To] Loaded');
      this.loaded.next(true);
      // this.login();
    }

    this.Tawk_API.onPrechatSubmit = (data) => {
      console.log('[Tawk.To] onPrechatSubmit', data);
      
      // SEND EVENT
      this.sendDatadogEvent();
    }
    this.Tawk_API.onChatMessageVisitor = (message) => {
      console.log('[Tawk.To] onChatMessageVisitor', message);
      
      this.sendDatadogEvent();
    }

    this.subs.sink = this.authSvc.authState.pipe(
      distinctUntilChanged(),
    ).subscribe((res) => {
      if (res === 'authenticated') {
        console.log(`AUTHENTICTED`);
        // login
        this.login();
      } else if (res === 'deauthenticated') {
        this.Tawk_API.logout();
      }

    });

  }

  async login() {
    const authorization = await this.getAuthorization();

    // wait for loaded
    await firstValueFrom(this.loaded.pipe(
      filter((loaded) => loaded === true),
    ));

    console.log(`[Tawk.To] Authenticating...`);

    if (!this.Tawk_API) { return; }
    if (!this.loaded) { return; }
    if (!authorization?.hash) { return; }
    // not supported when not in secure mode
    // this.Tawk_API.login({
    //   ...authorization,
    // });

    // only supported in insecure mode
    this.Tawk_API.setAttributes({
      ...authorization,
      ...this.getAttributes(),

      // login only
      // name: {
      //   first: ,
      //   last: ,
      // },
      // zone: this.authSvc.contextedZoneId,
    }, (err) => {
      if (err) {
        console.error(`[Tawk.To]`, err);
      } else {
        console.log(`[Tawk.To] Attributes Set`);
      }
    });

  }

  getAttributes() {
    const attributes: any = {
      email: this.authSvc.user?.email,
      phone: this.authSvc.user?.phone,
      name: `${ this.authSvc.user?.givenName } ${ this.authSvc.user?.familyName }`,
      zone: this.authSvc.contextedZoneId,
    };

    const datadog = this.datadogSvc.getSessionURL();
    if (datadog) {
      attributes.datadog = datadog;
    }

    return attributes;
  }

  async sendDatadogEvent() {
    const sessionUrl = this.datadogSvc.getSessionURL();
    if (!sessionUrl) { return; }

    const attributes = {
      ...await this.getAuthorization(),
      datadog: sessionUrl,
    };

    this.Tawk_API.setAttributes(attributes, (err) => {
      if (err) {
        console.log(`[Tawk.To]`, err);
      }
    });

    if (this.datadogLastSent < this.datadogSendEventInterval + Date.now()) {
      return;
    }

    this.datadogLastSent = Date.now();

    // doesn work
    this.Tawk_API.addEvent('datadog', {
      url: sessionUrl,
    }, (err) => {
      if (err) {
        console.error(`[Tawk.To]`, err);
      }
    });

  }

  async getAuthorization() {
    if (!this.secret && this.authSvc.user) {
      const { data: { tawkToSecret } } = await firstValueFrom(this.tawkToSecretGQL.fetch());
      this.secret = tawkToSecret;
    }

    if (!this.secret) {
      return {};
    }

    return {
      hash: this.secret,
      userId: this.authSvc.user?.id,
    }
  }
}
