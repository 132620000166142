<section class="activity">
  <div class="activity-header">
    <h2>
      <i class="pi pi-bolt p-mr-1"></i> <span class="p-mr-1">Activity</span>
    </h2>
    <p-badge [value]="totalComments$ | async" />
  </div>

  <main>
    <app-job-add-comment></app-job-add-comment>

    <p-timeline [value]="comments$ | async" class="comments">
      <ng-template pTemplate="marker" let-comment>
        <span
          class="p-timeline-event-marker"
          [pTooltip]="'Mode: ' + comment?.mode?.name || 'Comment'"
          [class.custom-icon]="comment.mode?.icon"
        >
          <i *ngIf="comment.mode?.icon" [class]="comment.mode.icon"></i>
        </span>
      </ng-template>

      <ng-template pTemplate="content" let-comment>
        <app-job-comment
          class="comment"
          [class.system]="comment.mode?.system || !comment?.author"
          [class.topLevel]="true"
          [comment]="comment"
          [job]="job$ | async"
        >
        </app-job-comment>
      </ng-template>
    </p-timeline>
  </main>
</section>
