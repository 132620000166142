<div class="card revenue" *ngIf="revenue$ | async as revenue">
  <div class="dashboard-card-header p-mb-4">
    <div>
      <h4 class="p-mb-1">Revenue<app-error-icon *ngIf="revenue.error"></app-error-icon></h4>
      {{ revenue.period }}
    </div>
    <app-dashboard-info-v2>
      <div class="p-mb-2">
        <b>Realized revenue:</b> The sum of the discounted subtotal (without damages) of all events ending in the selected period that have been <b>completed</b>.
      </div>
      <div class="p-mb-2">
        <b>Scheduled revenue:</b>  The sum of the discounted subtotal (without damages) of all events ending in the selected period that have <b>not</b> been completed.
      </div>
      <div class="p-mb-2">
        <b>Expected revenue:</b> The sum of the discounted subtotal (without damages) of <b>all</b> events ending in the selected period (regardless of status).
      </div>
      <div class="p-mb-2" *ngIf="revenue.showShared">
        <b>Shared revenue:</b> The sum of the discounted subtotal (without damages) of all events ending in the selected period that were shared from the OTR program (regardless of status).
      </div>
      <a [routerLink]="['/reports', 'dynamic', 'new']" [queryParams]="revenue.queryParams" target="_blank">View Report</a>.
    </app-dashboard-info-v2>
  </div>
  <div class="stats wrap" [class.p-flex-column]="layoutSize === 'small'">
    <div>
      Realized Revenue
      <app-dashboard-number
        [loading]="revenue.loading"
        [amount]="revenue.realized.amount"
        mode="currency"
        [currency]="revenue.realized.currency"
        [large]="layoutSize === 'small'"
        [params]="revenue.queryParams"
        skeletonWidth="90%"
        ></app-dashboard-number>
      <app-delta [delta]="revenue.realizedRevenueDelta"></app-delta>
    </div>
    <div>
      Scheduled Revenue
      <app-dashboard-number
        [loading]="revenue.loading"
        [amount]="revenue.scheduled.amount"
        mode="currency"
        [currency]="revenue.scheduled.currency"
        [large]="layoutSize === 'small'"
        [params]="revenue.queryParams"
        skeletonWidth="90%"
        >
      </app-dashboard-number>
      <app-delta [delta]="revenue.scheduledRevenueDelta"></app-delta>
    </div>
    <div>
      Expected Revenue
      <app-dashboard-number
        [loading]="revenue.loading"
        [amount]="revenue.expected.amount"
        mode="currency"
        [currency]="revenue.expected.currency"
        [large]="layoutSize === 'small'"
        [params]="revenue.queryParams"
        skeletonWidth="90%"
        ></app-dashboard-number>
      <app-delta [delta]="revenue.expectedRevenueDelta"></app-delta>
    </div>
    <div *ngIf="revenue.showShared">
      Shared Revenue
      <app-dashboard-number
        [loading]="revenue.loading"
        [amount]="revenue.shared.amount"
        mode="currency"
        [currency]="revenue.shared.currency"
        [large]="layoutSize === 'small'"
        [params]="revenue.queryParams"
        skeletonWidth="90%"
        ></app-dashboard-number>
      <app-delta [delta]="revenue.sharedRevenueDelta"></app-delta>
    </div>
  </div>
</div>
