import { AsyncPipe, CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { createSelector, Store } from '@ngrx/store';
import {
  AutoCompleteCompleteEvent,
  AutoCompleteSelectEvent,
} from 'primeng/autocomplete';
import { InplaceModule } from 'primeng/inplace';

import { TagModule } from 'primeng/tag';

import { SubSink } from 'subsink';

import { BaseTagFragment } from '../../../../generated/graphql.generated';
import { darkenColor } from '../../../colors';
import { FreyaCommonModule } from '../../../freya-common/freya-common.module';
import { SharedModule } from '../../../shared/shared.module';
import { JobToolActions } from '../../job-tool.actions';
import { jobToolFeature } from '../../job-tool.reducer';

function formatTagName(tag: BaseTagFragment) {
  return [tag.category ? `${tag.category}:` : null, tag.name]
    .filter(Boolean)
    .join(' ');
}

@Component({
  selector: 'app-job-tags',
  standalone: true,
  imports: [
    InplaceModule,
    AsyncPipe,
    CommonModule,
    TagModule,
    FreyaCommonModule,
    SharedModule,
  ],
  templateUrl: './job-tags.component.html',
  styleUrl: './job-tags.component.scss',
})
export class JobTagsComponent implements OnInit, OnDestroy {

  selectJobTags = createSelector(jobToolFeature.selectJob, (job) => {
    return job.tags.map((tag) => {
      return {
        ...tag,
        formattedName: formatTagName(tag),
        backgroundColor: darkenColor(tag.color, 50), // Darken by 50%
      };
    });
  });

  public tags$ = this.store.select(
    this.selectJobTags,
  );

  // tags filtered by the search params
  public filteredTags$ = this.store.select(
    createSelector(jobToolFeature.selectTags, (tags) => {
      return tags.map((tag) => {
        return {
          ...tag,
          formattedName: formatTagName(tag),
          backgroundColor: darkenColor(tag.color, 50), // Darken by 50%
        };
      });
    })
  );

  public hasJobTags$ = this.store.select(
    createSelector(this.selectJobTags, (tags) => tags?.length)
  );

  // public tags: any[] = [];
  public originalTags: BaseTagFragment[] = [];
  public selectedTags: BaseTagFragment[] = [];

  private subs = new SubSink();

  constructor(private store: Store) {}

  ngOnInit() {
    this.subs.sink = this.tags$.subscribe((tags) => {
      // Spread to create a shallow copy as we will be modifying the array
      this.selectedTags = [ ...tags ];
      this.originalTags = [ ...tags ];
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  searchTags(event: AutoCompleteCompleteEvent) {
    this.store.dispatch(JobToolActions.tagSearched({ tagSearch: event.query }));
  }

  onTagSelect(event: AutoCompleteSelectEvent) {

    // We aren't storing the currently selected tags in the state
    // because the autocomplete component is storing the updated
    // state. We have to remove duplicate categories from the state.
    // We then send the final state to the server.

    // At this point, the tag has been selected
    // from the dropdown and added to the selectedTags array
    const tag = event.value;

    this.removeOtherTagsWithSameCategory(tag);
  }

  removeOtherTagsWithSameCategory(tag: BaseTagFragment) {
    if (!tag?.category) { return; }
    this.selectedTags = this.selectedTags.filter(
      (t) => t.id ===  tag.id || !t.category || t.category !== tag.category,
    );
  }

  onDeactivate() {
    // if (isEqual(this.selectedTags, this.tags)) {
    //   return;
    // }
    this.store.dispatch(
      JobToolActions.saveTagChangesClicked({
        selectedTags: this.selectedTags,
        originalTags: this.originalTags,
      }),
    );
  }
}
