import { BaseCommentFragment } from '../../../../generated/graphql.generated';

export function mapCommentEmailStatus(
	comment: BaseCommentFragment,
) {

	if (!comment?.metadata || !comment?.attributes) return null;
    
	const statusPriority = [
	  { key: 'bounce', icon: 'pi-times-circle', color: '#dc2626' },
	  { key: 'dropped', icon: 'pi-times-circle', color: '#dc2626' },
	  { key: 'deferred', icon: 'pi-clock', color: '#facc15' },
	  { key: 'click', icon: 'pi-hand-pointer', color: '#7c3aed' },
	  { key: 'open', icon: 'pi-eye', color: '#2563eb' },
	  { key: 'delivered', icon: 'pi-check-circle', color: '#16a34a' },
	  { key: 'sent', icon: 'pi-envelope', color: '#3b82f6' }
	];

	const emailToStatus = new Map<string, string>();

	for (const status of statusPriority) {
	  const emails: string[] = comment.metadata[`email_${status.key}`] || [];
	  for (const email of emails) {
		if (!emailToStatus.has(email)) {
		  emailToStatus.set(email, status.key);
		}
	  }
	}

	const grouped = new Map<string, string[]>();
	for (const [email, status] of emailToStatus.entries()) {
	  if (!grouped.has(status)) {
		grouped.set(status, []);
	  }
	  grouped.get(status)!.push(email);
	}

	// Find the highest-priority status in the attributes list for the icon
	const mainStatus = statusPriority.find(s => comment.attributes.includes(s.key));
	if (!mainStatus) return null;

	const tooltip = statusPriority
	  .filter(s => grouped.has(s.key))
	  .map(s => ({
		status: s.key,
		emails: grouped.get(s.key)!
	  }));

	return {
	  icon: 'pi ' + mainStatus.icon,
	  color: mainStatus.color,
	  tooltip
	};
}