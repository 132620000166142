import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';

import { SharedModule } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';

import { InputNumberModule } from 'primeng/inputnumber';

import { User } from '../../../../../generated/graphql.generated';
import { FreyaCommonModule } from '../../../../freya-common/freya-common.module';
import { FreyaCurrencyPipe } from '../../../../shared/freya-currency.pipe';
import { DispatchActions } from '../../store/dispatch.actions';

@Component({
  selector: 'app-edit-crew-salary',
  standalone: true,
  imports: [
    CommonModule,
    DividerModule,
    ButtonModule,
    InputNumberModule,
    FormsModule,
    FreyaCommonModule,
    SharedModule,
  ],
  providers: [
    FreyaCurrencyPipe
  ],
  templateUrl: './edit-crew-salary.component.html',
  styleUrl: './edit-crew-salary.component.scss'
})
export class EditCrewSalaryComponent implements OnInit{
  
  @Input() user: User;
  
  // TODO: Remove this and use directly from state
  public userSalary: number;
  public shiftAvailability: string;

  constructor(
    private store: Store,
  ) {}

  ngOnInit(): void {
    this.userSalary = (this.user.salary || 0) / 100;
    this.shiftAvailability = this.user.shiftAvailability; 
  }

  public onSalaryChange(event: any){
    this.store.dispatch(DispatchActions.crewSalaryChanged({ salary: event.value * 100}));
  }
  public onAvailabilityChange(event: any){
    this.store.dispatch(DispatchActions.crewAvailabilityChanged({ shiftAvailability: event.target.value}));
  }

  public onSave(){
    this.store.dispatch(DispatchActions.crewSalarySaved());
  }

  public onClose(){
    this.store.dispatch(DispatchActions.editCrewSalaryDialogClosed());
  }
}
