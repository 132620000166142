<div
  class="details"
  (click)="openInDialog()"
>
  <i class="pi pi-file"></i>
  <div>
    <div class="document-name">
      <span>
        {{ (invoice.total - invoice.paidTotal) / 100 | currency : invoice.currency }}
      </span>
      <p-tag
        *ngFor="let tag of tags"
        rounded="true"
        [style]="{
          backgroundColor: tag.backgroundColor,
          color: tag.color
        }"
        [value]="tag.text"
        styleClass="p-ml-2"
      ></p-tag>
    </div>
    <div *ngIf="job$ | async as job">{{ invoice | invoiceName: (job.users | jobCustomer) : job.code }}</div>
  </div>
</div>
<div class="buttons">
  <p-button
    (onClick)="sendInvoice()"
    [icon]="sending ? 'pi pi-spinner pi-spin' : 'pi pi-send'"
    [label]="label"
    [disabled]="sending || sendInvoiceDisabled"
    [pTooltip]="sendInvoiceDisabledTooltip"
    styleClass="p-mr-2"
  ></p-button>
  <p-button
    icon="pi pi-ellipsis-v"
    text="true"
    (click)="menu.toggle($event)"
  ></p-button>
  <p-menu
    #menu
    [popup]="true"
    appendTo="body"
    [model]="actionsMenu"
  ></p-menu>
</div>
