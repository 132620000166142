import { BaseConfigValueFragment } from '../../generated/graphql.generated';
import { InventoryItem } from '../estimates/estimate.interfaces';
import { DEFAULT_HOW_HEARD_DROPDOWN_OPTIONS, DEFAULT_JOB_CLOSED_REASONS } from '../global.constants';
import { safeParseJSON } from '../js';

export interface FrontendConfig {
	key: string;
	format?: 'string' | 'boolean' | 'number' | 'object' | 'array',
	default?: any,
	includeInBranding: boolean;
}

export const frontendConfigs = [
	{
		key: 'jobs.closedReasons',
		includeInBranding: true,
		format: 'array',
		default: DEFAULT_JOB_CLOSED_REASONS,
	},
	{
		key: 'inventory.items',
		includeInBranding: true,
		format: 'array',
		default: [] as InventoryItem[],
	},
	{
		key: 'invoice.disableMultipleEvents',
		includeInBranding: true,
		format: 'boolean',
	},
	{
		key: 'jobs.howHeardOptions',
		includeInBranding: true,
		format: 'array',
		default: DEFAULT_HOW_HEARD_DROPDOWN_OPTIONS as string[],
	},
	{
		key: 'jobs.howHeardMandatory',
		includeInBranding: true,
		format: 'boolean',
	},
	{
		key: 'jobs.dwellingTypeMandatory',
		includeInBranding: true,
		format: 'boolean',
	},
	{
		key: 'jobs.bedroomsMandatory',
		includeInBranding: true,
		format: 'boolean',
	},
	{
		key: 'role.customer',
		includeInBranding: true,
	},
	{
		key: 'yembo-integration.enabled',
		includeInBranding: true,
		format: 'boolean',
	},
	{
		key: 'franchise-info.dockLocationId',
		includeInBranding: true,
	},
	{
		key: 'calendarEvents.estimateLength',
		includeInBranding: true,
		format: 'number',
	},
	{
		key: 'calendarEvents.virtualEstimateLength',
		includeInBranding: true,
		format: 'number',
	},
	{
		key: 'rolling-lock-date.currentDate',
		includeInBranding: true,
		format: 'number',
	},
	{
		key: 'find.startTimeInterval',
		includeInBranding: true,
		format: 'number',
	},
	{
		key: 'calendarEvents.includeDockTravelTime',
		includeInBranding: true,
		format: 'boolean',
	},
	{
		key: 'calendarEvents.dockTravelTimeCheckBox',
		includeInBranding: true,
		format: 'boolean',
	},
	{
		key: 'calendarEvents.depositMinToBook',
		includeInBranding: true,
		format: 'number',
	},
] as const;

export const configKeys = frontendConfigs.map((c) => c.key);
export type ConfigKey = typeof frontendConfigs[number]['key'];

// Helper type to get array item type from 'default' if it is an array
type DefaultArrayType<T> = T extends Array<infer U> ? U[] : never;

// Utility type to handle types based on 'format' and 'default'
type ConfigTypes<K extends ConfigKey> = 
  Extract<typeof frontendConfigs[number], { key: K }> extends { format: 'boolean' }
    ? boolean
    : Extract<typeof frontendConfigs[number], { key: K }> extends { format: 'number' }
    ? number
    : Extract<typeof frontendConfigs[number], { key: K }> extends { format: 'object' }
    ? object
    : Extract<typeof frontendConfigs[number], { key: K }> extends { format: 'array', default: infer D }
    ? DefaultArrayType<D>
    : Extract<typeof frontendConfigs[number], { key: K }> extends { format: 'string' }
    ? string
    : any;

export type ConfigValue<K extends ConfigKey> = ConfigTypes<K>;

export const brandingConfigKeys = frontendConfigs.filter((f) => f.includeInBranding).map((c) => c.key)

export function formatConfigValue(config: BaseConfigValueFragment): string | boolean | number | object | any[]{
	const frontendConfig: FrontendConfig = frontendConfigs.find((c) => c.key === config.key);
	if (!frontendConfig) { return config.value; }


	if (frontendConfig.format === 'boolean' && !config.value) {
		return frontendConfig.default || false;
	}

	if (frontendConfig.format === 'boolean') {
		return config.value?.toLowerCase() === 'true';
	}

	if (frontendConfig.format === 'number') {
		return parseFloat(config.value) || frontendConfig.default;
	}

	if (frontendConfig.format === 'array') {
		return safeParseJSON(config.value, frontendConfig.default);
	}

	if (frontendConfig.format === 'object') {
		if (!config.value) { return frontendConfig.default; }
		try {
			return JSON.parse(config.value);
		} catch (err) {
			console.error(`Failed to parse ${ config.key }, reverting to default`,
				err, config.value, frontendConfig.default
			);
			return frontendConfig.default;
		}
	}

	return config.value || frontendConfig.default;
}
