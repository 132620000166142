<div class="layout-dashboard" #layout>
  <div
    class="p-grid p-align-stretch vertical-container"
    *ngIf="header$ | async as header"
  >
    <div [class]="colClassMap['header'][layoutSize]">
      <div>
        <h2 class="p-mb-1">Account Dashboard</h2>
        <p>
          {{ header.displayPeriod }} |
          <span *ngIf="header.loading">Loading... </span>
          <ng-container *ngIf="!header.loading">
            Last updated
            <app-time-since [unixTime]="header.lastReload"></app-time-since>
          </ng-container>
        </p>
      </div>
    </div>
    <div [class]="colClassMap['dropdowns'][layoutSize]">
      <p-dropdown
        [ngModel]="header.period"
        (ngModelChange)="setPeriod($event)"
        appendTo="body"
        [options]="periodOptions"
      ></p-dropdown>
      <p-calendar
        *ngIf="!header.isMonthMode"
        appendTo="body"
        [(ngModel)]="customPeriod"
        selectionMode="range"
        [readonlyInput]="true"
        styleClass="full-width"
        inputId="range"
        [disabled]="header.customPeriodDisabled"
        (onClose)="validateCustomPeriod()"
      ></p-calendar>
      <p-calendar
        *ngIf="header.isMonthMode"
        [(ngModel)]="month"
        dataType="string"
        view="month"
        dateFormat="MM yy"
        (onSelect)="setMonth(month)"
        showIcon="true"
        iconDisplay="input"
        [readonlyInput]="true"
      />
      <p-dropdown
        *ngIf="header.showComparedTo"
        [ngModel]="header.comparedTo"
        (ngModelChange)="setComparedTo($event)"
        appendTo="body"
        [options]="comparedToOptions"
      ></p-dropdown>
      <p-dropdown
        [ngModel]="header.currency"
        (ngModelChange)="setCurrency($event)"
        appendTo="body"
        [options]="currencyOptions"
      ></p-dropdown>
    </div>
    <ng-container *ngIf="header.errors">
      <div class="p-col-12" *ngIf="header.errors.length">
        <div class="error-container" [id]="errorContainerId">
          <p-message
            *ngFor="let error of header.errors"
            severity="error"
            [text]="error"
          ></p-message>
        </div>
      </div>
    </ng-container>
    <div [class]="colClassMap['needsAttention'][layoutSize]">
      <app-attention-items-summary
        summaryStyle="dashboardv2"
      ></app-attention-items-summary>
    </div>
    <div [class]="colClassMap['pipeline'][layoutSize]">
      <app-pipeline></app-pipeline>
    </div>
    <div [class]="colClassMap['smallCards'][layoutSize]">
      <div
        class="card"
        *ngIf="averageInvoiceSize$ | async as averageInvoiceSize"
      >
        <div class="dashboard-card-header">
          <h6 class="p-mb-0">Average Invoice Size</h6>
          <app-dashboard-info-v2>
            <div class="p-mb-2">
              The average net subtotal for all invoiced jobs created in the
              selected period.
            </div>
            <a
              [routerLink]="['/reports', 'dynamic', 'new']"
              [queryParams]="averageInvoiceSize.queryParams"
              target="_blank"
              >View Report</a
            >.
          </app-dashboard-info-v2>
        </div>
        <app-dashboard-number
          [loading]="averageInvoiceSize.loading"
          [large]="true"
          mode="currency"
          [amount]="averageInvoiceSize.averageInvoiceSize.amount"
          [currency]="averageInvoiceSize.averageInvoiceSize.currency"
          [params]="averageInvoiceSize.queryParams"
        ></app-dashboard-number>
        <app-delta [delta]="averageInvoiceSize.delta"></app-delta>
      </div>
      <ng-container *ngIf="conversions$ | async as conversions">
        <div class="card">
          <div class="dashboard-card-header">
            <h6 class="p-mb-0">OBE Conversion</h6>
            <app-dashboard-info-v2>
              <div class="p-mb-2">
                Out of all jobs created in the selected period that came in as
                OBE leads<span *ngIf="!conversions.leadsLoading">
                  ({{ conversions.obeTotal }})</span
                >, the percentage that are currently in "booking" or "invoiced"
                stage<span *ngIf="!conversions.leadsLoading">
                  ({{ conversions.obeConverted }})</span
                >.
              </div>
              <a
                [routerLink]="['/reports', 'dynamic', 'new']"
                [queryParams]="conversions.leadsQueryParams"
                target="_blank"
                >View Report</a
              >.
            </app-dashboard-info-v2>
          </div>
          <app-dashboard-number
            [loading]="conversions.leadsLoading"
            [large]="true"
            [amount]="conversions.obePercent"
            mode="percent"
            [params]="conversions.leadsQueryParams"
          ></app-dashboard-number>
          <app-delta
            [delta]="conversions.obePercentDelta"
            mode="percent"
          ></app-delta>
        </div>
        <div class="card">
          <div class="dashboard-card-header">
            <h6 class="p-mb-0">Lead Conversion</h6>
            <app-dashboard-info-v2>
              <div class="p-mb-2">
                Out of all jobs created in the selected period (regardless of
                origin)<span *ngIf="!conversions.leadsLoading">
                  ({{ conversions.leadsTotal }})</span
                >, the percentage that are currently in "booking" or "invoiced"
                stage<span *ngIf="!conversions.leadsLoading">
                  ({{ conversions.leadsConverted }})</span
                >.
              </div>
              <a
                [routerLink]="['/reports', 'dynamic', 'new']"
                [queryParams]="conversions.leadsQueryParams"
                target="_blank"
                >View Report</a
              >.
            </app-dashboard-info-v2>
          </div>
          <app-dashboard-number
            [loading]="conversions.leadsLoading"
            [large]="true"
            [amount]="conversions.leadsPercent"
            mode="percent"
            [params]="conversions.leadsQueryParams"
          ></app-dashboard-number>
          <app-delta
            [delta]="conversions.leadsPercentDelta"
            mode="percent"
          ></app-delta>
        </div>
      </ng-container>
    </div>
    <div [class]="colClassMap['revenue'][layoutSize]">
      <app-revenue></app-revenue>
    </div>
    <div [class]="colClassMap['conversions'][layoutSize]">
      <app-conversions></app-conversions>
    </div>
    <div [class]="colClassMap['activity'][layoutSize]">
      <app-activity></app-activity>
    </div>
    <div [class]="colClassMap['logistics'][layoutSize]">
      <app-logistics></app-logistics>
    </div>
    <div [class]="colClassMap['eventSummary'][layoutSize]">
      <app-event-summary></app-event-summary>
    </div>
    <div [class]="colClassMap['sources'][layoutSize]">
      <app-sources></app-sources>
    </div>
  </div>
</div>
