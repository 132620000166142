import { createSelector } from "@ngrx/store";

import { isEqual } from 'lodash';
import { eventTypeInfoMap, eventTypeInfoMapV2 } from "src/app/global.constants";
import { getJobLocation } from "src/app/jobs/jobs.util";
import { createConfigSelector } from "src/app/state/branding.store";

import { EventStatuses } from '../../../schedules/dispatch/store/dispatch.reducer';
import { scheduleFeature } from '../../../schedules/store/schedules.reducer';
import { ExtendedCalendarEventForScheduleFragment } from '../../../services/full-calendar-helper.service';
import { jobToolFeature } from "../../job-tool.reducer";
import { calculateEventTime } from '../../jobsv2-events-helpers';

export const selectEventMissingLocations = createSelector(
  jobToolFeature.selectJob,
  jobToolFeature.selectEventToBook,
  (job, event) => {
      if (!event) {
          return [];
      }

      const eventTypeInfo = eventTypeInfoMapV2[event.type];

      const missingLocations = [];

      const requiredLocations = eventTypeInfo?.requiredLocations || [];

      for (const locationType of requiredLocations) {
          if (!getJobLocation(job, locationType)) {
              missingLocations.push(locationType);
          }
      }

      return missingLocations;
  }
);

export const selectEventsWithMissingLocations = createSelector(
  jobToolFeature.selectJob,
  (job) => {
    if (!job || !job.events) {
      return {};
    }

    const eventsWithMissingLocations: Record<string, string[]> = {};

    for (const event of job.events) {
      const eventTypeInfo = eventTypeInfoMapV2[event.type];

      // Find all missing locations for the current event
      const missingLocations = eventTypeInfo.requiredLocations.filter(
        (locationType) => !getJobLocation(job, locationType)
      );

      if (missingLocations.length > 0) {
        eventsWithMissingLocations[event.id] = missingLocations;
      }
    }

    return eventsWithMissingLocations;
  }
);

export const selectJobZone = createSelector(
  jobToolFeature.selectJob,
  (job) => {
    return job?.zone;
  }
);

const selectAllowedAssets = createSelector(
    scheduleFeature.selectAssets,
    jobToolFeature.selectEventToBook,
    jobToolFeature.selectIsBookingRestrictionDisabled,
    (assets = [], eventToBook, isBookingRestrictionDisabled) => {
      if (!eventToBook) return [];

      if (isBookingRestrictionDisabled) return assets;

      const assetTypes = eventTypeInfoMap[eventToBook.type]?.assetTypes;
      return assetTypes?.length ? assets.filter(a => assetTypes.includes(a.type)) : [];
    },
);

const selectPossibleAssets = createSelector(
    jobToolFeature.selectAvailableAssets,
    selectAllowedAssets,
    jobToolFeature.selectIsBookingRestrictionDisabled,
    (availableAssets, allAssets, restrictionsDisabled) => 
        restrictionsDisabled ? allAssets : availableAssets
);

export const selectEventItinerary = createSelector(
  jobToolFeature.selectJob,
  jobToolFeature.selectEventToBook,
  jobToolFeature.selectIncludeDockTravel,
  createConfigSelector('calendarEvents.estimateLength'),
  createConfigSelector('calendarEvents.virtualEstimateLength'),
  (job, event, includeDockTravel, estimatingLength, voseLength) => {

    if (!job || !event) {
      return undefined;
    }

    const eventTimeFromConfig = {
      estimating: estimatingLength,
      virtualEstimate: voseLength,
    }

    const result = calculateEventTime(
      job,
      event.id,
      includeDockTravel,
      includeDockTravel,
      eventTimeFromConfig
    );

    return result;
  }
);

export const selectTotalLocationTime = createSelector(
  selectEventItinerary,
  (itinerary) => {
    return itinerary?.totalLocationTime;
  }
);

export const selectTotalTravelTime = createSelector(
  selectEventItinerary,
  (itinerary) => {
    return itinerary?.totalTravelTime;
  },
);

export const selectTotalTime = createSelector(
  selectEventItinerary,
  (itinerary) => {
    return itinerary?.totalTime;
  },
);

export const selectPlaceholderEvent = createSelector(
  jobToolFeature.selectEventToBook,
  jobToolFeature.selectSelectedStartTime,
  selectTotalTime,
  jobToolFeature.selectSelectedAssets,
  selectPossibleAssets,
  jobToolFeature.selectScheduleMode,
  (event, selectedStartTime, totalTime, selectedAssetIds, assets, scheduleMode): ExtendedCalendarEventForScheduleFragment => {
    if (!selectedStartTime || !totalTime || !event) {
      return event;
    }

    if (scheduleMode === 'view') {
      return event;
    }

    const end = selectedStartTime + totalTime;
    const selectedAssets = (selectedAssetIds || [])
      .map((id) => assets.find((a) => a.id === id))
      .filter(Boolean);

    const eventAssetIds = event?.assets?.map((a) => a.id) || [];
    const hasChanges = event.start !== selectedStartTime || event.end !== end
      || !isEqual(selectedAssetIds, eventAssetIds);
    const isNotBooked = event.status !== EventStatuses.Booked;

    return {
      ...event,
      start: selectedStartTime,
      end,
      assets: selectedAssets,
      eventIsPlaceholder: hasChanges || isNotBooked,
    };
  },
)

export const selectEventsWithChanges = createSelector(
  scheduleFeature.selectEvents,
  selectPlaceholderEvent,
  (events, placeholderEvent) => {

    events = [ ...(events || []) ];
    if (!placeholderEvent) { return events; }

    const index = events.findIndex((e) => e.id === placeholderEvent.id);
    if (index >= 0) {
      events[index] = placeholderEvent;
    } else {
      events.push(placeholderEvent);
    }

    return events;

  },
);

export const selectStartTimeInterval = createConfigSelector('find.startTimeInterval');
export const selectDefaultForIncludeDockTravel = createConfigSelector('calendarEvents.includeDockTravelTime');
export const selectShowDockTravelTimeCheckbox = createConfigSelector('calendarEvents.dockTravelTimeCheckBox');

export const eventScheduleSelectors = {
    selectEventMissingLocations,
    selectEventsWithMissingLocations,
    selectJobZone,
    selectAllowedAssets,
    selectEventItinerary,
    selectTotalLocationTime,
    selectTotalTravelTime,
    selectTotalTime,
    selectStartTimeInterval,
    selectPossibleAssets,
    selectShowDockTravelTimeCheckbox,
    selectDefaultForIncludeDockTravel
}
