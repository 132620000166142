<table>
  <colgroup>
    <col style="width: 40%;" />
    <col style="width: 30%;" />
    <col style="width: 30%;" />
  </colgroup>
  <tr>
    <th></th>
    <th>Event</th>
    <th>Job</th>
  </tr>
  <tr>
    <td>Charges</td>
    <td>{{ (event$ | async)?.discountedSubTotalWithoutDamages / 100 | currency : (job$ | async)?.currency || 'USD' }}</td>
    <td>{{ (job$ | async)?.discountedSubTotalWithoutDamages / 100 | currency : (job$ | async)?.currency || 'USD' }}</td>
  </tr>
  <tr>
    <td>Discounts</td>
    <td>{{ (event$ | async)?.discountTotal / 100 | currency : (job$ | async)?.currency || 'USD' }}</td>
    <td>{{ (job$ | async)?.discountTotal / 100 | currency : (job$ | async)?.currency || 'USD' }}</td>
  </tr>
  <tr class="total-row">
    <td>Total</td>
    <td>{{ (event$ | async)?.total / 100 | currency : (job$ | async)?.currency || 'USD' }}</td>
    <td>{{ (job$ | async)?.total / 100 | currency : (job$ | async)?.currency || 'USD' }}</td>
  </tr>
  <tr>
    <td>Paid</td>
    <td></td>
    <td>
      <span>{{ (job$ | async)?.paidTotal || 0 | freyaCurrency : (job$ | async)?.currency }}</span>
    </td>
  </tr>
  <tr>
    <td>Balance</td>
    <td [ngClass]="{ 'td-with-btn': (job$ | async)?.total - (job$ | async)?.paidTotal }">
      <button
          *ngIf="(job$ | async)?.total - (job$ | async)?.paidTotal"
          pButton
          class="p-button-text p-m-1 workorders-btn-text"
          label="Pay"
          (click)="onPayButtonClick()"
        ></button>
    </td>
    <td>
      {{ ((job$ | async)?.total - (job$ | async)?.paidTotal) || 0 | freyaCurrency : (job$ | async)?.currency }}
    </td>
  </tr>
  <tr *ngIf="(job$ | async)?.taxTotal > 0">
    <td>Taxes</td>
    <td>{{ (event$ | async)?.taxTotal / 100 | currency : (job$ | async)?.currency || 'USD' }}</td>
    <td>{{ (job$ | async)?.taxTotal / 100 | currency : (job$ | async)?.currency || 'USD' }}</td>
  </tr>
  <tr *ngIf="(job$ | async)?.damageTotal">
    <td>Damages</td>
    <td>{{ (event$ | async)?.damageTotal / 100 | currency : (job$ | async)?.currency || 'USD' }}</td>
    <td>{{ (job$ | async)?.damageTotal / 100 | currency : (job$ | async)?.currency || 'USD' }}</td>
  </tr>
  <tr class="toggle-row" (click)="showConfidentialInfo = !showConfidentialInfo">
    <td colspan="3">
      {{ showConfidentialInfo ? 'Hide' : 'Show' }} office information
      <span>
        <i [class]="showConfidentialInfo ? 'pi pi-chevron-up' : 'pi pi-chevron-down'"></i>
      </span>
    </td>
  </tr>

  <ng-container *ngIf="showConfidentialInfo">
    <ng-container *hasPermission="['expenses.view']">
      <ng-container *ngIf="(job$ | async)?.total !== 0">
        <tr pTooltip="The expenses directly associated with this job" tooltipPosition="left">
          <td>Cost</td>
          <td>{{ (event$ | async)?.expenseTotal | freyaCurrency : (event$ | async)?.currency }}</td>
          <td>{{ (job$ | async)?.expenseTotal | freyaCurrency : (job$ | async)?.currency }}</td>
        </tr>
        <tr pTooltip="Gross Margin is your sub total minus your costs of goods sold (expenses)" tooltipPosition="left">
          <td>Gross Margin</td>
          <td>{{ ((event$ | async)?.discountedSubTotal - (event$ | async)?.expenseTotal) || 0 | freyaCurrency : (event$ | async)?.currency }}</td>
          <td>{{ ((job$ | async)?.discountedSubTotal - (job$ | async)?.expenseTotal) || 0 | freyaCurrency : (job$ | async)?.currency }}</td>
        </tr>
        <tr
          pTooltip="Gross profit margin is the difference between revenue and cost of goods sold, divided by revenue."
          tooltipPosition="left">
          <td>GPM</td>
          <td>{{ getJobGrossProfitMargin((event$ | async)!) | number: '1.2-2' | formatAmount: 'percentage' }}</td>
          <td>{{ getJobGrossProfitMargin((job$ | async)!) | number: '1.2-2' | formatAmount: 'percentage' }}</td>
        </tr>
      </ng-container>
    </ng-container>
  </ng-container>
</table>
