import { createFeature, createReducer, createSelector, on } from '@ngrx/store';

import { AvailabilityOutput, CalendarEventForScheduleFragment, FullAssetFragment } from '../../../generated/graphql.generated';
import { dayjs } from '../../core/dayjs/dayjs';

import { eventTypeInfoMap } from '../../global.constants';
import { ScheduleEventsActions } from '../../jobsv2/job-state/event-schedule-state/event-schedule.actions';

import { JobToolActions } from '../../jobsv2/job-tool.actions';

import { jobToolFeature } from '../../jobsv2/job-tool.reducer';

import { ScheduleActions } from './schedule.actions';

export const SCHEDULE_VIEW_TYPE_MAP = {
  'resourceTimelineDay': 'day',
  'resourceTimelineWeek': 'week',
  'dayGridMonth': 'month',
} as const;

export const SCHEDULE_VIEW_SIMPLE_MAP = Object.entries(SCHEDULE_VIEW_TYPE_MAP)
	.reduce((p, [ complex, simple ]) => ({
		...p,
		[ simple ]: complex,
	}), {});

export type ScheduleView = keyof typeof SCHEDULE_VIEW_TYPE_MAP;
export type ScheduleViewSimple = typeof SCHEDULE_VIEW_TYPE_MAP[ScheduleView];

export function simpleScheduleViewToFullCalendarView(simple: ScheduleViewSimple) {
	return SCHEDULE_VIEW_SIMPLE_MAP[simple];
}

export const scheduleInitialState = {
	// the date formatted as YYYY-MM-DD
	date: undefined as string,

	// start unix of the current view in the current timezone
	start: undefined as number,

	// end unix of the current view in the current timezone
	end: undefined as number,

	// zone: undefined as string,
	timezone: undefined as string,

	initialized: false as boolean,

	view: 'day' as ScheduleViewSimple,

	title: 'Schedule' as string,

	monthShowEvents: false as boolean,
	maxEvents: 0 as number,

	assetsLoading: true as boolean,
	assets: undefined as FullAssetFragment[],

	eventsLoading : true as boolean,
	events: undefined as CalendarEventForScheduleFragment[],
	availabilityLoading : true as boolean,
	availability: undefined as AvailabilityOutput[],
} as const;

export type ScheduleState = typeof scheduleInitialState;

export const scheduleFeature = createFeature({
	name: 'schedule',
	extraSelectors({
		selectDate,
		selectTimezone,
		selectAssetsLoading,
		selectEventsLoading,
		selectAvailabilityLoading,
		selectAssets,
	}) {

		const selectDayJsDate = createSelector(
			selectDate,
			selectTimezone,
			(date, timezone) => dayjs.tz(date, timezone),
		);

		const selectLoading = createSelector(
			selectAssetsLoading,
			selectEventsLoading,
			selectAvailabilityLoading,
			(assetsLoading, eventsLoading, availabilityLoading) => assetsLoading || eventsLoading || availabilityLoading,
		);

		return {
			selectDayJsDate,
			selectLoading,
		}
	},
	reducer: createReducer(
		scheduleInitialState,
		on(ScheduleActions.componentInitialized, (state, res): ScheduleState => {
			return {
				title: res.title,
				...scheduleInitialState,
			}
		}),
		on(ScheduleActions.storeInitialized, (state, res): ScheduleState => {
			return {
				...state,
				initialized: true,
				date: res.date,
				timezone: res.timezone,
				view: res.view,
			}
		}),
		on(ScheduleActions.timezoneChanged, (state, res): ScheduleState => {
			return {
				...state,
				timezone: res.timezone,
			}
		}),
		on(ScheduleActions.assetsLoaded, (state, res): ScheduleState => {
			return {
				...state,
				assetsLoading: false,
				assets: res.assets,
			}
		}),
		on(ScheduleActions.eventsLoaded, (state, res): ScheduleState => {
			return {
				...state,
				eventsLoading: false,
				events: res.events,
			};
		}),
		on(ScheduleActions.availabilityLoaded, (state, res): ScheduleState => {
			return {
				...state,
				availabilityLoading: false,
				availability: res.availability,
			};
		}),
		on(ScheduleActions.fcViewChanged, (state, { date, start, end, view, title, }): ScheduleState => {			
			return {
				...state,
				date,
				start,
				end,
				view: SCHEDULE_VIEW_TYPE_MAP[view],
				title,
				eventsLoading: true,
				availabilityLoading: true,

				// we don't need these
				// view,
				// timezone,
			}
		}),
		on(ScheduleEventsActions.bookEventSuccess, (state, {}): ScheduleState => {			
			return {
				...state,
				eventsLoading: true,
			}
		}),
		on(JobToolActions.eventUpdateSuccess, (state, {}): ScheduleState => {			
			return {
				...state,
				eventsLoading: true,
			}
		}),
		on(ScheduleActions.reloadButtonClicked, (state, {}): ScheduleState => {			
			return {
				...state,
				eventsLoading: true,
				availabilityLoading: true,
				assetsLoading: true,
			}
		}),
	)
})
