<ng-container *ngIf="!renderHeadless; else headlessEditSummary">
  <p-card>
    <div class="header-container">
      <div class="header-with-icon">
        <i class="pi pi-book p-mr-2"></i>
        <h2>Summary</h2>
      </div>
      <div class="saving-disabled-msg header-with-icon">
        <p-message
          severity="warn"
          *ngIf="creatingDisabled"
          [text]="savingDisabledMessage"
        ></p-message>
      </div>
    </div>
    <div class="one-column-container">
      <div class="create-btn-container">
        <div
        [style]="{ flex: 1 }"
        *ngFor="let errorMessage of jobSavingErrors$ | async"
        >
        <p-messages severity="error">
            <ng-template pTemplate>
                <i class="pi pi-exclamation-triangle"></i>
                <p [style]="{ flex: 1, marginBottom: 0 }">{{errorMessage}}</p>
                <button
                    pButton
                    label="Report Bug"
                    icon="pi pi-send"
                    class="p-button-danger"
                    (click)="reportBug(errorMessage)"
                    ></button>
            </ng-template>
        </p-messages>
        </div>
        <app-form-action-button
          *ngIf="mode === 'create' && !((jobSavingErrors$ | async) ?.length)"
          action="create"
        ></app-form-action-button>
        <app-form-action-button
          *ngIf="mode === 'edit' && !((jobSavingErrors$ | async) ?.length)"
          action="update"
        ></app-form-action-button>
      </div>
      <ng-container *ngTemplateOutlet="summaryEditTemplate"></ng-container>
    </div>
  </p-card>
</ng-container>

<ng-template #headlessEditSummary>
  <div class="headless">
    <ng-container *ngTemplateOutlet="summaryEditTemplate"></ng-container>
  </div>
</ng-template>

<ng-template #summaryEditTemplate>
  <p-tabView
    *ngIf="jobSummaries && (jobsV2Enabled$ | async)"
    (onChange)="selectSummaryIndex($event.index)"
    [activeIndex]="selectSummaryIndex$ | async"
  >
    <p-tabPanel *ngFor="let summary of jobSummaries; let i = index">
      <ng-template pTemplate="header">
        <div [tabindex]="jobCreateIndexes[summary.key]">
          <span>{{ summary.name }}</span>
        </div>
      </ng-template>
      <div class="description">
        <p>{{ summary.description }}</p>
      </div>
      <quill-editor
        #quillEditor
        format="object"
        [modules]="editModules"
        (onContentChanged)="contentChanged($event, summary.key)"
        (onEditorCreated)="editorCreated($event, summary.key)"
      >
      </quill-editor>
    </p-tabPanel>
  </p-tabView>
</ng-template>
