import { Component, EventEmitter, OnDestroy, Output, ViewChild } from '@angular/core';

import {dayjs} from '@karve.it/core';
import { Store } from '@ngrx/store';
import { QuillEditorComponent, QuillModules } from 'ngx-quill';

import { DropdownChangeEvent } from 'primeng/dropdown';
import Quill from 'quill';
import { Delta } from 'quill/core';

import { filter } from 'rxjs';
import { SubSink } from 'subsink';

import { QuillTemplate, TemplateLocation } from '../../../../../generated/graphql.generated';
import { FreyaCommonModule } from '../../../../freya-common/freya-common.module';
import { DispatchActions } from '../../store/dispatch.actions';
import { DispatchFeature } from '../../store/dispatch.reducer';

import { VARIABLE_BLOT_NAME, VariableBlotData, VariableType } from './TemplateVariable';

@Component({
  selector: 'app-dispatch-send-schedule',
  standalone: true,
  imports: [FreyaCommonModule, QuillEditorComponent],
  templateUrl: './dispatch-send-schedule.component.html',
  styleUrl: './dispatch-send-schedule.component.scss'
})
export class DispatchSendScheduleComponent implements OnDestroy {

  @Output() closeDialog = new EventEmitter<void>();

  @ViewChild('editor', { static: false }) editor: QuillEditorComponent | undefined

  public workingCrew$ = this.store.select(DispatchFeature.selectWorkingCrew);
  public templates$ = this.store.select(DispatchFeature.selectTemplates);
  public templates: QuillTemplate[] = [];

  public selectedWorkingCrew: any = [];
  public selectedTemplate: QuillTemplate | undefined;

  private subs = new SubSink();

  constructor(
    private store: Store
  ) { }

  public editorContents: Delta;
  public editorModules: QuillModules = {
    toolbar: {
      container: '#toolbar',
    },
  };

  public templateVariables: VariableBlotData[] = [
    {
      title: 'Dispatch Date',
      variable: 'events[0].start',
      variableType: VariableType.DATE,
      variableFormat: 'MMM DD YYYY',
      description: 'Dispatch date (9 AM CST, 10 AM EST)'
    },

    {
      title: 'Arrival Time',
      variable: 'events[0].start',
      variableType: VariableType.DATE,
      variableFormat: 'h:mm A',
      description: 'Arrival time (9 AM, 10 AM)'
    },

    {
      title: 'Arrival Location',
      variable: 'events[0].locations[0].location.addressLineOne',
      description: 'Arrival location (1234 Main St, Dallas, TX 75201)'
    },

    // Estimated working time
    {
      title: 'Estimated Working Time',
      variable: 'estWorkingTime',
      description: 'Estimated working time (2 hours, 3 hours)'
    },

    {
      title: 'Timeline with Event Details',
      variable: 'timelineWithEventDetails',
      description: 'Timeline with event details'
    }
  ];

  private setDefaultTemplate() {
    this.subs.sink = this.templates$.pipe(
      filter((templates) => templates.length > 0),
    ).subscribe((templates) => {
      
      try {
        this.templates = templates;

        const defaultTemplate = templates[0];
        const quillDelta = JSON.parse(defaultTemplate.contents);
        this.editor.quillEditor.setContents(quillDelta);

        this.selectedTemplate = defaultTemplate;
      } catch (error) {
        console.error('Failed to parse template contents:', error);
      }
    });
  }

  public onClose() {
    this.closeDialog.emit();
  }

  public onEditorCreated($event: any) {
    this.setDefaultTemplate();
  }

  public onSave() {
    this.store.dispatch(DispatchActions.crewScheduleSent({
      contents: this.editor.quillEditor.getContents(),
      userIds: this.selectedWorkingCrew.map((crew) => crew.user.id)
    }));

    const updateSuccess$ = this.store.select(DispatchFeature.crewScheduleSendLoaded);

    // Subscribe to the success indicator
    this.subs.sink = updateSuccess$.subscribe((success) => {
      if (success) {
        this.operationSuccessfull();
      }
      // If not successful, do nothing and let the user adjust as needed
    });
  }

  onTemplateVariableSelected($event: DropdownChangeEvent) {
    const range = this.editor.quillEditor.getSelection(true);

    const variable: VariableBlotData = $event.value;

    this.editor.quillEditor.insertEmbed(
      //Insert the TemplateMarker in the same range as the cursor is
      range.index,

      //This is the name of the Embed
      VARIABLE_BLOT_NAME,

      //These are the variables to enter
      variable,
    );

    //Add a space after the marker
    this.editor.quillEditor.insertText(range.index + 1, ' ', Quill.sources.USER);

    //Take the cursor to the end of the inserted TemplateMarker
    this.editor.quillEditor.setSelection(range.index + 2, Quill.sources.SILENT);

  }

  public onTemplateSelected($event: DropdownChangeEvent) {
    const template = $event.value;
    try {
      const quillDelta = JSON.parse(template.contents);
      this.editor.quillEditor.setContents(quillDelta);
    } catch (error) {
      console.error('Failed to parse template contents:', error);
    }
  }

  public saveAsTemplate() {
    const quillDelta = this.editor.quillEditor.getContents();
    const contents = JSON.stringify(quillDelta);
    const formattedDate = dayjs().format('D MMM, h:mma');
    this.store.dispatch(DispatchActions.addQuillTemplate({
      input: {
        name: `Template - ${formattedDate}`,
        contents,
        location: TemplateLocation.DispachSchedule,
      }  
      }));

  }

  private operationSuccessfull() {
    this.subs.unsubscribe();
    this.onClose()
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
