/* eslint-disable @typescript-eslint/naming-convention */
import { gql } from 'graphql-tag';

import { ZoneDirection } from './common.gql';


export interface DateTime {
    date: string;
    time: string;
    fullString: string;
    unix: string;
}

export interface ListTagsFilter extends ZoneDirection {
    tagIds?: string[];

    // The IDS of the object to filter
    objectIds?: string[];
    // The IDS of the object to filter
    objectTypes?: string[];

    // Searches for a tag by name and color and ranks them properly.
    search?: string;

    names?: string[];
    color?: string;
    categories_INCLUDES_ANY?: string[];

    /**
     * Whether to list public tags, defaults to true
     */
    showPublic?: boolean;

    /**
     * Whether to list private tags.
     */
    showPrivate?: boolean;

    /**
     * Limits tag owners
     */
    owners?: string[];
    skip?: number;
    limit?: number;
}

export interface TaggedObject {
    id: string;
    taggedPrivately: boolean;
    taggedBy: string;
    order?: number;
}

export interface Tag {
    id: string;
    name: string;
    objectType: string;
    category?: string;
    color?: string;
    private?: boolean;
    attributes: string[];
    order?: number;
    owner?: string;
    createdAt: DateTime;
    updatedAt: DateTime;
    deletedOn?: DateTime;
    objects?: TaggedObject[];
    history;
}

export interface UseObjectTagResult {
    tags: string[];
    objects: string[];
}

export interface ListTagsInput {
    filter?: ListTagsFilter;
    // In format type:asc|desc Available types: name, order
    order?: string;
    showObjects?: boolean;
    objectLimit?: number;
}

export interface ListTagsOutput {
    tags: {
        tags: Tag[];
    };
}

export const listTagsQuery = gql`
query tags($filter: TagFilter){
    tags( filter: $filter ) {
        tags {
            id
            name
            objectType
            category
            color
            private
            attributes
            order
        }
    }
}
`;

export interface TagRelationship{
    id: string;
    taggedPrivately: boolean;
    taggedBy: string;
    order: number;
}

export interface SetTagsRelationship{
    tagId: string;
    private: boolean;
}

export interface EditDialogInput {
    tagId: string;
    tagname: string;
    colour: string;
    attributes: string[];
    isPrivate: boolean;
  }

export const TagTypes = [
    'Appointments',
    'User',
    'Courses',
    'Job',
    // 'Role',
    'Event',
    'Skills',
    // 'Availability',
    // 'Adjustment',
];

export enum TAG_TYPES {
    Appointments = 'Appointments',
    User = 'User',
    Event = 'Event',
    Skills = 'Skills',
    Job = 'Job'
}

export interface CreateTagOutput{
    createTag: TagResult;
}

export const createTag = gql`
    mutation createTag($name: String!, $objectType: String!, $color: String, $private: Boolean!, $owner: String){
        createTag(tags:{name: $name, objectType: $objectType, color: $color }, private:$private, owner: $owner){
            tags{
                name
                id
                objectType
                color
                private
                attributes
                order
                owner
                createdAt {
                    unix
                }
                updatedAt {
                    unix
                }
                deletedOn {
                    unix
                }
            }
            filtered
            returned
        }
    }
`;

export interface AddTagToObjectOutput {
    addTagsToObject: UseObjectTagResult;
}

export interface TagResult {
    tags: Tag[];
    filtered?: number;
    returned?: number;
}

export const addTagToObject = gql`
    mutation addTagsToObject($tagsToUse: [String!]!, $order: Float!, $objectToTag: String!, $private: Boolean!){
        addTagsToObjects(tags:$tagsToUse, order: $order, objects: [$objectToTag], private: $private){
            tags
            objects
        }
    }
`;

export interface FindTagsByTypeOutput {
    tags: TagResult;
}

export const findTagsByType = gql`
    query tags($objectTypes: [String!]){
        tags( filter:{ objectTypes: $objectTypes, showPrivate: true } ){
            tags{
                id
                name
                objectType
                color
                private
                attributes
                order
                owner
                createdAt {
                    unix
                }
                updatedAt {
                    unix
                }
                deletedOn {
                    unix
                }
                objects {
                    id
                }
            }
            filtered
            returned
        }
    }
`;

export interface FindTagsByObjectOutput {
    tags: TagResult;
}

export const findTagsByObject = gql`
    query tags($objectIds: [String!]){
        tags( filter:{ objectIds: $objectIds, showPrivate: true }, showObjects: true ){
            tags{
            id
            name
            objectType
            color
            private
            attributes
            order
            owner
            objects{
                id
                taggedPrivately
                taggedBy
                order
            }
            }
        }
    }
`;

export const findAllTags = gql`
    query listTags($objectType: String){
    listAllTags(objectType: $objectType){
            tags{
            objectType
            id
            category
            name
            color
            attributes
            owner
            private
            }
        }
    }
`;

export interface RemoveTagsFromObjectOutput{
    removeTagsFromObjects: UseObjectTagResult;
}

export const removeTagFromObject = gql`
    mutation removeTagsFromObject($tags: [String!]!, $objects: [String!]!, $objectLabel: String!){
      removeTagsFromObjects(tags: $tags, objects: $objects, objectLabel: $objectLabel){
        tags
        objects
      }
    }
`;

export interface EditTagOutput {
    tags: {
        edit: boolean;
    };
}

export const editTag = gql`
    mutation editTag($tagId: String!, $name: String, $color: String, $attributes: [String!]){
        tags(filter:{tagIds:[$tagId], showPrivate: true}){
            edit(input:{name:$name ,color: $color, attributes: $attributes})
        }
    }
`;

export interface SetObjectTagsOutput{
    tags: {
        setObjectTags: boolean;
    };
}

export const setObjectTags = gql`
    mutation setTag($tags: [TagRelationship]!, $objectId: String!, $objectLabel: String!){
        tags{
            setObjectTags(tags: $tags, objectId:$objectId, objectLabel: $objectLabel)
        }
    }
`;

export const orderTags = gql`
    mutation order($tags: [TagRelationship], $objectId: String){
        tags{
            setObjectTags(tags: $tags, objectId: $objectId, order: true)
        }
    }
`;

export const getMyTags = gql`
    query tags($owner: String!, $objectType: String!){
        tags(filter:{showPrivate: true, showPublic: true, owners:[$owner], objectTypes: [$objectType]}){
            tags{
                id
                name
                objectType
                color
                private
                attributes
                order
            }
        }
    }
`;

export interface DeleteTagsOutput {
    tags: {
        delete: boolean;
    };
}

export const deleteTag = gql`
    mutation deleteTags($tagIds: [String!]){
        tags(filter: {tagIds: $tagIds}){
            delete
        }
    }
`;



