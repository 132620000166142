import { Clipboard } from '@angular/cdk/clipboard';
import { Component, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { clone } from 'lodash';
import { interval } from 'rxjs';
import { SubSink } from 'subsink';

import { FreyaNotificationsService } from '../../services/freya-notifications.service';
import { ResponsiveHelperService } from '../../services/responsive-helper.service';
import { TimezoneHelperService } from '../../services/timezone-helper.service';
import { MS_ONE_SECOND } from '../../time';

@Component({
  selector: 'app-time-since',
  templateUrl: './time-since.component.html',
  styleUrls: ['./time-since.component.scss']
})
export class TimeSinceComponent implements OnInit, OnDestroy {

  @Input() unixTime: number;

  @Input() refreshInterval = MS_ONE_SECOND;

  subs = new SubSink();

  constructor(
    public responsiveHelper: ResponsiveHelperService,
    private notify: FreyaNotificationsService,
    private clipboard: Clipboard,
    private timezoneHelper: TimezoneHelperService,
    private zone: NgZone,
  ) { }

  ngOnInit(): void {
    this.subs.sink = interval(this.refreshInterval)
      .subscribe(() => this.refresh());
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  refresh() {
    // run using angular zones so that we can avoid expression changed after checked errors
    this.zone.run(() => {
      // clone the current unix time so angular detects the change
      this.unixTime = clone(this.unixTime);
    })
  }

  copyTimeToClipboard() {
    const time = this.timezoneHelper.dayjs(this.unixTime * 1000);
    const formatted = time.format('YYYY/MM/DD HH:mm:ss')

    if(this.clipboard.copy(formatted)) {
      this.notify.info('Copied to clipboard', formatted);
    } else {
      this.notify.error('Failed to copy to clipboard', formatted);
    }

  }

}
