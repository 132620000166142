import { createSelector } from '@ngrx/store';


import { Charge, FullJobFragment, Price } from 'graphql.generated';

import { isNil } from 'lodash';
import { ChargeWithKey } from 'src/app/estimates/estimate-breakdown/estimate-breakdown.component';

import { eventTypeInfoMapV2 } from 'src/app/global.constants';
import { isFinalizedInvoice } from 'src/app/invoices/invoices.utils';

import { zonesFeature } from '../../../../app/core/zones/zones.reducer';
import { brandingFeature, createCheckSinglePermissionSelector, createConfigSelector } from '../../../state/branding.store';
import { LoadingState } from '../../../utilities/state.util';
import { calculateJobTotals } from '../../job-calculator';
import { jobToolFeature } from '../../job-tool.reducer';
import { getDiscountFromUpdates, getProductsWithClosestPricesByJobZone } from '../../jobsv2-charges-helpers';
import { getDamagesFromCharges } from '../../jobsv2-helpers';
import { CalendarEventWithLockedAndInvoicedFlag, ChargeWithLockedAndInvoicedFlag } from '../../jobv2-create/jobv2-interfaces';

export const selectCanModifyLockedEvents = createCheckSinglePermissionSelector({
  permission: 'calendarEvents.edit',
  restriction: {
    modifyLockedEvents: true,
  },
});

export const selectLockDate = createConfigSelector('rolling-lock-date.currentDate');

const checkLockedAndInvoiced = (calendarEvent: FullJobFragment['events'][number], canModifyLockedEvents: boolean, lockDate: number) => {

  const isLockDatePassed = calendarEvent?.end && (lockDate > calendarEvent?.end);
  const locked = Boolean(!canModifyLockedEvents && isLockDatePassed);

  const invoiced = Boolean(calendarEvent?.invoices?.some(isFinalizedInvoice));

  return { locked, invoiced };
};

export const selectJobEventsWithLockedAndInvoicedFlag = createSelector(
  jobToolFeature.selectJob,
  selectCanModifyLockedEvents,
  selectLockDate,
  (job, canModifyLockedEvents, lockDate) => {

    const updatedEvents = job?.events?.map(event => {

      const { locked, invoiced } = checkLockedAndInvoiced(event, canModifyLockedEvents, lockDate);

      return {
        ...event,
        locked,
        invoiced
      } as CalendarEventWithLockedAndInvoicedFlag;
    });

    return updatedEvents || [];
  });

export const selectChargesWithLockedAndInvoicedFlag = createSelector(
  jobToolFeature.selectJob,
  selectCanModifyLockedEvents,
  selectLockDate,
  (job, canModifyLockedEvents, lockDate) => {
    const updatedCharges = job?.charges?.map(charge => {

      const { locked, invoiced } = checkLockedAndInvoiced(charge?.calendarEvent, canModifyLockedEvents, lockDate);

      return {
        ...charge,
        calendarEvent: {
          ...charge?.calendarEvent,
          locked,
          invoiced
        }
      } as ChargeWithLockedAndInvoicedFlag;
    });

    return updatedCharges || [];
  }
);

export const selectDamages = createSelector(
  selectChargesWithLockedAndInvoicedFlag,
  (charges) => {
    return getDamagesFromCharges(charges);
  },
);

//all active events, including unsaved
export const selectAllActiveJobEventsIncludingUnsaved = createSelector(
  jobToolFeature.selectChargesUpdates,
  selectJobEventsWithLockedAndInvoicedFlag,
  (chargesUpdates, eventsWithLockedAndInvoicedFlag) => {
    const eventsToAddChanges = chargesUpdates?.filter(
      update => update?.changeType === 'event-added');

    const inactiveEventIds = chargesUpdates
      ?.reduce((ids, update) => {
        if (update.changeType === 'event-cancelled' || update.changeType === 'event-deleted') {
          ids.push(update.eventId);
        }
        return ids;
      }, [] as string[]) || [];
    // Filter out events with status 'cancelled' or 'deleted'
    const activeEvents = eventsWithLockedAndInvoicedFlag.filter(
      event => event.status !== 'cancelled' && event.status !== 'deleted' && !inactiveEventIds.includes(event.id)
    );

    //add unsaved events
    if (eventsToAddChanges?.length) {
      eventsToAddChanges.forEach(c => {
        const event = {
          id: c.eventId,
          charges: [],
          discounts: [],
          invoices: [],
          ...c.eventInput,
        };

        activeEvents.push(event as CalendarEventWithLockedAndInvoicedFlag);
      });
    }

    return activeEvents as CalendarEventWithLockedAndInvoicedFlag[];
  }
);

//apply changes from chargesUpdates

//each charge has calendarEvent field. When we move charges between events, we don't update that calendarEvent,
//only move charge in events array
export const selectJobWithPendingChargesUpdates = createSelector(
  jobToolFeature.selectChargesUpdates,
  jobToolFeature.selectJob,
  jobToolFeature.selectAvailableDiscounts,
  brandingFeature.selectProducts,
  selectJobEventsWithLockedAndInvoicedFlag,
  selectChargesWithLockedAndInvoicedFlag,
  (chargesUpdates, job, availableDiscounts, products,
    eventsWithLockedAndInvoicedFlag, chargesWithLockedAndInvoicedFlag) => {
    if (!job || !chargesUpdates) {
      return job;
    }

    //changes by category
    const chargesForUpdateChanges = chargesUpdates?.filter(
      update => update?.changeType === 'charge-updated' || update?.changeType === 'unsaved-charge-updated'
    );

    const chargesForAddChanges = chargesUpdates?.filter(
      update => update?.changeType === 'product-selected-for-adding' && update?.submitted
    );

    const discountsToAddChanges = chargesUpdates?.filter(
      update => update.changeType === 'discount-added');

    const discountsToRemoveChanges = chargesUpdates?.filter(
      update => update.changeType === 'discount-removed');

    const eventsOrderChangesInPending = chargesUpdates?.filter(
      update => update?.changeType === 'events-reordered');

    const eventsToAddChanges = chargesUpdates?.filter(
      update => update?.changeType === 'event-added');

    const duplicatedEventsChanges = chargesUpdates?.filter(
      update => update?.changeType === 'event-duplicated');

    const inactiveEventIds = chargesUpdates
      ?.reduce((ids, update) => {
        if (update.changeType === 'event-cancelled' || update.changeType === 'event-deleted') {
          ids.push(update.eventId);
        }
        return ids;
      }, [] as string[]) || [];


    // Filter out events with status 'cancelled' or 'deleted'
    const activeEvents = eventsWithLockedAndInvoicedFlag.filter(
      event => event.status !== 'cancelled' && event.status !== 'deleted' && !inactiveEventIds.includes(event.id)
    );

    //add unsaved events
    if (eventsToAddChanges?.length) {
      eventsToAddChanges.forEach(c => {
        const event = {
          id: c.eventId,
          charges: [],
          discounts: [],
          invoices: [],
          ...c.eventInput,
        };

        activeEvents.push(event as CalendarEventWithLockedAndInvoicedFlag);
      });
    }

    //add duplicated events
    if (duplicatedEventsChanges?.length) {
      duplicatedEventsChanges.forEach(c => {
        activeEvents.push(c.eventInput as unknown as CalendarEventWithLockedAndInvoicedFlag);
      });
    }

    //discounts to be added to the job
    const discountsToAdd = discountsToAddChanges?.map(change => {
      const matchingDiscount = availableDiscounts?.find(c => c.id === change?.discountId);

      const discount = getDiscountFromUpdates(matchingDiscount, change, change?.discountInput?.singleUse as boolean);

      return {
        //temporary value for ui calculations
        appliedAt: change?.discountInput?.appliedAt,
        //temporary value for ui calculations
        appliedId: change?.discountInput?.appliedId,
        eventId: change?.eventId,
        discount: {
          ...discount,
          amount: change?.discountInput?.customAmount !== undefined
            ? change?.discountInput?.customAmount
            : discount?.amount,
        },
        discountedAmount: change?.discountInput?.customAmount !== undefined
          ? change?.discountInput?.customAmount
          : discount?.amount,
      }
    })

    //charges to be added to the job
    const chargesForAdd = chargesForAddChanges?.map(change => {
      const matchingProduct = products.find(p => p.id === change?.productId);
      const matchingEvent = activeEvents?.find(e => e.id === change?.eventId);
      const activePrice = matchingProduct?.prices?.find(p => p?.id === change?.priceId);

      return {
        calendarEvent: matchingEvent,
        discounts: [],
        expensesV2: [],
        metadata: {},
        price: activePrice,
        //to display charge name when create custom charge from scratch, not from existing product
        product: matchingProduct || { name: change?.productName },
        order: change?.order,
        //temporary id
        id: change?.chargeId,
        //to distinguish that charge not created yet
        createdAt: null,
        quantity: change?.quantity,
        amount: change?.amount || activePrice?.amount,
        taxes: activePrice?.taxes || [],
      }
    });

    // Add charges and discounts to corresponding events
    const eventsWithAddedCharges = activeEvents.map(event => {
      const chargesForEvent = chargesForAdd.filter(charge => charge.calendarEvent?.id === event.id);
      const discountsForEvent = discountsToAdd?.filter(d => d?.eventId === event?.id);
      return {
        ...event,
        charges: [...(event.charges || []), ...chargesForEvent],
        discounts: [...(event.discounts || []), ...discountsForEvent],
      };
    });

    //filter out changes and discounts to be removed
    const eventsWithFilteredChargesAndDiscounts = eventsWithAddedCharges.map(event => {

      const chargesForEvent = event.charges;

      const discountsForEvent = event.discounts;

      const filteredCharges = chargesForEvent.filter(charge => {
        const matchingChange = chargesForUpdateChanges.find(change => change.chargeId === charge.id);
        return !(matchingChange?.removed);
      });

      const filteredDiscounts = discountsForEvent.filter(discount => {
        const matchingChange = discountsToRemoveChanges.find(
          change => change.discountInput?.appliedId === discount?.appliedId);
        return !matchingChange;
      });

      return {
        ...event,
        charges: filteredCharges,
        discounts: filteredDiscounts,
      };
    });

    // Update charge details (quantity, amount, order) for active events only
    const initialUpdatedCharges = eventsWithFilteredChargesAndDiscounts.flatMap(event =>
      event.charges.map(charge => {
        const matchingChange = chargesForUpdateChanges.find(change => change.chargeId === charge.id);

        // Apply updates from `matchingChange`, falling back to original charge values
        const quantity = matchingChange?.quantity ?? charge.quantity;
        const amount = matchingChange?.amount ?? charge.amount;
        const order = matchingChange?.order ?? charge.order;

        return {
          ...charge,
          quantity,
          amount,
          order,
        };
      })
    );

    // Reorganize charges into the correct events based on eventId from matchingChange
    const eventChargeMap = new Map(eventsWithFilteredChargesAndDiscounts.map(event => [event.id, { ...event, charges: [] }]));

    initialUpdatedCharges.forEach(charge => {
      const matchingChange = chargesForUpdateChanges.find(change => change.chargeId === charge.id);
      const targetEventId = matchingChange?.eventId || charge.calendarEvent?.id;

      const targetEvent = eventChargeMap.get(targetEventId);
      if (targetEvent) {
        targetEvent.charges.push(charge);
      }
    });

    // If there are events order changes, apply them
    if (eventsOrderChangesInPending.length > 0) {
      eventsOrderChangesInPending.forEach(update => {
        const { eventsWithNewOrder } = update;

        eventsWithNewOrder.forEach(({ eventId, newOrder }) => {
          const event = eventChargeMap.get(eventId);
          if (event) {
            const updatedEvent = {
              ...event,
              sequentialOrder: newOrder,
            };
            eventChargeMap.set(eventId, updatedEvent);
          }
        });
      });
    }

    const sortedEvents = Array.from(eventChargeMap.values())
      .sort((a, b) => a.sequentialOrder - b.sequentialOrder);

    const newJob = calculateJobTotals({
      ...job,
      events: sortedEvents as CalendarEventWithLockedAndInvoicedFlag[],
      charges: chargesWithLockedAndInvoicedFlag,
    });

    return newJob;
  }
);


export const selectProductsSelectedForAdding = createSelector(
  jobToolFeature.selectChargesUpdates,
  brandingFeature.selectProducts,
  jobToolFeature.selectJob,
  (chargesUpdates, products, job) => {
    const availableProducts = products || [];

    const productsWithMatchingPrices =
      getProductsWithClosestPricesByJobZone(availableProducts, job?.zone);

    const changesInPending = chargesUpdates?.filter(update =>
      update.submitted === false && update.changeType === 'product-selected-for-adding');

    return productsWithMatchingPrices.map(product => {
      const matchingChange = changesInPending.find(change => change.productId === product.id);
      return {
        ...product,
        quantity: matchingChange ? matchingChange.quantity : 0
      };
    });
  }
);

export const selectProductsSuggestionsForCustomCharges = createSelector(
  jobToolFeature.selectJob,
  brandingFeature.selectProducts,
  (job, products) => {
    return getProductsWithClosestPricesByJobZone(products, job?.zone);
  }
);

export const selectActivePriceForZone = (prices: Partial<Price>[], zoneId?: string) =>
  createSelector(
    zonesFeature.selectCurrentAppZone,
    (currentAppZone) => {
      let zonePrice;
      let effectiveZoneId = zoneId;

      // Filter out deleted prices
      const existingPrices = prices?.filter((p) => !p.deletedAt) || [];
      // Filter out inactive prices
      const activePrices = existingPrices.filter((p) => p.active);

      // If only one active price exists, return it
      if (activePrices.length === 1) {
        return activePrices[0];
      }

      // Find exact zone price if it exists
      if (effectiveZoneId) {
        zonePrice = activePrices.find((ap) => ap.zone?.id === effectiveZoneId);
      }

      if (!effectiveZoneId || !zonePrice) {
        // Default to contexted zone
        effectiveZoneId = currentAppZone?.id;
        // Check for price in contexted zone
        zonePrice = activePrices.find((ap) => ap.zone?.id === effectiveZoneId);
      }

      // If we have an exact zone match then return it
      if (zonePrice) {
        return zonePrice;
      }

      return activePrices[0];
    }
  );

export const selectPriceType = (charge: ChargeWithKey) =>
  createSelector(
    selectActivePriceForZone(charge.product?.prices || []),
    (activePrice) => {
      if (charge.price) {
        return charge.price.priceType;
      };

      if (charge.product && activePrice) {
        return activePrice.priceType;
      };

      return 'fixed';
    }
  );

export const selectUneditedAmount = (charge: ChargeWithKey) =>
  createSelector(
    // Pass product prices if available, otherwise an empty array
    selectActivePriceForZone(charge?.product?.prices || []),
    (activePrice) => {
      if (charge?.amount !== null && charge?.amount !== undefined) {
        return charge?.amount;
      }

      if (charge?.price) {
        return charge?.price?.amount;
      }

      if (charge?.product && activePrice) {
        return activePrice?.amount || 0;
      }

      return 0;
    }
  );

export const selectEditableAmounts = (events: { charges: Partial<Charge>[] }[]) =>
  createSelector(
    (state) => state,
    (state) => {
      const editableAmounts = {};

      events?.forEach(event => {
        event.charges?.forEach((charge) => {
          const amount = selectUneditedAmount(charge)(state as object);
          const priceType = selectPriceType(charge)(state as object);

          editableAmounts[charge.id] = priceType === 'fixed' ? amount / 100 : amount;
        });
      });

      return editableAmounts;
    }
  );

export const selectNumberEventsWithUnsavedChanges = createSelector(
  jobToolFeature.selectChargesUpdates,
  (chargesUpdates) => {
    if (!chargesUpdates) {
      return 0;
    }

    const uniqueEventIds = new Set(chargesUpdates.map(update => update.eventId));
    return uniqueEventIds.size;
  }
);

export const selectEventIdsWithUnsavedChanges = createSelector(
  jobToolFeature.selectChargesUpdates,
  (chargesUpdates) => {
    if (!chargesUpdates) {
      return [];
    }

    const uniqueEventIds = Array.from(
      new Set(chargesUpdates.map(update => update.eventId))
    );

    return uniqueEventIds;
  }
);


export const selectEventIds = createSelector(
  selectJobEventsWithLockedAndInvoicedFlag,
  (events) => {
    const validEventIds: string[] = [];
    const existingEventIds = events.map((e) => e.id);


    for (const event of events) {


      if (!event.locked
        && !event?.invoices?.some(isFinalizedInvoice)
        && event?.type !== eventTypeInfoMapV2?.estimating?.value
        && event?.type !== eventTypeInfoMapV2?.virtualEstimate?.value
      ) {
        validEventIds.push(event.id);
      }
    }


    return {
      validEventIds,
      existingEventIds
    };
  }
);

// event doesn't have any unsaved changes from workorders
export const selectEventById = (eventId: string) =>
  createSelector(jobToolFeature.selectJob, (job) =>
    job?.events?.find(
      (e): e is CalendarEventWithLockedAndInvoicedFlag => e?.id === eventId
    )
  );

export const selectEventInvoice = (eventId: string) =>
  createSelector(jobToolFeature.selectJob, (job) => {
    const event = job?.events?.find(
      (e): e is CalendarEventWithLockedAndInvoicedFlag => e?.id === eventId
    );
    const invoice = event?.invoices?.find(i => isNil(i.voidedAt) && isNil(i.deletedAt));
    return invoice;
  }
  );

export const selectIsInvoiceOutdated = (invoiceId: string) => createSelector(
  selectJobWithPendingChargesUpdates,
  jobToolFeature.selectInvoices,
  (updatedJob, invoices) => {
    const invoice = invoices.find(i => i.id === invoiceId);
    if (!invoice || invoice.deletedAt || invoice.voidedAt) {
      return false;
    }
    if (invoice.artifacts[0]?.attributes?.includes('outdated')) {
      return true;
    }
    return updatedJob?.total !== invoice.job.total;
  }
);

export const selectEventWithCalculatedTotals = (eventId: string) => createSelector(
  selectJobWithPendingChargesUpdates,
  (updatedJob) => {
    return updatedJob?.events?.find(e => e.id === eventId);
  }
);


export const workOrdersSelectors = {
  selectEventIds,
  selectEditableAmounts,
  selectProductsSelectedForAdding,
  selectJobWithPendingChargesUpdates,
  selectJobEventsWithLockedAndInvoicedFlag,
  selectAllActiveJobEventsIncludingUnsaved,
  selectNumberEventsWithUnsavedChanges,
  selectEventIdsWithUnsavedChanges,
  selectEventById,
  selectEventInvoice,
  selectProductsSuggestionsForCustomCharges,
  selectIsInvoiceOutdated,
  selectEventWithCalculatedTotals
};
