
import { Parchment, default as Quill, Bounds } from 'quill';
import { default as Inline } from 'quill/blots/inline';

import { dayjs } from '../../../core/dayjs/dayjs';

import { getQuillFromHtmlElement } from './quill-modules';

// const Inline = Quill.import('blots/inline') as InlineBlot;

export interface TimeBlotClickEvent {
  time: number;
  formattedTime: string;
  node: HTMLElement;
}

export class TimeBlot extends Inline {
  static blotName = 'time';
  static tagName = 'a';
  static className = 'link-look';

  static create(value: number) {
    const node = super.create() as HTMLElement;
    node.setAttribute('data-time', String(value));
    // node.textContent = TimeBlot.formatTimestamp(value);

    node.style.cursor = 'pointer';
    node.style.textDecoration = 'underline';
    // node.style.color = 'blue';
    const formattedTime = this.formatTimestamp(value);

    node.addEventListener('click', () => {
      const quill = getQuillFromHtmlElement(node);
      quill?.emitter.emit('time-click', {
        time: value,
        formattedTime,
        node
      } as TimeBlotClickEvent);
    });

    return node;
  }

  static formats(domNode: HTMLElement) {
    return domNode.getAttribute('data-time');
  }

  format(name: string, value: unknown) {
    if (name !== this.statics.blotName || !value) {
      super.format(name, value);
    } else {
      this.domNode.setAttribute('data-time', String(value));
    //   this.domNode.textContent = TimeBlot.formatTimestamp(Number(value));
    }
  }

  static formatTimestamp(timestamp: number): string {
    // TODO: timezone issue...
    return dayjs(timestamp).format(`YYYY/MM/DD HH:mm:ss`);
  }
}
