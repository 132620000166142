import { Clipboard } from '@angular/cdk/clipboard';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, QueryList, Renderer2, TemplateRef, ViewChildren } from '@angular/core';
import { FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {dayjs} from '@karve.it/core';
import { QueryRef } from 'apollo-angular';
import {
  BaseDynamicReportFragment,
  BaseOptionFragment,
  CreateDynamicReportsExportTokenGQL,
  DataOnlyGQL,
  DynamicReportOptionsGQL,
  DynamicReportsGQL,
  HourFilter,
  PreviewReportFilter,
  PreviewReportGQL,
  PreviewReportQuery,
  PreviewReportQueryVariables,
  Token,
  UpdateDynamicReportGQL,
  ZoneDir,
} from 'graphql.generated';

import { unparse } from 'papaparse';
import { LazyLoadEvent, MenuItem } from 'primeng/api';
import { TableColumnReorderEvent } from 'primeng/table';
import { combineLatest, firstValueFrom, interval, merge, Observable, Subject } from 'rxjs';
import { debounceTime, filter, switchMap, tap } from 'rxjs/operators';
import { SubSink } from 'subsink';

import { AppMainComponent } from '../../app.main.component';
import { PeriodService } from '../../dashboard/period.service';
import { JOB_EVENT_STATUSES, JOB_EVENT_TYPES, JOB_STAGES, JOB_STATES, MAX_32_BIT_INT, pagination } from '../../global.constants';
import { strToTitleCase } from '../../js';
import { periodToString } from '../../reports/report.utils';
import { AdvancedPeriodOptions } from '../../reports/reports.constants';
import { BrandingService } from '../../services/branding.service';
import { DetailsHelperService } from '../../services/details-helper.service';
import { FreyaHelperService } from '../../services/freya-helper.service';
import { FreyaMutateService } from '../../services/freya-mutate.service';
import { FreyaNotificationsService } from '../../services/freya-notifications.service';
import { TimezoneHelperService } from '../../services/timezone-helper.service';
import { Filter } from '../../shared/add-filter/add-filter.component';
import { ParamTypes, QueryParamsService } from '../../shared/query-params.service';
import { TemplateTypeDirective } from '../../shared/template-type.directive';
import { currentTimeSeconds, dateToUnixSeconds } from '../../time';
import { WatchQueryHelper } from '../../utilities/watchQueryHelper';

// Step 1: Define an interface for the form structure
interface ReportParamsFormControls {
  period: FormControl<AdvancedPeriodOptions>;
  customPeriod: FormControl<Date[]>;
  periodType: FormControl<string>;
  jobHasCompletedEventOfType: FormControl<string[]>;
  jobStage: FormControl<string[]>;
  jobState: FormControl<string[]>;
  jobSources: FormControl<string[]>;
  jobOrigins: FormControl<string[]>;
  jobTypes: FormControl<string[]>;
  currency: FormControl<string>;
  eventStatuses: FormControl<string[]>;
  eventSharedStatuses: FormControl<string[]>;
  eventTypes: FormControl<string[]>;
  bookedOnDaysOfWeek: FormControl<number[]>;
  bookedFromHour: FormControl<Date | null>;
  bookedUntilHour: FormControl<Date | null>;
  invoiceStatuses: FormControl<string[]>;
  invoiceBalances: FormControl<string[]>;
  excludeDamages: FormControl<boolean>;
  groupBy: FormControl<string>;
  sort: FormControl<string>;
  dataColumns: FormControl<string[]>;
  aggregationColumns: FormControl<string[]>;
  distanceMin: FormControl<number | null>;
  distanceMax: FormControl<number | null>;
  realizedBy: FormControl<Date>;
}

type ReportFormControlName = keyof ReportParamsFormControls;

export type ReportParamsForm = FormGroup<ReportParamsFormControls>;

@Component({
  selector: 'app-preview-dynamic-report',
  templateUrl: './preview-dynamic-report.component.html',
  styleUrls: ['./preview-dynamic-report.component.scss']
})
export class PreviewDynamicReportComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChildren(TemplateTypeDirective) filterTemplates: QueryList<TemplateTypeDirective>;

  pagination = pagination;

  reportQH: WatchQueryHelper = {
    limit: 10,
    skip: 0,
    loading: true,
    total: 100,
  };

  lastRefresh: number;

  reportQueryRef: QueryRef<PreviewReportQuery, PreviewReportQueryVariables>;

  existingReport: BaseDynamicReportFragment;

  aggregationHeaders: string[];

  aggregationRows: string[][];

  dataHeaders: string[];

  dataRows: string[][];

  breadcrumb: MenuItem[];

  home: MenuItem;

  groupByOpts: BaseOptionFragment[];

  defaultGroupBy = 'area';

  groupBy = this.defaultGroupBy;

  defaultPeriod: AdvancedPeriodOptions = 'This Month';

  defaultSort = 'discountedSubTotal DESC';

  aggregationColumnOptions: BaseOptionFragment[];

  reportParamsForm: ReportParamsForm;

  reportParamsFormDefault: typeof this.reportParamsForm.value;;

  periods = this.periodService.getPeriodNameValues({
    includeCustom: true,
    defaultPeriod: this.defaultPeriod,
  }).filter((p) => ![
    'All Time',
    'Future',
  ].includes(p.name));

  periodTypeOptions: BaseOptionFragment[];

  periodTypeDescriptions = {
    eventStart: 'The date the event was scheduled to start',
    eventBookedAt: 'The date the event was booked',
  };

  lastValidCustomPeriod: Date[];

  dataColumnOptions: { option: string; label: string }[];

  currencyOptions = [ 'CAD', 'USD'] as const;

  jobStages = Object.values(JOB_STAGES).map((s) => ({
    option: s.name,
    label: strToTitleCase(s.name)
  }));

  jobStates = JOB_STATES.map((s) => ({
    option: s,
    label: strToTitleCase(s),
  }));

  eventStatuses = JOB_EVENT_STATUSES.map((s) => ({
    option: s,
    label: strToTitleCase(s),
  }));

  eventSharedStatuses = [
    { option: 'shared', label: 'Shared' },
    { option: 'not-shared', label: 'Not Shared' },
  ];

  eventTypes = JOB_EVENT_TYPES.map((s) => ({
    option: s,
    label: strToTitleCase(s),
  }));

  daysOfTheWeek = [
    { option: 1, label: 'Monday' },
    { option: 2, label: 'Tuesday' },
    { option: 3, label: 'Wednesday' },
    { option: 4, label: 'Thursday' },
    { option: 5, label: 'Friday' },
    { option: 6, label: 'Saturday' },
    { option: 7, label: 'Sunday' },
  ];

  invoiceStatuses = [
    {
      option: 'draft',
      label: 'Draft',
    },
    {
      option: 'finalized',
      label: 'Finalized',
    },
  ];

  invoiceBalances = [
    {
      option: 'paid',
      label: 'Paid',
    },
    {
      option: 'outstanding',
      label: 'Outstanding',
    },
  ];

  subs = new SubSink();

  filters: Filter[];

  controlsWithDefaultFilters: ReportFormControlName[] = [ 'jobState', 'eventStatuses' ];

  chips: {
    ctrlName: ReportFormControlName;
    name: string;
    value: string | string[];
  }[] = [];

  controlDisplayNames: Partial<Record<ReportFormControlName, string>> = {
    jobStage: 'Job Stage',
    jobState: 'Job State',
    jobSources: 'Job Source',
    jobOrigins: 'Job Origin',
    jobTypes: 'Job Type',
    jobHasCompletedEventOfType: 'Has Completed Event of Type',
    eventStatuses: 'Event Status',
    eventSharedStatuses: 'Shared',
    eventTypes: 'Event Type',
    bookedOnDaysOfWeek: 'Booked On Day of The Week',
    bookedFromHour: 'Booked No Earlier Than',
    bookedUntilHour: 'Booked No Later Than',
    invoiceStatuses: 'Invoice Status',
    invoiceBalances: 'Invoice Balance',
    excludeDamages: 'Exclude Damages',
    currency: 'Currency',
    distanceMin: 'Distance Min',
    distanceMax: 'Distance Max',
    realizedBy: 'Realized By',
  };

  dataActions: MenuItem[] = [];

  existingReportActions: MenuItem[] = [
    {
      label: 'Save As',
      icon: 'pi pi-save',
      command: () => this.openCreateDynamicReportDialog(),
    },
  ];

  actionsLoading = false;

  skipAggregations = false;

  exportDialogOpen = false;

  exportFormula: string;

  exportSettingsForm = new FormGroup({
    includeSummary: new FormControl(true),
    includeData: new FormControl(true),
  }, { validators: this.atLeastOneTrue });

  exportTokenRefreshing = false;

  refreshEnabled = false;

  refreshEnabledMinSecs = 60;

  reset$ = new Subject<void>();

  hasChanges = false;

  saving = false;

  groupByWithDuplicateRevenue: Record<string, boolean> = {
    crewMember: true
  };

  constructor(
    private previewReportGQL: PreviewReportGQL,
    private dataOnlyGQL: DataOnlyGQL,
    private periodService: PeriodService,
    private clipboard: Clipboard,
    private localNotify: FreyaNotificationsService,
    private brandingService: BrandingService,
    private appMain: AppMainComponent,
    private queryParams: QueryParamsService,
    private router: Router,
    private route: ActivatedRoute,
    public freyaHelper: FreyaHelperService,
    private freyaMutate: FreyaMutateService,
    private dynamicReportsGQL: DynamicReportsGQL,
    private detailsHelper: DetailsHelperService,
    private dynamicReportOptionsGQL: DynamicReportOptionsGQL,
    private timezoneHelper: TimezoneHelperService,
    private createDynamicReportExportTokenGQL: CreateDynamicReportsExportTokenGQL,
    private updateDyanmicReportGQL: UpdateDynamicReportGQL,
    private el: ElementRef,
    private renderer: Renderer2,
  ) { }

  ngOnInit(): void {
    this.startTimer();
    this.fetchOptions().then(() => {
      this.initForm();
      this.watchPeriodChanges();
      this.watchCustomPeriodchanges();
      this.syncGroupByValueToSortByOptions();
      this.refreshOnFilterChanges();
      this.syncFilterChipsToForm();
      this.syncFilterToQueryParams();
      this.loadNewlyCreatedReports();
      this.setDataActions();
      this.resetSortOnColumnChanges();
    });
    this.setBreadcrumb();

    this.subs.sink = this.exportSettingsForm.valueChanges
      .pipe(
        filter(() => this.exportDialogOpen && this.exportSettingsForm.valid),
        debounceTime(400),
      )
      .subscribe(() => {
        this.refreshExportToken();
      });

  }

  ngAfterViewInit(): void {
    this.filterTemplates.changes
      .subscribe(() => {
        const templates = this.filterTemplates.toArray();
        const jobState = templates.find((t) => t.appTemplateType === 'jobState').templateRef;
        const jobStage = templates.find((t) => t.appTemplateType === 'jobStage').templateRef;
        const jobSources = templates.find((t) => t.appTemplateType === 'jobSources').templateRef;
        const jobOrigins = templates.find((t) => t.appTemplateType === 'jobOrigins').templateRef;
        const jobTypes = templates.find((t) => t.appTemplateType === 'jobTypes').templateRef;
        const eventStatus = templates.find((t) => t.appTemplateType === 'eventStatus').templateRef;
        const eventType = templates.find((t) => t.appTemplateType === 'eventType').templateRef;
        const eventSharedStatus = templates.find((t) => t.appTemplateType === 'eventSharedStatus').templateRef;
        const invoiceStatus = templates.find((t) => t.appTemplateType === 'invoiceStatus').templateRef;
        const invoiceBalance = templates.find((t) => t.appTemplateType === 'invoiceBalance').templateRef;
        const bookedOnDaysOfWeek = templates.find((t) => t.appTemplateType === 'bookedOnDaysOfWeek').templateRef;
        const bookedFromHour = templates.find((t) => t.appTemplateType === 'bookedFromHour').templateRef;
        const bookedUntilHour = templates.find((t) => t.appTemplateType === 'bookedUntilHour').templateRef;
        const currency = templates.find((t) => t.appTemplateType === 'currency').templateRef;
        const jobHasCompletedEventOfType = templates.find((t) => t.appTemplateType === 'jobHasCompletedEventOfType').templateRef;
        const distanceMin = templates.find((t) => t.appTemplateType === 'distanceMin').templateRef;
        const distanceMax = templates.find((t) => t.appTemplateType === 'distanceMax').templateRef;
        const realizedBy = templates.find((t) => t.appTemplateType === 'realizedBy').templateRef;
        const excludeDamages = templates.find((t) => t.appTemplateType === 'excludeDamages').templateRef;

        this.setFilters({
          jobState,
          jobStage,
          jobSources,
          jobOrigins,
          jobTypes,
          eventStatus,
          eventType,
          eventSharedStatus,
          invoiceStatus,
          invoiceBalance,
          bookedOnDaysOfWeek,
          bookedFromHour,
          bookedUntilHour,
          currency,
          jobHasCompletedEventOfType,
          distanceMin,
          distanceMax,
          realizedBy,
          excludeDamages,
        });
        this.appMain.cd.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  retrieveMoreRows(event: LazyLoadEvent) {
    this.reportQH.limit = event.rows;
    this.reportQH.skip = event.first;

    this.previewReport(event.first > 0);
  }

  fetchOptions(): Promise<void> {
    const obs = this.dynamicReportOptionsGQL.fetch({ groupBy: this.defaultGroupBy })
    return firstValueFrom(obs).then((val) => {
      this.groupByOpts = val.data.groupByOptions;
      this.periodTypeOptions = val.data.periodTypeOptions;
      this.aggregationColumnOptions = val.data.aggregationColumns;
      this.dataColumnOptions = val.data.dataColumns;
    });
  }

  initForm() {
    this.reportParamsForm = new FormGroup({
      period: new FormControl(this.defaultPeriod),
      currency: new FormControl(undefined),
      customPeriod: new FormControl({
        value: this.periodService.getPeriodAsDateArray(this.defaultPeriod),
        disabled: true,
      }),
      periodType: new FormControl('eventEnd'),
      jobStage: new FormControl([ ]),
      jobState: new FormControl([ 'open', 'closed', 'archived' ]),
      jobSources: new FormControl([ ]),
      jobOrigins: new FormControl([ ]),
      jobTypes: new FormControl([ ]),
      jobHasCompletedEventOfType: new FormControl([]),
      eventStatuses: new FormControl([ 'required', 'pending', 'booked', 'confirmed', 'completed' ]),
      eventSharedStatuses: new FormControl([]),
      eventTypes: new FormControl([ ]),
      bookedOnDaysOfWeek: new FormControl([ ]),
      bookedFromHour: new FormControl(null),
      bookedUntilHour: new FormControl(null),
      invoiceStatuses: new FormControl([ ]),
      invoiceBalances: new FormControl([ ]),
      excludeDamages: new FormControl(false),
      groupBy: new FormControl(this.defaultGroupBy),
      sort: new FormControl(this.defaultSort),
      dataColumns: new FormControl(this.getDefaultColumns(this.defaultGroupBy)),
      aggregationColumns: new FormControl(this.aggregationColumnOptions.map((o) => o.option)),
      distanceMin: new FormControl(null),
      distanceMax: new FormControl(null),
      realizedBy: new FormControl(undefined),
    });

    this.reportParamsFormDefault = this.reportParamsForm.value;

    this.lastValidCustomPeriod = this.reportParamsForm.value.customPeriod;
  }

  previewReport(skipAggregations = false) {

    this.skipAggregations = skipAggregations;

    if (this.reportQueryRef) {
      this.reportQueryRef.refetch(this.getQueryVariables());
      return;
    }

    this.reportQueryRef = this.previewReportGQL.watch(this.getQueryVariables(), {
      notifyOnNetworkStatusChange: true,
    });;

    this.subs.sink = this.reportQueryRef.valueChanges.subscribe((val) => {
      this.reportQH.loading = val.loading;

      if (val.loading) { return; }

      if (!val.data) { return; }

      if (!this.skipAggregations) {
        this.aggregationHeaders = val.data.previewReport.aggregationData.headers;
        this.aggregationRows = val.data.previewReport.aggregationData.rows;
        this.reportQH.total = val.data.previewReport.total;
      }

      this.dataHeaders = val.data.previewReport.data.headers;

      this.dataRows = val.data.previewReport.data.rows;

      this.dataColumnOptions = val.data.dataColumns;

      this.groupBy = val.data.previewReport.groupBy;

      this.lastRefresh = currentTimeSeconds();

      this.restartTimer();
    }, (err) => {
      this.localNotify.apolloError('Failed to preview report', err);
      this.reportQH.loading = false;
    });
  }

  getQueryVariables(): PreviewReportQueryVariables {

    const {
      groupBy,
      sort,
      period,
      periodType,
      jobStage,
      jobState,
      jobSources,
      jobOrigins,
      jobTypes,
      jobHasCompletedEventOfType,
      eventStatuses,
      eventSharedStatuses,
      eventTypes,
      bookedOnDaysOfWeek,
      bookedFromHour,
      bookedUntilHour,
      dataColumns,
      aggregationColumns,
      invoiceStatuses,
      invoiceBalances,
      currency,
      distanceMin,
      distanceMax,
      realizedBy,
      excludeDamages,
    } = this.reportParamsForm.getRawValue();

    let bookedFromHourFilter: HourFilter;
    let bookedUntilHourFilter: HourFilter;

    if (bookedFromHour) {
      bookedFromHourFilter = {
        hour: bookedFromHour.getHours(),
        minute: bookedFromHour.getMinutes(),
      };
    }

    if (bookedUntilHour) {
      bookedUntilHourFilter = {
        hour: bookedUntilHour.getHours(),
        minute: bookedUntilHour.getMinutes(),
      };
    }

    let { customPeriod } = this.reportParamsForm.getRawValue();

    if (!customPeriod) {
      customPeriod = this.periodService.getPeriodAsDateArray(period);
    }

    const [ startDate, endDate ] = customPeriod;

    const queryVars: PreviewReportQueryVariables = {
      skip: this.reportQH.skip,
      limit: this.reportQH.limit,
      periodType,
      groupBy,
      sort,
      dataColumns,
      aggregationColumns,
      start: dateToUnixSeconds(startDate),
      end: Math.min(dateToUnixSeconds(endDate), MAX_32_BIT_INT),
      filter: {
        job: {
          getJobsOfStage: jobStage,
          getJobsOfState: jobState,
          currency,
          hasCompletedEventOfType: jobHasCompletedEventOfType,
          sources: jobSources,
          origins: jobOrigins,
          types: jobTypes,
        },
        event: {
          getEventsOfStatus: eventStatuses,
          getEventsOfType: eventTypes,
          getEventsOfSharedStatus: eventSharedStatuses,
          bookedOnDaysOfWeek,
          bookedFromHour: bookedFromHourFilter,
          bookedUntilHour: bookedUntilHourFilter,
          distanceMin,
          distanceMax,
        },
        invoice: {
          getInvoiceOfStatus: invoiceStatuses,
          balance: invoiceBalances,
        },
        charge: {
          excludeDamages,
        }
      },
      columnParams: {
        realizedBy: realizedBy ? dateToUnixSeconds(realizedBy) : null,
      },
      performance: this.skipAggregations ? 'skip-count' : 'all',
    };

    return queryVars;
  }

  watchPeriodChanges() {
    this.subs.sink = this.reportParamsForm.controls.period.valueChanges
      .subscribe((period: AdvancedPeriodOptions) => this.setCustomPeriodEnabled(period));
  }

  setCustomPeriodEnabled(period: AdvancedPeriodOptions) {
    if (period === 'Custom') {
      this.reportParamsForm.controls.customPeriod.enable();
    } else {
      this.reportParamsForm.controls.customPeriod.disable({ emitEvent: false });
      this.reportParamsForm.controls.customPeriod.setValue(this.periodService.getPeriodAsDateArray(period), { emitEvent: false });
      this.refreshReport();
    }
  }

  watchCustomPeriodchanges() {
    this.subs.sink = this.reportParamsForm.controls.customPeriod.valueChanges
    .pipe(debounceTime(200))
    .subscribe((period: Date[]) => {

      period = period.filter(Boolean);

      if (period.length < 2) { return; }

      this.lastValidCustomPeriod = period;

      this.refreshReport();
    });
  }

  resetCustomPeriod() {
    const customPeriodCtrl = this.reportParamsForm.get('customPeriod');
    const customPeriod = customPeriodCtrl.value.filter(Boolean);

    if (customPeriod.length < 2) {
      customPeriodCtrl.setValue(this.lastValidCustomPeriod, { emitEvent: false });
    }
  }

  syncGroupByValueToSortByOptions() {

    const sortCtrl = this.reportParamsForm.get('sort');
    const columnsCtrl = this.reportParamsForm.get('dataColumns');

    this.subs.sink = this.reportParamsForm.controls.groupBy.valueChanges.subscribe((groupBy) => {
      sortCtrl.setValue(this.defaultSort, { emitEvent: false });
      columnsCtrl.setValue(this.getDefaultColumns(groupBy), { emitEvent: false });
    });
  }

  refreshOnFilterChanges() {

    const ctrlDebounceTimeMap: Partial<Record<ReportFormControlName, number>> = {
      groupBy: 0,
      dataColumns: 750,
      aggregationColumns: 750,
      periodType: 0,
      sort: 0,
      jobState: 750,
      jobStage: 750,
      jobSources: 0,
      jobOrigins: 0,
      jobTypes: 0,
      jobHasCompletedEventOfType: 750,
      eventStatuses: 750,
      eventTypes: 750,
      eventSharedStatuses: 750,
      bookedOnDaysOfWeek: 750,
      bookedFromHour: 750,
      bookedUntilHour: 750,
      distanceMin: 2500,
      distanceMax: 2500,
      invoiceStatuses: 750,
      invoiceBalances: 750,
      currency: 750,
      realizedBy: 750,
      excludeDamages: 0,
    };

    const changesToWatch: Observable<any>[] = [];

    for (const ctrlName of Object.keys(ctrlDebounceTimeMap)) {
      if (ctrlDebounceTimeMap[ctrlName] > 0) {
        changesToWatch.push(this.reportParamsForm.get(ctrlName).valueChanges.pipe(debounceTime(ctrlDebounceTimeMap[ctrlName])));
      } else {
        changesToWatch.push(this.reportParamsForm.get(ctrlName).valueChanges);
      }
    }

    this.subs.sink = merge(...changesToWatch)
      .pipe(debounceTime(200))
      .subscribe(() => this.refreshReport());
  }

  refreshReport() {
    const { dataColumns } = this.getQueryVariables();
    if (!dataColumns?.length) { return; }
    this.reportQH.skip = 0;
    this.reportQH.limit = 10;
    this.previewReport();
  }

  setFilters(input: {
    jobStage: TemplateRef<any>;
    jobState: TemplateRef<any>;
    jobSources: TemplateRef<any>;
    jobOrigins: TemplateRef<any>;
    jobTypes: TemplateRef<any>;
    eventStatus: TemplateRef<any>;
    eventType: TemplateRef<any>;
    eventSharedStatus: TemplateRef<any>;
    invoiceStatus: TemplateRef<any>;
    invoiceBalance: TemplateRef<any>;
    bookedOnDaysOfWeek: TemplateRef<any>;
    bookedFromHour: TemplateRef<any>;
    bookedUntilHour: TemplateRef<any>;
    currency: TemplateRef<any>;
    jobHasCompletedEventOfType: TemplateRef<any>;
    distanceMin: TemplateRef<any>;
    distanceMax: TemplateRef<any>;
    realizedBy: TemplateRef<any>;
    excludeDamages: TemplateRef<any>;
  }) {

    const {
      jobStage,
      jobState,
      jobSources,
      jobOrigins,
      jobTypes,
      eventStatus,
      eventType,
      eventSharedStatus,
      invoiceStatus,
      invoiceBalance,
      bookedOnDaysOfWeek,
      bookedFromHour,
      bookedUntilHour,
      currency,
      jobHasCompletedEventOfType,
      distanceMin,
      distanceMax,
      realizedBy,
      excludeDamages,
    } = input;

    this.filters = [
      {
        label: 'Job',
        items: [
          {
            label: 'Stage',
            filter: jobStage,
          },
          {
            label: 'State',
            filter: jobState,
          },
          {
            label: 'Has Completed Event of Type',
            filter: jobHasCompletedEventOfType,
          },
          {
            label: 'Currency',
            filter: currency,
          },
          {
            label: 'Source',
            filter: jobSources,
          },
          {
            label: 'Origin',
            filter: jobOrigins,
          },
          {
            label: 'Type',
            filter: jobTypes,
          }
        ],
      },
      {
        label: 'Event',
        items: [
          {
            label: 'Status',
            filter: eventStatus,
          },
          {
            label: 'Type',
            filter: eventType,
          },
          {
            label: 'Shared',
            filter: eventSharedStatus,
          },
          {
            label: 'Booked On Day of The Week',
            filter: bookedOnDaysOfWeek,
          },
          {
            label: 'Booked No Earlier Than',
            filter: bookedFromHour,
          },
          {
            label: 'Booked No Later Than',
            filter: bookedUntilHour,
          },
          {
            label: 'Distance Min',
            filter: distanceMin,
          },
          {
            label: 'Distance Max',
            filter: distanceMax,
          },
        ],
      },
      {
        label: 'Invoice',
        items: [
          {
            label: 'Status',
            filter: invoiceStatus,
          },
          {
            label: 'Balance',
            filter: invoiceBalance,
          }
        ],
      },
      {
        label: 'Revenue',
        items: [
          {
            label: 'Realized By',
            filter: realizedBy,
          },
          {
            label: 'Exclude Damages',
            filter: excludeDamages,
          }
        ],
      },
    ];
  }

  syncFilterChipsToForm() {
    this.subs.sink = this.reportParamsForm.valueChanges.subscribe(() => {
      this.setFilterChips();
    });
  }

  setFilterChips() {

    const checkedCtrlNames: string[] = [];

    const updatedChips: typeof this.chips = [];

    for (const chip of this.chips) {

      checkedCtrlNames.push(chip.ctrlName);

      const value = this.reportParamsForm.get(chip.ctrlName).value;

      if (this.isDefault(chip.ctrlName, value)) { continue; }

      const formattedValue = this.formatValueForChips(chip.ctrlName, value);

      updatedChips.push({
        ...chip,
        value: formattedValue as (string | string[]),
      });
    }

    const ctrlNames: ReportFormControlName[] = [
      'jobStage',
      'jobState',
      'jobOrigins',
      'jobSources',
      'jobTypes',
      'jobHasCompletedEventOfType',
      'eventStatuses',
      'eventTypes',
      'eventSharedStatuses',
      'bookedOnDaysOfWeek',
      'bookedFromHour',
      'bookedUntilHour',
      'invoiceStatuses',
      'invoiceBalances',
      'currency',
      'distanceMin',
      'distanceMax',
      'realizedBy',
      'excludeDamages',
    ];

    for (const ctrlName of ctrlNames) {

      if (checkedCtrlNames.includes(ctrlName)) { continue; }

      const value = this.reportParamsForm.get(ctrlName).value;

      const formattedValue = this.formatValueForChips(ctrlName, value);

      if (!this.isDefault(ctrlName, value)) {
        updatedChips.push({
          ctrlName,
          name: this.controlDisplayNames[ctrlName],
          value: formattedValue as (string | string[]),
        });
      }
    }

    this.chips = updatedChips;
  }

  isDefault(controlName: ReportFormControlName, value: any) {

    const defaultValue = this.reportParamsFormDefault[controlName];

    if (Array.isArray(defaultValue)) {
      return Array.isArray(value) && (value.length === defaultValue.length && value.every((v) => (defaultValue as any[]).includes(v)));
    }

    if (typeof defaultValue === 'boolean') {
      return value === defaultValue;
    }

    return value === null || value === undefined || value === '';
  }

  resetFilter(ctrlName: ReportFormControlName) {
    this.reportParamsForm.get(ctrlName).setValue(this.reportParamsFormDefault[ctrlName], { emitEvent: false });
    this.updateParams();
    this.refreshReport();
  }

  downloadAsCsv() {
    this.actionsLoading = true;

    const currentQueryVars = this.getQueryVariables();

    this.previewReportGQL.fetch({
      ...currentQueryVars,
      linkFormat: 'sheets',
      skip: 0,
      limit: -1,
    }).subscribe((val) => {

      this.actionsLoading = false;

      const aggregationsCSV = unparse([
        val.data.previewReport.aggregationData.headers,
        ...val.data.previewReport.aggregationData.rows,
      ]);

      const rowsCSV = unparse([
        val.data.previewReport.data.headers,
        ...val.data.previewReport.data.rows,
      ]);

      const mergedCSVData = aggregationsCSV + '\n' + rowsCSV;

      const blob = new Blob([ mergedCSVData ], { type: 'text/csv' });

      const link = document.createElement('a');

      link.href = window.URL.createObjectURL(blob);

      link.download = this.getFileName();

      document.body.appendChild(link);
      link.click();

      // Clean up
      document.body.removeChild(link);
      window.URL.revokeObjectURL(link.href);
    });
  }

  async openExportDialog() {
    if (!this.existingReport) {
      this.localNotify.error('Please save the report before exporting');
      return;
    }
    this.actionsLoading = true;
    await this.refreshExportToken();
    this.actionsLoading = false;
    this.exportDialogOpen = true;
  }

  refreshExportToken() {
    this.exportTokenRefreshing = true;
    const obs = this.createDynamicReportExportTokenGQL.mutate({
      dynamicReportId: this.existingReport.id,
      performance: this.getExportTokenPerformance(),
    })
      .pipe(tap((res) => {
        this.exportTokenRefreshing = false;
        this.exportFormula = this.getFormula(res.data.createDynamicReportExportToken);
      }));
    return firstValueFrom(obs);
  }

  getExportTokenPerformance() {
    if (this.exportSettingsForm.value.includeSummary && this.exportSettingsForm.value.includeData) {
      return 'all';
    }
    if (this.exportSettingsForm.value.includeSummary) {
      return 'skip-data';
    }
    if (this.exportSettingsForm.value.includeData) {
      return 'skip-count';
    }
  }

  getFormula(token: Token) {
    const url = new URL(`${ location.protocol }//${ location.hostname }`);
    url.pathname = `/api/export/${ token.token }`;
    return `=IMPORTDATA("${ url.toString() }")`;
  }

  getFileName() {
    const [ start, end ] = this.reportParamsForm.value.customPeriod|| [];

    const formattedPeriod = periodToString(this.reportParamsForm.value.period, { start, end });

    const zone = this.brandingService.currentZone().value?.name || 'current zone';
    const time = dayjs().format('YYYY/MM/DD HH:mm');
    return `Revenue by ${this.groupBy} at ${time} [${formattedPeriod}] (${ zone }).csv`;
  }

  copyToClipboard() {
    this.actionsLoading = true;

    const currentQueryVars = this.getQueryVariables();

    this.dataOnlyGQL.fetch({
      ...currentQueryVars,
      linkFormat: 'sheets',
      skip: 0,
      limit: -1,
    }).subscribe((val) => {

      this.actionsLoading = val.loading;

      const rowsCSV = unparse([
        val.data.previewReport.data.headers,
        ...val.data.previewReport.data.rows,
      ], { delimiter: '\t' });

      this.clipboard.copy(rowsCSV);

      this.localNotify.success('Copied to clipboard');
    });
  }

  getDefaultColumns(groupBy: string) {

    const defaultColumns = [
      'subTotal',
      'discountedSubTotal',
      'discountTotal',
      'taxTotal',
      'total',
    ];

    if (groupBy === 'none') {
      return defaultColumns;
    }

    return [
      groupBy,
      ...defaultColumns,
    ];
  }

  syncFilterToQueryParams() {

    combineLatest([
      this.route.queryParams.pipe(filter((params) => 'zone' in params)),
      this.route.paramMap,
    ]).subscribe((val) => {

      const [ params, paramMap ] = val;

      const reportId = paramMap.get('reportId');

      if (reportId) {
        this.fetchExistingReport(reportId);
        return;
      }

      const { zone, ...filterParams } = params;

      this.queryParams.stripEmptyParams(filterParams);

      if (!Object.keys(filterParams).length) { return; }

      const paramTypes: ParamTypes = {
        startEnd: { params: [ 'customPeriod' ] },
        numArr: { params: [ 'bookedOnDaysOfWeek' ] },
        arr: { params: [
          'dataColumns',
          'aggregationColumns',
          'eventStatuses',
          'eventSharedStatuses',
          'eventTypes',
          'jobState',
          'jobStage',
          'jobSources',
          'jobTypes',
          'jobOrigins',
          'jobHasCompletedEventOfType',
        ] },
        hour: { params: [ 'bookedFromHour', 'bookedUntilHour' ] },
        num: { params: [ 'distanceMin', 'distanceMax' ] },
        unix: { params: [ 'realizedBy' ] },
        bool: { params: [ 'excludeDamages' ] },
      }

      const parsedValue = this.queryParams.parseQueryParams(filterParams, paramTypes);

      if (!parsedValue.customPeriod) {
        const period = (parsedValue.period as AdvancedPeriodOptions) || this.defaultPeriod;
        parsedValue.customPeriod = this.periodService.getPeriodAsDateArray(period);
      }

      /**
       * In the url, we use 'any' to represent an empty array filter,
       * but here it's been parsed as an array with a single 'any' string.
       * We need to convert it back to an empty array.
       */
      for (const ctrlName of this.controlsWithDefaultFilters) {
        const val = parsedValue[ctrlName];
        if (Array.isArray(val) && val.includes('any')) {
          parsedValue[ctrlName] = [];
        }
      }

      parsedValue.dataColumns = parsedValue.dataColumns || this.getDefaultColumns(parsedValue.groupBy);

      const value = {
        ...this.reportParamsFormDefault,
        ...parsedValue,
      }

      this.reportParamsForm.setValue(value as any, { emitEvent: false });

      if (value.period === 'Custom') {
        this.reportParamsForm.get('customPeriod').enable({ emitEvent: false });
      }

      this.setFilterChips();

      this.refreshReport();
    });

    this.subs.sink = this.reportParamsForm.valueChanges.subscribe((value) => {
      this.updateParams(value);
    });

  }

  updateParams(formValue?: typeof this.reportParamsForm.value) {

    if (!formValue) {
      formValue = this.reportParamsForm.value;
    }

    const isCustomPeriodInvalid = formValue.customPeriod && formValue.customPeriod.filter(Boolean).length < 2;

    if (isCustomPeriodInvalid) { return; }

    const formattedValue = {
      ...formValue,
      bookedFromHour: this.formatTime(formValue.bookedFromHour) ? encodeURIComponent(this.formatTime(formValue.bookedFromHour)) : null,
      bookedUntilHour: this.formatTime(formValue.bookedUntilHour) ? encodeURIComponent(this.formatTime(formValue.bookedUntilHour)) : null,
      realizedBy: formValue.realizedBy ? dateToUnixSeconds(formValue.realizedBy) : null,
    }

    const customControls = {
      dateRange: { controls: [ 'customPeriod' ] }
    };

    const queryParams = this.queryParams.formatSearchFormValues(formattedValue, customControls);

    this.setHasSavedChanges(true);

    if (this.existingReport) { return; }

    /**
     * If a control with a default filter was set to an empty array,* it will be stripped from the query params.
     * We need to add it back and set it to 'any' so the component knows to treat it as an empty filter.
     * Otherwise, it will apply the default filter.
     */
    for (const ctrlName of this.controlsWithDefaultFilters) {
      if (ctrlName in queryParams) { continue; }
      queryParams[ctrlName] = 'any';
    }

    const searchParams = new URLSearchParams(queryParams as any).toString();

    const newUrl = `${location.pathname}?${searchParams}`;

    history.replaceState(null, '', newUrl);
  }

  updateReport() {

    const queryVariables = this.getQueryVariables();

    const isCustomPeriod = this.reportParamsForm.value.period === 'Custom';

    this.saving = true;

    this.updateDyanmicReportGQL.mutate({
      reportId: this.existingReport.id,
      parameters: {
        start: isCustomPeriod ? queryVariables.start : undefined,
        end: isCustomPeriod ? queryVariables.end : undefined,
        groupBy: queryVariables.groupBy,
        period: this.reportParamsForm.value.period,
        periodType: queryVariables.periodType,
        filter: queryVariables.filter,
        dataColumns: queryVariables.dataColumns as string[],
        aggregationColumns: queryVariables.aggregationColumns as string[],
        sort: queryVariables.sort,
      }
    }).subscribe(() => {
      this.saving = false;
      this.localNotify.success('Report saved');
      this.setHasSavedChanges(false);
    }, (err) => {
      this.saving = false;
      this.localNotify.apolloError('Failed to save report', err);
    });
  }

  openCreateDynamicReportDialog() {

    const queryVariables = this.getQueryVariables();

    const isCustomPeriod = this.reportParamsForm.value.period === 'Custom';

    this.freyaMutate.openMutateObject({
      mutateType: 'create',
      objectType: 'dynamicReport',
      object: {
        start: isCustomPeriod ? queryVariables.start : undefined,
        end: isCustomPeriod ? queryVariables.end : undefined,
        groupBy: queryVariables.groupBy,
        period: this.reportParamsForm.value.period,
        periodType: queryVariables.periodType,
        filter: queryVariables.filter,
        dataColumns: queryVariables.dataColumns,
        aggregationColumns: queryVariables.aggregationColumns,
        sort: queryVariables.sort,
      },
    });
  }

  setBreadcrumb() {
    this.breadcrumb = [
      { label: 'Reports', routerLink: '/reports', queryParams: { reportType: 'dynamic' } },
      { label: this.existingReport ? this.existingReport.name : 'New Report' },
    ];

    this.home = { icon: 'pi pi-home', routerLink: '/' };
  }

  fetchExistingReport(reportId: string) {
    this.dynamicReportsGQL.fetch({
      skip: 0,
      limit: 1,
      filter: {
        reportIds: [ reportId ],
        zoneDir: ZoneDir.Any,
      },
    }).subscribe((res) => {

      const [ report ] = res.data.dynamicReports.reports;

      if (report) {
        this.loadExistingReport(report);
        return;
      }

      this.localNotify.error('Report not found');
      this.router.navigate(['reports', 'dynamic', 'new']);
    });
  }

  loadNewlyCreatedReports() {
    this.subs.sink = this.detailsHelper.getObjectUpdates('DynamicReport').subscribe((val) => {
      if (val.action === 'create') {
        this.router.navigate(['reports', 'dynamic', val.id]);
      }
    });
  }

  loadExistingReport(report: BaseDynamicReportFragment) {

    let customPeriod: Date[];

    if (report.start && report.end) {
      customPeriod = [new Date(report.start * 1000), new Date(report.end * 1000)];
    } else if (report.period) {
      customPeriod = this.periodService.getPeriodAsDateArray(report.period as AdvancedPeriodOptions);
    } else {
      customPeriod = this.periodService.getPeriodAsDateArray(this.defaultPeriod);
    };

    const filter: PreviewReportFilter = JSON.parse(report.filter);

    const params = {
      period: report.period as AdvancedPeriodOptions,
      periodType: report.periodType,
      groupBy: report.groupBy,
      dataColumns: report.dataColumns,
      aggregationColumns: report.aggregationColumns,
      sort: report.sort,
      jobStage: filter?.job?.getJobsOfStage || [],
      jobState: filter?.job?.getJobsOfState || [],
      jobSources: filter?.job?.sources || [],
      jobOrigins: filter?.job?.origins || [],
      jobTypes: filter?.job?.types || [],
      jobHasCompletedEventOfType: filter?.job?.hasCompletedEventOfType || [],
      eventStatuses: filter?.event?.getEventsOfStatus || [],
      eventSharedStatuses: filter?.event?.getEventsOfSharedStatus || [],
      eventTypes: filter?.event?.getEventsOfType || [],
      bookedOnDaysOfWeek: filter?.event?.bookedOnDaysOfWeek || [],
      bookedFromHour: this.hourFilterToDate(filter?.event?.bookedFromHour),
      bookedUntilHour: this.hourFilterToDate(filter?.event?.bookedUntilHour),
      invoiceStatuses: filter?.invoice?.getInvoiceOfStatus || [],
      invoiceBalances: filter?.invoice?.balance || [],
      excludeDamages: filter?.charge?.excludeDamages || false,
      customPeriod,
      currency: filter?.job?.currency || '',
      distanceMin: filter?.event?.distanceMin || null,
      distanceMax: filter?.event?.distanceMax || null,
      realizedBy: null,
    }

    this.reportParamsForm.setValue(params, { emitEvent: false });

    this.existingReport = report;

    this.setCustomPeriodEnabled(params.period);

    this.setFilterChips();

    this.setBreadcrumb();

    this.refreshReport();

    this.setDataActions();

    this.setHasSavedChanges(false);
  }

  handleColReorder(event: TableColumnReorderEvent, columnType: 'dataColumns' | 'aggregationColumns') {

    const colCtrl = this.reportParamsForm.get(columnType);

    const columns = [ ...colCtrl.value ];

    // Destructure dragIndex and dropIndex from the event
    const { dragIndex, dropIndex } = event;

    // Ensure indices are within bounds
    if (dragIndex >= 0 && dragIndex < columns.length && dropIndex >= 0 && dropIndex < columns.length) {
      // Remove the column from the dragIndex
      const [movedColumn] = columns.splice(dragIndex, 1);
      // Add the column back at the dropIndex
      columns.splice(dropIndex, 0, movedColumn);
    }

    // Update the form control value with the reordered columns
    colCtrl.setValue(columns, { emitEvent: false });

    this.setHasSavedChanges(true);

    this.updateParams();

    this.previewReport();
  }

  formatValueForChips(ctrlName: ReportFormControlName, value: any) {

    if (typeof value === 'boolean') {
      return value ? 'Yes' : 'No';
    }

    if (this.controlsWithDefaultFilters.includes(ctrlName) && !value.length) {
      return 'Any';
    }

    switch  (ctrlName) {
      case 'bookedOnDaysOfWeek':
        return value.map((dow: number) => this.formatDayOfTheWeek(dow));
      case 'bookedFromHour':
      case 'bookedUntilHour':
        return this.formatTime(value);
      case 'distanceMin':
      case 'distanceMax':
        return value ? `${value} m` : null;
      case 'realizedBy':
        return this.timezoneHelper.getDayJsTimeZoneObject(value).format('h:mm a, MMM DD YYYY');
      default:
        return value;
    }
  }

  formatTime(time: Date) {
    if (!time) { return null; }
    const timezone = this.timezoneHelper.getCurrentTimezone();
    return dayjs(time).tz(timezone).format('HH:mm');
  }

  formatDayOfTheWeek(dow: number) {
    const dayOfTheWeek = this.daysOfTheWeek.find((d) => d.option === dow);
    if (!dayOfTheWeek) {
      throw new Error(`Invalid day of the week ${dow}`);
    }
    return dayOfTheWeek.label;
  }

  hourFilterToDate(filter: HourFilter) {
    if (!filter) { return null; }
    return dayjs().hour(filter.hour).minute(filter.minute).toDate();
  }

  openCreateScheduleReportDialog() {
    if (!this.existingReport) {
      this.localNotify.error('Please save the report before exporting');
      return;
    }

    this.freyaMutate.openMutateObject({
      mutateType: 'create',
      objectType: 'ScheduledReport',
      additionalValues: [
        {
          property: 'dynamicReport',
          value: this.existingReport,
        },
      ],
    })

  }

  setDataActions() {
    this.dataActions = [
      {
        label: 'Download as CSV',
        icon: 'pi pi-download',
        command: () => this.downloadAsCsv(),
      },
      {
        label: 'Semi-Live Export to Google Sheets',
        icon: 'pi pi-external-link',
        command: () => this.openExportDialog(),
        disabled: !this.existingReport,
      }
    ];
  }

  startTimer() {
    this.subs.sink = this.reset$.pipe(
      switchMap(() => interval(1000))
    ).subscribe((lastRefreshSecs) => {
      this.setRefreshEnabled(lastRefreshSecs);
    });
  }

  restartTimer() {
    this.reset$.next();
  }

  setRefreshEnabled(lastRefreshSecs: number) {
    this.refreshEnabled = lastRefreshSecs > this.refreshEnabledMinSecs;
  }

  setHasSavedChanges(changes: boolean) {
    this.hasChanges = changes && Boolean(this.existingReport);
  }

  copyFormulaAndCreateNewSheet() {
    if (!this.exportFormula) {
      this.localNotify.error('No formula to copy');
      return;
    }
    this.clipboard.copy(this.exportFormula);
    window.open('https://docs.google.com/spreadsheets/create', '_blank');
  }

  atLeastOneTrue(group: FormGroup): ValidationErrors | null {
    const includeSummary = group.get('includeSummary')?.value;
    const includeData = group.get('includeData')?.value;

    if (includeSummary || includeData) {
      return null;
    }

    return { noSelection: true };
  }

  changeSortBy(sortBy: string) {
    this.reportParamsForm.controls.sort.setValue(sortBy);
  }

  resetSortOnColumnChanges() {

    const defaultSortKey = 'discountedSubTotal';

    this.subs.sink = this.reportParamsForm.valueChanges.subscribe(({ sort, dataColumns }) => {


      const [ key ] = sort.split(' ');

      if (![ ...dataColumns, defaultSortKey ].includes(key)) {
        this.reportParamsForm.controls.sort.setValue(this.defaultSort);
      }
    });
  }

  setReorderIndicatorVisibility(visibility: 'visible' | 'hidden') {
    const reorderIndicatorTags = '.p-datatable-reorder-indicator-up, .p-datatable-reorder-indicator-down';
    const reorderIndicators = this.el.nativeElement.querySelectorAll(reorderIndicatorTags);
    for (const indicator of reorderIndicators) {
      this.renderer.setStyle(indicator, 'visibility', visibility);
    }
  }

}
