import { inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { dayjs } from '@karve.it/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, debounceTime, exhaustMap, filter, map, of, switchMap, withLatestFrom } from 'rxjs';

import { environment } from '../../../environments/environment';

import {
	AssetsGQL,
	AvailabilityGQL,
	ScheduleEventsGQL,
	ZoneDir
} from '../../../generated/graphql.generated';
import { ScheduleEventsActions } from '../../jobsv2/job-state/event-schedule-state/event-schedule.actions';
import { JobToolActions } from '../../jobsv2/job-tool.actions';
import { jobToolFeature } from '../../jobsv2/job-tool.reducer';
import { TimezoneHelperService } from '../../services/timezone-helper.service';

import { MS_ONE_SECOND } from '../../time';

import { parseGraphqlErrors } from '../../utilities/errors.util';

import { EventStatuses } from '../dispatch/store/dispatch.reducer';

import { ScheduleActions } from './schedule.actions';
import { scheduleFeature } from './schedules.reducer';

export const onComponentLoad = createEffect(
  (
    actions$ = inject(Actions),
    route = inject(ActivatedRoute),
    store = inject(Store),

    // temporary until we bring zone / timezone / auth info into global state
    timezoneHelper = inject(TimezoneHelperService)
  ) => {
    return actions$.pipe(
      ofType(ScheduleActions.componentInitialized),
      withLatestFrom(
        store.select(jobToolFeature.selectEventToBook),
      ),
      map(([action, eventToBook]) => {
        const strDateToSet =
          eventToBook?.start * 1000 ||
          action?.date ||
          route.snapshot.queryParamMap.get('date') ||
          sessionStorage.getItem(environment.lskeys.scheduleDate);

        const dateToSet = dayjs.tz(
          strDateToSet || undefined,
          timezoneHelper.currentTimezone
        );

        return ScheduleActions.storeInitialized({
          date: dateToSet.format('YYYY-MM-DD'),
          view: action.view || 'day',
          timezone: timezoneHelper.getCurrentTimezone(),
        });
      })
    );
  },
  { dispatch: true, functional: true }
);

export const timezoneUpdated = createEffect(
  (
    actions$ = inject(Actions),
    route = inject(ActivatedRoute),
    timezoneHelper = inject(TimezoneHelperService)
  ) => {
    return timezoneHelper.timezoneChanged.pipe(
      map((action) => {
        return ScheduleActions.timezoneChanged({
          timezone: timezoneHelper.getCurrentTimezone(),
        });
      })
    );
  },
  { dispatch: true, functional: true }
);

export const loadAssetsEffect = createEffect(
  (
    actions$ = inject(Actions),
    store = inject(Store),
    assetsGQL = inject(AssetsGQL)
  ) => {
    return actions$.pipe(
      ofType(
        ScheduleActions.storeInitialized,
        ScheduleActions.reloadButtonClicked,
        // ScheduleActions.filtersUpdated,
      ),
      switchMap((action) => {
        const noCache = action.type === ScheduleActions.reloadButtonClicked.type;
        return assetsGQL.fetch({
          filter: {
            zoneDir: ZoneDir.Any,
          },
          limit: -1,
        }, {
          fetchPolicy: noCache ? 'network-only' : 'cache-first',
        }).pipe(
          map((res) => {
            return ScheduleActions.assetsLoaded({
              assets: res.data.assets.assets,
            });
          })
        );
      }),
    );
  },
  { dispatch: true, functional: true }
);

export const loadAvailabilityEffect = createEffect(
  () => {
    const store = inject(Store);
    const actions$ = inject(Actions);
    const availabilityGQL = inject(AvailabilityGQL);

    return actions$.pipe(
      ofType(
        ScheduleActions.fcViewChanged,
        ScheduleActions.assetsLoaded,
        // ScheduleActions.reloadButtonClicked,
      ),
      debounceTime(500),
      withLatestFrom(
        store.select(scheduleFeature.selectStart),
        store.select(scheduleFeature.selectEnd),
        store.select(scheduleFeature.selectTimezone),
        store.select(scheduleFeature.selectAssets),
        store.select(jobToolFeature.selectJobZone),
        store.select(jobToolFeature.selectEventToBook),
      ),
      filter(([ action, start, end, assets, timezone ]) => Boolean(start && end && timezone && assets)),
      switchMap(([action, start, end, timezone, assets, zone, eventToBook]) => {
        const assetIds = assets?.map((a) => a?.id) || [];

        const startDate = dayjs
          .tz(start * MS_ONE_SECOND, timezone)
          .format('YYYY-MM-DD');
        const endDate = dayjs
          .tz(end * MS_ONE_SECOND, timezone)
          .format('YYYY-MM-DD');

        const excludeEvents: string[] = [];
        if (eventToBook?.id) {
          excludeEvents.push(eventToBook.id);
        }

        return availabilityGQL
          .fetch(
            {
              startDate,
              endDate,
              objectIds: assetIds,
              excludeEvents,

              // if we dont have a job in the job tool feature
              // this will be undefined so therefore show only your current zone
              zone: zone?.id,
            },
            {
              fetchPolicy: 'network-only',
            }
          )
          .pipe(
            map((response) => {
              const availability = response?.data?.availabilities || [];
              return ScheduleActions.availabilityLoaded({
                availability,
              });
            }),
            catchError((error) => {
              return of(
                ScheduleActions.availabilityLoadError({
                  error: error.message,
                })
              );
            })
          );
      })
    );
  },
  { functional: true, dispatch: true }
);

export const loadEventsEffect = createEffect(
  () => {
    const store = inject(Store);
    const actions$ = inject(Actions);
    const scheduleEventsGQL = inject(ScheduleEventsGQL);
    //   const localNotify = inject(FreyaNotificationsService);

    return actions$.pipe(
      ofType(
        ScheduleActions.fcViewChanged,
        ScheduleEventsActions.bookEventSuccess,
        ScheduleActions.reloadButtonClicked,
      ),
      debounceTime(500),
      withLatestFrom(
        store.select(scheduleFeature.selectStart),
        store.select(scheduleFeature.selectEnd)
      ),
      filter(([action, start, end]) => Boolean(start && end)),
      exhaustMap(([action, start, end]) => {
        return scheduleEventsGQL
          .fetch(
            {
              filter: {
                min: start,
                max: end,
                getEventsOfStatus: ['booked', 'confirmed', 'completed'],
              },
              limit: 2000,
            },
            {
              fetchPolicy: 'network-only',
            }
          )
          .pipe(
            filter((response) => !response.loading),
            switchMap((response) => {
              return of(
                ScheduleActions.eventsLoaded({
                  events: response.data?.calendarEvents?.events,
                })
              );
            }),
            catchError((error) => {
              const calendarEventDataErrors = parseGraphqlErrors(
                error.graphQLErrors || [],
                'calendarEvents'
              );
              return of(
                ScheduleActions.eventsLoadError({
                  error,
                  calendarEventDataErrors,
                })
              );
            })
          );
      })
    );
  },
  { functional: true, dispatch: true }
);

export const onBookingToolDateChanged = createEffect(
  (actions$ = inject(Actions), store = inject(Store)) => {
    return store.select(jobToolFeature.selectSelectedDate).pipe(
      withLatestFrom(store.select(scheduleFeature.selectTimezone)),
      filter(([date, timezone]) => !!date && !!timezone),
      map(([date, timezone]) => {
        // const dayjsDate = dayjs.tz(date, timezone);
        return ScheduleActions.viewSelected({
          date: dayjs(date, 'MM/DD/YYYY').format('YYYY-MM-DD'),
        });
      })
    );
  },
  { dispatch: true, functional: true }
);

export const onMonthViewDateClicked = createEffect(
  (actions$ = inject(Actions),
  store = inject(Store),
) => {


    return actions$.pipe(
      ofType(
        ScheduleActions.fcDateClicked,
      ),
      map((action) => {
        if (action.view === 'dayGridMonth' && action.date) {
          store.dispatch(ScheduleActions.viewSelected({
            date: action.date,
            view: 'day',
          }));
        }
      }),
    );
  },
  { dispatch: false, functional: true }
);
