import { Injectable, OnDestroy } from '@angular/core';
import { PlusAuthenticationService } from '@karve.it/core';

import { isEqual } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { SubSink } from 'subsink';

import { FullPermissionFragment } from '../../generated/graphql.generated';

import { allPermissionsExist, permissionHasRestrictions, resolvePermissionFromArray } from './permissions.util';

export interface WatchPermissionsAndRestrictionsInput {
  permission: string;
  restriction?: any;
}

@Injectable({
  providedIn: 'root'
})
export class PermissionService implements OnDestroy {

  subs = new SubSink();

  /**
   * The global permissions observable.
   * 
   * The system will load all permissions for the user at once
   * in the same query as the "branding" query. Those permissions will then
   * be stored in this service for fast retrieval.
   * 
   * If permissions are loading because the application is just starting or
   * the zone was changed then the value of this will be "undefined" which will
   * result in checks returning false when you may not want to have checked that.
   * 
   * Instead, you may want to use "watchPermissionsLoaded"
   */
  private $permissions = new BehaviorSubject<FullPermissionFragment[] | undefined>(undefined);

  constructor(
    private plusAuth: PlusAuthenticationService,
  ) {

    // Make the network call after waiting for other permissions to be added
    // this.refetchQuery.pipe(debounceTime(300)).subscribe(() => {
    //   this.fetchPermissions();
    // });

    // Reset the Service on logout
   this.subs.sink = this.plusAuth.authState.subscribe((state) => {
      if (state === 'deauthenticated') {
        this.resetService();
      }
    });

  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  resetService() {
    // this.permissionQueryRef = undefined;
    // this.cachedPermissions = [];
    // this.cachedInput = [];
    // this.permissionsChecked = [];
    this.$permissions.next(undefined);
  }

  /**
   * Called by branding service when we have a new copy of the
   * permissions array
   * @param permissions 
   */
  updatePermissions(permissions: FullPermissionFragment[] | undefined) {

    // console.log(permissions);
    this.$permissions.next(permissions);

  }

  public watch() {
    return this.$permissions.pipe(
      /**
       * If we are loading ($permissions value is undefined) then
       * do not fire anything, only resolve truthy permissions
       */
      filter(Boolean),
      distinctUntilChanged(isEqual),
    )
  }

  public watchPermission(
    permission: string
  ) {
    return this.watchPermissions(permission)
      .pipe(map((permissions) => {
        return permissions[0];
      }));
  }

  /**
   * Creates a filtered observable that returns boolean values for the permissions you pass, true if you have them, false if you don't
   *
   * @param permissions The array of permissions to check
   * @param combined If true merges the permissions to give one result for all of them
   * @returns An Observable of type boolean or boolean array
   */
  public watchPermissions(
    permissions: string[] | string,
  ): Observable <boolean[]> {
    return this.watch().pipe(
      map((v) => {
        if (!Array.isArray(permissions)) {
          permissions = [ permissions ];
        }

        const mappedResults = permissions.map((permissionName) => {
          return resolvePermissionFromArray(v, permissionName);
        });

        return mappedResults.map(Boolean);

      }),
      distinctUntilChanged(isEqual),
    );
  }

  public watchAllPermissions(
    permissions: string[] | string,
  ): Observable <boolean> {
    return this.watchPermissions(permissions).pipe(
      map((permissions) => {
        return allPermissionsExist(permissions);
      })
    );

  }

  /**
   * Creates a filtered observable that returns boolean values for the permissions and restrictions that you pass,
   * true if you have them, false if you don't
   *
   * @param inputs An array of permissions and their associated restrictions
   * @returns An Observable of type boolean array
   */
  watchPermissionsAndRestrictions(inputs: WatchPermissionsAndRestrictionsInput[]): Observable<boolean[]> {

    return this.watch().pipe(
      map((v) => {
        const mappedResults = inputs.map((input) => {
          const permission = resolvePermissionFromArray(v, input.permission);
          if (!permission) { return undefined; }
          if (!input.restriction) { return permission; }

          if(!permissionHasRestrictions(permission, input.restriction)) {
            return undefined;
          }

          return permission;
        });

        return mappedResults.map(Boolean);

      }),
      distinctUntilChanged(isEqual),
    );
  };

  getPermissions(permissionNames: string[]) {
    const allPermissions = this.$permissions?.value;
    if (!allPermissions) {
      //  throw new Error(`Unable to check ${ permissionsToCheck } permissions: cachedPermissions is undefined`);
      return false;
    }

    if (!allPermissions?.length) {
      // Nothing to check
      return false;
    }

    const permissions = permissionNames.map((permissionName, index) => {
      return resolvePermissionFromArray(allPermissions, permissionName);
    });

    return permissions;
  }

  getPermission(permission: string): FullPermissionFragment {
    return this.getPermissions([ permission ])[0];
  }

  checkPermissions(
    permissionsToCheck: string[],
    restrictionsToCheck?: any[]
  ): boolean {

    const allPermissions = this.$permissions?.value;
    if (!allPermissions) {
      //  throw new Error(`Unable to check ${ permissionsToCheck } permissions: cachedPermissions is undefined`);
      return false;
    }

    if (!allPermissions?.length) {
      // Nothing to check
      return false;
    }

    const permissions = permissionsToCheck.map((permissionName, index) => {
      const permission = resolvePermissionFromArray(allPermissions, permissionName);
      if (!permission) { return false; }
      if (!restrictionsToCheck) { return true; }

      const requiredPermissionRestrictions = restrictionsToCheck[index];
      if (!requiredPermissionRestrictions) { return true; }
      
      return permissionHasRestrictions(permission, requiredPermissionRestrictions);
    });

    return allPermissionsExist(permissions);
  }

  checkPermission(permission: string, restrictions?: any) {
    return this.checkPermissions([ permission ], restrictions ? [ restrictions ] : undefined);
  }

}
