import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { Store } from '@ngrx/store';
import { of, switchMap, tap, withLatestFrom } from 'rxjs';

import { baseEnvironment } from '../../../../environments/environment.base';
import { WorkOrdersActions } from '../../../jobsv2/job-state/workorders-state/workorders.actions';
import { JobToolActions } from '../../../jobsv2/job-tool.actions';

import { quickAccessFeature } from './quick-access-reducers';
import { QuickAccessActions } from './quick-access.actions';
import { storeQuickAccessState } from './quick-access.util';

export const loadQuickAccessItems = createEffect(() => {
	const actions$ = inject(Actions);
	const store = inject(Store);

	return actions$.pipe(
		ofType(QuickAccessActions.initialized, WorkOrdersActions.workOrderUnsavedChangesDumpedToLS),
		withLatestFrom(
			store.select(quickAccessFeature.selectPinnedJobs),
			store.select(quickAccessFeature.selectRecentJobs),
			store.select(quickAccessFeature.selectTodaysJobs),
		),
		switchMap(([ action, pinnedJobs, recentJobs, todaysJobs ]) => {

			const unsavedChanges = localStorage.getItem(baseEnvironment.lskeys.unsavedChanges);
			let jobsWithUnsavedChanges: string[] = [];

			if (unsavedChanges) {
				const unsavedChangesObj = JSON.parse(unsavedChanges);
				jobsWithUnsavedChanges = Object.keys(unsavedChangesObj);
			}


			// now load em & store it


			return of(QuickAccessActions.loaded({
				pinnedJobs,
				recentJobs,
				todaysJobs,
				jobsWithUnsavedChanges,
			}));
		})
	);
}, { functional: true, dispatch: true });

export const storeQuickAccessStateEffect = createEffect(() => {
	const actions$ = inject(Actions);
	const store = inject(Store);
	return actions$.pipe(
		ofType(
			QuickAccessActions.toogleQuickAccess,
			QuickAccessActions.loaded,
			JobToolActions.jobLoaded,
		),
		withLatestFrom(store.select(quickAccessFeature['selectQuick AccessState'])),
		tap(([ _, state ]) => storeQuickAccessState(state)),
	);
}, { functional: true, dispatch: false })
