import { AfterContentChecked, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ListProductsOutput, Product } from '@karve.it/interfaces/products';
import {QueryRef} from 'apollo-angular';

import { cloneDeep } from 'lodash';
import { COLORS } from 'src/app/colors';
import { DetailsHelperService } from 'src/app/services/details-helper.service';

import { FreyaMutateService } from 'src/app/services/freya-mutate.service';
import { FreyaNotificationsService } from 'src/app/services/freya-notifications.service';
import {
  BaseQuickbooksItemFragment,
  BaseQuickbooksTaxCodeFragment,
  CompanyInfoGQL,
  CreateProductsGQL,
  CreateProductsMutationVariables,
  FullConfigFragment,
  GetConfigValuesGQL,
  QuickbooksItemsGQL,
  QuickbooksTaxCodesGQL,
  QuickbooksTaxCodeWithSalesTaxListFragment,
  SetConfigValuesGQL,
  UpdateProductsGQL,
} from 'src/generated/graphql.generated';
import { SubSink } from 'subsink';

import { safeParseJSON } from '../../js';

import { ResponsiveHelperService } from '../../services/responsive-helper.service';
import { MutateObjectComponent, MutateObjectElement } from '../mutate-object/mutate-object.component';
import { productCategoriesDropdown } from '../product-categories/product-categories';

@Component({
  selector: 'app-mutate-product',
  templateUrl: './mutate-product.component.html',
  styleUrls: ['./mutate-product.component.scss']
})
export class MutateProductComponent implements OnInit, OnDestroy, AfterContentChecked {

  @ViewChild('mutate') mutateRef: MutateObjectComponent;

  // Template Refs
  @ViewChild('nameAndCategory') nameAndCategoryRef: TemplateRef<any>;
  @ViewChild('active') activeRef: TemplateRef<any>;
  @ViewChild('description') descriptionRef: TemplateRef<any>;
  @ViewChild('color') colorRef: TemplateRef<any>;
  @ViewChild('statementDescription') statementDescriptionRef: TemplateRef<any>;
  @ViewChild('quickbooksProduct') quickbooksProductRef: TemplateRef<any>;
  @ViewChild('quickbooksTaxCode') quickbooksTaxCodeRef: TemplateRef<any>;
  @ViewChild('unitLabel') unitLabelRef: TemplateRef<any>;

  @Input() mutateType: 'update' | 'create';
  @Input() product: Product;

  steps: MutateObjectElement[];
  productCategories = productCategoriesDropdown;
  colorList = COLORS;
  subs = new SubSink();

  productForm = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(80)]),
    category: new UntypedFormControl('General', []),
    active: new UntypedFormControl(true, []),
    color: new UntypedFormControl(undefined, []),
    description: new UntypedFormControl('', []),
    statementDescription: new UntypedFormControl('', []),
    quickbooksProduct: new UntypedFormControl(),
    quickbooksTaxCode: new UntypedFormControl(),
    unitLabel: new UntypedFormControl('unit', [Validators.required, Validators.minLength(1), Validators.maxLength(500)]),
  });

  productFormValues = cloneDeep(this.productForm.value);

  productQueryRef: QueryRef<ListProductsOutput>;

  quickbooksProducts: BaseQuickbooksItemFragment[];

  showTaxCodes = false;

  quickbooksTaxCodes: (QuickbooksTaxCodeWithSalesTaxListFragment & { disabled: boolean })[];

  quickbooksEnabled = false;

  productMap: Record<string, string>;

  taxCodeMap: Record<string, string>;

  constructor(
    private detailsHelper: DetailsHelperService,
    private localNotify: FreyaNotificationsService,
    private cd: ChangeDetectorRef,
    private freyaMutate: FreyaMutateService,
    private createProductGQL: CreateProductsGQL,
    private updateProductGQL: UpdateProductsGQL,
    public responsiveHelper: ResponsiveHelperService,
    private getConfigValuesGQL: GetConfigValuesGQL,
    private setConfigValuesGQL: SetConfigValuesGQL,
    private quickbooksItemsGQL: QuickbooksItemsGQL,
    private quickbooksTaxCodesGQL: QuickbooksTaxCodesGQL,
    private companyInfoGQL: CompanyInfoGQL,
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  ngAfterContentChecked() {
    this.cd.detectChanges();
  }

  mutateObject() {
    if (this.mutateType === 'create') {
      this.createProduct();
    } else if (this.mutateType === 'update') {
      this.editProduct();
    }
  }

  openDialog() {
    this.checkIfQuickbooksEnabled();

    this.steps = [
      {
        name: 'Name and Category', ref: this.nameAndCategoryRef, control: 'name', type: 'func',
        reviewFunc: () => (this.productForm.value.name + ' / ' + this.productForm.value.category || 'None')
      },
      { name: 'Active', ref: this.activeRef, control: 'active', type: 'boolean' },
      { name: 'Description', ref: this.descriptionRef, control: 'description', type: 'text' },
      { name: 'Statement Description', ref: this.statementDescriptionRef, control: 'statementDescription', type: 'text' },
      { name: 'Color', ref: this.colorRef, control: 'color', type: 'text' },
      { name: 'Unit Label', ref: this.unitLabelRef, control: 'unitLabel', type: 'text' },
    ];

    if (this.mutateType === 'create') {
      this.productForm.reset(this.productFormValues);
    } else if (this.mutateType === 'update') {
      this.setFormValues();
    }
    this.mutateRef.steps = this.steps;
    this.mutateRef.openDialog();
  }

  setFormValues() {
    this.productForm.reset({
      name: this.product.name,
      category: this.product.category || 'None',
      active: this.product.active,
      description: this.product.description,
      statementDescription: this.product.statementDescription,
      color: this.product.metadata?.color,
      unitLabel: this.product?.unitLabel || 'unit',
    });
  }

  createProduct() {
    const val = this.productForm.value;

    const category = val.category !== 'None' ? val.category : undefined;

    const createInput = {
      products: [{
        name: val.name,
        category,
        active: val.active,
        description: val.description,
        statementDescription: val.statementDescription,
        metadata: { color: val.color },
        unitLabel: val.unitLabel,
      }]
    } as CreateProductsMutationVariables;

    this.subs.sink = this.createProductGQL.mutate(createInput).subscribe((res) => {

      this.mutateRef.closeDialog();
      this.localNotify.success('Product created');

      this.detailsHelper.pushUpdate({
        id: res.data.createProducts[0].id,
        type: 'Products',
        action: 'create',
      });
      this.detailsHelper.open('product', { id: res.data.createProducts[0].id });
      // Open the create price dialog directly after creating a product
      this.freyaMutate.openMutateObject({
        mutateType: 'create',
        objectType: 'price',
        additionalValues: [{ property: 'product', value: res.data.createProducts[0] }],
      });
    }, (err) => {
      this.mutateRef.loading = false;
      console.error(err);
      this.localNotify.apolloError(`Failed to create product`,err);
    });
  }

  editProduct() {
    const {
      quickbooksProduct,
      quickbooksTaxCode,
      ...val
    } = this.productForm.value;

    this.updateMap('product', quickbooksProduct);

    if (this.showTaxCodes) {
      this.updateMap('taxCode', quickbooksTaxCode);
    }

    const category = val.category !== 'None' ? val.category : '';

    const input = {
      productId: this.product.id,
      category,
      active: val.active,
      description: val.description,
      statementDescription: val.statementDescription,
      name: val.name,
      //Metadata color explicity set empty so it can remove/have no color
      metadata: val.color ? { color: val.color } : { color: '' },
      unitLabel: val.unitLabel,
    };

    this.updateProductGQL.mutate({ products: [input] }).subscribe((res): void => {
      this.product = {
        ...this.product,
        ...val,
      };

      this.detailsHelper.pushUpdate({
        id: this.product.id,
        type: 'Products',
        action: 'update'
      });
      this.mutateRef.closeDialog();
      this.localNotify.addToast.next({ severity: 'success', summary: 'Product updated' });
    }, (err) => {
      this.mutateRef.loading = false;
      this.localNotify.apolloError(`Failed to update product`,err);
    });

  }

  resetSelectedColor() {
    this.productForm.controls.color.setValue(undefined);
  }

  checkIfQuickbooksEnabled() {
    this.getConfigValuesGQL.fetch({
      keys: [
        'quickbooks.enabled',
        'quickbooks.product-map',
        'quickbooks.taxCode-map'
      ],
    }, { fetchPolicy: 'no-cache' }).subscribe((res) => {

      if (res.loading) { return; }

      const quickbooksEnabledConfig = res.data.getConfigValues.find((c) => c.key === 'quickbooks.enabled');

      this.quickbooksEnabled = quickbooksEnabledConfig?.value === 'true';

      if (this.quickbooksEnabled) {
        this.setUpQuickbooks(res.data.getConfigValues);
      }
    });
  }

  setUpQuickbooks(quickbooksConfigs: FullConfigFragment[]) {

      this.retrieveQuickbooksProducts();

      this.steps = [
        ...this.steps,
        {
          name: 'Quickbooks Product',
          ref: this.quickbooksProductRef,
          control: 'quickbooksProduct',
          type: 'func',
          reviewFunc: () => {
            if (!this.productForm.value.quickbooksProduct) {
              return 'None';
            }
            if (!this.quickbooksProducts) {
              return 'Loading...';
            }
            return this.quickbooksProducts.find((a) => a.Id === this.productForm.value.quickbooksProduct)?.Name;
          },
        },
      ];

      const productMapConfig = quickbooksConfigs.find((c) => c.key === 'quickbooks.product-map');

      this.productMap = safeParseJSON(productMapConfig?.value, {});

      const quickbooksProduct = this.productMap[this.product.id];

      if (this.mutateType === 'update') {
        this.productForm.controls.quickbooksProduct.reset(quickbooksProduct);
      }

      this.companyInfoGQL.fetch().subscribe((res) => {
        if (res.data.companyInfo?.Country === 'CA') {
          this.setUpQuickbooksForCanada(quickbooksConfigs);
        }
      });
  }

  setUpQuickbooksForCanada(quickbooksConfigs: FullConfigFragment[]) {

    this.showTaxCodes = true;

    this.steps = [
      ...this.steps,
      {
        name: 'Quickbooks Tax Code',
        ref: this.quickbooksTaxCodeRef,
        control: 'quickbooksTaxCode',
        type: 'func',
        reviewFunc: () => {
          if (!this.productForm.value.quickbooksTaxCode) {
            return 'None';
          }
          if (!this.quickbooksTaxCodes) {
            return 'Loading...';
          }
          return this.quickbooksTaxCodes.find((a) => a.Id === this.productForm.value.quickbooksTaxCode)?.Name;
        }
      }
    ];

    this.retrieveQuickbooksTaxCodes();

    const taxCodeConfig = quickbooksConfigs.find((c) => c.key === 'quickbooks.taxCode-map');

    this.taxCodeMap = safeParseJSON(taxCodeConfig?.value, {});

    const quickbooksTaxCode = this.taxCodeMap[this.product.id];

    if (this.mutateType === 'update') {
      this.productForm.controls.quickbooksTaxCode.reset(quickbooksTaxCode);
    }

  }

  retrieveQuickbooksProducts() {
    this.quickbooksItemsGQL.fetch({ filter: {
      itemTypes: ['Inventory', 'Service'],
    }}).subscribe((res) => {
      this.quickbooksProducts = res.data.quickbooksItems;
    }, (err) => this.localNotify.apolloError('Unable to retrieve Quickbooks products', err));
  }

  retrieveQuickbooksTaxCodes() {
    this.quickbooksTaxCodesGQL.fetch().subscribe((res) => {
      this.quickbooksTaxCodes = res.data.quickbooksTaxCodes.map((tc) => ({
        ...tc,
        disabled: !tc.SalesTaxRateList?.TaxRateDetail?.length,
      }));
    }, (err) => this.localNotify.apolloError('Unable to retrieve Quickbooks tax codes', err));
  }

  updateMap(
    mapType: 'taxCode' | 'product',
    quickbooksProductId: string,
  ) {

    if (!this.quickbooksEnabled) { return; }

    if (!quickbooksProductId) { return; }

    if (!this.product) {
      throw new Error(`Unable to update ${mapType} map: product not found`);
    }

    const map = mapType === 'taxCode' ? this.taxCodeMap : this.productMap;

    if (!map) {
      throw new Error(`Unable to update ${mapType} map: current map not found`);
    }

    const updatedMap = {
      ...map,
      [this.product.id]: quickbooksProductId,
    };

    this.setConfigValuesGQL.mutate({ configs: [{
      key: `quickbooks.${mapType}-map`,
      value: JSON.stringify(updatedMap)
    }]}).subscribe(undefined, (err) => {
      this.localNotify.apolloError(`Unable to update quickbooks ${mapType}`, err);
    });
  }

}

