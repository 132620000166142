import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { NgxGpAutocompleteDirective, NgxGpAutocompleteModule } from '@angular-magic/ngx-gp-autocomplete';
import { Store } from '@ngrx/store';

import { AddLocationType } from 'src/app/global.constants';
import { GoogleHelperService } from 'src/app/services/google-helper.service';
import { SubSink } from 'subsink';

import { FreyaCommonModule } from '../../../freya-common/freya-common.module';
import { FreyaHelperService } from '../../../services/freya-helper.service';
import { showGoogleMapsRouteV2, viewLocationOnGoogleMaps } from '../../../utilities/locations.util';
import { JobToolActions } from '../../job-tool.actions';
import { DetailedLocation, FullJobFragmentWithFields, JobLocations, jobToolFeature } from '../../job-tool.reducer';
import { Jobv2LocationsComponent } from "../../jobv2-create/jobv2-create-locations/jobv2-create-locations.component";
import { JobCreateLocationsActions } from '../../jobv2-create/jobv2-create-locations-state/jobv2-create-locations-state.actions';
import { selectCanChangeArea } from '../../jobv2-edit/jobv2-edit-state/jobv2-edit.selectors';
import { TravelMapComponent } from '../travel-map/travel-map.component';
import { TravelTimeComponent } from '../travel-time/travel-time.component';

@Component({
  selector: 'app-overview-locations',
  standalone: true,
  imports: [
    FreyaCommonModule,
    TravelMapComponent,
    TravelTimeComponent,
    Jobv2LocationsComponent,
    NgxGpAutocompleteModule,
  ],
  templateUrl: './overview-locations.component.html',
  styleUrl: './overview-locations.component.scss'
})
export class OverviewLocationsComponent implements OnInit, OnDestroy {

  @ViewChild('googleAutocomplete') autocompleteRef: NgxGpAutocompleteDirective;

  private job$ = this.store.select(jobToolFeature.selectJob);
  private jobLocations$ = this.store.select(jobToolFeature.selectJobLocations)
  private jobLoaded$ = this.store.select(jobToolFeature.jobLoaded);

  public isDockEditMode$ = this.store.select(jobToolFeature.selectIsDockEditMode);

  public canChangeArea$ = this.store.select(selectCanChangeArea);
  public job: FullJobFragmentWithFields
  public jobLocations: JobLocations;
  public jobLoaded = false;

  public isEditMode = false;

  private subs = new SubSink();
  private editModeSubs = new SubSink();

  constructor(
    private store: Store,
    private freyaHelper: FreyaHelperService,
    public googleHelper: GoogleHelperService,
  ) { }

  ngOnInit(): void {
    this.watchJob();
    this.watchJobLocations();
    this.watchJobLoaded();
  }
  private watchJobLoaded(): void {
    this.subs.sink = this.jobLoaded$.subscribe((loaded) => {
      this.jobLoaded = loaded;
    });
  }

  private watchJob(): void {
    this.subs.sink = this.job$.subscribe((job) => {
      this.job = job;
    });
  }

  private watchJobLocations(): void {
    this.subs.sink = this.jobLocations$.subscribe((jobLocations) => {
      this.jobLocations = jobLocations;
    });
  }

  public openDirections(): void {

    if (!this.jobLocations?.start || !this.jobLocations?.end) {
      return;
    }

    window.open(
      showGoogleMapsRouteV2(
        this.jobLocations?.start?.addressLineOne,
        this.jobLocations?.end?.addressLineOne)
      , '_blank')
  }

  public viewLocationOnGoogleMaps(location: DetailedLocation): void {
    window.open(
      viewLocationOnGoogleMaps(location)
      , '_blank')
  }

  public editLocations(): void {
    this.isEditMode = true;
    this.store.dispatch(JobToolActions.locationDetailsInPlaceEditingActivated());
  }

  public cancelEditLocations(): void {
    this.isEditMode = false;
    this.editModeSubs.unsubscribe();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.editModeSubs.unsubscribe();
  }

  updateJob() {
    this.store.dispatch(JobToolActions.jobUpdateRequested({}));
    const updateSuccess$ = this.store.select(jobToolFeature.updateJobLoaded);

    // Subscribe to the success indicator
    this.editModeSubs.unsubscribe();
    this.editModeSubs.sink = updateSuccess$.subscribe((success) => {
      if (success) {
        this.cancelEditLocations();
      }
      // If not successful, do nothing and let the user adjust as needed
    });
  }

  async changeArea() {
    const res = await this.freyaHelper.openSelectAreaDialog({
      actionRequired: false,
      onlyShowCurrentSubzones: false,
    });

    if (!res) { return; }

    this.store.dispatch(JobCreateLocationsActions.locationSelectAreaManually({
      zone: res,
      requestUpdate: true,
    }));
  }

  editDockClicked() {
    this.store.dispatch(JobToolActions.editDockClicked());
  }

  saveUpdatedDockClicked() {
    this.store.dispatch(JobToolActions.jobUpdateRequested({}));
  }

  discardUpdatingDockClicked() {
    this.store.dispatch(JobToolActions.discardEditingDockClicked());
  }

  handleAddressChange(
    address: google.maps.places.AutocompletePrediction,
    locationType: AddLocationType) {
      if (!this.googleHelper.isValidGooglePlacesAddress(address)) {
        return;
      }

      const location = this.googleHelper.convertGoogleLocationToCreateLocationInput(address);

      this.store.dispatch(JobCreateLocationsActions.locationEntered({
        locationType,
        location: {
            ...location,
            public: false,
        },
    }));
    }

}
