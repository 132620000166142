import { on } from '@ngrx/store';

import { JobDetailsV2Fragment } from 'graphql.generated';
import { cloneDeep, isNil } from 'lodash';
import { LoadingState, generateUUID } from 'src/app/utilities/state.util';

import { CalendarEvent } from '../../../../generated/graphql.generated';
import { JOB_FORM_FIELDS } from '../../../global.constants';
import { InventoryActions } from '../../job-inventory/inventory.actions';
import { JobToolActions } from '../../job-tool.actions';
import { JobToolState } from '../../job-tool.reducer';

import { calculateChargeOffset, setOrderToIndexV2, trackAddingCharges, trackDuplicatingEvents, trackEventsReordering, trackModifyingCharges, trackModifyingUnsavedCharges, trackUpdatingDiscounts, trackUpdatingEventStatus } from '../../jobsv2-charges-helpers';
import { extractFieldsFromResponse, generateJobInputFromLatestChanges, trackChanges } from '../../jobsv2-helpers';
import { ChargesUpdate, DistanceCalculations } from '../../jobv2-create/jobv2-interfaces';

import { WorkOrdersActions } from './workorders.actions';
function handleProductForAddingSelected(
    state: JobToolState,
    res: any,
    chargesUpdates?: ChargesUpdate[],
) {
    chargesUpdates = chargesUpdates || state.chargesUpdates;
    const eventsToAddChanges = chargesUpdates?.filter(
        update => update?.changeType === 'event-added'
    );

    // Used to calculate order only, doesn't affect state
    const allEvents = [...(state?.job?.events || [])];

    if (eventsToAddChanges?.length) {
        const newEvents = eventsToAddChanges.map(c => ({
            id: c.eventId,
            charges: [],
            discounts: [],
            invoices: [],
            ...c.eventInput,
        } as CalendarEvent));

        allEvents.push(...newEvents);
    }

    const eventType = allEvents?.find(event => event.id === res.eventId)?.type;
    const existingEventCharges = allEvents?.find(event => event.id === res.eventId)?.charges?.length;
    const chargesInPending = chargesUpdates?.filter(
        u => u.changeType === 'product-selected-for-adding' && u?.eventId === res?.eventId
    )?.length;

    const indexForChargeNumber = existingEventCharges + chargesInPending + 1;

    const incomingChange: ChargesUpdate = {
        changeType: 'product-selected-for-adding',
        chargeId: generateUUID(),
        productId: res.productId,
        priceId: res?.priceId,
        quantity: res.quantity,
        eventId: res.eventId,
        submitted: false,
        order: calculateChargeOffset(res.eventId, eventType, allEvents) + indexForChargeNumber,
    };

    return trackAddingCharges(chargesUpdates, incomingChange);
}

export function createUpdatedJobState(state: JobToolState, job: JobDetailsV2Fragment, setCallState = false): JobToolState {
    if (!job || job.id !== state.job.id) {

        return setCallState
            ? { ...state, callState: { ...state.callState, updateJob: LoadingState.MUTATED } }
            : state;
    }

    const updatedJob = structuredClone({
        ...state.job,
        ...job,
        fields: extractFieldsFromResponse(job.fields), // TODO: use selectors instead of storing fields in different objects
        distances: job.distances as DistanceCalculations,
    });

    // TODO: Instead of storing comments, documents in different objects, use selectors, so we can avoid this
    const newState = {
        ...state,
        job: updatedJob,
        jobId: job.id,
        // Once ticket: #3552 is resolved, we can uncomment this line
        // comments: sortCommentsWithReplies(job.comments.comments, false),
        totalComments: job.comments.total,
        documents: job.artifacts,
        invoices: job.invoices,
        transactions: job.transactions,
    };

    if (setCallState) {
        newState.callState = { ...newState.callState, updateJob: LoadingState.MUTATED };
    }

    return newState;
}

export const workOrdersReducers = [
    on(WorkOrdersActions.expensesLoaded, (state: JobToolState, { expenses }) => ({
        ...state,
        expenses
    })),
    on(WorkOrdersActions.taxesLoaded, (state: JobToolState, { taxes }) => ({
        ...state,
        taxes
    })),
    on(WorkOrdersActions.availableDiscountsLoading, (state: JobToolState, res): JobToolState => {
        return {
            ...state,
            callState: {
                ...state.callState,
                discounts: LoadingState.LOADING,
            }
        }
    }),
    on(WorkOrdersActions.availableDiscountsLoadedSuccess, (state: JobToolState, res): JobToolState => {
        return {
            ...state,
            availableDiscounts: res.discounts,
            callState: {
                ...state.callState,
                discounts: LoadingState.LOADED,
            }
        }
    }),
    on(WorkOrdersActions.availableDiscountsLoadedError, (state: JobToolState, res): JobToolState => {
        return {
            ...state,
            callState: {
                ...state.callState,
                discounts: {
                    error: res.error.message,
                }
            }
        }
    }),
    on(WorkOrdersActions.productForAddingSelected, (state: JobToolState, res): JobToolState => {

        return {
            ...state,
            chargesUpdates: handleProductForAddingSelected(state, res),
        }
    }),
    on(WorkOrdersActions.productForAddingCancelled, (state: JobToolState, res): JobToolState => {

        return {
            ...state,
            chargesUpdates: state?.chargesUpdates?.filter(update =>
                !(update.submitted === false && update.changeType === 'product-selected-for-adding')
            ),
        }
    }),
    on(WorkOrdersActions.productsForAddingSubmitted, (state: JobToolState, res): JobToolState => {

        const latestChanges = state?.chargesUpdates?.map(update =>
            update.changeType === 'product-selected-for-adding'
                ? { ...update, submitted: true }
                : update
        ) || [];

        return {
            ...state,
            chargesUpdates: latestChanges,
        }
    }),
    on(
        WorkOrdersActions.saveButtonClicked,
        InventoryActions.saveButtonClicked,
        WorkOrdersActions.addDocumentButtonClicked,
        (state: JobToolState, res): JobToolState => {
            return {
                ...state,
                callState: {
                    ...state.callState,
                    updateJob: LoadingState.MUTATING,
                }
            }
        },
    ),
    on(WorkOrdersActions.cancelButtonClicked, (state: JobToolState, res): JobToolState => {
        const eventId = res?.eventId;

        const latestChanges = eventId
            ? state?.chargesUpdates?.filter(c => c?.eventId !== eventId)
            : [];
        return {
            ...state,
            chargesUpdates: latestChanges,
            summaryUpdates: [],
            isSummaryEditMode: false,
        }
    }),
    on(WorkOrdersActions.changesSavedSuccess,
        JobToolActions.updateJobAreaSuccess,
        JobToolActions.updateJobStageSuccess,
        JobToolActions.jobAgentChangeSuccess,
        JobToolActions.jobStateUpdatedSuccess,
        JobToolActions.jobUpdateSuccess,
        (state: JobToolState, { updatedJob }): JobToolState => {

            return createUpdatedJobState(state, updatedJob, true);
        }),
    on(WorkOrdersActions.changesSavedInitiated, (state: JobToolState, { mutationId }): JobToolState => ({
        ...state,
        summaryUpdates: state.summaryUpdates.map((u) => ({
            ...u,
            mutationId,
        })),
        changes: state.changes.map((c) => ({
            ...c,
            mutationId,
        })),
        chargesUpdates: state.chargesUpdates.map((c) => ({
            ...c,
            mutationId,
        })),
    })),
    on(WorkOrdersActions.changesSavedSuccess, (state: JobToolState, res): JobToolState => {
        let summaryUpdates = state.summaryUpdates;
        let changes = state.changes;
        let chargesUpdates = state.chargesUpdates;

        if (res.mutationId) {
            summaryUpdates = summaryUpdates.filter((u) => u.mutationId !== res.mutationId);
            changes = changes.filter((c) => c.mutationId !== res.mutationId);
            chargesUpdates = chargesUpdates.filter((c) => c.mutationId !== res.mutationId);
        }

        return {
            ...state,
            summaryUpdates,
            changes,
            chargesUpdates,
            isSummaryEditMode: Boolean(summaryUpdates.length)
        };
    }),
    on(WorkOrdersActions.changesSavedError, (state: JobToolState, res): JobToolState => {

        const callState = {
            ...state.callState,
            updateJob: {
                error: res.error.message,
            }
        }

        if (!res.mutationId) {
            return {
                ...state,
                callState,
            }
        }

        // If mutation fails, mark relevant changes as no longer in the process of being saved
        const summaryUpdates = [ ...state.summaryUpdates ];

        for (const update of summaryUpdates) {
            if (!update.mutationId || update.mutationId !== res.mutationId) { continue; }
            delete update.mutationId;
        }

        const changes = [ ...state.changes ];

        for (const change of changes) {
            if (!change.mutationId || change.mutationId !== res.mutationId) { continue; }
            delete change.mutationId;
        }

        return {
            ...state,
            callState,
            summaryUpdates,
            changes,
        }
    }),
    on(WorkOrdersActions.changesSavedNoChanges, (state: JobToolState, res): JobToolState => {
        return {
            ...state,
            callState: {
                ...state.callState,
                updateJob: LoadingState.MUTATED,
            }
        }
    }),
    on(WorkOrdersActions.addDiscount, (state: JobToolState, res): JobToolState => {

        let latestChanges = cloneDeep(state?.chargesUpdates);

        for (const discount of res?.discounts) {
            const incomingChange: ChargesUpdate = {
                changeType: 'discount-added',
                discountId: discount?.discountId,
                eventId: discount?.eventId,
                discountInput: {
                    ...(discount.customAmount !== undefined ? { customAmount: discount.customAmount } : {}),
                    //temporary value for ui calculations
                    appliedAt: new Date().toISOString(),
                    //temporary value for ui calculations
                    appliedId: generateUUID(),
                },
            };

            latestChanges = trackUpdatingDiscounts(latestChanges, incomingChange);
        }
        return {
            ...state,
            chargesUpdates: latestChanges,

        }
    }),
    on(WorkOrdersActions.cancelEvent, (state: JobToolState, res): JobToolState => {
        const incomingChange: ChargesUpdate = {
            changeType: 'event-cancelled',
            eventId: res.eventId
        }
        const latestChanges = trackUpdatingEventStatus(state?.chargesUpdates, incomingChange);
        return {
            ...state,
            chargesUpdates: latestChanges,
        }
    }),
    on(WorkOrdersActions.deleteEvent, (state: JobToolState, res): JobToolState => {
        const incomingChange: ChargesUpdate = {
            changeType: 'event-deleted',
            eventId: res.eventId
        }
        const latestChanges = trackUpdatingEventStatus(state?.chargesUpdates, incomingChange);
        return {
            ...state,
            chargesUpdates: latestChanges,
        }
    }),
    on(WorkOrdersActions.createEvent, (state: JobToolState, res): JobToolState => {
        const incomingChange: ChargesUpdate = {
            changeType: 'event-added',
            eventId: generateUUID(),
            eventInput: res.eventInput,
        }
        const latestChanges = trackUpdatingEventStatus(state?.chargesUpdates, incomingChange);
        return {
            ...state,
            chargesUpdates: latestChanges,
        }
    }),
    on(WorkOrdersActions.duplicateEvent, (state: JobToolState, { eventInput }): JobToolState => {

        let latestChanges = cloneDeep(state?.chargesUpdates);

        const eventId = generateUUID();
        const newEvent = {
            ...eventInput,
            id: eventId,
            status: 'required',
            start: null,
            end: null,
            charges: [],
            discounts: [],
        };

        const incomingChange: ChargesUpdate = {
            changeType: 'event-duplicated',
            eventInput: newEvent,
        }

        latestChanges = trackDuplicatingEvents(latestChanges, incomingChange);

        if (eventInput?.charges?.length) {
            const eventsToAddChanges = state?.chargesUpdates?.filter(
                update => update?.changeType === 'event-added');

            //used to calculate order only, doesn't affect state
            const allEvents = cloneDeep(state?.job?.events) || [];

            if (eventsToAddChanges?.length) {
                const newEvents = eventsToAddChanges.map(c => ({
                    id: c.eventId,
                    charges: [],
                    discounts: [],
                    invoices: [],
                    ...c.eventInput,
                } as CalendarEvent));

                allEvents.push(...newEvents);
            }

            for (const charge of eventInput?.charges) {

                const eventType = eventInput?.type;
                const chargesInPending = state?.chargesUpdates?.filter(
                    u => u.changeType === 'product-selected-for-adding' && u?.eventId === eventId)?.length;

                const indexForChargeNumber = chargesInPending + 1;

                const incomingChange: ChargesUpdate = {
                    changeType: 'product-selected-for-adding',
                    chargeId: generateUUID(),
                    productId: charge.product?.id,
                    quantity: charge.quantity,
                    eventId: eventId,
                    submitted: true,
                    order: calculateChargeOffset(eventId, eventType, allEvents) + indexForChargeNumber,
                    taxIds: charge?.taxes?.map(t => t.id),
                }

                latestChanges = trackAddingCharges(
                    latestChanges, incomingChange);
            }
        }

        if (eventInput?.discounts?.length) {
            const filteredDiscounts = eventInput?.discounts?.filter(d => !d?.discount?.attributes?.includes['single-use']);
            for (const discount of filteredDiscounts) {
                const singleUseDiscountInput = {
                    active: true,
                    maxRedemptions: 1,
                    attributes: ['single-use'],
                    amount: discount?.discount?.amount,
                    code: discount?.discount?.code,
                    discountType: discount?.discount?.discountType,
                    name: discount?.discount?.name,
                }
                const incomingChange: ChargesUpdate = {
                    changeType: 'discount-added',
                    discountId: discount?.discount?.id || generateUUID(),
                    eventId: eventId,
                    discountInput: {
                        //temporary value for ui calculations
                        appliedAt: new Date().toISOString(),
                        //temporary value for ui calculations
                        appliedId: generateUUID(),
                        ...(discount?.discount?.attributes?.includes('single-use') ? { singleUse: true } : {}),
                        ...(discount?.discount?.attributes?.includes('single-use') ? { ...singleUseDiscountInput } : {}),
                    },
                };

                latestChanges = trackUpdatingDiscounts(latestChanges, incomingChange);
            }
        }


        return {
            ...state,
            chargesUpdates: latestChanges,
        }
    }),
    on(WorkOrdersActions.removeDiscount, (state: JobToolState, res): JobToolState => {
        const incomingChange: ChargesUpdate = {
            changeType: 'discount-removed',
            discountInput: {
                appliedId: res.appliedId,
            }
        }
        //filter out all discounts that are not saved yet
        const filteredChanges = state?.chargesUpdates?.filter(u => u.discountInput?.appliedId !== res?.appliedId);
        const latestChanges = trackUpdatingDiscounts(filteredChanges, incomingChange)


        return {
            ...state,
            chargesUpdates: latestChanges,
        }
    }),
    on(WorkOrdersActions.createSingleUseDiscount, (state: JobToolState, res): JobToolState => {
        let latestChanges = cloneDeep(state?.chargesUpdates);

        for (const eventId of res.eventsIds) {
            const incomingChange: ChargesUpdate = {
                changeType: 'discount-added',
                //temporary id
                discountId: generateUUID(),
                eventId: eventId,
                discountInput: {
                    //temporary value for ui calculations
                    appliedAt: new Date().toISOString(),
                    //temporary value for ui calculations
                    appliedId: generateUUID(),
                    singleUse: true,
                    ...res.createDiscountInput,
                },
            };

            latestChanges = trackUpdatingDiscounts(latestChanges, incomingChange);
        }
        return {
            ...state,
            chargesUpdates: latestChanges,
        }
    }),
    on(WorkOrdersActions.createCustomCharge, (state: JobToolState, res): JobToolState => {
        const eventType = state?.job?.events?.find(event => event.id === res.createChargeInput?.eventId)?.type;
        const existingEventCharges = state?.job?.events?.find(event => event.id === res.createChargeInput?.eventId)?.charges?.length;
        const chargesInPending = state?.chargesUpdates?.filter(
            u => u.changeType === 'product-selected-for-adding' && u?.eventId === res?.createChargeInput?.eventId)?.length;

        const indexForChargeNumber = existingEventCharges + chargesInPending + 1;

        const incomingChange: ChargesUpdate = {
            changeType: 'product-selected-for-adding',
            chargeId: generateUUID(),
            productId: res.createChargeInput?.productId,
            productName: res?.createChargeInput?.productName,
            amount: res?.createChargeInput?.amount,
            quantity: res.createChargeInput?.quantity,
            eventId: res.createChargeInput?.eventId,
            submitted: true,
            order: calculateChargeOffset(res.createChargeInput?.eventId, eventType, state?.job?.events) + indexForChargeNumber,
            ...(res.createChargeInput?.taxIds !== undefined ? { taxIds: res.createChargeInput?.taxIds } : {}),
        }

        const latestChanges = trackAddingCharges(
            state?.chargesUpdates, incomingChange);

        return {
            ...state,
            chargesUpdates: latestChanges,
        }
    }),
    on(WorkOrdersActions.existingChargesUpdated, (state: JobToolState, res): JobToolState => {

        let latestChanges;

        const matchingCharge = state?.job?.events
            ?.flatMap(event => event.charges || [])
            .find(charge => charge?.id === res?.chargeId);

        if (!isNil(matchingCharge?.createdAt)) {
            const incomingChange: ChargesUpdate = {
                changeType: 'charge-updated',
                chargeId: res.chargeId,
                submitted: false,
                ...(res.eventId !== undefined ? { eventId: res.eventId } : {}),
                ...(res.quantity !== undefined ? { quantity: res.quantity } : {}),
                ...(res.amount !== undefined ? { amount: res.amount } : {}),

            };

            latestChanges = trackModifyingCharges(
                state?.chargesUpdates, incomingChange);
        } else {
            const incomingChange: ChargesUpdate = {
                changeType: 'unsaved-charge-updated',
                chargeId: res.chargeId,
                submitted: false,
                ...(res.eventId !== undefined ? { eventId: res.eventId } : {}),
                ...(res.quantity !== undefined ? { quantity: res.quantity } : {}),
                ...(res.amount !== undefined ? { amount: res.amount } : {}),

            };

            latestChanges = trackModifyingUnsavedCharges(
                state?.chargesUpdates, incomingChange);
        }

        return {
            ...state,
            chargesUpdates: latestChanges,
        }
    }),
    on(WorkOrdersActions.applyUnsavedChangesFromLS, (state: JobToolState, res): JobToolState => {

        let summaryChanges = [];
        const summaryUpdates = res.unsavedSummaryChanges || [];

        // Convert summary updates to changes format
        // Summary updates schema is { key: string, text: string, contents: Delta }
        for (const summaryUpdate of summaryUpdates) {
            summaryChanges = trackChanges(summaryChanges, {
                fieldName: JOB_FORM_FIELDS[summaryUpdate.key],
                namespace: 'jobInput',
                value: {
                    text: summaryUpdate.text,
                    contents: summaryUpdate.contents,
                }
            });
        }

        const { adminSummary, crewSummary, customerSummary } = generateJobInputFromLatestChanges(summaryChanges);

        return {
            ...state,
            chargesUpdates: res.unsavedChanges,
            changes: [ ...state.changes, ...summaryChanges ],
            jobInput: {
                ...state.jobInput,
                customerSummary,
                crewSummary,
                adminSummary,
            },
            summaryUpdates,
            isSummaryEditMode: summaryUpdates?.length > 0,
        }
    }),
    on(WorkOrdersActions.updateChargesOrder, (state: JobToolState,
        { targetEvent, insertedCharge, insertedChargeIndex, isInsertedChargeNew, donorEvent }) => {

        const chargesToUpdate = isInsertedChargeNew
            ? [...targetEvent.charges]
            : targetEvent.charges.filter(charge => charge !== insertedCharge);

        chargesToUpdate.splice(insertedChargeIndex, 0, insertedCharge);

        const offset = calculateChargeOffset(targetEvent.id, targetEvent.type, state.job?.events);

        const chargesWithOrder = setOrderToIndexV2(chargesToUpdate, offset);

        let latestChanges = cloneDeep(state?.chargesUpdates);

        for (const charge of chargesWithOrder) {
            const incomingChange: ChargesUpdate = {
                changeType: !isNil(charge?.createdAt) ? 'charge-updated' : 'unsaved-charge-updated',
                submitted: false,
                chargeId: charge.id,
                eventId: targetEvent?.id,
                order: charge.order,
                eventType: targetEvent.type,
            };

            latestChanges = !isNil(charge?.createdAt)
                ? trackModifyingCharges(latestChanges, incomingChange)
                : trackModifyingUnsavedCharges(latestChanges, incomingChange)
        }

        return {
            ...state,
            chargesUpdates: latestChanges,
        };
    }),
    on(WorkOrdersActions.removeCharge, (state: JobToolState, res): JobToolState => {

        const incomingChange: ChargesUpdate = {
            changeType: 'charge-updated',
            chargeId: res?.chargeId,
            removed: true,
        }

        //filter out all changes for charges that are not saved yet
        const filteredChanges = state?.chargesUpdates?.filter(u => u.chargeId !== res?.chargeId);
        const latestChanges = trackModifyingCharges(filteredChanges, incomingChange)

        return {
            ...state,
            chargesUpdates: latestChanges,
        }
    }),
    on(WorkOrdersActions.reorderEvents, (state: JobToolState, res): JobToolState => {

        const latestChange: ChargesUpdate = {
            changeType: 'events-reordered',
            submitted: false,
            eventsWithNewOrder: res.eventsWithNewOrder
        }

        const latestChanges = trackEventsReordering(
            state?.chargesUpdates, latestChange);

        return {
            ...state,
            chargesUpdates: latestChanges,
        }
    }),
    on(WorkOrdersActions.generateUnsavedChangesToasts, (state: JobToolState, res): JobToolState => {
        return {
            ...state,
            unsavedChangesReminders: res.unsavedChangesToastsInfo,
        }
    }),
    on(WorkOrdersActions.promoteEventSuccess, (state: JobToolState, payload): JobToolState => {
        const currentEventIndex = state.job.events.findIndex((e) => e.id === payload.eventId);

        if (currentEventIndex < 0) { return state };

        const currentEvent = state.job.events[currentEventIndex];

        const updatedEvent = {
            ...currentEvent,
            status: payload.status,
        };

        const updatedEvents = [ ...state.job.events ];

        updatedEvents[currentEventIndex] = updatedEvent;

        const updatedJob = {
            ...state.job,
            events: updatedEvents,
        };

        return {
            ...state,
            job: updatedJob,
        };
    }),

    on(WorkOrdersActions.generateChargesWithAI, WorkOrdersActions.regenerateChargesWithAI, (state: JobToolState, res): JobToolState => ({
        ...state,
        callState: {
            ...state.callState,
            generateChargesWithAI: LoadingState.LOADING,
        }
    })),
    
    on(WorkOrdersActions.openChargesGeneratedWithAISummary, (state: JobToolState, res): JobToolState => ({
        ...state,
        callState: {
            ...state.callState,
            generateChargesWithAI: LoadingState.LOADED,
        }
    })),

    on(WorkOrdersActions.generateChargesWithAISuccess, (
        state: JobToolState,
        { eventId, selectedProducts, agentInstructions, itinerary }
    ): JobToolState => {

        // let stateCopy = { ...state };
        // const jobZone = state.job.zone.type === 'franchise' ? state.job.zone.id : state.job.zone.parent.id;

        const eventCharges = state.job.events.find(event => event.id === eventId)?.charges || [];

        let chargesUpdates = cloneDeep(state.chargesUpdates);
        // debugger;
        // console.log(eventCharges);

        // Remove existing charges for the event - WorkOrdersActions.removeCharge
        for (const charge of eventCharges) {
            // filter out all changes for charges that are not saved yet
            // const filteredChanges = stateCopy?.chargesUpdates?.filter(u => u.chargeId !== charge.id);
            chargesUpdates = trackModifyingCharges(chargesUpdates, {
                changeType: 'charge-updated',
                chargeId: charge.id,
                removed: true,
            });

        };

        // Add new charges generated by AI - WorkOrdersActions.productForAddingSelected
        for (const product of selectedProducts) {

            // const { metadata, prices, zones, ...productWithoutMetadata } = product;
            // const selectedProduct = selectedProducts.find(p => p.id === product.id);
            // const activePrice = prices.find(p => p.zone.id === jobZone);

            const res = {
                productId: product?.id,
                priceId: product?.priceId,
                quantity: product.quantity,
                // add reason here
                eventId,
            }

            chargesUpdates = handleProductForAddingSelected(state, res, chargesUpdates);
        }

        // Finalize the changes - WorkOrdersActions.productsForAddingSubmitted
        chargesUpdates = chargesUpdates?.map(update =>
            update.changeType === 'product-selected-for-adding'
                ? { ...update, submitted: true }
                : update
        ) || [];

        return {
            ...state,
            itinerary,
            chargesUpdates,
            selectedProducts,
            agentInstructions,
            callState: {
                ...state.callState,
                generateChargesWithAI: LoadingState.LOADED,
            }
        };
    }),

    on(WorkOrdersActions.generateChargesWithAIError, (
        state: JobToolState,
        { error }
    ): JobToolState => {
        return {
            ...state,

            callState: {
                ...state.callState,
                generateChargesWithAI: {
                    error,
                },
            }
        };
    }),
] as const;
