import { createFeature, createReducer, on } from '@ngrx/store';

import { uniqBy } from 'lodash';

import { environment } from '../../../../environments/environment';
import { JobToolActions } from '../../../jobsv2/job-tool.actions';
import { safeParseJSON } from '../../../js';
import { CallState, LoadingState } from '../../../utilities/state.util';

import { QuickAccessActions } from './quick-access.actions';
import { parseQuickAccessJob, QuickAccessJob, updateRecentJobs } from './quick-access.util';

export const quickAccessInitialState = {

	pinnedJobs: [] as QuickAccessJob[],
	recentJobs: [] as QuickAccessJob[],
	todaysJobs: [] as QuickAccessJob[],

	jobsWithUnsavedChanges: [] as string[],

	loading: LoadingState.INIT as CallState,
	expanded: true as boolean,

} as const;

export type QuickAccessState = typeof quickAccessInitialState;

export const quickAccessFeature = createFeature({
	name: 'Quick Access',
	reducer: createReducer(
		quickAccessInitialState,
		on(QuickAccessActions.toogleQuickAccess, (state, props): QuickAccessState => {

			return {
				...state,
				expanded: !state.expanded,
			};
		}),
		on(QuickAccessActions.initialized, (state, {  }): QuickAccessState => {
			
			const existingState: QuickAccessState = safeParseJSON(localStorage.getItem(environment.lskeys.quickAccessState), undefined);

			return {
				...state,
				pinnedJobs: [],
				recentJobs: [],
				todaysJobs: [],
				expanded: true,
				...existingState,

				loading: LoadingState.LOADING,
			}
		}),
		on(QuickAccessActions.loaded, (state, props): QuickAccessState => {
			
			return {
				...state,
				...props,
				loading: LoadingState.LOADED,
			};
		}),
		on(
			JobToolActions.jobLoaded,
			QuickAccessActions.jobUpdated,
			(state, props): QuickAccessState => {

			// maybe we need comments, fields, totalComments too

			const job = parseQuickAccessJob({
				...props.job,
			}, props.fields, props.comments, props.totalComments);

			const recentJobs = updateRecentJobs<QuickAccessJob>([
				...state.recentJobs,
			], job);

			// TODO: remove similar jobs from here
			return {
				...state,
				recentJobs,
			}
		}),
		// on(QuickAccessActions.jobPinned, (state, props): QuickAccessState => {


		// 	const job = parseQuickAccessJob({
		// 		...props.job,
		// 	}, props.fields, props.comments, props.totalComments);

		// 	const pinnedJobs =  uniqBy([
		// 		job,
		// 		...state.recentJobs,
		// 	], (j) => j.id).slice(0, 3);
			
		// 	const newState: QuickAccessState = {
		// 		...state,
		// 		pinnedJobs,
		// 	};
			
		// 	localStorage.setItem(environment.lskeys.quickAccessState, JSON.stringify(newState));
			
		// 	return newState;
		// }),
	)
})
